<template>
  <BInputLabel
    :label="label"
    v-model="value"
    :icon="getIcon"
    @input="handleSearch"
    :handler="clearInput"
  />
</template>

<script>
export default {
  name: 'BInputSearch',
  props: {
    delay: {
      default: 300,
    },
    withIcon: {
      default: true,
    },
    icon: {
      default: 'icon-local-search',
    },
    label: {
      type: String,
    },
    disabled: {
      default: false,
    },
    minLength: {
      default: 0,
    },
  },
  data() {
    return {
      value: '',
    };
  },
  computed: {
    getIcon() {
      return (this.value.length && 'icon-local-times') || this.icon;
    },
  },
  methods: {
    handleSearch(value) {
      clearTimeout(this.deBounce);
      if (this.value.length >= this.minLength) {
        this.deBounce = setTimeout(async () => {
          this.$emit('input', value);
        }, this.delay);
      } else {
        this.$emit('input', '');
      }
    },
    clearInput() {
      console.log('clearInput');
      clearTimeout(this.deBounce);
      setTimeout(() => {
        if (!this.selectedItem) {
          this.selectedItem = '';
          this.request = '';
        }
      }, 200);
      this.$emit('input', '');
    },
    reset() {
      this.request = '';
    },
  },
};
</script>
