<template>
  <div>
    <b-modal
      centered
      id="guest-invite-modal"
      ref="refModal"
      modal-class="modal-with-b-form-row"
      @ok="onOk"
      :ok-title="$t('apartment.GeoUnitsUsersPortlet.invite')"
      :title="$t('apartment.guest_invite_modal.invite_guest')"
      @keydown.native.enter="onOk"
    >
      <div class="modal-body-row with-cols">
        <b-form-row>
          <b-col cols="12">
            <BInputLabel
              id="input-key-set-modal_name"
              :label="$t('global.phone_number')"
              autocomplete="off"
              v-model="invite.phone"
              :invalid-feedback="errors.phone"
              @keydown.native.enter="onOk"
            />
          </b-col>
        </b-form-row>
      </div>
    </b-modal>
    <div
      class="btn btn-outline-primary btn-sm"
      @click="$bvModal.show('guest-invite-modal')"
    >
      {{ $t('apartment.GeoUnitsUsersPortlet.invite') }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'GuestInviteModal',
  props: {
    geoUnitId: {
      geoUnitId: Number,
      required: true,
    },
  },
  data() {
    return {
      invite: {},
      errors: [],
    };
  },
  methods: {
    onOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      request2(
        {
          method: 'post',
          url: `/api/backend/personal/geo_units/${this.geoUnitId}/guest_invites`,
        },
        {
          guest_invite: Object.assign({}, this.invite, {
            geo_unit_id: this.geoUnitId,
          }),
        },
      )
        .then((res) => {
          this.$bvModal.hide('guest-invite-modal')
          location.reload();
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
  },
});
</script>

<style lang="scss" scoped></style>
