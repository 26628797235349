<template>
  <b-modal id="image-modal" ref="modal" footer-class="d-none" size="xl">
    <template #modal-header="{ close }">
      <slot name="modal-custom-title">
        {{ title }}
      </slot>
      <button class="close" @click="close()">×</button>
    </template>
    <img
      :src="url"
      class="my-0 mx-auto p-4 d-flex justify-content-center image-modal__content"
    />
    <template #modal-footer></template>
  </b-modal>
</template>
<script>
import I18n from '@app/i18n';

export default {
  name: 'ImageModal',
  props: {
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: I18n.t('image'),
    },
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
  },
};
</script>
<style lang="sass">
.image-modal {
  &__content {
    max-width: 100%;
    height: auto;
  }
}

.modal-title {
  font-size: 16px !important;
}
</style>
