<template>
  <div>
    <span v-for="(locale, key) in locales" :id="locale.id" @click="localeSelection" :key="key" style="cursor: pointer; margin-left: 2px; margin-right: 2px" :class="{activeButton: activeLocale(locale.id), passiveButton: passiveLocale(locale.id)}">
      {{ locale.name }}
    </span>
  </div>
</template>

<script>
import i18n from "@app/i18n";
export default {
  name: 'LocaleSelection',
  props: {
    locales: {
      type: Array,
      default: [
        {
          id: 'ru',
          name: 'Ru',
        },
        {
          id: 'en',
          name: 'En',
        }
      ]
    }
  },
  methods: {
    activeLocale(id) {
      return id == this.locale;
    },
    passiveLocale(id) {
      return id != this.locale;
    },
    browserLocale() {
      return window.navigator.language.split('-')[0] == 'ru' ? 'ru' : 'en';
    },
    localeSelection(event) {
      let locale = event.target.id;
      this.$cookies.set('locale', locale);
      i18n.locale = locale;
      this.locale = locale;
    }
  },
  mounted() {
    this.locale = this.$cookies.get('locale') || this.browserLocale();
    if (!this.$cookies.get('locale')) {
      this.$cookies.set('locale', this.locale);
      i18n.locale = this.locale;
    }
  },
  data() {
    return {
      locale: {
        type: String,
        default: this.locales[0],
      }
    }
  }
};
</script>
<style>
  .activeButton {
    color: #03A9F4;
    font-weight: bolder;
  }
  .passiveButton {
    color: #B4C3CD;
  }
</style>
