<template>
  <div>
    <b-modal
      id="camera-fast-modal"
      class="fast-modal"
      ref="uuidSelect"
      :title="$t('devices.modal.create')"
      size="sm"
      no-stacking
    >
      <div class="modal-body-row with-cols pt-30">
        <b-form-row>
          <b-col cols="12">
            <devices-autocomplete
              :clientId="clientId"
              v-model="camera.uuid_or_serial"
              @select:item="selectDevice"
              :placeholder="$t('devices.modal.uuid_or_serial')"
              :url="urlAutocomplete"
              type-device="camera"
            />
            <span id="camera-uuid-info" class="fast-modal__hint">
              {{ $t('devices.modal.uuid_hint') }}
            </span>
            <b-popover
              target="camera-uuid-info"
              triggers="hover"
              placement="bottom"
            >
              <p>
                {{ $t('devices.modal.hint_text') }}
                <a :href="platform_support">
                  {{ $t('devices.modal.hint_link') }}
                </a>
              </p>
            </b-popover>
          </b-col>
          <b-button
            @click="openCameraSelectionModal"
            variant="primary"
            size="sm"
            class="fast-modal__btn w-auto h-auto mx-auto"
          >
            {{ $t('devices.modal.model_selection') }}
          </b-button>
        </b-form-row>
      </div>
      <template #modal-footer>
        <div
          class="d-flex align-items-center justify-content-between flex-grow-1"
        >
          <b-button
            size="sm"
            variant="outline-primary"
            class="fast-modal__btn w-auto h-auto"
            @click="hide"
          >
            {{ $t('devices.modal.cancel') }}
          </b-button>
          <b-button
            @click="openReadSelectedModal"
            variant="primary"
            size="sm"
            class="fast-modal__btn w-auto h-auto"
            :disabled="!camera.uuid"
          >
            {{ $t('devices.modal.next') }}
          </b-button>
        </div>
      </template>
    </b-modal>

    <select-camera-model-modal
      ref="SelectCameraModel"
      id="camera-select-model"
      :clientId="clientId"
    />

    <read-selected-modal
      :clientId="clientId"
      ref="ReadSelectedModalCamera"
      id="camera-read-selected"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import ReadSelectedModal from '../fastAdd/ReadSelectedModal.vue';
import DevicesAutocomplete from '@/components/autocompletes/DevicesAutocomplete.vue';
import SelectCameraModelModal from '../modelSelection/SelectCameraModelModal.vue';
import { API_URLS } from '@/consts';

export default {
  name: 'UuidSelectModal',
  components: {
    ReadSelectedModal,
    DevicesAutocomplete,
    SelectCameraModelModal,
  },
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      geoUnit: null,
    };
  },
  computed: {
    ...mapState('camera', ['camera']),
    ...mapGetters('camera', ['getCamera']),
    ...mapState('global', ['global']),
    ...mapGetters('global', ['getPlatformSupport']),

    camera: {
      get() {
        return this.getCamera;
      },
      set(value) {
        this.setCamera(value);
      },
    },
    platform_support() {
      return this.getPlatformSupport;
    },
    urlAutocomplete() {
      return API_URLS.backendManage.cameras;
    },
  },
  methods: {
    ...mapMutations('camera', ['setCamera', 'resetCamera']),

    show(reset = true, geoUnit = null) {
      if (reset) {
        this.resetCamera();
      }

      if (geoUnit) {
        this.selectGeoUnit(geoUnit);
      }

      this.$refs.uuidSelect.show();
    },
    hide() {
      this.$refs.uuidSelect.hide();
    },
    selectGeoUnit(geoUnit) {
      this.setCamera(
        Object.assign({}, this.camera, {
          geo_unit_id: geoUnit.id,
          geo_unit: geoUnit,
        }),
      );
    },
    selectDevice(device) {
      this.setCamera(Object.assign({}, device));
      this.selectGeoUnit(this.geoUnit);
    },
    openCameraSelectionModal() {
      this.$refs.SelectCameraModel.show();
    },
    openReadSelectedModal() {
      this.$refs.ReadSelectedModalCamera.show();
    },
  },
};
</script>

<style></style>
