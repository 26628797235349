<template>
  <div>
    <div v-if="data.kind != 'electro'">
      <bar-chart-custom
        :chart-data="chartdata"
        :tooltips="tooltips"
        :kind="labelText"
      ></bar-chart-custom>
    </div>
    <div v-if="data.kind == 'electro'">
      <line-chart-custom
        :chart-data="chartdata"
        :tooltips="electroTooltips"
        :kind="labelText"
      ></line-chart-custom>
    </div>
  </div>
</template>

<script>
import BarChartCustom from '@/components/charts/BarChartCustom.js';
import LineChartCustom from '@/components/charts/LineChartCustom.js';

export default {
  name: 'StaticConsumptionChart',
  components: {
    BarChartCustom,
    LineChartCustom,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      chartdata: {},
      tooltips: {
        callbacks: {
          label: (tooltipItem, chart) =>
            chart.datasets[0].data[tooltipItem.index],
        },
      },
      electroTooltips: {},
    };
  },
  computed: {
    labelText() {
      switch (this.data.kind) {
        case 'cold':
        case 'hot':
          return this.$t("meters.consumption_m_cube");
        case 'electro':
          return this.$t("meters.consumption_kWh");
        case 'heat':
          return this.$t("meters.energy_consumed_for_heating_Gcal");
        default:
          return this.$t("meters.consumption_m_cube");
      }
    },
  },
  mounted() {
    this.chartdata = {
      labels: this.data.labels,
      datasets:
        this.data.kind == 'electro'
          ? this.electroDatasets()
          : this.waterDatasets(),
    };
  },
  methods: {
    waterDatasets() {
      return [
        {
          barPercentage: 1.0,
          categoryPercentage: this.catPercentage(),
          label: this.data.serial,
          backgroundColor: this.labelColor(),
          data: this.data.data,
        },
      ];
    },
    electroDatasets() {
      const lines = [];
      Object.entries(this.data.data).forEach((t) =>
        lines.push({
          label: t[0],
          backgroundColor: this.tariffColor(t[0]),
          borderColor: this.tariffColor(t[0]),
          fill: false,
          data: Object.values(t[1]),
        }),
      );
      return lines;
    },
    labelColor() {
      switch (this.data.kind) {
        case 'cold':
          return '#03A9F4';
        case 'hot':
          return '#FF4D4D';
        case 'heat':
          return '#F7981C';
      }
    },
    tariffColor(t) {
      switch (t) {
        case 'T1':
          return '#75AD5E';
        case 'T2':
          return '#53C1FF';
        case 'T3':
          return '#6F3FFF';
        case 'T4':
          return '#8B93A6';
        default:
          return '#19233C';
      }
    },
    catPercentage() {
      return this.data.labels.count > 12 ? 0.7 : 0.4;
    },
  },
};
</script>

<style lang="scss"></style>
