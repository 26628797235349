<template>
  <BHintPopover
    :hintId="id_rand"
    :placement="placement"
    :hint="hint"
    :popoverText="popoverText"
  >
    <template #popover-hint>
      <div v-for="(user, index) in users" :key="index">
        <a :href="`/admin/users/${user.id}`">
          {{ user.select_user_field }}
        </a>
      </div>
    </template>
  </BHintPopover>
</template>

<script>
import BHintPopover from '@/components/base/BHintPopover';

export default {
  name: 'BPopoverGuest',
  components: { BHintPopover },
  props: {
    popoverText: {
      type: String,
      default: 'Guests',
    },
    users: {
      type: Array,
      default: [],
    },
    placement: {
      type: String,
      default: 'bottom',
    },
    hint: { type: Boolean, default: false },
  },
  data() {
    return {
      id_rand: '',
    };
  },
  created() {
    this.id_rand = `id_rand${Math.random()}`;
  },
};
</script>

<style></style>
