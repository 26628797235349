import Vue from 'vue';

const state = {
  device: {
    area: {},
    kind: {},
    pipe_identifier: null,
    model: {},
    serial: null,
    issued_on: null,
    installed_on: null,
    start_value: null,
    geo_unit: {},
    is_calc: null,
    photo: null,
    photo_url: null,
    pulse_counters_model_id: null,
    uspd_device_id: null,
    channels: [],

    uspd_device: {},
  },
  photo_url: null,
};

const getEmptyChannel = () => ({
  id: null,
  meter: {},
  index: null,
});

const getters = {
  getDevice(state) {
    return state.device;
  },
};

const mutations = {
  setDevice(state, payload) {
    state.device = payload;
  },
  setArea(state, payload) {
    state.device.area = payload;
  },
  addChannel(state) {
    if (!Array.isArray(state.device.channels)) {
      Vue.set(state.device, 'channels', new Array());
    }
    state.device.channels.push(getEmptyChannel());
  },
  resetChannels(state) {
    const defaultChannels = new Array();
    defaultChannels.push(getEmptyChannel());
    Vue.set(state.device, 'channels', defaultChannels);
  },
  removeChannel(state, payload) {
    if (payload.id) {
      let channel = state.device.channels[payload.index];
      channel._destroy = true;
      state.device.channels.splice(payload.index, 1, channel);
    } else {
      state.device.channels.splice(payload.index, 1);
    }

    const appliedChannels = state.device.channels.filter(
      (ch) => ch._destroy !== true,
    );

    if (appliedChannels.length == 0) {
      state.device.channels.push(getEmptyChannel());
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
