import { cloneDeep } from 'lodash';
import {
  GeoUnitPrettify,
  MarkUnitDeleted,
  FindMaxId,
  CheckMetaStructure,
} from '@app/components/building_edit/helpers/helpers';
import {
  addMoveListeners,
  removeMoveListeners,
  removeDummy,
} from '@app/components/building_edit/helpers/addHepler';
import { generateBuilding } from '@/vue_app/components/building_edit/helpers/generateBuildingHelper';
import { ApiGeoUnits } from '@/api';

function getInitialState() {
  return {
    building: {},
    selectedEntrance: '',
    selectedEntranceIndex: '',
    selectedUnits: [],
    showContext: false,
    contextPosition: { top: 0, left: 0 },
    clickedUnit: false,
    maxUnitId: 0,
    addType: false,
    editingUnit: false,
    showBuildingLevel: false,
    currentMode: '',
    initiallyEmptyBuilding: false,
    rulesWereUpdated: false,
    copiedUnits: [],
    lastAppliedSettings: {},
    childrenChanged: false,
  };
}

const state = {
  building: {},
  selectedEntrance: '',
  selectedEntranceIndex: '',
  selectedUnits: [],
  showContext: false,
  contextPosition: { top: 0, left: 0 },
  clickedUnit: false,
  maxUnitId: 0,
  addType: false,
  editingUnit: false,
  showBuildingLevel: false,
  currentMode: '',
  initiallyEmptyBuilding: false,
  rulesWereUpdated: false,
  copiedUnits: [],
  lastAppliedSettings: {},
  childrenChanged: false,
};

const getters = {
  entrancesNumber(state) {
    if (!state.building.children || !state.building.children.length) return 0;
    return state.building.children.filter(
      ({ type, _destroy }) => type === 'Entrance' && !_destroy,
    ).length;
  },
  roofInBuilding(state) {
    if (!state.building.children) return false;
    return state.building.children.find(
      ({ type, _destroy }) => type === 'Roof' && !_destroy,
    );
  },
  hasRoofInEntrance(state) {
    const entrances = state.building.children.filter(
      ({ type, _destroy }) => type === 'Entrance' && !_destroy,
    );
    if (!entrances.length) return false;

    const roofs = entrances.map((entrance) =>
      entrance.children.some(
        ({ type, _destroy }) => type === 'Roof' && !_destroy,
      ),
    );
    return roofs.some((value) => value);
  },
  hasParkingInEntrance(state) {
    if (!state.selectedEntrance || !state.selectedEntrance.children) {
      return false;
    }
    return state.selectedEntrance.children.some(
      ({ type, _destroy }) => type === 'ParkingArea' && !_destroy,
    );
  },
  hasParkingInBuilding(state) {
    if (!state.building || !state.building.children) return false;
    return state.building.children.some(
      ({ type, _destroy }) => type === 'ParkingArea' && !_destroy,
    );
  },
  hasStructure(state) {
    if (!state.building.children) return false;
    return state.building.children.some(({ _destroy }) => !_destroy);
  },
};

const mutations = {
  setBuilding(state, payload) {
    state.building = payload;
  },
  setSelectedEntrance(state, payload) {
    state.selectedEntrance = payload;
    state.selectedEntranceIndex = payload;
  },
  setSelectedEntranceIndex(state, payload) {
    state.selectedEntranceIndex = payload;
  },
  selectUnit(state, payload) {
    if (state.selectedUnits.includes(payload)) {
      state.selectedUnits = state.selectedUnits.filter((el) => {
        return el.id !== payload.id;
      });
      return;
    }

    state.selectedUnits = state.selectedUnits.filter(
      ({ id, parentId, parent }) => {
        return (
          id !== payload.parentId &&
          id !== payload.parent.parentId &&
          parent.parentId !== payload.id &&
          parentId !== payload.id
        );
      },
    );
    state.selectedUnits.push(payload);
  },
  setUnitsDeleted(state) {
    MarkUnitDeleted(state.selectedUnits);
    state.selectedUnits = [];

    if (!getters.hasStructure(state)) state.currentMode = 'Settings';
  },
  setClickedUnit(state, payload) {
    state.clickedUnit = payload;
  },
  setContextParams(state, { position, open }) {
    state.contextPosition = position;
    state.showContext = open;
  },
  clearSelectedUnits(state) {
    state.selectedUnits = [];
  },
  setMaxUnitId(state, payload = null) {
    if (payload) state.maxUnitId = payload;
    else state.maxUnitId = FindMaxId(state.building);
  },
  setAddType(state, payload) {
    if (state.editingUnit) return;
    state.addType = payload;
    if (payload) {
      addMoveListeners();
    } else {
      removeMoveListeners();
    }
    removeDummy();
  },
  changeEditingUnit(state, payload) {
    if (state.editingUnit) {
      state.editingUnit.editing = false;
    }
    state.editingUnit = payload;
  },
  toggleBuildingLevel(state) {
    state.showBuildingLevel = !state.showBuildingLevel;
  },
  setCopiedUnits(state, payload) {
    state.copiedUnits = payload || [];
  },
  resetState(state) {
    Object.assign(state, getInitialState());
    removeMoveListeners();
  },
  setCurrentMode(state, payload) {
    state.currentMode = payload;
    if (state.editingUnit) {
      state.editingUnit.editing = false;
      state.editingUnit = false;
    }
    state.selectedUnits = [];
  },
  setInitiallyEmptyBuilding(state, payload) {
    state.initiallyEmptyBuilding = payload;
  },
  setRulesUpdated(state) {
    state.rulesWereUpdated = true;
  },
  setLastAppliedSettings(state) {
    state.lastAppliedSettings = cloneDeep(state.building.meta.structure);
  },
  resetSettings(state) {
    state.building.meta.structure = cloneDeep(state.lastAppliedSettings);
  },
};

const actions = {
  async actionInit({ commit, dispatch, getters: localGetters }, building_id) {
    return new Promise((resolve, reject) => {
      ApiGeoUnits.getOne(building_id, {
        includes: 'children_geo_units_chain',
      })
        .then((response) => {
          if (response.type !== 'Building') {
            reject(new Error('Wrong geo unit type'));
            return;
          }

          CheckMetaStructure(response);
          commit('setBuilding', GeoUnitPrettify(response, response.parent_id));
          commit('setLastAppliedSettings');
          dispatch('actionSelectEntrance', 0);
          commit('setMaxUnitId');

          let newMode = 'Settings';
          if (response.children.length) newMode = 'Building';
          if (localGetters.entrancesNumber) newMode = 'Entrance';
          commit('setCurrentMode', newMode);

          if (!response.children.length) {
            commit('setInitiallyEmptyBuilding', true);
          }
          resolve({
            rebuild_structure_is_blocked: response.rebuild_structure_is_blocked,
          });
        })
        .catch((err) => {
          reject(new Error(err));
        });
    });
  },
  actionInitPreMade({ commit, dispatch }, building) {
    commit('setBuilding', building);
    commit('setCurrentMode', 'Entrance');
    dispatch('actionSelectEntrance', 0);
    commit('setMaxUnitId');
  },
  actionSelectEntrance({ commit, getters: { entrancesNumber } }, payload) {
    if (payload < 0 || payload > entrancesNumber - 1) {
      return;
    }
    if (!state.building.children.length) return;
    const entrances = state.building.children.filter(
      ({ type, _destroy }) => type === 'Entrance' && !_destroy,
    );
    commit('setSelectedEntrance', entrances[payload]);
    commit('setSelectedEntranceIndex', payload);
    commit('clearSelectedUnits');
  },
  actionSelectUnit({ commit, state }, unit) {
    commit('selectUnit', unit);
    if (state.editingUnit) {
      commit('changeEditingUnit', false);
    }
  },
  actionOnOpenContext({ commit, dispatch }, { event, unit }) {
    dispatch('actionOnCloseContext');
    process.nextTick(() => {
      const { innerHeight, innerWidth } = window;
      const { pageX, pageY } = event;
      const xPositionSide = pageX + 150 < innerWidth ? 'left' : 'right';
      const yPositionSide = pageY + 400 < innerHeight ? 'top' : 'bottom';
      const xPosition =
        xPositionSide === 'left' ? `${pageX}px` : `${innerWidth - pageX}px`;
      const yPosition =
        yPositionSide === 'top' ? `${pageY}px` : `${innerHeight - pageY}px`;
      commit('setClickedUnit', unit);
      commit('setContextParams', {
        position: { [xPositionSide]: xPosition, [yPositionSide]: yPosition },
        open: true,
      });
    });
  },
  actionOnCloseContext({ commit }) {
    commit('setClickedUnit', false);
    commit('setContextParams', { position: { top: 0, left: 0 }, open: false });
  },
  actionApplySettings({ commit }) {
    commit('setLastAppliedSettings');
    generateBuilding();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
