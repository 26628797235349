<template>
  <b-form-group
    :label-class="labelClass"
    v-bind="$attrs"
    :label="label"
    :data-title="label"
  >
    <slot ref="slot"></slot>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';

export default {
  name: 'BFormGroupSlot',
  components: {
    BFormGroup,
  },
  props: {
    value: {},
    hasError: Boolean,
    label: String,
  },
  data() {
    return {
      isValue: false,
      focus: false,
      input: '',
    };
  },
  computed: {
    labelClass() {
      return this.isValue || this.focus
        ? 'float-label-active'
        : 'float-label-inactive';
    },
  },
  mounted() {
    let input = this.$el.querySelector('input');
    input ||= this.$el.querySelector('textarea');
    if (input) {
      this.input = input;

      this.input.classList.add('float-input', 'form-control');
      this.isValue = !!this.input.value;

      this.input.onfocus = (event) => {
        this.focus = true;
        this.$emit('focus', event.target.value);
      };

      this.input.oninput = (event) => {
        this.$emit('input', event.target.value);
      };

      this.input.onblur = (event) => {
        this.isValue = !!event.target.value;
        this.focus = false;
        this.$emit('blur', event.target.value);
      };
    } else {
      const select = this.$el.querySelector('select');
      if (select) {
        this.isValue = true;
      }
    }
  },
  methods: {
    setInputValue(val) {
      this.input.value = val;
      this.isValue = !!String(val);
    },
  },
  watch: {
    value(val) {
      this.setInputValue(val);
    },
    hasError(val) {
      this.input.setAttribute('aria-invalid', val);
      const classAction = val ? 'add' : 'remove';
      this.input.classList[classAction]('is-invalid');
    },
  },
};
</script>
<style lang="scss">
</style>
