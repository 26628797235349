<template>
  <div>
    <component
      v-bind:is="currentComponent"
      :handler="handler"
      :canCreate="canCreate"
      :compareProperty="compareProperty"
      :debouncedTts="debouncedTts"
      :description="description"
      :disabled="disabled"
      :displayProperty="displayProperty"
      :label="label"
      :state="state"
      :formattedResult="formattedResult"
      :scrollable="scrollable"
      :autocomplete="autocomplete"
      :popoverText="popoverText"
      :popoverHint="popoverHint"
      :hintId="hintId"
      :placementPopover="placementPopover"
      :showLoading="showLoading"
      :variantSpinner="variantSpinner"
      :minWordLength="minWordLength"
      :maxWordLength="maxWordLength"
      :value="value"
      :selected="selected"
      :emptyMessage="emptyMessage"
      :createMessage="createMessage"
      :clearable="clearable"
      :comboBox="comboBox"
      :validator="validator"
      :multiple="multiple"
      @focus="onFocus"
      @input="onInput"
      @clear="onClear"
      @select="onSelect"
      @select:item="onSelectItem"
      @hide="onHide"
      @create="onCreateNew"
      :clearOnFocus="clearOnFocus"
    ></component>
  </div>
</template>

<script>
import i18n from '@app/i18n';
import BAutocomplete from './BAutocomplete.vue';
import BMultiAutocomplete from './BMultiAutocomplete.vue';

export default {
  name: 'BDropdownInput',
  components: { BAutocomplete, BMultiAutocomplete },
  props: {
    // функция для поиска элементов (промис, возвращает элементы)
    handler: {
      type: Function,
      required: true,
    },
    // можно ли из автокомплита создавать элемент
    canCreate: {
      type: Boolean,
    },
    // свойство, которое прокидывается при выборе элемента наверх (например для фильтра)
    compareProperty: {
      type: [String, Function],
      default: 'id',
    },
    debouncedTts: {
      type: Number,
      default: 200,
    },
    description: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    // отображаемое свойство выбранного элемента
    displayProperty: {
      type: [String, Function],
    },
    label: {
      type: String,
    },
    state: {
      type: Boolean,
      default: undefined,
    },
    // особое отображение результатов поиска
    formattedResult: { type: Object, default: null },
    // скроллятся ли результаты поиска
    scrollable: { type: Boolean, default: true },
    // автозаполнение
    autocomplete: { type: String, default: 'off' },
    // текст, на который надо навести для подсказки
    popoverText: {
      type: String,
      default: 'i',
    },
    // текст подсказки
    popoverHint: {
      type: String,
      default: null,
    },
    // айди хинта
    hintId: {
      type: String,
      default: null,
    },
    // положение хинта
    placementPopover: {
      type: String,
      default: 'bottom',
    },
    // показываем ли спиннер
    showLoading: {
      type: Boolean,
      default: true,
    },
    // тип спиннера
    variantSpinner: {
      type: String,
      default: 'primary',
    },
    // минимальное количество символов для поиска
    minWordLength: {
      type: Number,
      default: 4,
    },
    maxWordLength: {
      type: Number,
      default: 100,
    },
    // айди выбранного элемента
    value: {
      type: [Number, String, Array],
    },
    // выбранный элемент
    selected: {
      type: [Object, Array],
      default: null,
    },
    // сообщение, если ни один элемент не нашелся
    emptyMessage: {
      type: String,
      default: () => i18n.t('global.not_found'),
    },
    // текст, по клику на который создается новый элемент
    createMessage: {
      type: String,
      default: () => i18n.t('button.add'),
    },
    // очищаемое
    clearable: {
      type: Boolean,
      default: true,
    },
    // комбобокс - если не вбит ни один символ, то отображать все элементы
    comboBox: {
      type: Boolean,
      default: false,
    },
    validator: {
      type: Function,
    },
    // мультиселект
    multiple: {
      type: Boolean,
      default: false,
    },
    clearOnFocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      debouncedSearch: null,
      inputFocus: false,
      isLoading: false,
      results: null,
    };
  },
  computed: {
    currentComponent() {
      if (this.multiple) {
        return 'BMultiAutocomplete';
      } else return 'BAutocomplete';
    },
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
    onBlur() {
      this.$emit('blur');
    },
    onCreateNew(value) {
      this.$emit('create', value);
    },
    onClear() {
      this.$emit('input', null);
      this.$emit('select', null);
      this.$emit('clear');
    },
    onFocus(event) {
      this.$emit('focus', event);
    },
    onSelectItem(value) {
      this.$emit('select:item', value);
    },
    onSelect(value) {
      this.$emit('select', value);
    },
    onHide(value) {
      this.$emit('hide', value);
    },
  },
};
</script>
