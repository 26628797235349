export function getAuthToken() {
  if (!localStorage) return false;
  try {
    const token = JSON.parse(localStorage.getItem('token'));
    if (token && token.access_token) {
      return token;
    }
    return false;
  } catch (err) {
    localStorage.removeItem('token');
    return false;
  }
}

export function startStream() {
  debugger;
}

export function getSelectedRolePanel() {
  return window.location.pathname.match(/\/[a-z]*/)[0].replace('/', '');
}

export function getUserName(user) {
  if (user instanceof Object && Object.keys(user).length) {
    const name = user.name ? `${user.surname || ''} ${user.name}`.trim() : null;
    return name || user.phone || user.email;
  }
  console.warn('getUserName: Provided data is invalid');
  return false;
}
