import bridge from '@/helpers/bridge';

const state = {
  selectedGeoUnit: null,
};

const getters = {
  selectedGeoUnitId(state) {
    return state.selectedGeoUnit?.id || null;
  },
  isLoadedSelectedGeoUnit(state) {
    return !!state.selectedGeoUnit;
  },
  isSelectedGeoUnitAll(state) {
    return state.selectedGeoUnit && !state.selectedGeoUnit.id;
  },
};

const mutations = {
  setGeoUnit(state, payload) {
    state.selectedGeoUnit = payload;
  },
};
const actions = {
  actionSetGeoUnit({ commit }, payload) {
    commit('setGeoUnit', payload);
    bridge.geoUnit = payload || null;
  },
  actionSetGeoUnitAll({ commit }) {
    commit('setGeoUnit', {});
    bridge.geoUnit = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
