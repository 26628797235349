<template>
  <div class="geo-units-autocomplete">
    <BDropdownInput
      v-model="selected_id"
      :description="description"
      :disabled="disabled"
      :label="placeholder"
      :handler="fetch"
      :selected="territory"
      :state="state"
      :showLoading="showLoading"
      :variantSpinner="variantSpinner"
      :debouncedTts="debouncedTts"
      compare-property="id"
      display-property="name"
      @clear="onClear"
      @focus="onFocus"
      @select="onSelect"
      @select:item="onSelectItem"
      @create="openModal"
      :popoverText="popoverText"
      :popoverHint="popoverHint"
      :hintId="hintId"
      :minWordLength="minWordLength"
      :comboBox="comboBox"
      canCreate
    />
    <GeoUnitTerritoryModal
      :districtId="this.districtId"
      ref="territoryModal"
      @entityCreated="onTerritoryCreated"
    />
  </div>
</template>

<script>
import GeoUnitTerritoryModal from '@/components/modals/GeoUnitTerritoryModal.vue';
import { API_URLS, GEO_UNITS } from '@/consts';
import { request3 } from '@/api/request';
import BDropdownInput from '@/components/base/BDropdownInput.vue';

export default {
  name: 'GeoUnitsTerritoryAutocomplete',
  components: {
    BDropdownInput,
    GeoUnitTerritoryModal,
  },
  props: {
    description: {
      type: String,
    },
    territoryData: Object,
    clientId: [String, Number],
    districtId: Number,
    placeholder: String,
    state: {},
    debouncedTts: {
      type: Number,
      default: 200,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minWordLength: {
      type: Number,
      default: 3,
    },
    popoverText: {
      type: String,
      default: 'i',
    },
    popoverHint: {
      type: String,
      default: null,
    },
    hintId: {
      type: String,
      default: null,
    },
    placementPopover: {
      type: String,
      default: 'bottom',
    },
    showLoading: {
      type: Boolean,
      default: true,
    },
    variantSpinner: {
      type: String,
      default: 'primary',
    },
    comboBox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      territory: this.territoryData,
      selected_id: null,
    };
  },
  mounted() {
    if (this.territoryData instanceof Object) {
      this.selected_id = this.territoryData.id;
    }
  },
  methods: {
    onSelect(value) {
      this.$emit('input', value);
      this.$emit('select', value);
      this.selected_id = value;
    },
    onSelectItem(item) {
      this.territory = item;
      this.$emit('select:item', item);
    },
    fetch(val) {
      const q = {
        type_eq: GEO_UNITS.Territory,
        district_id_eq: this.districtId,
        territories_for_building: 1,
        short_name_cont_all: val,
        short_name_search: true,
      };
      return request3
        .get(API_URLS.backendManage.geoUnits.fetch(this.clientId), {
          params: {
            q: q,
            limit: 8,
          },
        })
        .then((res) => res.data.geo_units);
    },
    onClear() {
      this.selected_id = null;
      this.territory = null;
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
    openModal() {
      this.$refs.territoryModal.show();
    },
    onTerritoryCreated(entity) {
      this.onSelectItem(entity);
    },
  },
};
</script>
