<template>
  <div>
    <div class="row mt-4">
      <div class="col-3">
        <b-form-group-slot ref="max_sms_per_day_limit" label='Суточный лимит СМС'>
          <input v-model="config.max_sms_per_day_limit"/>
        </b-form-group-slot>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-3">
        <b-form-group-slot ref="breakpoints_limit" label='Шаг повторной отправки письма'>
          <input v-model="config.breakpoints_limit"/>
        </b-form-group-slot>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">
        <h5>
          Почтовые адреса для оповещения:
        </h5>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 px-0" style="display: inline-block;">
        <span v-for="(email, index) in config.alert_emails">
          <button @click="destroyEmail(index)" class="btn btn-secondary ml-4"
            v-b-tooltip="{
              title: $t('button.delete'),
              placement: 'top',
              trigger: 'hover',
              boundary: 'viewport',
            }"
          >
            {{ email }}
          </button>
        </span>
      </div>
    </div>
    <div class="mt-4 col-4 p-0">
      <button @click="addEmail" :disabled="emailIsEmpty" class="btn btn-primary ml-4" style="float: right;">
        Добавить новый адрес
      </button>
      <b-form-email-slot ref="email_field" label='Email'>
        <input v-model="email"/>
      </b-form-email-slot>
    </div>
    <button @click="save" class="btn btn-primary mt-4">
      Сохранить
    </button>
  </div>
</template>

<script>
import BFormEmailSlot from "@/components/base/BFormEmailSlot";
import BFormGroupSlot from "@/components/base/BFormGroupSlot";
import { isEmpty } from 'lodash';
import {ApiConfigAlertSms} from "@/api";

export default {
  name: 'ConfigSmsAlertForm',
  props: {
    configData: Object,
    errors: Object,
    state: {},
  },
  components: {
    BFormEmailSlot,
    BFormGroupSlot,
  },
  data() {
    return {
      email: '',
      config: {
        max_sms_per_day_limit: this.configData.max_sms_per_day_limit,
        alert_emails: this.configData.alert_emails,
        breakpoints_limit: this.configData.breakpoints_limit
      },
    };
  },
  mounted() {
  },
  computed: {
    emailIsEmpty() {
      return isEmpty(this.email);
    }
  },
  methods: {
    addEmail() {
      if (!this.$refs.email_field.error) {
        if (!this.config.alert_emails.includes(this.email)) {
          this.config.alert_emails.push(this.email);
        }
        this.resetEmail();
      }
    },
    destroyEmail(index){
      this.config.alert_emails.splice(index, 1)
    },
    resetEmail() {
      this.email = '';
    },
    save() {
      ApiConfigAlertSms.update(1, { config: this.config })
        .then(() => {
          this.$bvToast.toast('Изменения успешно сохранены!', { variant: 'success' });
        })
        .catch((err) => {
          this.$bvToast.toast(JSON.stringify(err.errors.config[0]));
        });
    }
  },
};
</script>
