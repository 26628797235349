import EventBus from '@/packs/EventBus';
import Store from '@app/store';

import { MarkUnitDeleted } from '@/vue_app/components/building_edit/helpers/helpers';
import {
  generateUnit,
  generateName,
  getExcludedNumbers,
  getNextIndex,
  getAdditiveRules,
  getRuleOrException,
} from '@/vue_app/components/building_edit/helpers/buildingSettingsHelper';
import { getValuesFromStringWithNumbers } from '@/vue_app/components/building_creat/utils';
import { renameUnitsContinuously } from '@app/components/building_edit/helpers/unitsRenameHelper';

let nextId = 1;
let apartmentNumber = 1;
let apartmentNumberExclude = [];

function onClearBuilding() {
  MarkUnitDeleted(Store.state.edit_building.building.children);
}

function resetApartmentSettings() {
  const { Apartment } = Store.state.edit_building.building.meta.structure;
  const excluded = Apartment ? Apartment.exclude : '';
  nextId += 1;
  apartmentNumber = 0;
  apartmentNumberExclude = getValuesFromStringWithNumbers(excluded);
  increaseApartmentNumber();
}

function increaseApartmentNumber() {
  apartmentNumber += 1;

  while (apartmentNumberExclude.includes(apartmentNumber)) {
    apartmentNumber += 1;
  }
}

function createUnit(id, name, type, index, parent) {
  const newUnit = generateUnit(id, name, type, index, parent);
  nextId += 1;
  return newUnit;
}

function generateEntrances() {
  const { Entrance } = Store.state.edit_building.building.meta.structure;
  if (!Entrance.quantity) return;

  const exceptions = getValuesFromStringWithNumbers(Entrance.exclude);

  let count = 1;
  for (let i = 1; count <= Entrance.quantity; i += 1) {
    if (!exceptions.includes(i)) {
      const name = generateName(
        i,
        Store.state.edit_building.building,
        Entrance.prefix,
      );
      const newEntrance = createUnit(
        nextId,
        name,
        'Entrance',
        i * 1000,
        Store.state.edit_building.building,
      );
      Store.state.edit_building.building.children.push(newEntrance);
      generateTechPlaces('TechRoom', newEntrance, i);
      generateTechPlaces('StoreRoom', newEntrance, i);
      generateTechPlaces('Bkfn', newEntrance, i);
      generateParking(newEntrance, i);
      generateFloors(newEntrance, i);
      generateRoof(newEntrance, i);
      count += 1;
    }
  }
}

function generateFloors(parent, parentNumber) {
  const { Floor } = Store.state.edit_building.building.meta.structure;
  if (!Floor || !Floor.length) return;

  const suitableRule = getRuleOrException(Floor, parentNumber);
  if (!suitableRule.quantity) return;

  const start = Number(suitableRule.start) || 1;
  const exceptionNumbers = getValuesFromStringWithNumbers(suitableRule.exclude);
  let count = 1;

  for (let i = start; count <= suitableRule.quantity; i += 1) {
    if (!exceptionNumbers.includes(i)) {
      const unitIndex = getNextIndex(parent);
      const name = generateName(i, parent, suitableRule.prefix);
      const newFloor = createUnit(nextId, name, 'Floor', unitIndex, parent);
      parent.children.push(newFloor);

      fillFloor(
        newFloor,
        suitableRule.structure,
        parentNumber,
        i,
        suitableRule.apartmentsStartsAtFloor,
      );
      generateTechPlaces('TechRoom', newFloor, i);
      generateTechPlaces('StoreRoom', newFloor, i);
      generateTechPlaces('Bkfn', newFloor, i);
      count += 1;
    }
  }
}

function fillFloor(
  parent,
  fillRule,
  entranceNumber,
  floorNumber,
  apartmentStart,
) {
  if (!fillRule || !fillRule.length) return;

  const { Section } = Store.state.edit_building.building.meta.structure;
  if (!Section || !Section.length) return;

  const shouldAddApartments =
    apartmentStart === '' || floorNumber >= apartmentStart;

  const suitableRule = getRuleOrException(Section, entranceNumber);
  const exceptions = getValuesFromStringWithNumbers(suitableRule.exclude);

  let nextSectionNumber = 1;

  fillRule.forEach((unit) => {
    if (unit.type === 'Section') {
      while (exceptions.includes(nextSectionNumber)) {
        nextSectionNumber += 1;
      }

      const name = generateName(nextSectionNumber, parent, suitableRule.prefix);
      createSection(
        unit,
        parent,
        name,
        nextSectionNumber,
        floorNumber,
        shouldAddApartments,
      );
      nextSectionNumber += 1;
    }
    if (unit.type === 'Apartment' && shouldAddApartments) {
      createApartment(parent, floorNumber);
    }
  });
}

function createSection(
  rule,
  parent,
  name,
  sectionNumber,
  floorNumber,
  shouldAddApartments,
) {
  const unitIndex = getNextIndex(parent);
  const newSection = createUnit(nextId, name, 'Section', unitIndex, parent);
  parent.children.push(newSection);

  generateTechPlaces('TechRoom', newSection, sectionNumber);
  generateTechPlaces('StoreRoom', newSection, sectionNumber);

  if ('children' in rule && shouldAddApartments) {
    rule.children.forEach(() => {
      createApartment(newSection, floorNumber);
    });
  }
}

function createApartment(parent, floorNumber) {
  const { Apartment } = Store.state.edit_building.building.meta.structure;
  if (Apartment && Apartment.start) {
    if (floorNumber < Apartment.start) return;
  }

  const unitIndex = getNextIndex(parent);
  const newApartment = createUnit(
    nextId,
    String(apartmentNumber),
    'Apartment',
    unitIndex,
    parent,
  );
  parent.children.push(newApartment);
  increaseApartmentNumber();
}

function generateTechPlaces(unitType, parent, parentNumber = null) {
  const rules = Store.state.edit_building.building.meta.structure[unitType];
  if (!rules || !rules.length) return;

  const suitableRules = getAdditiveRules(rules, parent.type, parentNumber);
  if (!suitableRules.length) return;

  const excludedNumbers = getExcludedNumbers(suitableRules);
  let count = 1;
  suitableRules.forEach((rule) => {
    let createdForRule = 1;
    for (let i = 0; createdForRule <= rule.quantity; i += 0) {
      if (!excludedNumbers.includes(count)) {
        const unitIndex = getNextIndex(parent);
        const name = generateName(count, parent, rule.prefix);
        const newPlace = createUnit(nextId, name, unitType, unitIndex, parent);
        parent.children.push(newPlace);
        createdForRule += 1;
      }
      count += 1;
    }
  });
}

function generateParking(parent, parentNumber = null) {
  const { ParkingArea } = Store.state.edit_building.building.meta.structure;
  if (!ParkingArea || !ParkingArea.length) return;

  const suitableRules = ParkingArea.filter((rule) => {
    const properParentType = rule.parentType === parent.type;

    if (parent.type === 'Building') return properParentType;
    if (!rule.parentIndexes) return properParentType;

    const parentNumbers = getValuesFromStringWithNumbers(
      String(rule.parentIndexes),
    );
    return properParentType && parentNumbers.includes(parentNumber);
  });
  if (!suitableRules.length) return;

  let count = 1;

  suitableRules.forEach((rule) => {
    let createdForRule = 1;
    for (let i = 0; createdForRule <= rule.quantity; i += 0) {
      const unitIndex = getNextIndex(parent);
      const name = generateName(count, parent, rule.prefix);
      const newArea = createUnit(
        nextId,
        name,
        'ParkingArea',
        unitIndex,
        parent,
      );
      parent.children.push(newArea);
      fillParkingArea(newArea, rule, createdForRule, parentNumber);
      createdForRule += 1;
      count += 1;
    }
  });
}

function fillParkingArea(parent, rule, areaIndex, entranceIndex = null) {
  if (parent.parent.type === 'Entrance' && !entranceIndex) {
    return;
  }

  let placeRule;
  if (parent.parent.type === 'Building') {
    placeRule = rule.structure.find((place) => place.parentIndex === areaIndex);
  } else {
    placeRule = rule.structure.filter(
      (place) => place.parentIndex === entranceIndex,
    )[areaIndex - 1];
  }
  if (!placeRule || !placeRule.quantity) return;

  const excludedNumbers = getExcludedNumbers([placeRule]);
  let count = 1;

  for (let i = 1; count <= placeRule.quantity; i += 1) {
    if (!excludedNumbers.includes(i)) {
      const unitIndex = getNextIndex(parent);
      const name = generateName(i, parent, placeRule.prefix);
      const newPlace = createUnit(
        nextId,
        name,
        'ParkingPlace',
        unitIndex,
        parent,
      );
      parent.children.push(newPlace);
      count += 1;
    }
  }
  generateTechPlaces('TechRoom', parent);
}

function generateRoof(parent, parentNumber = null) {
  const { Roof } = Store.state.edit_building.building.meta.structure;
  if (!Roof || !Roof.parentType === 'none') return;
  if (Roof.parentType !== parent.type) return;

  const exclude =
    Roof.parentType === 'Entrance'
      ? getValuesFromStringWithNumbers(Roof.exclude)
      : [];
  if (exclude.includes(parentNumber)) return;

  const unitIndex = getNextIndex(parent);
  const name = generateName(1, parent, Roof.prefix);
  const newRoof = createUnit(nextId, name, 'Roof', unitIndex, parent);
  parent.children.push(newRoof);
}

export function generateBuilding() {
  EventBus.$emit(
    'preloader::show',
    'Идет создание структуры дома, пожалуйста подождите...',
  );

  const { building } = Store.state.edit_building;
  const { TechRoom, StoreRoom, Bkfn } = building.meta.structure;

  onClearBuilding();
  resetApartmentSettings();
  generateEntrances();
  generateTechPlaces('TechRoom', building);
  generateTechPlaces('StoreRoom', building);
  generateTechPlaces('Bkfn', building);
  generateParking(building);
  generateRoof(building);

  if (TechRoom[0].continuousNumbering) {
    renameUnitsContinuously(building, 'TechRoom', TechRoom);
  }

  if (StoreRoom[0].continuousNumbering) {
    renameUnitsContinuously(building, 'StoreRoom', StoreRoom);
  }

  if (Bkfn[0].continuousNumbering) {
    renameUnitsContinuously(building, 'Bkfn', Bkfn);
  }

  const hasEntrances = building.children.some(
    ({ type, _destroy }) => type === 'Entrance' && !_destroy,
  );

  if (hasEntrances) {
    Store.dispatch('edit_building/actionSelectEntrance', 0);
  }

  Store.commit('edit_building/setCurrentMode', 'Building');
  Store.commit('edit_building/setMaxUnitId', nextId);

  EventBus.$emit('preloader::hide');
}
