<template>
  <div>
    <slot
      name="default"
      :fa-can-device-data="faCanDeviceData"
      :fa-ppk-device-data="faPpkDeviceData"
      :fa-can-device="faCanDevice"
      :fa-ppk-device="faPpkDevice"
      :select-can-device="selectCanDevice"
      :disabled-ppk-autocomplete="disabledPpkAutocomplete"
      :clear-can-device="clearCanDevice"
      :fa-can-device-id="faCanDeviceId"
      :fa-ppk-device-id="faPpkDeviceId"
      :clear-ppk-device="clearPpkDevice"
      :select-ppk-device="selectPpkDevice"
    ></slot>
    <input
      :value="faCanDeviceId"
      :id="`${model}_fa_can_eth_device_id`"
      type="hidden"
      :name="`${model}[fa_can_eth_device_id]`"
    />
    <input
      :value="faPpkDeviceId"
      :id="`${model}_fa_ppk_device_id`"
      type="hidden"
      :name="`${model}[fa_ppk_device_id]`"
    />
  </div>
</template>

<script>
export default {
  name: 'DevicesCanAndPpkFormSlim',
  props: {
    faCanDeviceData: {
      type: Object,
      default: null,
    },
    faPpkDeviceData: {
      type: Object,
      default: null,
    },
    model: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      faCanDevice: this.faCanDeviceData,
      faPpkDevice: this.faPpkDeviceData,
      faCanDeviceId: null,
      faPpkDeviceId: null,
    };
  },
  mounted() {
    if (this.faCanDevice) {
      this.faCanDeviceId = this.faCanDevice.id;
    }
    if (this.faPpkDevice) {
      this.faPpkDeviceId = this.faPpkDevice.id;
    }
  },
  computed: {
    disabledPpkAutocomplete() {
      if (this.faCanDeviceId) {
        return false;
      }
      return true;
    },
  },
  methods: {
    selectCanDevice(val) {
      this.faCanDevice = val;
      this.faCanDeviceId = val.id;
    },
    selectPpkDevice(val) {
      this.faPpkDevice = val;
      this.faPpkDeviceId = val.id;
    },
    clearCanDevice() {
      this.faCanDevice = null;
      this.faCanDeviceId = null;

      this.clearPpkDevice();
    },

    clearPpkDevice() {
      this.faPpkDevice = null;
      this.faPpkDeviceId = null;
    },
  },
};
</script>

<style></style>
