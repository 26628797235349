<template>
  <div class="geo-units-list">
    <div v-for="(item, index) in value" :key="index" class="geo-units-item">
      <DistrictsAutocomplete
        :clientId="clientId"
        :validator="checkIsDuplicate"
        :value="item.district_id"
        :state="item.state"
        :init-name="getInitTitle(item)"
        @select:item="(value) => onInput(value, index)"
        @clear="onClear(index)"
        compare-property="id"
        display-property="name"
        class="mb-3"
      />
      <b-button
        variant="outline-danger"
        class="btn-square btn-trash"
        @click="onRemoveItem(index)"
      >
        <i class="icon-local-trash" />
      </b-button>
      <b-button
        v-if="index === value.length - 1"
        variant="outline-primary"
        class="btn-square btn-add"
        :disabled="isDisabledAddBtn"
        @click="onAddItem()"
      >
        <i class="icon-local-plus" />
      </b-button>
      <div v-else class="empty-box"></div>
    </div>
    <b-button
      v-if="value.length === 0"
      class="w-100"
      variant="outline-primary"
      @click="onAddItem()"
      :disabled="disabledBtn"
    >
      {{ addTitle }}
    </b-button>
  </div>
</template>

<script>
import DistrictsAutocomplete from '@/components/autocompletes/DistrictsAutocomplete';
import { removeInArrayByIndex, replaceInArrayByIndex } from '@/helpers';
import i18n from '@app/i18n';

export default {
  name: 'DistrictsList',
  components: {
    DistrictsAutocomplete,
  },
  props: {
    addTitle: {
      type: String,
      default: i18n.t('geo_unit.add_address'),
    },
    clientId: {
      type: [Number, String],
    },
    disableAddCheckProperty: {
      type: String,
      required: true,
    },
    initGeoUnitNameProperty: {
      type: String,
      default: 'short_name',
    },
    value: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabledBtn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isDisabledAddBtn() {
      if (this.value.length === 0) return false;
      return this.value.some((item) => !item[this.disableAddCheckProperty]);
    },
  },
  methods: {
    checkIsDuplicate(id) {
      const duplicate = this.value.find((item) => item.district_id == id);
      if (duplicate) {
        this.$bvToast.toast(this.$t('geo_unit.object_already_exists'));
        return false;
      }
      return true;
    },
    clonedItems() {
      return this.value.map((item) => item);
    },
    getInitTitle(value) {
      if (value) {
        return value[this.initGeoUnitNameProperty];
      }
      return undefined;
    },
    onAddItem() {
      this.$emit('input', [
        ...this.value,
        { district_id: null, state: this.required ? false : undefined },
      ]);
    },
    onInput(district, index) {
      this.$emit(
        'input',
        replaceInArrayByIndex(this.value, index, {
          district_id: district.id,
          district_name: district.name,
          state: this.required && !Boolean(district.id) ? false : undefined,
        }),
      );
    },
    onClear(index) {
      this.$emit(
        'input',
        replaceInArrayByIndex(this.value, index, {
          district_id: null,
          district_name: null,
          state: false,
        }),
      );
    },
    onRemoveItem(index) {
      this.$emit('input', removeInArrayByIndex(this.value, index));
    },
  },
};
</script>

<style lang="scss">
@import 'app/javascript/assets/styles/components/variables';

.geo-units-list {
  .geo-units-item {
    display: flex;
    .geo-units-autocomplete-2 {
      width: 100%;
    }
  }
  .btn-trash {
    color: $gray-700;
    &:hover {
      color: $white;
    }
  }
  .btn-add {
    &:not([disabled]) {
      border-color: $blue;
    }
  }
  .empty-box {
    height: 50px;
    width: 50px;
    margin-left: 10px;
    flex-shrink: 0;
  }
}
</style>
