<template>
  <div>
    <slot ref="slot"></slot>
  </div>
</template>

<script>
export default {
  name: 'MeterValueFilters',
  mounted() {
    const app = this;
    const inputs = this.$el.querySelectorAll('input[type="checkbox"]');
    inputs.forEach(function (input) {
      input.addEventListener('change', (event) => {
        app.checkAndSubmit(event.target);
      });
    });
  },
  methods: {
    checkAndSubmit(input) {
      if (input.checked) {
        const opposite = this.oppositeEL(input.id);
        opposite.forEach(function (el) {
          el.checked = false;
        });
      }
      input.form.submit();
    },
    oppositeEL(id) {
      switch (id) {
        case 'q_only_reading':
          return [
            this.$el.querySelector(`#q_with_tampered`),
            this.$el.querySelector(`#q_with_magnetic_alarmed`),
          ];
        case 'q_with_tampered':
        case 'q_with_magnetic_alarmed':
          return [this.$el.querySelector(`#q_only_reading`)];
        default:
          return [];
      }
    },
  },
};
</script>
