<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @mouseover="isHover = true"
    @mouseout="isHover = false"
  >
    <path
      d="M4 12H12V4M20 4V12H28M4 20H12V28M28 20H20V28"
      :stroke="isHover ? hoverColor : color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ExitFullscreen',
  props: {
    color: { type: String, default: 'black' },
    hoverColor: { type: String, default: 'black' },
  },
  data() {
    return {
      isHover: false,
    };
  },
};
</script>

<style lang="scss" scoped>
path {
  transition: all ease-in 0.2s;
}
</style>
