function onBlurInResults(event, index) {
  // Only propogate focusout if the last element in the list.
  if (this.results && index < this.results.length - 1) {
    event.stopPropagation();
  }
}

function onBlurCreate(event) {
  // Only propogate focusout if no results present.
  // (This means create-li is the last element)
  if (this.results && this.results.length > 0) {
    event.stopPropagation();
  }
}
function onBlurInput(event) {
  const emptyResults = !this.results || this.results.length == 0;
  if (!this.loading && (this.canCreate || !emptyResults)) {
    event.stopPropagation();
  }
}

export { onBlurInResults, onBlurCreate, onBlurInput };
