import { uniqueId } from 'lodash-es';
export const WsJsonRpcAsync = {
  data() {
    return {
      ws: null,
      wsRequests: [],
      onMessageCb: null,
    };
  },
  methods: {
    getId() {
      return Number(uniqueId());
    },
    wsCloseConnect() {
      if (this.ws) {
        this.ws.close();
        this.ws = null;
        this.wsRequests = [];
      }
    },
    async wsConnect(url) {
      this.ws = new WebSocket(url);
      this.ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);

          const request = this.wsRequests[data.id];
          if (request) {
            if (data.result) {
              request.resolve(data.result);
            } else if (data.error) {
              request.reject(data.error);
            }
          } else if (typeof this.onMessageCb === 'function') {
            this.onMessageCb(data);
          }
        } catch (err) {
          console.error(err);
        }
      };
      this.ws.onclose = () => {
        this.ws = null;
        this.wsRequests = [];
      };
      return new Promise((resolve, reject) => {
        this.ws.onopen = (event) => {
          resolve(event);
        };
        this.ws.onerror = (event) => {
          reject(event);
        };
      });
    },
    wsSend(method, params = {}) {
      if (!this.ws) {
        console.error('Error: WebSocket not connected');
        return;
      }
      const message = JSON.stringify({
        jsonrpc: '2.0',
        method,
        params,
        id: this.getId(),
      });
      this.ws.send(message);
    },
    wsAsyncSend(method, params = {}) {
      if (!this.ws) {
        console.error('Error: WebSocket not connected');
        return;
      }
      const id = this.getId();
      return new Promise((resolve, reject) => {
        const message = JSON.stringify({
          jsonrpc: '2.0',
          method,
          params,
          id,
        });
        this.wsRequests[id] = { resolve, reject };
        this.ws.send(message);
      });
    },
    wsOnMessage(callback = () => {}) {
      this.onMessageCb = callback;
    },
  },
};
