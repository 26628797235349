<template>
  <div>
    <slot
      :add-block="addBlock"
      :geo-blocks="geoBlocks"
      :geo-unit-input-name="geoUnitInputName"
      :on-update-unit="onUpdateUnit"
      :remove-block="removeBlock"
      :key-input-name="keyInputName"
      :add-key="addKey"
      :is-disabled-submit="isDisabledSubmit"
      :errors="errors"
      :select-key="selectKey"
      :clear-key="clearKey"
      :remove-key="removeKey"
    ></slot>
  </div>
</template>
<script>
export default {
  name: 'ManageAccountForm',
  props: {
    geoBlocksData: Array,
    errors: Object,
  },
  data() {
    return {
      geoBlocks: this.geoBlocksData,
      oldAddressId: null,
      user: this.userData,
    };
  },
  computed: {
    isDisabledSubmit() {
      const isHaveInvalidState = this.geoBlocks.some(
        (geoUnit) => geoUnit.state === false,
      );
      return isHaveInvalidState;
    },
  },
  mounted() {
    if (this.errors.geo_units) {
      this.$bvToast.toast(this.$t('account.cannot_add_acc_without_property'));
    }
  },
  methods: {
    addKey(blockId) {
      this.geoBlocks
        .find((b) => b.id == blockId)
        .keys.push({ id: 0, code: '' });
    },
    selectKey(blockId, keyId, val) {
      const index = this.geoBlocks.findIndex((b) => b.id == blockId);
      if (index > -1) {
        this.geoBlocks[index].keys[keyId] = Object.assign({}, val);
      }
    },
    clearKey(blockId, keyId) {
      const index = this.geoBlocks.findIndex((b) => b.id == blockId);
      if (index > -1) {
        this.geoBlocks[index].keys[keyId] = Object.assign(
          {},
          { id: 0, code: '' },
        );
      }
    },
    addBlock() {
      let id =
        (Number(
          this.geoBlocks
            .map((b) => b.id)
            .sort()
            .reverse()[0],
        ) || 0) + 1;
      this.geoBlocks.push({ id: id, keys: [{ id: 0 }], geo_unit: {} });
    },
    removeBlock(i) {
      this.geoBlocks.splice(i);
    },
    getKeyByBlock(i, blockId) {
      return this.geoBlocks.find((b) => b.id).keys[i];
    },
    removeKey(i, blockId) {
      this.geoBlocks.find((b) => b.id == blockId).keys.splice(i);
    },
    addGeoUnit(blockId) {
      this.geoBlocks.find((b) => b.id == blockId).geo_units.push({ id: 0 });
    },
    geoUnitHiddenFieldName(blockId) {
      return `geo_units[${blockId}][id]`;
    },
    keyHiddenFieldName(blockId) {
      return `keys[${blockId}][id]`;
    },
    keyFormId(i, attr) {
      return `user_${this.$props.type}_keys_attributes_${i}_${attr}`;
    },
    keyInputName(blockId, i) {
      return `user[blocks][${blockId}][keys][${i}]`;
    },
    geoUnitInputName(blockId, i) {
      return `user[blocks][${blockId}][geo_units][${i}]`;
    },
    getKeyError(i, attr) {
      let errrosArr = this.errors[`keys[${i}].${attr}`];
      if (errrosArr) {
        return errrosArr.join(', ');
      }
    },
    onUpdateUnit(geoUnit, index) {
      let state = true;
      if (geoUnit.has_owner) {
        this.$bvToast.toast(
          this.$t('account.error_occupied property', [geoUnit.short_name]),
        );
        state = false;
      }
      if (!geoUnit.id) {
        state = false;
      }
      this.$set(this.geoBlocks[index], 'state', state);
    },
  },
};
</script>
