import Vue from 'vue';
import Store from '@app/store';
import EventBus from '@/packs/EventBus';
import {
  unitWithParentTypes,
  allParentTypes,
} from '@/vue_app/components/building_edit/consts';
import { getValuesFromStringWithNumbers } from '@/vue_app/components/building_creat/utils';

const basicStructure = {
  Entrance: {
    quantity: '',
    exclude: '',
    prefix: '',
  },
  Floor: [
    {
      quantity: '',
      start: '',
      prefix: '',
      sectionsPerFloor: '',
      apartmentsPerSection: '',
      apartmentsPerFloor: '',
      apartmentsStartsAtFloor: '',
      structure: [],
      parentIndexes: '',
      exclude: '',
      parentType: 'Entrance',
    },
  ],
  Section: [
    {
      exclude: '',
      prefix: '',
      parentIndexes: '',
    },
  ],
  Apartment: {
    exclude: '',
  },
  TechRoom: [
    {
      parentType: 'none',
      parentIndexes: '',
      quantity: '',
      exclude: '',
      prefix: '',
      continuousNumbering: false,
    },
  ],
  Bkfn: [
    {
      parentType: 'none',
      parentIndexes: '',
      quantity: '',
      exclude: '',
      prefix: '',
      continuousNumbering: false,
    },
  ],
  StoreRoom: [
    {
      parentType: 'none',
      parentIndexes: '',
      quantity: '',
      exclude: '',
      prefix: '',
      continuousNumbering: false,
    },
  ],
  Roof: {
    parentType: 'none',
    exclude: '',
    prefix: '',
  },
  ParkingArea: [
    {
      parentType: 'none',
      parentIndexes: '',
      quantity: '',
      prefix: '',
      structure: [],
    },
  ],
};

const MODE_UNIT_TYPES = ['Building', 'Entrance'];

export function getUnitById(id, unit) {
  let result = null;
  if (unit.id === id) {
    result = unit;
  } else {
    const hasValidChildren =
      unit.children && Array.isArray(unit.children) && unit.children.length;
    if (hasValidChildren) {
      for (const child of unit.children) {
        result = getUnitById(id, child);
        if (result) break;
      }
    }
  }
  return result;
}

/**
 * Checks whether unit has not deleted children
 * If **viableType** is provided, will take it into account
 * @param unit
 * @param viableType
 * @returns {boolean}
 */

export function checkHasViableChild(unit, viableType = undefined) {
  if (!unit.children || !Array.isArray(unit.children)) return false;

  return (
    unit.children.findIndex(({ type, _destroy }) => {
      const isViableType = viableType ? type === viableType : true;
      return !_destroy && isViableType;
    }) !== -1
  );
}

function animateUnit(unitId) {
  const unitElements = document.querySelectorAll(`[id="${String(unitId)}"]`);
  if (!unitElements || !unitElements.length) return;
  const initialColor = unitElements[0].style.backgroundColor;
  unitElements.forEach((el) => {
    el.animate(
      [
        { backgroundColor: 'white' },
        { backgroundColor: '#ED1B34' },
        { backgroundColor: 'white' },
        { backgroundColor: '#ED1B34' },
        { backgroundColor: 'white' },
        { backgroundColor: '#ED1B34' },
        { backgroundColor: 'white' },
        { backgroundColor: initialColor },
      ],
      { duration: 1500, easing: 'linear' },
    );
  });
}

export function GeoUnitPrettify(
  geoUnit,
  parentId = null,
  parentType = null,
  parent = null,
) {
  const { id, name, type, meta, structure_index } = geoUnit;
  const result = {
    id,
    name,
    type,
    parentType,
    parentId,
    parent,
    meta,
    structure_index,
    editing: false,
  };
  if (type === 'Building') result.district_id = geoUnit.district_id;
  AppendUnitAnimation(result);

  const hasValidChildren = geoUnit.children && Array.isArray(geoUnit.children);
  if (hasValidChildren) {
    result.children = geoUnit.children
      .map((el) => GeoUnitPrettify(el, id, type, result))
      .sort((current, next) => current.structure_index - next.structure_index);
  }
  return result;
}

export function AppendUnitAnimation(data) {
  if (data === null) return;
  if (Array.isArray(data)) {
    data.forEach((unit) => {
      unit.animateError = () => animateUnit(unit.id);
    });
  }
  if (typeof data === 'object') {
    data.animateError = () => animateUnit(data.id);
  }
}

export function MarkUnitDeleted(unitsArray) {
  if (!Array.isArray(unitsArray) || !unitsArray.length) return;
  unitsArray.forEach((el) => {
    Vue.set(el, '_destroy', true);
    if (el.children) MarkUnitDeleted(el.children);
  });
}

function GetNewIndex(targetUnit, nextId) {
  let newIndex;
  if (nextId) {
    const nextUnit = targetUnit.children.find(({ id }) => id === nextId);
    const nextUnitIndex = targetUnit.children.indexOf(nextUnit);
    const previousUnit = targetUnit.children[nextUnitIndex - 1];
    const nextIndex = nextUnit.structure_index;
    const previousIndex = previousUnit ? previousUnit.structure_index : 0;
    newIndex = (previousIndex + nextIndex) / 2;
  } else {
    const previousUnit = targetUnit.children[targetUnit.children.length - 1];
    newIndex = previousUnit ? previousUnit.structure_index + 1000 : 1000;
  }
  return newIndex;
}

export function MoveUnit(moveableId, targetId, nextId) {
  const moveable = getUnitById(moveableId, Store.state.edit_building.building);
  const target = getUnitById(targetId, Store.state.edit_building.building);
  if (!moveable || !target) return;

  // Remove movable from it's parent's children list
  moveable.parent.children = moveable.parent.children.filter(({ id }) => {
    return id !== moveableId;
  });

  // Find out if target has unit with same type and name that one that moved
  const hasSameNameInParent = target.children.find(
    ({ name, type }) => name === moveable.name && type === moveable.type,
  );

  // Set new params for movable
  moveable.structure_index = GetNewIndex(target, nextId);
  moveable.parent = target;
  moveable.parentType = target.type;
  moveable.parentId = target.id;
  Vue.set(moveable, '_update', true);

  // Set moveable as a child to target
  target.children.push(moveable);
  target.children.sort(
    (unitA, unitB) => unitA.structure_index - unitB.structure_index,
  );

  // Set editing mode for moveable if it has clone in target
  if (hasSameNameInParent) {
    EventBus.$bvToast.toast('Юнит с таким имененм уже существует', {
      variant: 'danger',
    });
    process.nextTick(() => {
      moveable.editing = true;
    });
  }
}

export function AddUnit(targetId, nextId, onSaveHandler, unitType = null) {
  const target = getUnitById(targetId, Store.state.edit_building.building);
  if (!target) return;
  const newMaxUnitId = Store.state.edit_building.maxUnitId + 1;
  const newUnit = {
    id: newMaxUnitId,
    name: '',
    type: unitType || Store.state.edit_building.addType,
    structure_index: GetNewIndex(target, nextId),
    parentType: target.type,
    parentId: target.id,
    parent: target,
    editing: false,
    _create: true,
    onSaveHandler,
  };
  if (
    ['Entrance', 'Floor', 'Section', 'ParkingArea', 'TechRoom'].includes(
      newUnit.type,
    )
  ) {
    Vue.set(newUnit, 'children', []);
  }
  AppendUnitAnimation(newUnit);
  target.children.push(newUnit);
  target.children.sort((unitA, unitB) => unitA.index - unitB.index);

  Store.commit('edit_building/changeEditingUnit', newUnit);
  Store.commit('edit_building/setMaxUnitId', newMaxUnitId);

  // Trigger editing mode
  process.nextTick(() => {
    newUnit.editing = true;
  });
}

export function FindMaxId(geoUnit) {
  let maxId = geoUnit.id;

  const hasValidChildren =
    geoUnit.children &&
    Array.isArray(geoUnit.children) &&
    geoUnit.children.length;
  if (hasValidChildren) {
    const childMaxIds = geoUnit.children.map((child) => {
      return FindMaxId(child);
    });
    const childrenMaxId = childMaxIds.reduce((max, next) => {
      return next > max ? next : max;
    });
    if (childrenMaxId > maxId) maxId = childrenMaxId;
  }
  return maxId;
}

export function GetFirstUnitInPath(path) {
  return path.find((el) => {
    const elementType = el.getAttribute && el.getAttribute('type');
    if (!elementType) return false;
    return (
      elementType === 'Floor' ||
      elementType === 'Section' ||
      elementType === 'Apartment' ||
      elementType === 'StoreRoom' ||
      elementType === 'TechRoom' ||
      elementType === 'ParkingArea' ||
      elementType === 'Entrance' ||
      elementType === 'Building'
    );
  });
}

export function GetOffset(el) {
  const rect = el.getBoundingClientRect();
  const { left, top } = rect;
  return {
    left,
    right: left + rect.width,
    top,
    bottom: top + rect.height,
    width: rect.width,
    height: rect.height,
  };
}

export function getAppendableChild(target, cursorPosition, horizontal = true) {
  const result = { alignment: '', element: {} };

  let searchableElement = target;

  // Use div.unit__children-container as searchable element if it exists
  // This covers wrapping child container for Floors and ParkingAreas
  // According to unit component templates it is only possible with horizontal search
  if (horizontal) {
    searchableElement =
      target.querySelector('.unit__children-container') || target;
  }

  // If searchableElement is empty
  // or every element is hidden (.unit__children-container.offsetWidth === 0)
  // no need to find child params just add new one
  if (!searchableElement.children.length || !searchableElement.offsetWidth) {
    result.alignment = 'appendChild';
    return result;
  }

  // Creating children parameters array
  let childrenParams = [];
  for (const element of searchableElement.children) {
    const childPosition = GetOffset(element);

    // Find closest horizontal side for child element
    let closestX;
    if (horizontal) {
      closestX =
        Math.abs(cursorPosition.x - childPosition.left) <=
        Math.abs(cursorPosition.x - childPosition.right)
          ? childPosition.left
          : childPosition.right;
    } else {
      closestX = childPosition.right;
    }
    // As far as all children has different height,
    // using center point of any child to measure distance
    const yCenter = childPosition.top + childPosition.height / 2;
    const xCenter = childPosition.left + childPosition.width / 2;

    const xDistance = Math.abs(closestX - cursorPosition.x);
    const yDistance = Math.abs(yCenter - cursorPosition.y);

    const distance = Math.sqrt(xDistance * xDistance + yDistance * yDistance);

    // Find if cursor is to the right from element
    const isCursorToTheRight = xCenter <= cursorPosition.x;
    // Find if cursor is higher then element
    const isCursorHigher = yCenter <= cursorPosition.y;

    childrenParams.push({
      childXCenter: xCenter,
      childYCenter: yCenter,
      distance,
      isCursorToTheRight,
      isCursorHigher,
      element,
    });
  }
  // If working with horizontal list filter children
  // by their Y position to work with appropriate row
  if (horizontal) {
    childrenParams = childrenParams.filter(
      ({ childYCenter }) => Math.abs(cursorPosition.y - childYCenter) < 18,
    );
  }

  // Find child with the smallest distance
  const closestChild = childrenParams.reduce((current, next) => {
    return current.distance < next.distance ? current : next;
  }, childrenParams[0]);

  if (closestChild) {
    result.element = closestChild.element;
    if (horizontal) {
      result.alignment = closestChild.isCursorToTheRight
        ? 'appendAfter'
        : 'appendBefore';
    } else {
      result.alignment = closestChild.isCursorHigher
        ? 'appendBefore'
        : 'appendAfter';
    }

    return result;
  }
  return false;
}

function CheckCursorOverFloor(target, cursorPosition) {
  const floor = target.querySelector('div[type="Floor"]:not(.hidden-unit)');
  if (!floor) return true;
  const floorNumberBlock = floor.querySelector('div.edit-block');
  if (!floorNumberBlock) return false;
  const floorNumberPositioning = GetOffset(floorNumberBlock);
  return cursorPosition.x <= floorNumberPositioning.right;
}

export function TryAppendDummy(
  target,
  cursorPosition,
  elementType,
  targetType,
  dummy,
) {
  const isViableUnit =
    unitWithParentTypes[elementType] &&
    unitWithParentTypes[elementType].includes(targetType);

  const horizontal = targetType !== 'Entrance';

  if (!isViableUnit) {
    return;
  }

  if (horizontal) {
    const shouldAppendToEntrance = CheckCursorOverFloor(target, cursorPosition);
    if (!shouldAppendToEntrance) {
      return;
    }
  }

  let unitType = 'unit_smallest';
  if (targetType === 'Entrance') {
    unitType = 'unit_biggest';
  }
  if (targetType === 'Floor' && elementType === 'Section') {
    unitType = 'unit_grouping';
  }
  if (targetType === 'TechRoom' && target.classList.contains('unit_smallest')) {
    target.classList.replace('unit_smallest', 'unit_grouping');
  }

  const appendingConditions = getAppendableChild(
    target,
    cursorPosition,
    horizontal,
  );

  if (appendingConditions.alignment === 'appendChild') {
    target.appendChild(dummy);
  }
  if (appendingConditions.alignment === 'appendAfter') {
    // Check type to not allow to add anything higher then roof
    if (!horizontal) {
      const appendableElementType =
        appendingConditions.element.getAttribute &&
        appendingConditions.element.getAttribute('type');
      if (appendableElementType === 'Roof') return;
    }
    appendingConditions.element.parentNode.insertBefore(
      dummy,
      appendingConditions.element.nextSibling,
    );
  }
  if (appendingConditions.alignment === 'appendBefore') {
    // Prevent placing unit before edit-block
    if (appendingConditions.element.classList.value === 'edit-block') {
      return;
    }
    appendingConditions.element.parentNode.insertBefore(
      dummy,
      appendingConditions.element,
    );
  }
  dummy.className = `dummy unit ${unitType} ${elementType.toLowerCase()}`;
}

/**
 * Validate whether it is possible to place dragged unit in to hovered one
 * @param {number} targetUnitId
 * @param {number} draggedUnitId
 * @returns {boolean}
 */
export function ValidateNestedUnits(targetUnitId, draggedUnitId) {
  const targetUnit = getUnitById(
    targetUnitId,
    Store.state.edit_building.building,
  );
  const draggedUnit = getUnitById(
    draggedUnitId,
    Store.state.edit_building.building,
  );

  if (
    !targetUnit ||
    !draggedUnit ||
    targetUnit.type === targetUnit.parentType
  ) {
    return false;
  }

  if (targetUnit.type !== draggedUnit.type) {
    return true;
  }

  return !checkHasViableChild(draggedUnit);
}

export function flattenUnitChildren(geoUnit, filterType) {
  if (
    !geoUnit.children ||
    !geoUnit.children.length ||
    !Array.isArray(geoUnit.children)
  )
    return [];
  const result = [];
  geoUnit.children.forEach((unit) => {
    if (unit._destroy) return;
    if (filterType) {
      unit.type === filterType && result.push(unit);
    } else {
      result.push(unit);
    }
    const nested = flattenUnitChildren(unit, filterType);
    result.push(...nested);
  });
  return result;
}

export function PrepareBuildingData(unit, index = 1) {
  const result = {};
  const { name, type, children, meta } = unit;
  const unitIndex = (index + 1) * 1000;
  Object.assign(result, {
    name,
    type,
    structure_index: unitIndex,
    meta: { ...meta, index: unitIndex },
  });
  if (children && children.length) {
    result.children_attributes = children
      .filter(({ _destroy }) => !_destroy)
      .map((el, i) => {
        return PrepareBuildingData(el, i);
      });
  }
  return result;
}

export function GetDummyParent(element) {
  if (!element.parentNode) return false;

  let result;
  const parentType =
    element.parentNode.getAttribute && element.parentNode.getAttribute('type');
  if (allParentTypes.includes(parentType)) {
    result = element.parentNode;
  } else {
    result = GetDummyParent(element.parentNode);
  }
  return result;
}

export function GetIndexForDuplicate(initialUnit) {
  const initialUnitIndex = initialUnit.parent.children.findIndex(
    ({ id }) => id === initialUnit.id,
  );
  const nextUnit = initialUnit.parent.children[initialUnitIndex + 1];

  return nextUnit
    ? (nextUnit.structure_index + initialUnit.structure_index) / 2
    : initialUnit.structure_index + 1000;
}

export function CloneUnit(unitsArray, copiesNumber) {
  if (!Array.isArray(unitsArray)) return;

  const hasDifferentParent = unitsArray.some(
    (unit) => unit.parentId !== unitsArray[0].parentId,
  );
  if (hasDifferentParent) throw new Error('Units has not the same parent');

  unitsArray.forEach((unit) => {
    const canAddRoof =
      unit.type === 'Roof' && CheckRoofPasteAbility(unit, unit.parent);

    if (unit.type !== 'Roof' || canAddRoof) {
      for (let i = copiesNumber; i > 0; i -= 1) {
        const cloneIndex = GetIndexForDuplicate(unit);
        const cloneId = Store.state.edit_building.maxUnitId + 1;
        const unitIndex = unit.parent.children.findIndex(
          ({ id }) => id === unit.id,
        );

        const clone = Vue.observable({
          id: cloneId,
          name: '',
          type: unit.type,
          structure_index: cloneIndex,
          parentType: unit.parentType,
          parentId: unit.parentId,
          parent: unit.parent,
          meta: { index: cloneIndex },
          editing: false,
          _create: true,
        });
        AppendUnitAnimation(clone);

        unit.parent.children.splice(unitIndex + 1, 0, clone);

        Store.commit('edit_building/setMaxUnitId', cloneId);

        if (unit.children) {
          Vue.set(clone, 'children', []);
          CreateClonedChildren(unit.children, clone);
        }
      }
    }
  });
}

export function CheckRoofPasteAbility(roof, targetUnit) {
  const hasRoof = targetUnit.children.some(({ type }) => type === 'Roof');
  const cantAddToEntrance =
    targetUnit.type === 'Entrance' &&
    Store.getters['edit_building/roofInBuilding'];
  const cantAddToBuilding =
    targetUnit.type === 'Building' &&
    Store.getters['edit_building/hasRoofInEntrance'];

  let errorMessage;
  if (hasRoof) {
    errorMessage = 'Объект "Кровля" уже создан в рамках данного подъезда';
  } else if (cantAddToEntrance) {
    errorMessage = 'Объект "Кровля" уже создан в рамках одного из подъездов';
  } else if (cantAddToBuilding) {
    errorMessage = 'Объект "Кровля" уже существуют в рамках данного дома';
  }
  errorMessage && EventBus.$bvToast.toast(errorMessage, { variant: 'danger' });

  return !errorMessage;
}

export function CreateClonedChildren(initialChildrenArray, clone) {
  if (!Array.isArray(initialChildrenArray))
    throw new Error('Children are nor an array');

  clone.children = [];

  initialChildrenArray
    .filter(({ _destroy }) => !_destroy)
    .forEach((unit, index) => {
      const cloneId = Store.state.edit_building.maxUnitId + 1;

      const clonedChild = Vue.observable({
        id: cloneId,
        name: '',
        type: unit.type,
        structure_index: index * 1000,
        parentType: clone.type,
        parentId: clone.parentId,
        parent: clone,
        meta: { index: index * 1000 },
        editing: false,
        _create: true,
      });
      AppendUnitAnimation(clonedChild);

      clone.children.push(clonedChild);
      Store.commit('edit_building/setMaxUnitId', cloneId);

      if (unit.children) {
        Vue.set(clonedChild, 'children', []);
        CreateClonedChildren(unit.children, clonedChild);
      }
    });
}

export function getModeByUnit(unit) {
  if (MODE_UNIT_TYPES.includes(unit.type)) return unit.type;
  return getModeByUnit(unit.parent);
}

export function GetUnitsEntrance(unit) {
  if (unit.type === 'Entrance') return unit;
  return GetUnitsEntrance(unit.parent);
}

export function CheckMetaStructure(building) {
  if (!building.meta) Vue.set(building, 'meta', {});
  if (!building.meta.structure) Vue.set(building.meta, 'structure', {});

  for (const setting in basicStructure) {
    if (!building.meta.structure[setting]) {
      Vue.set(building.meta.structure, setting, basicStructure[setting]);
    }
  }
}

export function getValidUnitIndexByName(units, unit, respectParentId = true) {
  return units.findIndex(({ _destroy, name, parentId, type }) => {
    const properUnit = !_destroy && unit.name === name && unit.type === type;
    return respectParentId
      ? properUnit && unit.parentId === parentId
      : properUnit;
  });
}

export function getUserFullName(user) {
  return ['surname', 'name', 'patronymic']
    .map((field) => user[field])
    .filter(Boolean)
    .join(' ');
}
