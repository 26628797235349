<template>
  <div>
    <div class="mb-3 mt-3 d-flex justify-content-between align-items-center">
      <span>
        {{ $t('relay.title') }}
        <span v-if="relay.index">{{ relay.index }}</span>
        <span v-else>{{ index + 1 }}</span>
      </span>
      <b-select-label
        v-if="visibleActions"
        :label="$t('relay.action')"
        :items="relaysActions"
        display="title"
        object-property="value"
        v-model="relay.npr_action"
        class="mt-2 w-50"
        :disabled="disabledActions"
        @select="selectAction"
      />
      <b-input-label
        v-else
        :value="$t('barrier.relay_used')"
        :label="$t('relay.action')"
        class="w-50 mt-2"
        disabled
        type="text"
      />
    </div>
    <div class="mb-3 d-flex justify-content-between align-items-center">
      <span>{{ $t('relay.npr_delay') }}</span>
      <div class="w-50" :key="key">
        <b-input-label
          :label="$t('relay.delay')"
          type="text"
          v-model="relay.npr_delay"
          :disabled="disabledDelay"
          @input="(val) => onInputDelay(val)"
          :ref="`relay${index}`"
          :state="relayState('npr_delay')"
        />
        <b-error
          v-if="
            relaysErrors.$each.$iter[index]['npr_delay'].$invalid && isSubmit
          "
          :error="$t('global.errors.error_validate', [0, 180])"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import BError from '@/components/base/BError';

export default {
  name: 'Relay',
  components: { BError },
  props: {
    barrierId: {
      type: Number,
      default: null,
    },
    relaysActions: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
    relaysErrors: {},
  },
  data() {
    return {
      key: Date.now(),
    };
  },
  computed: {
    ...mapState('barrier', ['barrier']),
    ...mapGetters('barrier', ['getRelay']),

    relay: {
      get() {
        return this.getRelay(this.index);
      },
      set(value) {
        this.setRelay({ index: this.index, camera: value });
      },
    },

    visibleActions() {
      return (
        this.relay.npr_barrier_id == this.barrierId ||
        !this.relay.npr_barrier_id
      );
    },
    disabledActions() {
      return (
        !this.relay.id ||
        (this.relay.npr_barrier_id &&
          this.relay.npr_barrier_id != this.barrierId)
      );
    },
    disabledDelay() {
      return (
        !this.relay.id ||
        (this.relay.npr_barrier_id &&
          this.relay.npr_barrier_id != this.barrierId) ||
        this.relay.npr_action == 'unused'
      );
    },
  },
  methods: {
    ...mapMutations('barrier', ['setRelay']),

    relayState(field) {
      if (!this.errors || !this.isSubmit) {
        return undefined;
      }

      return !this.relaysErrors.$each.$iter[this.index][field].$invalid;
    },

    selectAction(val) {
      if (val == 'unused') {
        this.relay.npr_action = 'unused';
        this.relay.npr_delay = null;
      }

      this.setRelay({ index: this.index, relay: this.relay });
    },

    onInputDelay(value) {
      value = value.replace(/\D/g, '');
      value = Number(value);

      this.relay.npr_delay = value;
      this.setRelay({ index: this.index, relay: this.relay });

      this.key = Date.now();
      this.$nextTick(() => {
        this.$refs['relay' + this.index].focus();
      });
    },
  },
};
</script>

<style></style>
