export const SIGN_IN_COMPONENTS_NAME = {
  DemoSignInForm: 'DemoSignInForm',
  EmailPassword: 'EmailPassword',
  PhoneCode: 'PhoneCode',
  PhoneNumberCode: 'PhoneNumberCode',
  UserName: 'UserName',
};

const AUTH_NETWORKS = {
  APPLE: 'apple',
  GOOGLE: 'google',
};

const AUTH_PROVIDERS = {
  [AUTH_NETWORKS.APPLE]: 'apple.com',
  [AUTH_NETWORKS.GOOGLE]: 'google.com',
};

export const FIREBASE_AUTH = { AUTH_NETWORKS, AUTH_PROVIDERS };
