<template>
  <b-form-group :label-for="`input_${property}`" :label="getTranslate">
    <b-input
      v-model="model[property]"
      :id="`input_${property}`"
      :state="validateState ? validateState(property) : undefined"
    />
  </b-form-group>
</template>

<script>
export default {
  name: 'BFormGroupModel',
  props: {
    model: {
      required: true,
      type: Object,
    },
    property: {
      required: true,
      type: String,
    },
    translate: {
      type: String,
    },
    validateState: {
      type: Function,
    },
  },
  computed: {
    getTranslate() {
      if (this.translate) return this.$t(`${this.translate}.${this.property}`);
      return '';
    },
  },
};
</script>
