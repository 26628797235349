<template>
  <b-modal
    :title="name"
    autoplay
    modal-class="stream-video"
    hide-footer
    ref="modal"
    header-class="stream-video__header"
    @hide="onHideModal"
    id="modal"
  >
    <template #modal-header="{ close }">
      <h5 class="stream-video__title">{{ name }}</h5>
      <div class="stream-video__close">
        <i class="icon-local-times" @click="close()"></i>
      </div>
    </template>
    <div id="stream">
      <StreamVideo
        autoplay
        :background="live_snapshot_url"
        :device-id="deviceId"
        :device-type="deviceType"
        ref="StreamVideo"
        :height="heightBlock"
      ></StreamVideo>
    </div>
  </b-modal>
</template>

<script>
import StreamVideo from '@/components/intercom/StreamVideo.vue';
import { request3 } from '@/api/request';
import { API_URLS } from '@/consts';
import { getManageId } from '@/helpers';

export default {
  components: {
    StreamVideo,
  },
  props: {
    deviceId: {
      required: true,
    },
    clientId: {
      type: [Number, String],
      default: () => getManageId(),
    },
    deviceType: {
      type: String,
      required: true,
    },
    name: {
      type: String,
    },
    background: {
      type: String,
    },
  },
  data() {
    return {
      block: null,
      heightBlock: null,
      live_snapshot_url: this.background,
      isOpen: false,
    };
  },
  created() {
    window.addEventListener('resize', this.resizeBlock);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeBlock);
  },
  methods: {
    async show() {
      this.$refs.modal.show();
      await this.$nextTick();
      this.block = document.getElementById('stream');
      this.updateSnapshot();
      this.$refs.StreamVideo.play();
      this.isOpen = true;
      this.resizeBlock();
    },
    async updateSnapshot() {
      this.live_snapshot_url = await request3
        .get(API_URLS.backendManage.cctv.cameras.devices.fetch(this.clientId), {
          params: {
            q: {
              id_in: [this.deviceId],
            },
          },
        })
        .then((res) => res.data.cameras[0].live_snapshot_url);
    },
    onHideModal() {
      this.$refs.StreamVideo.stop();
      this.isOpen = false;
    },
    resizeBlock() {
      const modalContent = document.querySelector('.modal-content');
      const documentHeight = document.documentElement.clientHeight;
      const documentWidth = document.documentElement.clientWidth;

      if (!modalContent || !this.isOpen) {
        return;
      }

      let heightPxs = documentHeight - 100;
      let widthPxs = (heightPxs * 16) / 9;

      if (documentWidth < widthPxs) {
        widthPxs = documentWidth - 100;
        heightPxs = (9 * widthPxs) / 16;
      }

      this.heightBlock = heightPxs + 'px';
      modalContent.style.width = widthPxs + 'px';
    },
  },
};
</script>
<style lang="scss">
.stream-video {
  backdrop-filter: blur(5px);
  display: flex !important;
  padding: 0 !important;

  .modal-content {
    clip-path: inset(1px);
  }
}
.modal-content .stream-video {
  &__header {
    padding: 15px 20px;
    padding-top: 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
  &__close {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__title {
    margin: 0;
  }
}
.stream-video .video-wrapper {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.stream-video .video-components {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.stream-video .modal-dialog {
  min-width: 100px;
  max-width: none;
}
</style>
