<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
import { request2 } from '@/api/request';
import EventBus from '@/packs/EventBus';
import i18n from '@app/i18n';

export default {
  name: 'ConfirmModalSlot',
  props: {
    apiUrl: {
      type: String,
    },
    method: {
      type: String,
    },
    title: {
      type: String,
    },
    message: {
      type: String,
      required: true,
      validator(val) {
        return val.length > 0;
      },
    },
    okVariant: {
      type: String,
      default: 'danger',
    },
    okTitle: {
      type: String,
      default: i18n.t('global.delete'),
    },
    emit: {
      type: String,
    },
    reload: {
      type: Boolean,
    },
    data: {
      type: String,
    },
    dataId: {
      type: String,
      default: null,
    },
  },
  computed: {
    parseData() {
      try {
        return this.data ? JSON.parse(this.data) : null;
      } catch (e) {
        console.warn('ConfirmModalSlot parse data', e); // TODO add Rollbar
        return this.data;
      }
    },
  },
  mounted() {
    const slot = this.$el.childNodes[0];
    if (slot) {
      slot.onclick = () => {
        this.onShowModal();
      };
    }
  },
  methods: {
    onShowModal() {
      this.$bvModal
        .msgBoxConfirm(this.message, {
          title: this.title,
          okTitle: this.okTitle,
          okVariant: this.okVariant,
          bodyClass: 'modal-body__confirm',
          headerClass: 'border-0',
        })
        .then(async (res) => {
          if (res) {
            if (this.method && this.apiUrl) {
              request2(
                { method: this.method, url: this.apiUrl, ids: this.dataId },
                this.parseData,
              )
                .then(() => {
                  if (this.reload) location.reload();
                  if (this.emit.trim()) EventBus.$emit(this.$emit, res);
                })
                .catch((err) => {
                  this.$bvToast.toast(err, { variant: 'danger' });
                });
            } else {
              if (this.reload) location.reload();
              if (this.emit.trim()) EventBus.$emit(this.emit);
            }
          }
        });
    },
  },
};
</script>

<style scoped></style>
