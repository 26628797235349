import EventBus from '@/packs/EventBus';

export * from './ws';

export const RegEventBusMixin = {
  name: 'RegEventBusMixin',
  data() {
    return {
      eventMixinsArray: [],
    };
  },
  methods: {
    registerInEventBus(name, callback) {
      if (this.eventMixinsArray.includes(name)) return;
      this.eventMixinsArray.includes(name);
      EventBus.$on(name, callback);
      this.eventMixinsArray.push(name);
    },
  },
  beforeDestroy(to, from, next) {
    EventBus.$off(this.eventMixinsArray);
    return next && next();
  },
};

export const KeyNameMixin = {
  name: 'KeyNameMixin',
  methods: {
    /** @param {ResKey} key */
    formatterKeyName(key) {
      const keyNumber = `${this.$t('intercom.key.title')} ${key.id}`;
      return key.name ? `${keyNumber} (${key.name})` : keyNumber;
    },
  },
};

export const SubscribeToEvents = {
  data() {
    return {
      subscribeEventsMixinsArray: [],
    };
  },
  methods: {
    registerEventListener(type, listener) {
      document.addEventListener(type, listener);
      this.subscribeEventsMixinsArray.push({ type, listener });
    },
    registerEventClick(listener) {
      this.registerEventListener('click', listener);
    },
    registerEventEsc(listener) {
      this.registerEventListener('keydown', (event) => {
        if (event.keyCode === 27) {
          listener && listener();
        }
      });
    },
  },
  beforeDestroy(to, from, next) {
    this.subscribeEventsMixinsArray.forEach((event) => {
      document.removeEventListener(event.type, event.listener);
    });
    return next && next();
  },
};
