<template>
  <div class="sign-in-container">
    <slot
      :send-code="sendCode"
      :code="code"
      :errors="errors"
      :on-input-code="onInputCode"
    ></slot>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'CodeConfirmForm',
  props: {
    phone: Number,
  },
  data() {
    return {
      code: '',
      errors: undefined,
      codeAccept: false,
    };
  },
  methods: {
    onInputCode(event) {
      this.code = event.target.value;
    },
    sendCode() {
      const form = document.querySelector('form');
      if (this.codeAccept) {
        form.submit();
      } else {
        const token = document.head
          .querySelector('meta[name=csrf-token]')
          .getAttribute('content');
        axios.defaults.headers.common['X-CSRF-Token'] = token;
        return axios
          .post('/api/v1/users/check_code', {
            params: {
              code: this.code,
              phone: this.phone,
            },
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(({ data }) => {
            if (data.errors) {
              this.errors = data.errors;
            } else {
              this.codeAccept = true;
              this.errors = undefined;
              form.submit();
            }
          });
      }
    },
  },
};
</script>
