<template>
  <div class="row">
    <div class="col-md-6">
      <div class="geo-units-autocomplete">
        <div class="dropdown">
          <BInputLabel
            ref="device_id"
            v-model="device_id"
            @keyup="onKeyUp"
            type="text"
            :label="placeholderId"
            class="autocomplete-results-dropdown"
            :disabled="disabled"
            :name="deviceIdName"
          />
          <div class="close-icon-container" v-if="!disabled">
            <i
              class="icon-local-times"
              v-if="!empty_device_id"
              @click="deviceIdReset"
            ></i>
          </div>
          <div v-show="!empty_results" class="dropdown-menu py-0">
            <div
              v-for="result in results"
              :key="result.key"
              @click="choosed(result)"
              :data-id="result.key"
              :data-name="result.key"
              class="dropdown-item"
            >
              {{ result.human_name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="geo-units-autocomplete">
        <div class="dropdown">
          <BSelectLabel
            id="device_type"
            :items="device_types"
            :label="placeholderType"
            :disabled="disabled"
            display="name"
            v-model="device_type"
            object-property="id"
            @selected="saveForm"
          />
        </div>
      </div>
    </div>
    <input
      type="hidden"
      :id="`${deviceTypeName}`"
      :name="`${deviceTypeName}`"
      :value="device_type"
    />
  </div>
</template>

<script>
import { isEmpty, isNull, debounce } from 'lodash';
import BInputLabel from '@/components/base/BInputLabel';
import BSelectInputLabel from '@/components/base/BSelectInputLabel';

export default {
  name: 'idTypeSearch',
  components: {
    BInputLabel,
    BSelectInputLabel,
  },
  props: {
    namespace: {
      type: String,
      required: true,
    },
    clientId: {
      type: Number,
      required: true,
    },
    buildingId: {
      type: Number,
      required: true,
    },
    debouncedTts: {
      type: Number,
      default: 200,
    },
    deviceId: {
      type: String,
      default: null,
    },
    deviceType: {
      type: String,
      default: null,
    },
    deviceTypes: Array,
    deviceIdName: String,
    deviceTypeName: String,

    disabled: {
      type: Boolean,
      default: false,
    },
    placeholderId: String,
    placeholderType: String,
    saveFormId: String,
  },
  data() {
    return {
      debouncedSearch: null,
      device_id: null,
      device_type: null,
      device_types: [],
      results: [],
    };
  },
  computed: {
    human_results() {
      if (isEmpty(this.results)) {
        return [];
      } else {
        return [];
      }
    },
    empty_results() {
      return isEmpty(this.results);
    },
    empty_device_id() {
      return isNull(this.device_id);
    },
    debounced() {
      return this.debouncedTts > 0;
    },
  },
  mounted() {
    this.$refs.device_id.blur();
    if (this.debounced) {
      this.initDebounce();
    }
    this.device_types = this.deviceTypes;
    this.device_id = this.deviceId;
    this.device_type = this.deviceType;
  },
  methods: {
    choosed(id_type_pack) {
      this.device_id = id_type_pack.id;
      this.device_type = id_type_pack.type;
      this.resultsReset();
      this.saveForm();
    },
    onKeyUp() {
      if (this.debounced) {
        this.debouncedSearch();
      } else {
        this.search();
      }
    },
    resultsOpen() {
      $('.autocomplete-results-dropdown').dropdown('show');
      this.$refs.device_id.focus();
    },
    resultsClose() {
      $('.autocomplete-results-dropdown').dropdown('hide');
      this.$refs.device_id.focus();
    },
    resultsReset() {
      this.results = [];
    },
    saveForm() {
      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    deviceIdReset() {
      this.device_id = null;
      this.saveForm();
    },
    async search() {
      if (isEmpty(this.device_id)) return;
      let prms = {
        id: this.device_id,
        limit: 8,
      };

      this.results = await request2(
        {
          method: 'get',
          url: `/api/backend/manage/${this.clientId}/${this.namespace}/buildings/${this.buildingId}/id_type_search`,
        },
        prms,
      ).then((r) => {
        return r.id_types;
      });
    },
    initDebounce() {
      this.debouncedSearch = debounce(() => {
        this.search();
      }, this.debouncedTts);
    },
  },
  watch: {
    device_id(value) {
      if (isNull(value) || value.length < 1) {
        this.results = [];
      }
    },
    results(value) {
      if (!isEmpty(value)) {
        this.resultsOpen();
      } else {
        this.resultsClose();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../assets/styles/components/variables';
.geo-units-autocomplete {
  position: relative;
  .close-icon-container {
    padding: 5px;
    cursor: pointer;
    position: absolute;
    right: 13px;
    top: 13px;
  }
  fieldset {
    width: 100%;
  }
  .dropdown-menu {
    overflow-x: auto;
    .dropdown-item {
      border: 1px solid #edeef0;
      height: 50px;
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover {
        background: $table-hover-bg;
      }
    }
  }
}
</style>
