<template>
  <b-button v-if="value.npr_action" @click="triggerRelay">
    {{ value.npr_action_localized }} {{ directions }}
  </b-button>
</template>
<script>
import NprRelay from './RelayActions.vue';
import { ApiBackendRelays } from '@/api';
export default {
  name: 'NprRelayActions',
  components: {
    NprRelay,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    cameras: {
      type: Array,
      default: new Array(),
    },
  },
  computed: {
    selectedCameras() {
      return this.cameras.filter((camera) => camera.relay_id == this.value.id);
    },
    directions() {
      let allDirections = this.selectedCameras.map((camera) => {
        return camera.npr_direction;
      });
      allDirections = _.uniq(allDirections);
      if (allDirections.length > 0) {
        if (allDirections.length > 1) {
          return `(${allDirections.join('/')})`;
        }
        return `(${allDirections.join()})`;
      }
      return allDirections.join();
    },
  },
  methods: {
    relayTriggerSuccess() {
      this.$bvToast.toast(this.$t('relay.success'), {
        variant: 'success',
      });
    },
    relayTriggerError() {
      this.$bvToast.toast(this.$t('relay.error'));
    },
    async triggerRelay() {
      ApiBackendRelays.createWithId(this.value.id, 'unlock', {
        source: 'web_unlock',
      })
        .then(() => this.relayTriggerSuccess())
        .catch(() => this.relayTriggerError());
    },
  },
};
</script>
