<template>
  <b-form-group
    :label="label"
    label-class="float-label-active"
    :data-title="label"
  >
    <i v-if="icon" class="float-icon" :class="icon" @click="onIconClick"></i>
    <div class="float-input form-control overflow-ellipsis">{{ value }}</div>
  </b-form-group>
</template>

<script>
export default {
  name: 'BInputFake',
  props: {
    value: {},
    label: String,
    icon: String,
  },
  methods: {
    onIconClick() {
      this.$emit('click-on-icon', this.value);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/styles/components/variables';

.float-icon {
  cursor: pointer;
  position: absolute;
  right: 30px;
  margin-top: 20px;
  font-size: 12px;
  color: #bfc5d1;
}
.overflow-ellipsis {
  padding-right: 35px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
