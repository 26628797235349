import EventBus from '@/packs/EventBus';

export const PreloaderMixin = {
  data() {
    return {
      loader: null,
      isMounted: false,
    };
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.loadingContainer,
      color: '#03A9F4',
      loader: this.loaderType || 'spinner',
      width: 64,
      height: 64,
    });
  },
  methods: {
    stopLoading() {
      this.loader.hide();
      this.timer = setTimeout(() => {
        this.isMounted = true;
      }, 150);
    },
    startLoading() {
      this.loader = this.$loading.show({
        container: this.$refs.loadingContainer,
        color: '#03A9F4',
        loader: this.loaderType || 'spinner',
        width: 64,
        height: 64,
      });
    },
  },
};

export const EventBusMixin = {
  name: 'RegEventBusMixin',
  data() {
    return {
      EventBusComponentEvents: [],
    };
  },
  computed: {
    EventBusRootEvents() {
      return EventBus._events;
    },
  },
  methods: {
    EventBusOn(name, callback) {
      const existingSubscription = this.EventBusComponentEvents.find(
        (event) => event.name === name,
      );
      if (existingSubscription) return;

      EventBus.$on(name, callback);
      this.EventBusComponentEvents.push({ name, callback });
    },
    EventBusEmmit(name, payload) {
      EventBus.$emit(name, payload);
    },
  },
  beforeDestroy() {
    this.EventBusComponentEvents.forEach(({ name, callback }) => {
      EventBus.$off(name, callback);
    });
  },
};

export const KeyNameMixin = {
  name: 'KeyNameMixin',
  methods: {
    formatterKeyName(key) {
      const keyNumber = `${this.$t('intercom.key.title')} ${key.id}`;
      return key.name ? `${keyNumber} (${key.name})` : keyNumber;
    },
  },
};

export const RouterPushToParentMixin = {
  methods: {
    routerPushToParent({ usePrevQuery = false, query = {} } = {}) {
      const to = { query };
      const [{ name }] = this.$route.matched.splice(-2, 1);
      to.name = name;
      if (usePrevQuery) {
        to.query = query;
      }
      this.$router.push(to).catch(() => {});
    },
  },
};

export const YaMapLangMixin = {
  name: 'YaMapLangMixin',
  computed: {
    yaMapLang() {
      const yaMapLocales = {
        ru: 'ru_RU',
        en: 'en_US',
        tr: 'tr_TR',
      };
      return yaMapLocales[this.$cookies.get('locale')];
    },
  },
};
