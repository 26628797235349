<template>
  <div class="portlet w-100 mb-5">
    <h5 class="col-lg-12">{{ $t('barrier.title') }}</h5>
    <b-form-validate
      ref="barrierForm"
      @submit="onOk"
      :validations="validations"
      :model="barrier"
    >
      <template slot-scope="{ validateState, v }">
        <b-form-row class="mb-3">
          <b-col cols="6">
            <b-input-label
              label="Название"
              v-model="barrier.name"
              :state="validateState('name')"
            />
            <b-error
              v-if="!v.model.name.required && isSubmit"
              :error="$t('global.errors.not_empty')"
            />
            <b-form-checkbox v-model="barrier.allow_emergency">
              {{ $t('barrier.allow_emergency') }}
            </b-form-checkbox>
          </b-col>

          <b-col cols="6" class="gap-3 d-grid">
            <div>
              <geo-units-autocomplete
                :placeholder="$t('barrier.geo_unit')"
                geo-unit-property="short_name"
                :additional-query-params="{
                  type_in: ['Building', 'Territory', 'ParkingArea'],
                  district_id_not_null: true,
                }"
                context-property="short_name"
                :value="barrier.geo_unit_id"
                :client-id="clientId"
                :geo-unit="selectedGeoUnit"
                @update-unit="selectGeoUnit"
                :state="validateState('geo_unit_id')"
                class="mb-0"
              />
              <b-error
                v-if="!v.model.geo_unit_id.required && isSubmit"
                :error="$t('devices.errors.not_empty')"
              />
            </div>
            <div>
              <b-input-label
                :label="$t('barrier.repeat_delay')"
                :value="barrier.repeat_delay"
                :state="validateState('repeat_delay')"
                @input="(val) => onInputDelay(val)"
                :key="key"
                ref="repeatDelayBarrier"
                class="mb-0"
              />
              <b-error
                v-if="
                  (!v.model.repeat_delay.minValue ||
                    !v.model.repeat_delay.maxValue) &&
                  isSubmit
                "
                :error="$t('global.errors.error_validate', [0, 300])"
              />
            </div>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col cols="6">
            <relays-list
              :value="intercom"
              :relays-actions="relaysActions"
              :barrier-id="barrier.id"
              :client-id="clientId"
              :validateState="validateState"
              :v="v"
              :isSubmit="isSubmit"
            />
          </b-col>

          <b-col cols="6">
            <cameras-list
              :geo-unit-id="barrier.geo_unit_id"
              :barrier-id="barrier.id"
              :client-id="clientId"
              :driving-directions="drivingDirections"
              :mode-recognitions="modeRecognitions"
              :validateState="validateState"
              :v="v"
              :isSubmit="isSubmit"
            />
          </b-col>
        </b-form-row>
      </template>
    </b-form-validate>
    <div class="gap-3 d-flex">
      <b-button @click="onOk">{{ $t('button.save') }}</b-button>
      <b-button variant="outline-primary" @click="onCancel">
        {{ $t('button.cancel') }}
      </b-button>
    </div>
  </div>
</template>
<script>
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete';
import BError from '@/components/base/BError';
import RelaysList from '@/vue_slim/manage_components/npr/form/RelaysList';
import CamerasList from '@/vue_slim/manage_components/npr/form/CamerasList';
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';
import { isEmpty } from 'lodash';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';

export default {
  name: 'BarrierForm',
  components: {
    GeoUnitsAutocomplete,
    BError,
    CamerasList,
    RelaysList,
  },
  props: {
    value: {
      type: Object,
      default: {},
    },
    geoUnit: {
      type: Object,
      default: null,
    },
    barrierIntercom: {
      type: Object,
      default: {},
    },
    clientId: {
      type: Number,
      required: true,
    },
    relaysActions: {
      type: Array,
      required: true,
    },
    drivingDirections: {
      type: Array,
      default: null,
    },
    modeRecognitions: {
      type: Array,
      default: null,
    },
    barrierCameras: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      intercom: this.barrierIntercom,
      selectedGeoUnit: this.geoUnit,
      isSubmit: false,
      key: Date.now(),
      errors: [],
    };
  },
  async created() {
    this.setBarrier(this.value);
    this.setCameras(this.barrierCameras);

    let relays = null;
    if (this.barrierIntercom?.id) {
      relays = await request3
        .get(API_URLS.backendManage.intercoms.fetch(this.clientId), {
          params: {
            q: {
              uuid_or_name_or_geo_unit_short_name_cont_any:
                this.barrierIntercom.uuid,
            },
          },
        })
        .then((res) => res.data[0].relays);

      this.setRelays(relays);
    } else {
      this.intercom = {};
      this.resetRelays();
    }

    if (this.selectedGeoUnit) {
      this.barrier.geo_unit_id = this.selectedGeoUnit.id;
      this.barrier.district_id = this.selectedGeoUnit.district_id;
    }
  },
  computed: {
    ...mapState('barrier', ['barrier']),
    ...mapGetters('barrier', ['getBarrier']),

    validations() {
      return {
        name: {
          required,
        },
        geo_unit_id: {
          required,
        },
        repeat_delay: {
          required,
          minValue: minValue(0),
          maxValue: maxValue(300),
        },
        cameras_attributes: {
          required,
          $each: {
            uuid: {
              required,
            },
            relay_id: {
              required,
            },
            npr_direction: {
              required,
            },
            attempt_time: {
              required,
              minLength: minValue(1),
              maxLength: maxValue(180),
            },
          },
        },
        relays_attributes: {
          required,
          $each: {
            id: { required },
            npr_delay: {
              minLength: minValue(0),
              maxLength: maxValue(180),
            },
          },
        },
      };
    },

    barrier: {
      get() {
        return this.getBarrier;
      },
      set(value) {
        this.setBarrier(value);
      },
    },
  },
  methods: {
    ...mapMutations('barrier', [
      'setBarrier',
      'setCameras',
      'setRelays',
      'resetRelays',
      'setBarrierDelay',
    ]),
    ...mapActions('barrier', ['create', 'update']),

    onOk() {
      this.isSubmit = true;

      if (!this.$refs.barrierForm.validate()) {
        return;
      }

      const barrierVal = Object.assign({}, this.barrier);

      barrierVal.relays_attributes = barrierVal.relays_attributes.filter(
        (relay) =>
          relay.id &&
          relay.npr_action &&
          relay.npr_action !== 'unused' &&
          (relay.npr_barrier_id == this.barrier.id ||
            relay.npr_barrier_id == null),
      );

      if (this.barrier.id) {
        this.update({ clientId: this.clientId, barrier: barrierVal })
          .then(() => {
            this.goBarrierPage();
          })
          .catch((err) => {
            this.errors = err.response.data.errors;
            this.$bvToast.toast(err.response.data.errors.base);
          });
      } else {
        this.create({ clientId: this.clientId, barrier: barrierVal })
          .then(() => {
            this.goBarrierPage();
          })
          .catch((err) => {
            this.errors = err.response.data.errors;
            this.$bvToast.toast(err.response.data.errors.base);
          });
      }
    },
    goBarrierPage() {
      window.location.replace(
        `/manage/${this.clientId}/npr/districts/${this.barrier.district_id}/geo_units/${this.barrier.geo_unit_id}/barriers/${this.barrier.id}`,
      );
    },
    onCancel() {
      if (this.barrier.id) {
        this.goBarrierPage();
      } else {
        window.location.replace(`/manage/${this.clientId}/npr/districts`);
      }
    },

    selectGeoUnit(geoUnit) {
      if (isEmpty(geoUnit)) {
        this.selectedGeoUnit = {};
        this.barrier.geo_unit_id = null;
        this.barrier.district_id = null;
      } else {
        this.barrier.geo_unit_id = geoUnit.id;
        this.barrier.district_id = geoUnit.district_id;
        this.selectedGeoUnit = geoUnit;
      }
    },

    onInputDelay(value) {
      value = value.replace(/\D/g, '');
      value = Number(value);
      this.setBarrierDelay(value);

      this.key = Date.now();
      this.$nextTick(() => {
        this.$refs['repeatDelayBarrier'].focus();
      });
    },
  },
};
</script>
