<template>
  <div>
    <BDropdownInput
      v-model="selected_id"
      :disabled="disabled"
      :label="placeholder"
      :handler="fetch"
      :selected="status"
      :state="state"
      :debouncedTts="debouncedTts"
      @clear="onClear"
      @focus="onFocus"
      @select="onSelect"
      @select:item="onSelectItem"
      :minWordLength="minWordLength"
      compare-property="id"
      :display-property="'title'"
      comboBox
    />
  </div>
</template>

<script>
import BDropdownInput from '@/components/base/BDropdownInput';

export default {
  name: 'StatusesAutocomplete',
  components: {
    BDropdownInput,
  },
  props: {
    debouncedTts: {
      type: Number,
      default: 200,
    },
    placeholder: String,
    state: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    items: Array,
    value: {
      type: String,
      default: null,
    },
    minWordLength: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selected_id: null,
      status: null,
    };
  },
  mounted() {
    if (this.value && this.value != null && this.value != undefined) {
      this.status = this.items.find((item) => item.id == this.value);
      this.selected_id = this.status.id;
    }
  },
  methods: {
    onSelect(value) {
      this.$emit('input', value);
      this.$emit('select', value);
      this.selected_id = value;
    },
    onSelectItem(item) {
      this.status = item;
      this.$emit('select:item', item);
    },
    onClear() {
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
    fetch(val) {
      let statuses = this.items;
      if (val) {
        statuses = this.items.filter((item) => {
          return item.title.toLowerCase().includes(val.toLowerCase());
        });
      }
      return new Promise((resolve) => resolve(statuses));
    },
  },
};
</script>
