<template>
  <div class="building-structure">
    <div class="entrances">
      <div class="entrance" v-for="entrance in structure" :key="entrance.id">
        <div class="entrance-title">
          <span class="entrance-title__text">{{ $t("geo_unit.type.Entrance", [entrance.name])}}</span>
          <span class="store-rooms ml-2" v-if="entrance.storeRooms">
            {{ entrance.storeRooms.length }} {{$t("general.units")}}
          </span>
        </div>
        <div class="floors">
          <div class="floor" v-for="floor in entrance.children" :key="floor.id">
            <div class="floor-title pointer">
              {{ floor.name }}
            </div>
            <div class="floor-elements">
              <template v-for="floorElement in floor.children">
                <div
                  :key="floorElement.id"
                  class="apartment floor-element pointer"
                  v-if="floorElement.type === 'Apartment'"
                >
                  {{ floorElement.name }}
                </div>
                <div
                  :key="floorElement.id"
                  class="section pointer"
                  :style="{ flex: sectionFlexCount(floorElement) }"
                  v-if="floorElement.type === 'Section'"
                >
                  <div
                    class="apartment"
                    v-for="apartment in floorElement.children"
                    :key="apartment.id"
                  >
                    {{ apartment.name }}
                  </div>
                  <div
                    :key="floorElement.id"
                    class="store-rooms floor-element pointer"
                    v-if="floorElement.storeRooms"
                  >
                    {{ floorElement.storeRooms.length }} {{$t("general.units")}}
                  </div>
                </div>
              </template>
              <div class="store-rooms pointer" v-if="floor.storeRooms">
                {{ floor.storeRooms.length }} {{$t("general.units")}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sortBy } from 'lodash';

export default {
  name: 'BuildingStructure',
  props: {
    geoUnits: {},
    building: {},
  },
  computed: {
    structure() {
      const entrances = this.geoUnits.filter(({ type }) => type === 'Entrance');
      entrances.forEach((entrance) => this.findChildren(entrance));
      return entrances;
    },
  },
  mounted() {
    // console.log('geoUnits', this.geoUnits);
    // this.structure = this.geoUnits.filter(({type}) => type==='Entrance')
  },
  methods: {
    findChildren(parent) {
      if (['Apartment', 'StoreRoom'].includes(parent.type)) return;
      const storeRooms = [];
      const geoUnits = sortBy(this.geoUnits, 'position');
      const children = geoUnits.filter((geoUnit) => {
        if (geoUnit.parent_id === parent.id) {
          if (geoUnit.type === 'StoreRoom') {
            storeRooms.push(geoUnit);
            return false;
          }
          return true;
        }
        return false;
      });
      // console.log('children', children.length);
      if (storeRooms.length) parent.storeRooms = storeRooms;
      if (children.length) {
        parent.children = sortBy(children, 'meta.index');
        children.forEach((geoUnit) => this.findChildren(geoUnit));
      }
    },
    sectionFlexCount({ children, storeRooms }) {
      return (
        ((children && children.length) || 0) +
        ((storeRooms && storeRooms.length) || 0)
      );
    },
  },
};
</script>

<style lang="scss">
.building-structure {
  display: inline-block;
  .apartment {
    display: flex;
    min-width: 60px;
    height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background-color: #03a9f4;
    color: #fff;
    margin: 0 5px;
    padding: 0 5px;
    /*flex-shrink: 0;*/
    &:hover {
      background: #0273a7;
      border-radius: 3px;
    }
  }
  .entrances {
    display: flex;
    .entrance {
      flex-direction: column-reverse;
      display: flex;
      .entrance-title {
        display: flex;
        justify-content: center;
        align-items: center;
        .entrance-title__text {
          font-weight: bold;
        }
      }
    }
  }
  .floors {
    flex-direction: column-reverse;
    display: flex;
    .floor {
      display: flex;
      min-height: 38px;
      margin: 2px;
      .floor-title {
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .floor-title {
        &:hover {
          background: #b7e1f5;
          border-radius: 3px;
        }
      }
      .floor-elements {
        display: flex;
        align-items: center;
        width: 100%;
        .floor-element {
          display: flex;
          flex: 1;
        }
      }
    }
  }
  .section {
    display: flex;
    padding: 5px;
    border-radius: 5px;
    background-color: rgba(3, 169, 244, 0.1);
    margin: 0 5px;
    div {
      display: flex;
      flex: 1;
    }
  }
  .store-rooms {
    cursor: pointer;
    border: 1px solid #03a9f4;
    background: white;
    display: flex;
    min-width: 60px;
    height: 24px;
    margin: 0 5px;
    padding: 0 5px;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    &:hover {
      background: #b7e1f5;
    }
  }
}
</style>
