<template>
  <div class="portlet">
    <div v-if="dataLoadingComplete">
      <div v-if="hintsFullPresent" class="text-right mb-2" style="margin-top: -16px;">
        <button class="btn btn-primary-outline text-primary" @click="showToggle">{{ previewMessage }}</button>
      </div>
      <transition name="fade">
        <div v-if="previewMode" key="preview" class="row row-md">
          <div v-for="hint in hints.slice(0,3)" class="col-12 col-sm-6 col-md-4 col-xl-4">
            <div class="mb-3 card information-count-card standard" style="min-height: auto;">
              <div class="px-3 pt-3 pb-1 d-flex align-items-start">
                <div :class="'text-' + hint.color + ' mr-2'">
                  <div class="icon h3 m-0">
                    <i :class="iconByColor(hint.color)" />
                  </div>
                </div>
                <div v-html="hint.message" class="mt-1">
                  {{ hint.message }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="row row-md">
          <div v-for="hint in hints" key="full" class="mb-3 card information-count-card standard" style="min-height: auto;">
            <div class="px-3 pt-3 pb-1 d-flex align-items-start justify-content-between">
              <div class="d-flex">
                <div :class="'text-' + hint.color + ' mr-2'">
                  <div class="icon h3 m-0">
                    <i :class="iconByColor(hint.color)" />
                  </div>
                </div>
                <div v-html="hint.message" class="mt-1">
                  {{ hint.message }}
                </div>
              </div>
              <div class="text-right text-muted">
                {{ hint.weight }}
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-else>
      <div
        class="d-flex flex-column justify-content-center align-items-center h-100 w-100 preloader"
      >
        <div class="d-flex justify-content-center align-items-center">
          <b-spinner
            small
            variant="primary"
            type="grow"
            class="spinner-small mr-2"
          ></b-spinner>
          <b-spinner
            small
            variant="primary"
            type="grow"
            class="spinner-small mr-2"
          ></b-spinner>
          <b-spinner
            small
            variant="primary"
            type="grow"
            class="spinner-small mr-2"
          ></b-spinner>
        </div>
        <div class="preloader-text mt-2">{{ $t("global.loading") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue';

export default {
  name: 'BuildingStructure',
  props: {
    namespace: {
      type: String,
      required: true,
    },
    isAdmin: {
      required: true,
      type: Boolean,
    },
    buildingId: {
      required: true,
      type: Number,
    },
    clientId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      hints: [],
      previewMode: true,
      dataLoadingComplete: false,
    };
  },
  computed: {
    previewMessage() {
      return this.previewMode ? this.$t("global.show_all") : this.$t("global.hide");
    },
    hintsFullPresent() {
      return this.isAdmin && this.hints.length > 3;
    },
  },
  mounted() {
    this.getHints();
  },
  methods: {
    async getHints() {
      this.hints = await request2(
        {
        method: 'get',
        url: `/api/backend/manage/${this.clientId}/${this.namespace}/buildings/${this.buildingId}/hint_messages`,
      }).then((request2Result) => {
        this.dataLoadingComplete = true;
        return request2Result;
      });
    },
    showToggle() {
      this.previewMode = !this.previewMode;
    },
    iconByColor(color) {
      return color === 'danger' ? 'icon-local-error' : 'icon-local-bell';
    },
  },
  components: {
    BSpinner,
  },
};
</script>
