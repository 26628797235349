<template>
  <div class="card information-count-card px-4 py-3">
    <div class="row row-md">
      <div class="col-auto d-none d-lg-block">
        <div
          :class="
            'object-condition object-condition-' +
            devicesConditionColor +
            ' object-condition-' +
            devicesCondition +
            ' mr-2'
          "
        >
          {{ devicesCondition < 0 ? '-' : devicesCondition }}
          <pie-chart
            :percent="devicesCondition < 0 ? 0 : devicesCondition"
            :stroke-width="1"
            :stroke-opacity="0.8"
            :opacity="0.8"
            label=" "
            label-small=" "
            class="object-condition-segment"
          />
        </div>
      </div>
      <div class="col-12 col-lg">
        <div class="row align-items-center">
          <div
            class="col-12 col-lg-auto font-size-16px font-weight-bold my-1 my-lg-2"
          >
            {{ deviceName }}
          </div>
          <div class="col-12 col-lg">
            <div
              v-if="kindGroup"
              class="btn-group mb-1"
              role="group"
              aria-label="Resources"
            >
              <button
                type="button"
                class="btn btn-sm"
                :class="kind == 'sum' ? 'btn-info' : 'btn-light-grey'"
                @click="kind = 'sum'"
              >
                {{ $t('global.general') }}
              </button>
              <button
                type="button"
                class="btn btn-sm"
                :class="kind == 'water' ? 'btn-info' : 'btn-light-grey'"
                @click="kind = 'water'"
              >
                {{ $t('meters.water') }}
              </button>
              <button
                type="button"
                class="btn btn-sm"
                :class="kind == 'heat' ? 'btn-info' : 'btn-light-grey'"
                @click="kind = 'heat'"
              >
                {{ $t('meters.heat') }}
              </button>
              <button
                type="button"
                class="btn btn-sm"
                :class="kind == 'electro' ? 'btn-info' : 'btn-light-grey'"
                @click="kind = 'electro'"
              >
                {{ $t('meters.electricity') }}
              </button>
            </div>
          </div>
        </div>
        <div class="row mt-2 pr-lg-5">
          <div class="col-6 col-lg mb-2 mb-lg-0">
            <div class="font-size-12px text-muted">
              {{ $t('global.total') }}
            </div>
            <div class="font-size-18px">
              <a class="simple-link" :href="kindStats.sum[1]">
                {{ kindStats.sum[0] }}
              </a>
            </div>
          </div>
          <div class="col-6 col-lg mb-2 mb-lg-0">
            <div class="font-size-12px text-muted">
              {{ $t('global._online') }}
            </div>
            <div class="font-size-18px text-success">
              <a class="simple-link" :href="kindStats.online[1]">
                {{ kindStats.online[0] }}
              </a>
            </div>
          </div>
          <div class="col-6 col-lg">
            <div class="font-size-12px text-muted">
              {{ $t('meters.with_error') }}
            </div>
            <div class="font-size-18px text-warning">
              <a class="simple-link" :href="kindStats.broken[1]">
                {{ kindStats.broken[0] }}
              </a>
            </div>
          </div>
          <div class="col-6 col-lg">
            <div class="font-size-12px text-muted">
              {{ $t('global.no_connection') }}
            </div>
            <div class="font-size-18px text-danger">
              <a class="simple-link" :href="kindStats.offline[1]">
                {{ kindStats.offline[0] }}
              </a>
            </div>
          </div>
          <div v-if="showUnlinked" class="col-6 col-lg mt-2 mt-lg-0">
            <div class="font-size-12px text-muted">
              {{ $t('meters.not_bound') }}
            </div>
            <div class="font-size-18px text-muted">
              <a class="simple-link" :href="kindUnlinked[1]">
                {{ kindUnlinked[0] }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from '@/components/base/PieChart.vue';

export default {
  props: {
    deviceName: String,
    kindGroup: Boolean,
    stats: Object,
    unlinked: {
      type: Object,
      default() {
        return {};
      },
    },
    showUnlinked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      kind: 'sum',
    };
  },
  computed: {
    kindStats() {
      if (this.kindGroup) {
        return this.stats[this.kind];
      }
      return this.stats;
    },
    devicesCondition() {
      const sum = this.kindStats.sum[0];
      const online = this.kindStats.online[0];

      return sum > 0 ? Math.round((online / sum) * 100) : -1;
    },
    devicesConditionColor() {
      const perc = this.devicesCondition;
      if (perc < 0) {
        return 'grey';
      }
      if (perc < 35) {
        return 'red';
      }
      if (perc < 70) {
        return 'yellow';
      }
      return 'green';
    },
    kindUnlinked() {
      if (this.showUnlinked && !!this.unlinked[this.kind]) {
        return this.unlinked[this.kind];
      }
      return ['-', '#'];
    },
  },
  components: {
    PieChart,
  },
};
</script>

<style lang="scss">
a.simple-link {
  text-decoration: none;
  color: inherit;
}
</style>
