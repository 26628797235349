<template>
  <div class="geo-units-autocomplete-2">
    <BDropdownInput
      v-model="selected_id"
      :description="description"
      :selected="district"
      :disabled="disabled"
      :label="label"
      :state="state"
      :validator="validator"
      :handler="fetch"
      autocomplete="off"
      :compare-property="compareProperty"
      :display-property="displayProperty"
      :minWordLength="minWordLength"
      @clear="onClear"
      @focus="onFocus"
      @select="onSelect"
      @select:item="onSelectItem"
    />
  </div>
</template>

<script>
import { request3 } from '@/api/request';
import BDropdownInput from '@/components/base/BDropdownInput.vue';
import { API_URLS } from '@/consts';
import { getManageId } from '@/helpers';
import i18n from '@app/i18n';

export default {
  name: 'DistrictsAutocomplete',
  components: { BDropdownInput },
  props: {
    clientId: {
      type: [Number, String],
      default: () => getManageId(),
    },
    description: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    initName: {
      type: String,
    },
    label: {
      type: String,
      default: () => i18n.tc('geo_unit.type_only_names.District', 0),
    },
    state: {
      type: [Boolean, undefined],
      default: undefined,
    },
    validator: {
      type: Function,
    },
    value: {
      type: Number,
    },
    districtData: {
      type: Object,
      default: null,
    },
    compareProperty: {
      type: [String, Function],
      default: 'id',
    },
    displayProperty: {
      type: [String, Function],
    },
    minWordLength: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selected_id: null,
      district: null,
    };
  },
  async mounted() {
    this.initInputValue();
  },
  methods: {
    fetch(val) {
      return request3
        .get(API_URLS.backendManage.districts.fetch(this.clientId), {
          params: {
            q: { name_cont_all: val },
            limit: 8,
          },
        })
        .then((res) => res.data);
    },
    async initInputValue() {
      if (this.districtData) {
        this.district = this.districtData;
      } else if (this.value) {
        this.district = await request3(
          API_URLS.backendManage.districts.one(this.value, this.clientId),
        ).then((res) => res.data);
      }
    },
    onClear() {
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
    onSelectItem(item) {
      this.$emit('select:item', item);
      this.district = item;
    },
    onSelect(value) {
      this.$emit('input', value);
      this.$emit('select', value);
      this.selected_id = value;
    },
  },
};
</script>
