<template>
  <div class="plates-autocomplete">
    <BDropdownInput
      v-model="selected_id"
      :description="description"
      :display-property="displayProperty"
      :disabled="disabled"
      :label="placeholder"
      :handler="fetch"
      :selected="plateData"
      :state="state"
      :showLoading="showLoading"
      :variantSpinner="variantSpinner"
      :debouncedTts="debouncedTts"
      :compareProperty="compareProperty"
      @clear="onClear"
      @focus="onFocus"
      @input="onInput"
      @select="onSelect"
      @select:item="onSelectItem"
      :popoverText="popoverText"
      :popoverHint="popoverHint"
      :hintId="hintId"
      :minWordLength="minWordLength"
    />
    <input
      :value="selected_id"
      :id="`${model}_${name}`"
      type="hidden"
      :name="`${model}[${name}]`"
    />
  </div>
</template>

<script>
import BDropdownInput from '@/components/base/BDropdownInput';
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';
import { get } from 'lodash';

export default {
  name: 'PlatesAutocomplete',
  components: {
    BDropdownInput,
  },
  props: {
    saveFormId: String,
    model: String,
    name: String,
    plateData: Object,
    clientId: {
      type: Number,
    },
    placeholder: String,
    value: {
      type: [String, Number],
    },
    state: {},
    debouncedTts: {
      type: Number,
      default: 200,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
    },
    minWordLength: {
      type: Number,
      default: 3,
    },
    additionalQueryParams: {
      type: Object,
      default() {
        return {};
      },
    },
    userId: {
      type: Number,
      default: null,
    },
    showLoading: {
      type: Boolean,
      default: true,
    },
    variantSpinner: {
      type: String,
      default: 'primary',
    },
    compareProperty: {
      type: String,
      default: 'id',
    },
    displayProperty: {
      type: String,
      default: 'plate',
    },
    popoverText: {
      type: String,
      default: 'i',
    },
    popoverHint: {
      type: String,
      default: null,
    },
    hintId: {
      type: String,
      default: null,
    },
    placementPopover: {
      type: String,
      default: 'bottom',
    },
  },
  data() {
    return {
      selected_id: null,
    };
  },
  mounted() {
    if (this.plateData instanceof Object) {
      this.selected_id = get(this.plateData, this.compareProperty);
    }
  },
  methods: {
    onSelect(value) {
      this.$emit('input', value);
      this.$emit('select', value);
      this.selected_id = value;
      this.saveForm();
    },
    onSelectItem(item) {
      this.$emit('select:item', item);
    },
    saveForm() {
      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    fetch(val) {
      let params = {
        plate_cont_any: val,
        user_id_eq: this.userId,
      };
      let query = {
        ...params,
        ...this.additionalQueryParams,
      };

      return request3
        .get(API_URLS.backendManage.plates.fetch(this.clientId), {
          params: {
            q: query,
          },
        })
        .then((res) => res.data);
    },
    onClear() {
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
    onInput(val) {
      this.fetch(val);
    },
  },
};
</script>
