<template>
  <div class="cars-autocomplete">
    <BDropdownInput
      v-model="selected"
      :description="description"
      :disabled="disabled"
      :label="placeholder"
      :handler="fetch"
      :selected="carData"
      :state="state"
      :showLoading="showLoading"
      :variantSpinner="variantSpinner"
      :debouncedTts="debouncedTts"
      :formattedResult="formattedResult"
      :compare-property="getId"
      :display-property="getTitle"
      :comboBox="true"
      @hide="onHide"
      @clear="onClear"
      @focus="onFocus"
      @select="onSelect"
      @select:item="onSelectItem"
      :popoverText="popoverText"
      :popoverHint="popoverHint"
      :hintId="hintId"
      :minWordLength="minWordLength"
      :multiple="multiple"
    />
    <input
      v-if="q_name"
      :value="inputValue"
      :id="`${model}_${q_name}`"
      type="hidden"
      :name="`${model}[${q_name}]`"
    />
  </div>
</template>

<script>
import BDropdownInput from '@/components/base/BDropdownInput.vue';
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';
import { isEqual } from 'lodash';

export default {
  name: 'CarsAutocomplete',
  components: {
    BDropdownInput,
  },
  props: {
    saveFormId: String,
    model: String,
    name: String,
    description: {
      type: String,
    },
    carData: {
      type: [Object, Array],
      default: null,
    },
    clientId: {
      type: Number,
    },
    placeholder: {
      type: String,
    },
    state: {},
    debouncedTts: {
      type: Number,
      default: 200,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    additionalQueryParams: {
      type: Object,
      default() {
        return {};
      },
    },
    optional: {
      type: Object,
      default: null,
    },
    popoverText: {
      type: String,
      default: 'i',
    },
    popoverHint: {
      type: String,
      default: null,
    },
    hintId: {
      type: String,
      default: null,
    },
    placementPopover: {
      type: String,
      default: 'bottom',
    },
    showLoading: {
      type: Boolean,
      default: true,
    },
    variantSpinner: {
      type: String,
      default: 'primary',
    },
    minWordLength: {
      type: Number,
      default: 3,
    },
    // мультиселект
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hide: false,
      selected: null,
      q_name: this.name,
    };
  },
  computed: {
    formattedResult() {
      return {
        medium: [
          {
            title: 'name',
            formatter: (val, item) =>
              `${item.mark.name || ''} ${item.model.name || ''}`,
          },
        ],
      };
    },

    isCastomQuery() {
      if (this.optional) {
        return true;
      } else return false;
    },
    inputValue() {
      if (Array.isArray(this.selected) && this.multiple) {
        return JSON.stringify(this.selected.map((value) => JSON.parse(value)));
      } else {
        return this.selected;
      }
    },
  },
  mounted() {
    if (this.multiple) {
      if (this.carData) {
        this.selected = this.carData;
      }
    } else {
      if (this.carData instanceof Object) {
        if (this.carData.id) {
          this.selected = this.carData.id;
        }
      }
    }
  },
  methods: {
    onHide(value = false) {
      this.hide = value;
      if (this.hide && !isEqual(this.carData, this.selected)) {
        this.$emit('input', this.selected);
        this.getName();
        this.saveForm();
      }
    },
    onSelect(result) {
      this.$emit('input', result);
      this.$emit('select', result);
      this.selected = result;
      this.saveForm();
    },
    onSelectItem(result) {
      if (!result) return;
      this.selected = result.model.id ? result.model.id : result.mark.id;
      this.getName(result);
      this.$emit('input', this.selected);
      this.$emit('select', result);
      this.saveForm();
    },
    getName(value) {
      if (this.isCastomQuery) {
        this.q_name = value.model.id ? this.optional.model : this.optional.mark;
      } else this.q_name = this.name;
    },
    getTitle(item) {
      if (item.mark || item.model) {
        return `${item.mark.name || ''} ${item.model.name || ''}`;
      } else return item.name;
    },
    getId(item) {
      if (item.model || item.mark) {
        return item.model.id ? item.model.id : item.mark.id;
      } else return item.id;
    },
    saveForm() {
      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    fetch(val) {
      let params = {
        name_cont_any: val,
      };
      let query = {
        ...params,
        ...this.additionalQueryParams,
      };

      return request3
        .get(API_URLS.npr.cars.fetch(this.clientId), {
          params: {
            q: query,
          },
        })
        .then((res) => res.data);
    },
    onClear() {
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
  },
};
</script>
