var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-form-group',{staticClass:"b-input-label",class:_vm.getFormGroupClasses,attrs:{"id":_vm.getGroupId,"label":_vm.label,"invalid-feedback":_vm.invalidFeedback,"label-class":_vm.labelIsActive || _vm.value || _vm.value === 0 || _vm.placeholder || _vm.autofilled
      ? 'float-label-active'
      : 'float-label-inactive',"description":_vm.description,"state":_vm.state,"data-title":_vm.label}},[(_vm.security && _vm.value)?_c('div',{staticClass:"float-security",class:_vm.show ? 'icon-local-eye-closed' : 'icon-local-eye',on:{"click":_vm.toggleShowingPassword}}):_vm._e(),_vm._v(" "),(_vm.isShowIconSlot)?_c('div',{staticClass:"icon-slot"},[_vm._t("icon")],2):_vm._e(),_vm._v(" "),(_vm.icon)?_c('div',{staticClass:"icon-block pointer",on:{"click":() => {
        _vm.onIconClick();
        _vm.handler && _vm.handler();
      }}},[_c('i',{class:_vm.icon})]):_vm._e(),_vm._v(" "),_c('b-form-input',_vm._b({ref:"input",staticClass:"float-input",class:{
      'active-validation-to-disable': this.activeValidationToDisable,
      'is-invalid': _vm.isInvalid,
    },attrs:{"value":_vm.value,"id":_vm.id,"type":_vm.getInputType,"name":_vm.name,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"state":_vm.state,"autocomplete":_vm.autocomplete,"autofocus":_vm.autofocus,"pattern":_vm.pattern},on:{"keyup":function($event){return _vm.$emit('keyup')},"focus":function($event){return _vm.onInputFocus($event)},"blur":function($event){return _vm.onInputBlur($event)},"input":function($event){return _vm.input($event)},"change":_vm.change,"update":_vm.update,"animationstart":_vm.checkAnimation},nativeOn:{"input":function($event){return _vm.emmitEvent($event, 'inputNative')}}},'b-form-input',_vm.$attrs,false)),_vm._v(" "),(_vm.withCustomValidation)?_c('div',{staticClass:"custom-validation"},[_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.customValidationText))]),_vm._v(" "),_c('i',{staticClass:"icon-error"})]):_vm._e(),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }