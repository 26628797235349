<template>
  <div class="portlet p-0 m-0 mb-3 mb-md-4 d-flex building-card">
    <a
      class="text-decoration-none d-flex flex-grow-1"
      :href="`/manage/${clientId}/home/districts/${districtId}/buildings/${building.id}`"
    >
      <div class="mr-3 d-none d-md-block" style="width: 200px">
        <div
          v-if="building.image"
          class="rounded-left bg-secondary building-card__photo"
          :style="`background-image: url(${building.image});`"
        ></div>
        <div
          v-else
          class="rounded-left-lg building-card__photo building-card__photo_default"
        ></div>
      </div>
      <div class="pt-3 pb-2 px-4 px-md-2 d-flex flex-column flex-grow-1">
        <div class="ml-0 mb-0">
          <h5 class="mb-0">{{ building.name }}</h5>
          <div class="small text-muted">
            {{ building.construction_address }}
          </div>
          <div class="text-muted">
            {{ infoBuilding }}
          </div>
        </div>
        <div
          class="mt-2 d-flex flex-wrap align-items-center"
          v-if="
            building.equipment_installed && building.equipment_installed.status
          "
        >
          <div class="mt-2 d-flex flex-wrap align-items-center">
            <div
              v-if="
                isVisibleScore(
                  building.score.intercom,
                  building.equipment_blue_status.status_intercom,
                )
              "
              class="d-flex align-items-center mr-3 mr-md-5"
              :class="
                getScoreColorClass(
                  building.score.intercom,
                  building.equipment_blue_status.status_intercom,
                )
              "
            >
              <div class="h4 icon-local-intercom0 mr-2 mb-1"></div>
              {{ building.score.intercom }}%
            </div>
            <div
              v-if="
                isVisibleScore(
                  building.score.fire_alarm,
                  building.equipment_blue_status.status_fa,
                )
              "
              class="d-flex align-items-center mr-3 mr-md-5"
              :class="
                getScoreColorClass(
                  building.score.fire_alarm,
                  building.equipment_blue_status.status_fa,
                )
              "
            >
              <div class="h4 icon-local-safety mr-2 mb-1"></div>
              {{ building.score.fire_alarm }}%
            </div>
            <div
              v-if="
                isVisibleScore(
                  building.score.metering,
                  building.equipment_blue_status.status_meters,
                )
              "
              class="d-flex align-items-center mr-3 mr-md-5"
              :class="
                getScoreColorClass(
                  building.score.metering,
                  building.equipment_blue_status.status_meters,
                )
              "
            >
              <div class="h4 icon-local-askue mr-2 mb-1"></div>
              {{ building.score.metering }}%
            </div>
            <div
              v-if="
                isVisibleScore(
                  building.score.cctv,
                  building.equipment_blue_status.status_cctv,
                )
              "
              class="d-flex align-items-center mr-3 mr-md-5"
              :class="
                getScoreColorClass(
                  building.score.cctv,
                  building.equipment_blue_status.status_cctv,
                )
              "
            >
              <div class="h4 icon-local-cam_f mr-2 mb-1"></div>
              {{ building.score.cctv }}%
            </div>
          </div>
        </div>
      </div>
      <div v-if="building.territory">
        <a
          :href="`/manage/${clientId}/home/districts/${districtId}/geo_units/${building.territory.id}`"
          class="building-card__territory"
        >
          {{ building.territory.name }}
        </a>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'BuildingCard',
  props: {
    building: {
      type: Object,
      required: true,
    },
    clientId: {
      type: [String, Number],
      required: true,
    },
    districtId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    infoBuilding() {
      let stringArrayInfo = [];
      if (this.building.apartments_cnt > 0) {
        stringArrayInfo.push(
          `${this.building.apartments_cnt} ${this.$tc(
            'plurals.geo_units.Apartment',
            this.building.apartments_cnt,
          )}`,
        );
      }
      if (this.building.bkfns_cnt > 0) {
        stringArrayInfo.push(
          `${this.building.bkfns_cnt} ${this.$tc(
            'plurals.geo_units.Bkfn',
            this.building.bkfns_cnt,
          )}`,
        );
      }
      if (this.building.store_rooms_cnt > 0) {
        stringArrayInfo.push(
          `${this.building.store_rooms_cnt} ${this.$tc(
            'plurals.geo_units.StoreRoom',
            this.building.store_rooms_cnt,
          )}`,
        );
      }
      if (this.building.parking_places_cnt > 0) {
        stringArrayInfo.push(
          `${this.building.parking_places_cnt} ${this.$tc(
            'plurals.geo_units.ParkingPlace',
            this.building.parking_places_cnt,
          )}`,
        );
      }
      return stringArrayInfo.join(', ');
    },
  },
  methods: {
    getScoreColorClass(score, inspection = false) {
      if (inspection) {
        return 'text-primary';
      }

      if (0 <= score && score <= 34) {
        return 'text-danger';
      }
      if (35 <= score && score <= 74) {
        return 'text-warning';
      }
      if (75 <= score && score <= 100) {
        return 'text-success';
      }
      return 'text-danger';
    },
    isVisibleScore(score, status) {
      if (score != undefined && status != undefined) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.building-card {
  position: relative;

  &__photo {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 120px;

    &_default {
      background-color: #f7f9fa;
      background-image: url('/img/illustrations/empty_building.svg');
      background-size: 60%;
    }
  }

  &__territory {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
</style>
