<template>
  <div>
    <b-modal
      :ok-title="$t('button.close')"
      centered
      id="user-edit-modal"
      modal-class="user-edit-modal"
      ok-only
      ok-variant="secondary"
      ref="modal"
      size="lg"
      @ok="onOk"
    >
      <template v-slot:modal-header="{ close }">
        <div class="header-logo">
          <UserAvatar :user="model" size="64px" />
        </div>
        <div class="title">{{ userName }}</div>
        <button
          class="close"
          @click="
            onOk();
            close();
          "
        >
          x
        </button>
      </template>
      <b-card no-body>
        <b-tabs
          nav-wrapper-class="nav-tabs-wrapper"
          nav-class="nav-tabs-tablist"
          v-model="tabIndex"
          vertical
        >
          <b-tab :title="$t('user.profile')" active>
            <template v-slot="{ validateState }">
              <div class="modal-body-row pt-30 with-cols border-bottom">
                <b-form-row>
                  <b-col cols="6">
                    <div class="title">
                      {{ $t('user.create.auth_title') }}
                    </div>
                    <BInputLabel
                      :label="$t('phone')"
                      disabled
                      v-model="model.phone"
                    />
                    <UserModalForm
                      v-model="model"
                      form-name="personal"
                      @changed="onChanged"
                      :isUserGeoUnit="isUserGeoUnit"
                    ></UserModalForm>
                  </b-col>
                  <b-col cols="6">
                    <div class="user-edit-modal-profile-logo">
                      <UserAvatar :user="model" size="248px" />
                    </div>
                  </b-col>
                </b-form-row>
                <b-form-row>
                  <b-col cols="6">
                    <UserModalForm
                      v-model="model"
                      form-name="comment"
                      @changed="onChanged"
                      :isUserGeoUnit="isUserGeoUnit"
                    ></UserModalForm>
                  </b-col>
                </b-form-row>
              </div>
              <div class="modal-body-row with-cols">
                <div class="title">
                  {{ $t('user.create.alt_auth_title') }}
                </div>
                <b-form-row>
                  <b-col cols="6">
                    <UserModalForm
                      v-model="model"
                      form-name="email"
                      @changed="onChanged"
                      :isUserGeoUnit="isUserGeoUnit"
                    ></UserModalForm>
                  </b-col>
                </b-form-row>
              </div>
            </template>
          </b-tab>
          <b-tab :title="$t('property.title')" lazy>
            <UserModalTabProperty />
          </b-tab>
          <b-tab v-if="isAdmin" :title="$t('role.title')" lazy>
            <UserModalTabPermissions :is-admin="isAdmin" />
          </b-tab>
          <b-tab :title="$t('user.edit.key.title')" lazy>
            <UserModalTabKeys />
          </b-tab>
          <b-tab :title="$t('user.edit.plate.title')" lazy>
            <UserModalTabPlates />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import UserAvatar from '@/components/base/UserAvatar';
import { PERMISSIONS } from '@/consts';
import { EventBusMixin } from '@app/mixins';
import UserModalForm from './forms/UserModalForm.vue';
import UserModalTabKeys from './tabs/UserModalTabKeys.vue';
import UserModalTabPermissions from './tabs/UserModalTabPermissions.vue';
import UserModalTabProperty from './tabs/UserModalTabProperty.vue';
import UserModalTabPlates from './tabs/UserModalTabPlates.vue';
import { cloneDeep } from 'lodash-es';
import { getUserName } from '@/helpers';

const userReactive = Vue.observable({ id: 0 });

function initData(user) {
  if (user) {
    userReactive.id = user.user_id || user.id;
  }
  return {
    changedModel: false,
    model: cloneDeep(user) || {
      email: '',
      name: '',
      patronymic: '',
      phone: '',
      surname: '',
      comment: '',
    },
    tabIndex: 0,
  };
}

export default {
  name: 'UserEditModal',
  mixins: [EventBusMixin],
  components: {
    UserAvatar,
    UserModalForm,
    UserModalTabKeys,
    UserModalTabPermissions,
    UserModalTabProperty,
    UserModalTabPlates,
  },
  props: {
    clientId: { type: Number, required: true },
    me: { required: true },
    permission: { type: Array, required: false },
  },
  provide() {
    return {
      clientId: this.clientId,
      user: userReactive,
    };
  },
  data() {
    return {
      ...initData(),
      isUserGeoUnit: false,
    };
  },
  computed: {
    userName() {
      return getUserName(this.model);
    },
    isAdmin() {
      const ownerPermission = this.permission.find(
        (permission) => permission.type === PERMISSIONS.Owner,
      );
      return this.me.is_admin || ownerPermission?.buildings.length === 0;
    },
  },
  mounted() {
    this.EventBusOn('UserEditModal::show', this.show);
  },
  methods: {
    onChanged(changed) {
      if (!this.changedModel && changed) {
        this.changedModel = true;
      }
    },
    onOk() {
      if (this.changedModel) {
        location.reload();
      }
    },
    async show(user, userGeoUnit = false) {
      Object.assign(this.$data, initData(user));
      this.isUserGeoUnit = userGeoUnit;
      this.$refs.modal.show();
    },
  },
};
</script>
