<template>
  <div>
    <slot
      :clients="clients"
      :selected-client-obj="selectedClientObj"
      :select-client="selectClient"
      :clear-client="clearClient"
      :permission="permission"
      :buildings="filteredBuildingsPermissions"
      :districts="filteredDistrictsPermissions"
      :on-input-set-buildings="onInputSetBuildings"
      :on-input-set-districts="onInputSetDistricts"
      :buildings-inputs="buildingsInputs"
      :districts-inputs="districtsInputs"
      :disabled-add-btn="disabledAddBtn"
    ></slot>
  </div>
</template>
<script>
import adminClientsAutocomplete from '@/components/autocompletes/adminClientsAutocomplete';
import FormPermissionsBuildings from '@/components/forms/FormPermissionsBuildings.vue';
import BFormGroupSlot from '@/components/base/BFormGroupSlot';
import SelectSlot from '@/vue_slim/components/base/SelectSlot';
import { NestedAttributes } from '@/helpers';

export default {
  name: 'PermissionAdminForm',
  components: {
    adminClientsAutocomplete,
    BFormGroupSlot,
    FormPermissionsBuildings,
    SelectSlot,
  },
  props: {
    clientId: {
      type: [String, Number],
      default: null,
    },
    buildingsPermissions: {
      type: Array,
    },
    districtsPermissions: {
      type: Array,
    },
    permissions: {
      type: Array,
    },
    permissionData: {
      type: Object,
      default: () => ({}),
    },
    clients: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      buildingsPermissionsSaved: { list: [], compare: [] },
      districtsPermissionsSaved: { list: [], compare: [] },
      permission: this.permissionData,
    };
  },
  computed: {
    disabledAddBtn() {
      return !this.permission.client_id && this.permission.client_id != 0
    },
    selectedClientObj() {
      return this.clients.find(
        (client) => client.id === this.permission.client_id,
      );
    },
    buildingsInputs() {
      return this.buildingsPermissionsSaved.compare.map((building, index) => {
        return Object.entries(building).map(([key, value]) => ({
          name: `permission[buildings_permissions_attributes][${index}][${key}]`,
          value: value,
        }));
      });
    },
    districtsInputs() {
      return this.districtsPermissionsSaved.compare.map((district, index) => {
        return Object.entries(district).map(([key, value]) => ({
          name: `permission[districts_permissions_attributes][${index}][${key}]`,
          value: value,
        }));
      });
    },
    formattedBuildingsPermissions() {
      return this.buildingsPermissions.map((permission) => ({
        building_id: permission.building.id,
        building_name: permission.building.short_name,
        id: permission.id,
      }));
    },
    formattedDistrictsPermissions() {
      return this.districtsPermissions.map((permission) => ({
        district_id: permission.district.id,
        district_name: permission.district.name,
        id: permission.id,
      }));
    },
    filteredBuildingsPermissions() {
      return this.buildingsPermissionsSaved.compare.filter(
        (permission) => !permission._destroy,
      );
    },
    filteredDistrictsPermissions() {
      return this.districtsPermissionsSaved.compare.filter(
        (permission) => !permission._destroy,
      );
    },
    permissionInputName() {
      return `permission[permission_type]`;
    },
    permissionsItems() {
      return this.permissions.map(([name, permission]) => ({
        name,
        permission,
      }));
    },
  },
  watch: {
    // When props change save them to data.
    buildingsPermissions: 'init',
    districtsPermissions: 'init',
  },
  methods: {
    // Saves props to data so we can manipulate them in future.
    init() {
      // buildingsPermissions (props) =>
      // formattedBuildingsPermissions (parsed props) =>
      // buildingsPermissionsSaved.list (current data) =>
      // filteredBuildingsPermissions (current data without destroyed permissions)
      this.buildingsPermissionsSaved = new NestedAttributes(
        this.formattedBuildingsPermissions,
        'building_id',
      );
      this.districtsPermissionsSaved = new NestedAttributes(
        this.formattedDistrictsPermissions,
        'district_id',
      );
    },
    selectClient(val) {
      if (this.permission.client_id == val) {
        return;
      }
      this.permission.client_id = val;
      this.resetBuildingsAndDistricts();
    },
    clearClient() {
      this.permission.client_id = null;
      this.resetBuildingsAndDistricts();
    },
    resetBuildingsAndDistricts() {
      this.buildingsPermissionsSaved.list.splice(0);
      this.districtsPermissionsSaved.list.splice(0);
    },
    onInputSetBuildings(permissions) {
      this.buildingsPermissionsSaved.list = permissions;
    },
    onInputSetDistricts(permissions) {
      this.districtsPermissionsSaved.list = permissions;
    },
  },
};
</script>
