<template>
  <b-form-group
    :label-for="id"
    :label-cols-sm="size"
    class="d-flex align-items-center"
  >
    <template slot="label">
      <div class="d-flex flex-column">
        <div>{{ title }}</div>
        <small>{{ subtitle }}</small>
      </div>
    </template>
    <slot></slot>
  </b-form-group>
</template>

<script>
export default {
  name: 'BFormGroupCount',
  props: {
    title: String,
    subtitle: String,
    id: String,
    size: {
      type: String,
      default: '8',
    },
  },
};
</script>
