<template>
  <div>
    <div class="d-flex gap-2 mb-3">
      <slot ref="slot" v-bind:showButtons="showButtons"></slot>
      <b-button
        :href="`/manage/${clientId}/districts/${buildingId}/edit`"
        type="button"
        v-if="isEdit && showButtons"
      >
        {{ $t('building.edit.structure') }}
      </b-button>
      <b-button
        :href="`/manage/${clientId}/home/districts/${districtId}/buildings/${buildingId}/edit_address`"
        type="button"
        v-if="isEdit && showButtons"
      >
        {{ $t('building.edit.address') }}
      </b-button>
    </div>
    <building-update-structure-status
      :building-id="buildingId"
      :is-updating="isUpdating"
      :is-complete="isComplete"
    />
  </div>
</template>

<script>
import { request2 } from '@/api';
import { getManageId } from '@/helpers';
import BuildingUpdateStructureStatus from './BuildingUpdateStructureStatus.vue';
import axios from 'axios';

const STATUS_CHECK_INTERVAL = 3000;

export default {
  name: 'BuildingHeaderMenu',
  components: { BuildingUpdateStructureStatus },
  props: {
    clientId: {
      type: String,
      default: getManageId(),
    },
    buildingId: {
      type: String,
      required: true,
    },
    districtId: {
      type: String,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isUpdating: false,
      isComplete: false,
      showButtons: false,
    };
  },
  computed: {},
  created() {
    this.checkStatus();
  },
  methods: {
    checkStatus() {
      return axios
        .get(
          `/api/backend/manage/${this.clientId}/geo_units/${this.buildingId}/structure/status`,
        )
        .then((response) => {
          if (response.data.status === 'in_progress') {
            this.isUpdating = true;
            setTimeout(() => {
              this.checkStatus();
            }, STATUS_CHECK_INTERVAL);
          } else {
            if (response.data.status === 'ok') {
              if (this.isUpdating) {
                this.isComplete = true;
              }
              this.isUpdating = false;
              this.showButtons = true;
            }
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 422) {
            this.showButtons = true;
          }
        });
    },
  },
};
</script>

<style></style>
