<template>
  <b-modal
    id="modal"
    ref="modal"
    centered
    modal-class="modal-request"
    header-class="modal-request__head modal-request__head_create"
    size="sm"
  >
    <template #modal-header>
      <div class="title modal-request__title modal-request__title_left">
        {{ $t('client_request.modal.accept_alert') }}
      </div>
    </template>

    <template #modal-footer="{ close }">
      <div class="d-flex gap-2">
        <b-button variant="secondary" @click="close">
          {{ $t('client_request.modal.back') }}
        </b-button>
        <b-button variant="primary" @click="requestAccept">
          {{ $t('client_request.modal.accept') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ClientRequestAcceptModal',

  methods: {
    requestAccept() {
      this.$emit('requestAccept');

      this.$refs.modal.hide();
    },
    show() {
      this.$refs.modal.show();
    },
  },
};
</script>

<style></style>
