<template>
  <div class="modal-request__head">
    <div class="title">
      №{{ request.id }} {{ $t('client_request.date_prefix') }} {{ getDate }}
    </div>

    <div
      class="modal-request__status"
      :class="`modal-request__status_${request.status}`"
    >
      <i
        :class="[iconClassStatus, `modal-request__icon_${request.status}`]"
        class="modal-request__icon"
      />
      {{ request.t_status }}
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';

export default {
  name: 'ClientRequestModalHeader',
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getDate() {
      return dayjs(this.request.created_at).format('L, LT');
    },

    iconClassStatus() {
      if (this.request.status == 'new') {
        return 'icon-local-tree-end';
      }
      if (this.request.status == 'in_progress') {
        return 'icon-local-tree-end';
      }
      if (
        this.request.status == 'denied' ||
        this.request.status == 'canceled'
      ) {
        return 'icon-local-times';
      }
      if (this.request.status == 'accepted') {
        return 'icon-local-ok';
      }
      return '';
    },
  },
};
</script>
