<template>
  <div class="creator">
    <div class="creator-body">
      <div class="creator-content">
        <div class="creator-content-block p-5">
          <div class="step-title">
            {{ $t('building_create.addresses.title') }}
          </div>
          <AddressStep
            ref="AddressStep"
            :client-id="clientId"
            @change="emptyBuilding = !emptyBuilding"
          />
        </div>
      </div>
    </div>
    <div class="creator-footer">
      <div class="creator-footer-left">
        <b-button size="lg" variant="secondary" @click="onGoBack">
          {{ $t('button.cancel') }}
        </b-button>
      </div>
      <div class="creator-footer-right">
        <b-button size="lg" variant="primary" @click="onCreate()">
          {{ $t('button.create') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm';
import AddressStep from './AddressStep.vue';
import { ApiBackendGeoUnits, ApiBackendCities } from '@/api';
import {getLocale} from "@/helpers";

export default Vue.extend({
  name: 'BuildingCreator',
  components: {
    AddressStep,
  },
  props: {
    clientId: {
      type: [String, Number],
      required: true,
      validator: (val) => Number(val),
    },
  },
  data() {
    return {
      emptyBuilding: false,
    };
  },
  computed: {
    backHref() {
      return `/manage/${this.clientId}/districts`;
    },
  },
  methods: {
    async onGoBack() {
      location.href = this.backHref;
    },
    onCreate() {
      this.$refs.AddressStep.getData()
        .then(async (address) => {
          const { building, city, geoUnitsList } = address;
          let parentElement;

          let cityObj = await ApiBackendCities.create(city);
          try {
            for (const geoUnit of geoUnitsList) {
              if (!geoUnit.meta) geoUnit.meta = {};
              // eslint-disable-next-line no-await-in-loop
              parentElement = await ApiBackendGeoUnits.create({
                ...geoUnit,
                parent_id: parentElement?.id || null,
                city_id: cityObj.id,
                geocoder_locale: getLocale(),
              });
            }

            parentElement = await ApiBackendGeoUnits.create({
              ...building,
              parent_id: parentElement.id,
              city_id: cityObj.id,
              geocoder_locale: getLocale(),
            });
          } catch (err) {
            this.$bvToast.toast(Object.values(err.errors));
            return;
          }
          const { district_id, id } = parentElement;

          if (this.emptyBuilding) {
            location.href = `/manage/${this.clientId}/home/districts/${district_id}/buildings/${id}`;
          } else {
            this.$router.push({
              name: 'building_edit',
              params: {
                client_id: Number(this.$route.params.client_id),
                building_id: id,
              },
            });
          }
        })
        .catch((err) => {
          console.log('err', err);
          this.$bvToast.toast(Object.values(err.errors).join(', '));
        });
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'app/javascript/assets/styles/components/variables';

.creator {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 800px;
  min-height: 500px;
  background-color: $white;
  border-radius: 10px;

  &-header {
    height: 90px;
    width: 100%;
    display: flex;
    border-bottom: $gray-border solid 1px;
    padding: 0 30px;
  }

  &-panel {
    display: flex;
    align-items: center;

    .dropdown {
      width: 50px;
    }

    .btn {
      &:first-child {
        margin: 0 20px;
        width: 290px;
        font-size: $font-size-16px;
      }

      font-size: $font-size-16px;
      height: 50px;
      width: 50px;
      margin: 0 10px;

      &.disabled {
        border-color: $border;
        color: $color-close-button;
      }
    }
  }

  &-title-block {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;

    div {
      font-size: $font-size-24px;
      line-height: $line-height-29px;
    }
  }

  &-body {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: auto;

    .creator-content {
      overflow: auto;
    }
  }

  &-navbar {
    display: flex;
    width: 220px;
    border-right: $gray-border solid 1px;
  }

  &-content {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;

    &-block {
      width: 100%;
    }

    &-editor {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      flex: 1;
    }
  }

  &-footer {
    display: flex;
    flex-direction: row;
    height: 90px;
    width: 100%;
    border-top: $gray-border solid 1px;
    padding: 0 20px;

    .btn {
      height: 50px;
      min-width: 200px;
      margin: 0 10px;
    }

    &-left {
      display: flex;
      flex: 4;
      align-items: center;
    }

    &-right {
      display: flex;
      flex: 8;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .btn-outline-secondary {
    color: $blue;
    border-color: $gray-300;
  }

  .btn-secondary {
    background-color: $gray-300;
    border-color: $gray-300;
  }
}

.step-title {
  font-size: $font-size-20px;
  line-height: $line-height-29px;
  font-weight: 400;
  margin-bottom: 20px;
}
</style>
