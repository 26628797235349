import Vue from 'vue';

const bridge = {
  user: Vue.observable({}),
  permissions: [],
  isAdmin: false,
  appData: Vue.observable({}),
};

document.addEventListener('DOMContentLoaded', () => {
  const el = document.querySelector('#user-client-permissions');
  if (el) {
    const { admin, permissions = '[]' } = el.dataset;
    bridge.permissions = JSON.parse(permissions);
    bridge.isAdmin = admin === 'true';
  }
});

export default bridge;
