<template>
  <b-modal
    id="modal"
    ref="modal"
    footer-class="pt-0"
    centered
    modal-class="modal-request"
    size="lg"
  >
    <template #modal-header="{ close }">
      <client-request-modal-header :request="request" />

      <button class="close" @click="close()">
        <i class="icon-local-times"></i>
      </button>
    </template>

    <div class="modal-body-row with-cols pt-30" :key="request.id">
      <client-request-info :request="request" />

      <div v-if="request && request.manager && request.status != 'new'">
        <div class="title">
          {{ $t('client_request.service_data.manager') }} {{ platformName }}
        </div>

        <b-form-row class="mb-3">
          <b-col cols="6">
            <b-input-label
              :label="$t('client_request.manager_data.name')"
              :value="managerRequest"
              disabled
            />
          </b-col>
          <b-col cols="6">
            <b-input-label
              :label="$t('client_request.manager_data.email')"
              :value="request.manager.email"
              disabled
            />
          </b-col>

          <b-col cols="6">
            <b-input-label
              :label="$t('client_request.manager_data.phone')"
              v-model="request.manager.phone"
              disabled
            ></b-input-label>
          </b-col>
        </b-form-row>

        <div v-if="request.status == 'accepted'">
          <div class="title">
            {{ $t('client_request.service_data.tariff') }}
          </div>

          <b-form-row>
            <b-col cols="6" class="mb-3">
              <tariffs-autocomplete
                v-model="request.service_plan_id"
                :placeholder="$t('client_request.service_data.tariff')"
                :items="service_plans"
                objectProperty="id"
                display="full_name"
                disabled
              />
            </b-col>

            <b-col cols="6">
              <date-picker
                class="w-100 d-block"
                v-model="request.active_from"
                :label="$t('client_request.service_data.tariff_first_date')"
                format="YYYY-MM-D"
                disabled
              ></date-picker>
            </b-col>

            <b-col cols="6">
              <date-picker
                class="w-100 d-block"
                :label="$t('client_request.service_data.tariff_last_date')"
                v-model="request.active_to"
                format="YYYY-MM-D"
                disabled
              ></date-picker>
            </b-col>
          </b-form-row>
        </div>

        <div v-if="request.status == 'denied'">
          <div class="title">
            {{ $t('client_request.service_data.reason_denied') }}
          </div>
          <b-form-row>
            <b-col cols="12">
              <b-input-label
                :label="$t('client_request.service_data.comment')"
                :value="request.comment"
                disabled
              />
            </b-col>
          </b-form-row>
        </div>
      </div>
    </div>

    <template #modal-footer>
      <div class="text-secondary small">
        {{ statusAt }}
      </div>
    </template>
  </b-modal>
</template>

<script>
import ClientRequestInfo from './ClientRequestInfo.vue';
import ClientRequestModalHeader from './ClientRequestModalHeader';
import dayjs from 'dayjs';
import bridge from '@/helpers/bridge';
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';
import DatePicker from '@app/components/base/DatePicker';
import TariffsAutocomplete from '@/components/autocompletes/TariffsAutocomplete';

export default {
  name: 'ClientRequestShowModal',
  components: {
    ClientRequestInfo,
    ClientRequestModalHeader,
    TariffsAutocomplete,
    DatePicker,
  },
  data() {
    return {
      request: {},
      service_plans: [],
    };
  },

  created() {
    request3
      .get(API_URLS.backendManage.autocomplete.service_plans.fetch())
      .then((res) => (this.service_plans = res.data));
  },

  computed: {
    platformName() {
      return bridge.appData.platform_name;
    },

    statusAt() {
      return dayjs(this.request.status_at).format('L, LT');
    },

    managerRequest() {
      let val = '';
      if (this.request.manager) {
        val += this.request.manager.surname
          ? this.request.manager.surname + ' '
          : '';

        val += this.request.manager.name ? this.request.manager.name + ' ' : '';

        val += this.request.manager.patronymic
          ? this.request.manager.patronymic
          : '';
      }

      return val;
    },
  },

  methods: {
    show(request) {
      this.request = Object.assign({}, request);
      this.$refs.modal.show();
    },
  },
};
</script>

<style></style>
