<template>
  <div>
    <slot :cameras="cameras" :relays="relays" :intercom="intercom"></slot>
  </div>
</template>
<script>
export default {
  name: 'NprBarrier',
  props: {
    events: {
      type: Array,
      default: new Array(),
    },
    cameras: {
      type: Array,
      default: new Array(),
    },
    relays: {
      type: Array,
      default: new Array(),
    },
    intercom: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    document.addEventListener('DOMContentLoaded', function (event) {
      const scrollpos = sessionStorage.getItem('scrollpos');
      if (scrollpos) {
        setTimeout(() => {
          window.scrollTo(0, scrollpos);
        }, 100);
        sessionStorage.removeItem('scrollpos');
      }
    });

    window.addEventListener('beforeunload', function (e) {
      sessionStorage.setItem('scrollpos', window.scrollY);
    });
  },
};
</script>
