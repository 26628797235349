<template>
  <div>
    <vue2-date-picker
      v-bind="$attrs"
      :disabled-date="notAfterToday"
      :editable="false"
      :formatter="format"
      :lang="lang"
      :value="value"
      @input="onChange"
      range
    >
      <template v-slot:input>
        <BFormGroupSlot :label="label" :value="inputValue">
          <b-input type="text" :value="inputValue" />
        </BFormGroupSlot>
      </template>
    </vue2-date-picker>
    <input
      :value="valueStart"
      :id="inputStartName"
      type="hidden"
      :name="inputStartName"
    />
    <input
      :value="valueEnd"
      :id="inputEndName"
      type="hidden"
      :name="inputEndName"
      ref="inputEnd"
    />
  </div>
</template>

<script>
import { isEmpty, compact } from 'lodash';
import Vue2DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import dayjs from 'dayjs';
import BFormGroupSlot from '@/components/base/BFormGroupSlot.vue';

export default {
  name: 'RangeDatePicker',
  props: {
    label: String,
    required: {
      type: Boolean,
      default: true,
    },
    autoSubmit: {
      type: Boolean,
      default: false
    },
    initialDateStart: String,
    initialDateEnd: String,
    inputStartName: {
      type: String,
      default: "created_at_start",
    },
    inputEndName: {
      type: String,
      default: "created_at_end",
    },
    submitAckId: String, // для изменения значения инпута на false, нужно для некоторых форм
  },
  components: {
    Vue2DatePicker,
    BFormGroupSlot,
  },
  data() {
    return {
      format: {
        stringify: (val) => (val ? dayjs(val).format('LL') : null),
        parse: (val) => (val ? dayjs(val) : null),
      },
      value: null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
    };
  },
  computed: {
    inputValue() {
      if (this.value) {
        if (Array.isArray(this.value)) {
          return this.value
            .map((val) => this.format.stringify(val))
            .join(' - ');
        }
        return this.format.stringify(this.value);
      }
      return '';
    },
    valueStart() {
      if (this.value) {
        return dayjs(this.value[0]).format('YYYY-MM-DD')
      }
      return '';
    },
    valueEnd() {
      if (this.value) {
        return dayjs(this.value[1]).format('YYYY-MM-DD')
      }
      return '';
    },
  },
  mounted() {
    if (!isEmpty(this.initialDateStart) && !isEmpty(this.initialDateEnd)) {
      this.value = [
        dayjs(this.initialDateStart).toDate(),
        dayjs(this.initialDateEnd).toDate()
      ];
    } else if (this.required) {
      this.value = this.defaultDate();
    };
  },
  methods: {
    defaultDate() {
      return [
        dayjs().subtract(1, 'months'),
        dayjs(),
      ];
    },
    onChange(val) {
      this.value = isEmpty(compact(val)) ? this.defaultDate() : val;

      if (this.autoSubmit === true) {
        this.setSubmitAck();
        // HINT: задержка отправки чтобы value инпутов успело обновиться
        this.$nextTick(function () {
          this.$refs.inputEnd.form.submit();
        })
      };
    },
    notAfterToday(date) {
      return date > new Date();
    },
    setSubmitAck() {
      if (this.submitAckId) {
        document.getElementById(this.submitAckId).value = false;
      }
    }
  },
};
</script>

<style lang="scss">
$primary-color: #03a9f4;
@import '~vue2-datepicker/scss/index';

.mx-datepicker {
  width: 100%;
  input {
    background: none !important;
    cursor: pointer !important;
  }
  .mx-icon-calendar, .mx-icon-clear {
    margin-right: 10px;
  }
}
.mx-datepicker-main {
  .mx-date-row {
    td {
      border: none !important;
    }
  }
}
</style>
