const state = {
  platform_support: null,
};

const getters = {
  getPlatformSupport(state) {
    return state.platform_support;
  },
};

const mutations = {
  setPlatformSupport(state, payload) {
    state.platform_support = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
