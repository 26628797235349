<template>
  <div class="admin-districts-autocomplete">
    <BDropdownInput
      v-model="selected_id"
      :description="description"
      :disabled="disabled"
      :label="placeholder"
      :handler="fetch"
      :selected="districtData"
      :state="state"
      :showLoading="showLoading"
      :variantSpinner="variantSpinner"
      :debouncedTts="debouncedTts"
      compare-property="id"
      display-property="name"
      @clear="onClear"
      @focus="onFocus"
      @select="onSelect"
      @select:item="onSelectItem"
      :popoverText="popoverText"
      :popoverHint="popoverHint"
      :hintId="hintId"
      :minWordLength="minWordLength"
    />
    <input :value="selected_id" type="hidden" :name="hiddenFieldName" />
  </div>
</template>

<script>
import BDropdownInput from '@/components/base/BDropdownInput.vue';

export default {
  name: 'adminDistrictsAutocomplete',
  components: {
    BDropdownInput,
  },
  props: {
    hiddenFieldName: String,
    saveFormId: String,
    districtData: Object,
    state: {},
    debouncedTts: {
      type: Number,
      default: 200,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
    },
    placeholder: String,
    isInvalid: {
      type: Boolean,
      default: false,
    },
    additionalQueryParams: {
      type: Object,
      default() {
        return {};
      },
    },
    minWordLength: {
      type: Number,
      default: 3,
    },
    popoverText: {
      type: String,
      default: 'i',
    },
    popoverHint: {
      type: String,
      default: null,
    },
    hintId: {
      type: String,
      default: null,
    },
    placementPopover: {
      type: String,
      default: 'bottom',
    },
    showLoading: {
      type: Boolean,
      default: true,
    },
    variantSpinner: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      selected_id: null,
    };
  },
  mounted() {
    if (this.districtData) {
      this.selected_id = this.districtData.id;
    }
  },
  methods: {
    onSelect(value) {
      this.$emit('input', value);
      this.$emit('select', value);
      this.selected_id = value;
      this.saveForm();
    },
    onSelectItem(item) {
      this.$emit('select:item', item);
    },
    onClear() {
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
    saveForm() {
      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    fetch(val) {
      let params = {
        q: {
          name_cont_any: val,
          ...this.additionalQueryParams,
        },
      };
      return request2(
        {
          method: 'get',
          url: `/api/backend/admin/autocomplete/districts/`,
        },
        params,
      );
    },
  },
};
</script>
