<template>
  <div>
    <b-modal
      id="user-role-create-modal"
      ref="modal"
      centered
      modal-class="modal-with-b-form-row"
      :ok-title="$t('button.add')"
      :title="$t('role.add.title')"
      @ok="onOk"
    >
      <div class="modal-body-row with-cols">
        <b-form-row>
          <BSelectLabel
            v-model="permission"
            :label="$t('permissions.title')"
            class="w-100"
            :items="permissions"
            :custom-display="translatePermission"
          />
        </b-form-row>
      </div>
      <div v-if="!isOwnerSelected" class="modal-body-row">
        <p>
          {{ `${$t('user.edit.permission.provide_access_to_buildings')}:` }}
        </p>
        <BuildingsList
          v-model="buildings"
          :required="true"
          :add-title="$t('user.edit.permission.add_building')"
          disable-add-check-property="building_id"
          type-eq="Building"
        />
        <p class="mt-3">
          {{ `${$t('user.edit.permission.provide_access_to_districts')}:` }}
        </p>
        <DistrictsList
          v-model="districts"
          :required="true"
          :add-title="$t('user.edit.permission.add_district')"
          disable-add-check-property="district_id"
          init-geo-unit-name-property="title"
        ></DistrictsList>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ApiPermissions } from '@/api';
import DistrictsList from '@/components/lists/DistrictsList.vue';
import BuildingsList from '@/components/lists/BuildingsList.vue';
import { PERMISSIONS } from '@/consts';

export default {
  name: 'UserModalPermissionAdd',
  components: { DistrictsList, BuildingsList },
  inject: ['clientId', 'user'],
  props: {
    permissions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      buildings: [],
      districts: [],
      permission: null,
    };
  },
  computed: {
    isOwnerSelected() {
      return this.permission === PERMISSIONS.Owner;
    },
  },
  methods: {
    async onOk(event) {
      event.preventDefault();
      const emptyBuilding = this.buildings.filter(x => x.building_id === null)
      const emptyDistrict = this.districts.filter(x => x.district_id === null)
      if (emptyBuilding.length > 0 || emptyDistrict.length > 0) {
        this.$bvToast.toast("Заполните недостающие поля!");
      } else {
        ApiPermissions.create({
          buildings_permissions_attributes: this.buildings.filter(({ building_id }) => building_id),
          districts_permissions_attributes: this.districts.filter(({ district_id }) => district_id),
          client_id: this.clientId,
          type: this.permission,
          user_id: this.user.id,
        })
          .then(() => {
            this.$emit('success');
            this.$refs.modal.hide();
          })
          .catch((err) => {
            this.$bvToast.toast(err);
          });
      }
    },
    show(permission) {
      this.permission = permission || this.permissions[0];

      if(this.buildings) {
        this.buildings = this.buildings.filter(x => x.building_id)
      }
      if(this.districts) {
        this.districts = this.districts.filter(x => x.district_id)
      }

      this.$refs.modal.show();
    },
    translatePermission(permission) {
      return this.$t(`permissions.${permission}`);
    }
  },
};
</script>

<style lang="scss" scoped></style>
