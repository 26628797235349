<template>
  <b-button @click="onClick">
    {{ $t('building.copy_button_title') }}
  </b-button>
</template>

<script>
import { STYLES_MODAL } from '@/consts';

export default {
  name: 'BuildingCopyButton',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      link: null,
    };
  },
  mounted() {
    const originalButton = document.querySelector('#copy-house');

    originalButton.style.display = 'none';
    this.link = originalButton.getAttribute('href');
  },
  methods: {
    onClick() {
      this.$bvModal
        .msgBoxConfirm(this.$t('building.copy_confirm_message'), {
          title: this.$t('building.copy_confirm_title'),
          okTitle: this.$t('apartment.copy_confirm_ok'),
          okVariant: 'primary',
          ...STYLES_MODAL.confirm.primary,
        })
        .then((res) => {
          if (res && this.link) {
            location.pathname = this.link;
          }
        });
    },
  },
};
</script>
