import { getExcludedNumbers, generateName } from './buildingSettingsHelper';
import { getValuesFromStringWithNumbers } from '@app/components/building_creat/utils';
import { flattenUnitChildren } from '@app/components/building_edit/helpers/helpers';

export function getNewNameAndCount(count, names, excludedNumbers) {
  let localCount = count;
  let newName;

  if (names[localCount] === undefined) {
    while (excludedNumbers.includes(localCount + 1)) {
      localCount += 1;
    }
    newName = localCount + 1;
  } else if (names[localCount] === null) {
    while (!names[localCount]) {
      localCount += 1;
    }
    newName = names[localCount];
  } else {
    newName = names[localCount];
  }
  return { newName: String(newName), newCount: localCount + 1 };
}

export function getChildrenByType(parent, childType) {
  return parent.children.filter(
    ({ type, _destroy }) => type === childType && !_destroy,
  );
}

export function generateNamesForRules(rules, parent, unitsNumber = 0) {
  const excludedNumbers = getExcludedNumbers(rules);
  const names = [];

  let lastNumber = 1;
  let validNames = 0;
  rules.forEach((rule) => {
    let createdForRule = 0;

    while (createdForRule < rule.quantity) {
      let newName;
      if (!excludedNumbers.includes(lastNumber)) {
        newName = generateName(lastNumber, parent, rule.prefix);
        createdForRule += 1;
        validNames += 1;
      } else {
        newName = null;
      }
      names.push(newName);
      lastNumber += 1;
    }
  });

  while (validNames < unitsNumber) {
    let newName;

    if (!excludedNumbers.includes(lastNumber)) {
      const prefix = rules[0]?.prefix || '';

      newName = generateName(lastNumber, parent, prefix);
      validNames += 1;
    } else {
      newName = null;
    }
    names.push(newName);
    lastNumber += 1;
  }

  return names;
}

export function renameUnitsContinuously(parent, unitType, rules) {
  const units = flattenUnitChildren(parent, unitType);
  const exceptions = getValuesFromStringWithNumbers(rules[0].exclude);
  const prefix = rules[0].prefix?.trim() || '';

  let nextName = 1;
  units.forEach((unit) => {
    while (exceptions.includes(nextName)) {
      nextName += 1;
    }

    if (Number(unit.name) !== nextName && !unit._create) {
      unit._update = true;
    }

    unit.name = `${prefix}${String(nextName)}`;
    nextName += 1;
  });
}
