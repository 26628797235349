<template>
  <div>
    <b-modal
      centered
      id="apartment-key-add-modal"
      ref="modal"
      @ok="onOk"
      :ok-title="$t('apartment.add_user_modal.ok_title')"
      :cancel-title="$t('apartment.add_user_modal.cancel_title')"
      :title="$t('apartment.add_user_modal.title')"
    >
      <BFormValidate
        :model="model"
        :validations="validations"
        ref="form"
        @submit="onOk"
      >
        <template slot-scope="{ validateState }">
          <div class="modal-body-row with-cols mb-3">
            <div class="title">
              {{ $t('apartment.add_user_modal.params_label') }}
            </div>
            <b-form-row>
              <b-col cols="12">
                <BSelectSearch
                  :label="$t('apartment.add_user_modal.user')"
                  :format-result="formatSearchResult"
                  :state="validateState('user')"
                  :handler="searchUser"
                  @select:item="inputUser"
                  :displayProperty="getUserDisplay"
                />
              </b-col>
            </b-form-row>
          </div>
        </template>
      </BFormValidate>
    </b-modal>
  </div>
</template>

<script>
import { BFormValidate, BSelectSearch } from '@/components/base';
import { required } from 'vuelidate/lib/validators';
import { ApiBackendUsers, ApiGeoUnitUser } from '@/api';
import { getUserName } from '@/utils';
import EventBus from '@/packs/EventBus';

function init() {
  return {
    model: {
      user: '',
    },
  };
}

export default {
  name: 'AddUserModal',
  inject: {
    preloader: {
      default: () => {},
    },
  },
  components: {
    BFormValidate,
    BSelectSearch,
  },
  props: {
    selectedGeoUnit: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      ...init(),
      is_guest: false,
      validations: { user: { required } },
      formatSearchResult: {
        top: [
          {
            title: 'name',
            formatter: (val, item) => getUserName(item),
          },
          { title: 'id', formatter: (value) => `#${value}` },
        ],
        bottom: [
          {
            title: 'contact',
            formatter: (val, item) => item.phone || item.email,
          },
        ],
      },
    };
  },
  methods: {
    getUserDisplay(user) {
      if (user.name || user.surname) {
        return `${user.name || ''} ${user.surname || ''}`;
      } else {
        return user.phone ? user.phone : user.email;
      }
    },
    show() {
      this.$refs.modal.show();
    },
    searchUser(val) {
      const encoldeVal = encodeURIComponent(val);
      return ApiBackendUsers.getPagination({
        q: {
          name_or_surname_or_patronymic_or_email_or_phone_or_full_name_or_reverse_full_name_cont_any:
            encoldeVal,
          permissions_client_id_or_users_clients_client_id_eq:
            this.$route.params.client_id,
        },
        per_page: 10,
      }).then((resp) => resp.data);
    },
    inputUser(val) {
      this.model.user = val;
    },
    async onOk(event) {
      event.preventDefault();
      if (this.$refs.form.validate()) {
        this.preloader.show(this.$t('apartment.add_user_modal.user_creation'));
        const [geoUnitRelation] = await ApiGeoUnitUser.getAll({
          q: {
            geo_unit_id_eq: this.selectedGeoUnit.id,
            user_id_eq: this.model.user.id,
          },
        });
        if (geoUnitRelation) {
          this.preloader.hide();
          this.$bvToast.toast(
            this.$t('apartment.add_user_modal.user_already_bound_to_flat'),
            {
              variant: 'primary',
            },
          );
          return;
        }
        await ApiGeoUnitUser.create({
          geo_unit_id: this.selectedGeoUnit.id,
          user_id: this.model.user.id,
          is_guest: this.is_guest,
        })
          .then(() => {
            EventBus.$emit('GeoUnitUser::create');
            this.$refs.modal.hide();
            this.preloader.hide();
          })
          .catch((err) => {
            this.preloader.hide();
            this.$bvToast.toast(err);
          });
      }
    },
  },
};
</script>

<style scoped></style>
