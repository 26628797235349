<template>
  <div>
    <GeoUnitsList
      :value="list"
      disable-add-check-property="id"
      :q="q"
      @input:origin="onInput"
    />
    <input
      v-for="value in inputsValue"
      :name="value.name"
      :value="value.value"
      :key="value.index"
      hidden
    />
  </div>
</template>

<script>
import GeoUnitsList from '@/components/lists/GeoUnitsList.vue';

export default {
  name: 'GeoUnitsListSlim',
  components: {
    GeoUnitsList,
  },
  props: {
    q: {
      type: Object,
      default: {},
    },
    value: {
      type: Array,
      default: new Array(),
    },
    name: {
      type: String, 
      required: true
    }
  },
  data() {
    return {
      list: this.value,
    };
  },
  computed: {
    inputsValue() {
      return this.list
        .filter((i) => i)
        .map(({ id }) => ({
          value: id,
          name: `${this.name}[]`,
        }));
    },
  },
  methods: {
    onInput(list) {
      this.list = list;
    },
  },
};
</script>
