<template>
  <div class="tab" :class="{ 'tab--active': active }">
    <div class="tab-container">
      <div class="tab__name">{{ tab.title }}</div>
      <div v-if="isPercentIndication">
        <div v-for="color in tab.colors">
          <div
            class="multi-color-segment-line"
            :style="{ background: color.color, width: `${color.percent}%` }"
          ></div>
        </div>
      </div>
      <div v-else>
        <div
          v-if="dottedLine"
          class="tab__dotted-line"
          :class="tab.class"
        ></div>
        <div v-else class="tab__line" :class="tab.class"></div>
      </div>
      <div class="tab__value">{{ tab.value }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tab',
  props: {
    tab: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
    },
    dottedLine: {
      type: Boolean,
    },
  },
  computed: {
    isPercentIndication() {
      return this.tab.colors && Array.isArray(this.tab.colors);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../../assets/styles/components/variables';

.multi-color-segment-line {
  height: 2px;
  float: left;
}

.tab {
  position: relative;
  line-height: 30px;
  width: 100%;
  padding: 30px;
  max-width: 250px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .tab-container {
    width: 100%;
  }
  &__name {
    font-size: $font-size-18px-consistent;
  }
  &__line {
    height: 2px;
    background-color: $tab-delimiter;
    border-radius: 2px;
  }
  &__dotted-line {
    border-top: white 2px dotted;
  }
  &__value {
    margin-top: 5px;
    font-size: $font-size-30px;
    font-weight: normal;
  }
  &--active {
    background: #e6f6fe;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 5px;
      width: 100%;
      background-color: $blue;
    }
  }
}
</style>
