<template>
  <div class="row district-list">
    <div class="portlet mx-3">
      <b-input-label
        v-model="word"
        @input="search"
        :label="$t('district.name')"
        autocomplete="off"
      >
        <template #icon>
          <i
            class="icon-local-search district-list__icon district-list__icon_search"
            v-if="word.length == 0"
          ></i>
          <i
            class="icon-local-times district-list__icon district-list__icon_clear"
            v-else
            @click="onClear"
          ></i>
        </template>
      </b-input-label>
    </div>
    <district-card
      v-for="district in filteredDisctricts"
      :key="district.id"
      :district="district"
      :client-id="clientId"
    />
    <div
      class="districts-list__reset portlet mx-3 text-center"
      v-if="filteredDisctricts.length == 0"
    >
      {{ $t('global.not_found') + '.' }}
      <span class="ml-1 text-primary pointer" @click="onClear">
        {{ $t('global.reset') }}
      </span>
    </div>
  </div>
</template>

<script>
import DistrictCard from './DistrictCard.vue';

export default {
  name: 'IndexDistrictsList',
  components: { DistrictCard },
  props: {
    districts: {
      type: Array,
      required: true,
    },
    clientId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      filteredDisctricts: this.districts,
      word: '',
    };
  },
  methods: {
    search() {
      if (this.word.length >= 2 || this.word.length == 0) {
        this.filteredDisctricts = this.districts.filter((district) =>
          district.name.toLowerCase().includes(this.word.toLowerCase()),
        );
      }
    },
    onClear() {
      this.word = '';
      this.filteredDisctricts = this.districts;
    },
  },
};
</script>

<style lang="scss">
.district-list {
  &__icon {
    font-size: 18px;
    color: #99adc0;

    &_clear {
      font-size: 14px;
    }
  }
  &__reset {
    font-size: 16px;
  }
}
</style>
