<template>
  <div class="user-search">
    <BSelectSearch
      :id="`${model}_${name}`"
      :handler="searchUser"
      :de-bounce-timeout="deBounceTimeout"
      :format-result="formatSearchResult"
      :label="placeholder"
      @clear="onClear"
      @focus="onFocus"
      @select="onSelect"
      @select:item="onSelectItem"
      v-model="selected_id"
      :selected="selected"
      :displayProperty="display"
    />
    <input
      :value="selected_id"
      :id="`${model}_${name}`"
      type="hidden"
      :name="`${model}[${name}]`"
    />
  </div>
</template>
<script>
import BSelectSearch from '@/components/base/BSelectSearch.vue';
import i18n from '@app/i18n';
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';

export default {
  name: 'UserSearch',
  components: { BSelectSearch },
  props: {
    model: String,
    name: String,
    deBounceTimeout: {
      type: Number,
      default: 200,
    },
    placeholder: {
      type: String,
      default: i18n.t('global.owner'),
    },
    saveFormId: String,
    selected: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      user: this.selected || {},
      selected_id: null,
    };
  },
  computed: {
    formatSearchResult() {
      return {
        top: [
          {
            title: 'name',
            formatter: (val, item) =>
              item.name && item.surname
                ? `${item.surname || ''} ${item.name || ''} ${
                    item.patronymic || ''
                  }`
                : item.phone,
          },
          { title: 'id', formatter: (value) => `#${value}` },
        ],
        bottom: [
          {
            title: 'contact',
            formatter: (val, item) =>
              [item.phone, item.email].filter((e) => e).join(', '),
          },
        ],
      };
    },
  },
  mounted() {
    if (this.selected) {
      this.selected_id = this.selected.id;
    }
  },
  methods: {
    searchUser(val) {
      const encoldeVal = encodeURIComponent(val);
      return request3
        .get(API_URLS.backendManage.users.fetch(this.clientId), {
          params: {
            q: {
              phone_or_email_or_name_or_surname_or_patronymic_or_full_name_or_reverse_full_name_or_users_clients_name_or_users_clients_surname_or_users_clients_patronymic_or_users_clients_full_name_or_users_clients_reverse_full_name_cont_any:
                encoldeVal,
            },
            per_page: 10,
          },
        })
        .then((res) => res.data);
    },
    saveForm() {
      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    onSelect(result) {
      this.$emit('input', result);
      this.$emit('select', result);
      this.selected_id = result;
      this.saveForm();
    },
    onSelectItem(result) {
      if (!result) return;
      this.user = result;
      this.selected_id = result.id;
      this.display(result);
      this.$emit('input', this.selected_id);
      this.$emit('select', result);
      this.saveForm();
    },
    display(val) {
      if (val.name || val.surname || val.patronymic) {
        return `${val.surname || ''} ${val.name || ''} ${val.patronymic || ''}`;
      }
      return val.phone;
    },
    onClear() {
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
  },
};
</script>
