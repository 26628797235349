const state = {
  clients: ['yandex_home', 'google', 'mail'],
  urlParams: new URLSearchParams(),
};
const getters = {};
const mutations = {
  setUrlParams(state, payload) {
    state.urlParams = payload;
  },
};
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
