<template>
  <b-tabs
    nav-wrapper-class="intercom-tabs-wrapper"
    active-tab-class="intercom-tabs__tab_active"
    active-nav-item-class="intercom-tabs__tab_active"
    nav-class="intercom-tabs"
  >
    <b-tab
      :title-link-class="[
        {
          'intercom-tabs__error': mainTabErrors && isSubmit,
        },
        'intercom-tabs__tab',
      ]"
      active
    >
      <template #title>
        <div class="d-flex align-items-center gap-2">
          <i class="icon-local-intercom-tablet" />
          <p class="mb-0">{{ $t('intercom.table_titles.main') }}</p>
        </div>
      </template>
      <intercom-main-setting-tab
        :errors="errors"
        :client-id="clientId"
        :geo-unit-deep-at="geoUnitDeepAt"
        :intercom-models="intercomModels"
        :validate-state="validateState"
        :is-requred-uuid="isRequredUuid"
        :is-required-ip-address="isRequiredIpAddress"
        :is-required-mac-address="isRequiredMacAddress"
        :is-show-control-panel="isShowControlPanel"
        :is-submit="isSubmit"
        :backend-errors="backendErrors"
      />
    </b-tab>
    <b-tab
      :title-link-class="[
        {
          'intercom-tabs__error': relaysErrors.$invalid && isSubmit,
        },
        'intercom-tabs__tab',
      ]"
      lazy
    >
      <template #title>
        <div class="d-flex align-items-center gap-2">
          <i class="icon-local-door-open" />
          <p class="mb-0">{{ $t('intercom.table_titles.doors') }}</p>
        </div>
      </template>
      <intercom-relays-tab
        :errors="relaysErrors"
        :client-id="clientId"
        :geo-unit-deep-at="geoUnitDeepAt"
        :disabled="!intercomModel"
        :validate-state="validateState"
        :is-submit="isSubmit"
      />
    </b-tab>
    <b-tab
      :title-link-class="[
        {
          'intercom-tabs__error':
            (proxyErrors.$invalid || sipErrors.$invalid) && isSubmit,
        },
        'intercom-tabs__tab',
      ]"
      v-if="isShowCallButton"
      lazy
    >
      <template #title>
        <div class="d-flex align-items-center gap-2">
          <i class="icon-local-intercom0" />
          <p class="mb-0">{{ $t('intercom.table_titles.calls') }}</p>
        </div>
      </template>
      <intercom-calls-tab
        :proxy-errors="proxyErrors"
        :sip-errors="sipErrors"
        :client-id="clientId"
        :validate-state="validateState"
        :actual-servers="actualServers"
        :is-required-select-sip-server="isRequiredSelectSipServer"
        :is-submit="isSubmit"
      />
    </b-tab>
    <b-tab
      :title-link-class="[
        {
          'intercom-tabs__error': keysErrors && isSubmit,
        },
        'intercom-tabs__tab',
      ]"
      lazy
    >
      <template #title>
        <div class="d-flex align-items-center gap-2">
          <i class="icon-local-key" />
          <p class="mb-0">{{ $t('intercom.table_titles.keys') }}</p>
        </div>
      </template>
      <intercom-keys-tab
        :client-id="clientId"
        :validate-state="validateState"
        :is-show-keys-profiles="isShowKeysProfiles"
        :is-multiple-profiles="isMultipleProfiles"
        :is-show-key-types="isShowKeyTypes"
        :errors="settingErrors"
        :is-submit="isSubmit"
      />
    </b-tab>
    <b-tab
      title-link-class="intercom-tabs__tab"
      v-if="intercomButtonsCount"
      lazy
    >
      <template #title>
        <div class="d-flex align-items-center gap-2">
          <i class="icon-local-dots-dots-vertical" />
          <p class="mb-0">{{ $t('intercom.table_titles.buttons') }}</p>
        </div>
      </template>
      <intercom-buttons-tab
        :client-id="clientId"
        :validate-state="validateState"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import IntercomKeysTab from './IntercomKeysTab.vue';
import IntercomCallsTab from './IntercomCallsTab.vue';
import IntercomRelaysTab from './IntercomRelaysTab.vue';
import IntercomButtonsTab from './IntercomButtonsTab.vue';
import IntercomMainSettingTab from './IntercomMainSettingTab.vue';
import { GEO_UNITS } from '@/consts';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'IntercomTabs',
  components: {
    IntercomKeysTab,
    IntercomCallsTab,
    IntercomRelaysTab,
    IntercomButtonsTab,
    IntercomMainSettingTab,
  },
  props: {
    clientId: {
      type: [Number, String],
      required: true,
    },
    validateState: {
      type: Function,
    },
    intercomModels: {
      type: Array,
      default: [],
    },
    isRequredUuid: {
      type: Boolean,
      required: true,
    },
    isRequiredIpAddress: {
      required: true,
    },
    isShowKeysProfiles: {
      type: Boolean,
    },
    isMultipleProfiles: {
      type: Boolean,
    },
    isShowCallButton: {
      type: Boolean,
    },
    isShowControlPanel: {
      type: Boolean,
      required: true,
    },
    actualServers: {
      type: Array,
      default: [],
    },
    isRequiredSelectSipServer: {
      type: Boolean,
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
    isRequiredMacAddress: {
      type: Boolean,
    },
    isShowKeyTypes: {
      type: Boolean,
      default: false,
    },
    backendErrors: {
      type: Object,
      default: null,
    },
    v: {},
  },
  computed: {
    ...mapState('intercom', ['intercomModel']),
    ...mapGetters('intercom', ['getIntercomModel']),
    intercomModel() {
      return this.getIntercomModel;
    },
    geoUnitDeepAt() {
      const baseGeoUnits = [
        GEO_UNITS.Building,
        GEO_UNITS.Entrance,
        GEO_UNITS.Floor,
        GEO_UNITS.ParkingArea,
        GEO_UNITS.Section,
        GEO_UNITS.TechRoom,
        GEO_UNITS.Territory,
      ];
      if (this.intercomModel?.tags?.includes('one_property')) {
        return [
          GEO_UNITS.Apartment,
          GEO_UNITS.Bkfn,
          GEO_UNITS.ParkingPlace,
          GEO_UNITS.StoreRoom,
          GEO_UNITS.TechRoom,
        ];
      }
      if (this.intercomModel?.type === 'access_control') {
        return [...baseGeoUnits, GEO_UNITS.Roof];
      } else return baseGeoUnits;
    },
    intercomButtonsCount() {
      return this.intercomModel?.buttons_count || undefined;
    },
    relaysErrors() {
      return this.v?.model?.relays_attributes;
    },
    proxyErrors() {
      return this.v?.model?.settings.proxy_schema;
    },
    sipErrors() {
      return this.v?.model?.sip_server_id;
    },
    settingErrors() {
      return this.v?.model?.settings;
    },
    keysErrors() {
      if (this.v?.model) {
        return (
          this.v?.model?.settings.keys_profile_ids.$invalid ||
          this.v?.model?.settings.key_types.$invalid
        );
      } else return false;
    },
    mainTabErrors() {
      if (this.v?.model) {
        return (
          this.v.model.intercom_model_id.$invalid ||
          this.v.model.control_panel_id.$invalid ||
          this.v.model.uuid.$invalid ||
          this.v.model.geo_unit_id.$invalid ||
          this.v.model.macaddr.$invalid ||
          this.v.model.ip_address.$invalid ||
          this.v.model.serial.$invalid ||
          this.v.model.name.$invalid ||
          this.v.model.settings.username.$invalid ||
          this.v.model.settings.password.$invalid ||
          (this.backendErrors &&
            (this.backendErrors['uuid'] ||
              this.backendErrors['serial'] ||
              this.backendErrors['macaddr'] ||
              this.backendErrors['ip_address']))
        );
      } else return false;
    },
    errors() {
      return this.v?.model;
    },
  },
  methods: {},
};
</script>

<style lang="scss">
@import 'app/javascript/assets/styles/components/variables';
.intercom-tabs-wrapper {
  padding: 0 15px;
  .nav-tabs {
    .nav-link {
      border: none;
      border-bottom: 1px solid transparent !important;
      color: $color-font-black-black;
      background: transparent;
      font-size: 18px;
      font-weight: 350;
      padding: 10px 15px;
    }
    .intercom-tabs {
      &__tab {
        background: transparent;
        border: 1px solid transparent;
        &_active {
          background: transparent;
          color: $blue;
          border-bottom: 1px solid $blue !important;
        }
      }
      &__error {
        color: #f83838;
        border-bottom-color: #f83838 !important;
      }
    }
  }
}

.intercom-tabs {
  border-bottom: 1px solid transparent;
}
</style>
