<template>
  <div class="buildings-list">
    <div class="portlet">
      <b-input-label
        v-model="word"
        @input="search"
        :label="$t('address.title')"
      >
        <template #icon>
          <i
            class="icon-local-search buildings-list__icon buildings-list__icon_search"
            v-if="word.length == 0"
          ></i>
          <i
            class="icon-local-times buildings-list__icon buildings-list__icon_clear"
            v-else
            @click="onClear"
          ></i>
        </template>
      </b-input-label>
    </div>
    <building-card
      v-for="building in filteredBuildings"
      :key="building.id"
      :building="building"
      :client-id="clientId"
      :district-id="districtId"
    />
    <div
      class="buildings-list__reset portlet text-center"
      v-if="filteredBuildings.length == 0"
    >
      {{ $t('global.not_found') + '.' }}
      <span class="ml-1 text-primary pointer" @click="onClear">
        {{ $t('global.reset') }}
      </span>
    </div>
  </div>
</template>

<script>
import BuildingCard from './BuildingCard.vue';

export default {
  name: 'IndexBuildingsList',
  components: { BuildingCard },
  props: {
    buildings: {
      type: Array,
      required: true,
    },
    clientId: {
      type: [Number, String],
      required: true,
    },
    districtId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      filteredBuildings: this.buildings,
      word: '',
    };
  },
  methods: {
    search() {
      if (this.word.length >= 2 || this.word.length == 0) {
        this.filteredBuildings = this.buildings.filter((building) =>
          building.name.toLowerCase().includes(this.word.toLowerCase()),
        );
      }
    },
    onClear() {
      this.word = '';
      this.filteredBuildings = this.buildings;
    },
  },
};
</script>

<style lang="scss">
.buildings-list {
  &__icon {
    font-size: 18px;
    color: #99adc0;

    &_clear {
      font-size: 14px;
    }
  }
  &__reset {
    font-size: 16px;
  }
}
</style>
