<template>
  <div class="marks-autocomplete">
    <BDropdownInput
      v-model="selected_id"
      :description="description"
      displayProperty="name"
      :disabled="disabled"
      :label="placeholder"
      :handler="fetch"
      :selected="markData"
      :state="state"
      :showLoading="showLoading"
      :variantSpinner="variantSpinner"
      :debouncedTts="debouncedTts"
      compareProperty="id"
      @clear="onClear"
      @focus="onFocus"
      @select="onSelect"
      @input="onInput"
      @select:item="onSelectItem"
      :popoverText="popoverText"
      :popoverHint="popoverHint"
      :hintId="hintId"
      :minWordLength="minWordLength"
      :comboBox="comboBox"
      :emptyMessage="$t('plate.marks_empty')"
      :multiple="multiple"
    />
  </div>
</template>

<script>
import BDropdownInput from '@/components/base/BDropdownInput';
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';

export default {
  name: 'MarksAutocomplete',
  components: {
    BDropdownInput,
  },
  props: {
    markId: {
      type: [String, Number],
      default: null,
    },
    description: {
      type: String,
    },
    clientId: {
      type: [String, Number],
    },
    placeholder: {
      type: String,
    },
    value: {
      type: [String, Number],
    },
    state: {},
    debouncedTts: {
      type: Number,
      default: 200,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    additionalQueryParams: {
      type: Object,
      default() {
        return {};
      },
    },
    optional: {
      type: Object,
      default: null,
    },
    popoverText: {
      type: String,
      default: 'i',
    },
    popoverHint: {
      type: String,
      default: null,
    },
    hintId: {
      type: String,
      default: null,
    },
    placementPopover: {
      type: String,
      default: 'bottom',
    },
    showLoading: {
      type: Boolean,
      default: true,
    },
    variantSpinner: {
      type: String,
      default: 'primary',
    },
    minWordLength: {
      type: Number,
      default: 3,
    },
    comboBox: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected_id: null,
      markData: null,
    };
  },
  async mounted() {
    if (this.markId) {
      let params = {
        id_eq: this.markId,
      };

      let query = {
        ...params,
        ...this.additionalQueryParams,
      };

      this.markData = await request3
        .get(API_URLS.npr.marks.fetch(this.clientId), {
          params: {
            q: query,
          },
        })
        .then((res) => res.data[0]);

      this.selected_id = this.markData.id;
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
    onSelect(value) {
      this.$emit('input', value);
      this.$emit('select', value);
      this.selected_id = value;
    },
    onSelectItem(item) {
      this.$emit('select:item', item);
    },
    fetch(val) {
      let params = {
        name_cont_any: val,
      };
      let query = {
        ...params,
        ...this.additionalQueryParams,
      };

      return request3
        .get(API_URLS.npr.marks.fetch(this.clientId), {
          params: {
            q: query,
          },
        })
        .then((res) => res.data);
    },
    onClear() {
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
  },
};
</script>
