<template>
  <div>
    <BSelectLabel
      :label="label"
      :items="items"
      :deselect="deselect"
      :disabled="Boolean(disabled)"
      :state="state"
      display="title"
      @select="onSelect"
      v-model="value"
      :class="selector"
    />
    <slot></slot>
  </div>
</template>

<script>
import BSelectLabel from '@/components/base/BSelectLabel.vue';

export default {
  name: 'SelectSlot',
  props: {
    label: {
      type: String,
    },
    disabled: {
      type: [String, Boolean],
      default: false,
      validator: (val) =>
        typeof val === 'boolean' || ['true', 'false'].includes(val),
    },
    deselect: {
      type: Boolean,
    },
    state: {
      type: Boolean,
      default: undefined,
    },
    selector: {
      type: String,
    },
  },
  data() {
    return {
      items: [],
      value: null,
      select: null,
    };
  },
  components: {
    BSelectLabel,
  },
  mounted() {
    const select = this.$el.querySelector('select');
    if (select) {
      const options = select.querySelectorAll('option');
      this.select = select;
      options.forEach((option) => {
        this.items.push({ value: option.value, title: option.text });
      });
      this.value = this.items.find((item) => item.value === select.value);
    } else {
      console.error('Not find select');
    }
  },
  methods: {
    onSelect(item) {
      if (item) {
        this.select.value = item.value;
        this.select.dispatchEvent(new Event('change'));
        this.$emit('select', item.value);
      } else {
        this.select.value = '';
        this.select.dispatchEvent(new Event('change'));
        this.$emit('select', null);
      }
    },
  },
};
</script>

<style scoped lang="scss">
select {
  display: none;
}
</style>
