<template>
  <b-modal :title="$t('devices.modal.create')" ref="errorCreate" no-stacking>
    <div class="modal-body-row with-cols text-center p-8">
      <div class="d-flex gap-2 align-items-center justify-content-center mb-3">
        <i
          class="icon-local-attention fast-modal__icon fast-modal__icon_attention"
        />
        <p class="fast-modal__text mb-0">
          {{ $t('devices.modal.added.error.title') }}
        </p>
      </div>
      <p class="fast-modal__text">
        {{ $t('devices.modal.added.error.text') }}
        <a :href="platform_support">
          {{ $t('devices.modal.added.error.link') }}
        </a>
      </p>
    </div>
    <template #modal-footer>
      <div
        class="d-flex align-items-center justify-content-between flex-grow-1"
      >
        <b-button
          size="sm"
          variant="outline-primary"
          class="fast-modal__btn w-auto h-auto"
          @click="hide"
        >
          {{ $t('devices.modal.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          class="fast-modal__btn w-auto h-auto"
          @click="openUuidSelectModal"
        >
          {{ $t('devices.modal.reset') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import EventBus from '@/packs/EventBus';
import { EVENTS } from '@/consts';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'ErrorCreateIntercom',
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapState('global', ['global']),
    ...mapGetters('global', ['getPlatformSupport']),

    platform_support() {
      return this.getPlatformSupport;
    },
  },
  methods: {
    hide() {
      this.$refs.errorCreate.hide();
    },
    show() {
      this.$refs.errorCreate.show();
    },
    openUuidSelectModal() {
      this.hide();
      EventBus.$emit(EVENTS.intercom.fast.modal.show);
    },
  },
};
</script>
