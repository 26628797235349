<template>
  <div>
    <b-modal
      centered
      id="user-key-edit-modal"
      ref="modal"
      modal-class="modal-with-b-form-row"
      @ok="onOk"
      :ok-title="isCreate ? $t('button.add') : $t('button.save')"
    >
      <template #modal-header="{ close }">
        <div class="title">
          {{
            isCreate
              ? $t('user.edit.key.add_title')
              : $t('user.edit.key.edit_title')
          }}
        </div>
        <div v-if="!isCreate" class="subtitle">
          {{ $t('intercom.key.title') }} {{ model.name || model.id }}
        </div>
        <button class="close" @click="close()">×</button>
      </template>
      <BFormValidate :model="model" :validations="validations" ref="form">
        <template slot-scope="{ validateState }">
          <div class="modal-body-row with-cols border-bottom">
            <div class="title">{{ $t('intercom.key.title') }}</div>
            <b-form-row v-if="isCreate">
              <b-col cols="6">
                <BSelectLabel
                  v-model="model.keySet"
                  :items="keySets"
                  display="name"
                  :label="$t('intercom.key.set_keys')"
                  :state="validateState('keySet')"
                  no-padding-label
                />
              </b-col>
              <b-col cols="6">
                <BSelectSearch
                  v-model="model.key.id"
                  @select:item="onSelectKey"
                  :items="keys"
                  :label="$t('intercom.key.title')"
                  :state="validateState('key')"
                  display-property="number"
                  :format-result="formatSearchResult"
                  :handler="onChangeSearch"
                />
              </b-col>
            </b-form-row>
            <b-form-row v-else>
              <b-col cols="6">
                <BInputLabel
                  v-model="model.key.number"
                  :label="getCustomDisplayNameKey(model)"
                  :state="validateState('number')"
                  disabled
                />
              </b-col>
              <b-col cols="6">
                <BSelectLabel
                  v-model="model.key.type"
                  :items="types"
                  :label="$t('intercom.key.type')"
                  :state="validateState('type')"
                  display="label"
                  object-property="type"
                  @selected="onSelectKeyType"
                  no-padding-label
                />
              </b-col>
            </b-form-row>
          </div>
          <div class="modal-body-row with-cols">
            <div class="title">
              {{ $t('intercom.intercom_keys.add.access_title') }}
            </div>
            <b-form-row>
              <b-col cols="12">
                <div
                  v-for="(relation, index) in relatedGeoUnits"
                  :key="`${relation.id}_${index}`"
                  class="d-flex"
                >
                  <GeoUnitsAutocomplete
                    :client-id="Number(clientId)"
                    :geo-unit.sync="model.key.related_geo_units[index]"
                    :placeholder="$t('address.title')"
                    geo-unit-property="post_name"
                    class="flex-grow-1 mb-3"
                    :additional-query-params="{
                      type_not_in: ['City', 'Street'],
                    }"
                    @select:item="
                      (val) => (model.key.related_geo_units[index] = val)
                    "
                  />
                  <b-button
                    @click="onRemoveGeoUnit(index)"
                    class="btn-square"
                    variant="outline-primary"
                  >
                    <i class="icon-local-trash"></i>
                  </b-button>
                </div>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <b-button
                    :disabled="isDisabledAddGeoUnit"
                    variant="info"
                    block
                    @click="onAddRelatedGeoUnit"
                  >
                    {{ $t('button.add_property') }}
                  </b-button>
                </b-form-group>
              </b-col>
            </b-form-row>
            <template v-if="model.key.type === 'Service'">
              <div class="title">{{ $t('district.title') }}</div>
              <b-form-row>
                <b-col v-if="isShowDistrictAutocomplete" cols="12">
                  <div class="d-flex">
                    <DistrictsAutocomplete
                      v-model="model.key.district_id"
                      @clear="onClearDistrict"
                      @select:item="onSelectDistrict"
                      class="flex-grow-1"
                    />
                    <b-button
                      @click="onRemoveDistrict"
                      class="btn-square"
                      variant="outline-primary"
                    >
                      <i class="icon-local-trash"></i>
                    </b-button>
                  </div>
                </b-col>
                <b-col cols="12">
                  <b-form-group v-if="!isShowDistrictAutocomplete">
                    <b-button variant="info" @click="onAddDistrict" block>
                      {{ $t('button.add_district') }}
                    </b-button>
                  </b-form-group>
                  <b-form-group>
                    <b-button
                      variant="outline-primary"
                      block
                      :disabled="isDisabledSetupDoorAccessBtn"
                      @click="onSetupDoorsAccess"
                    >
                      {{ $t('button.setup_doors_access') }}
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </template>
            <b-form-row>
              <b-col cols="12">
                <b-form-checkbox
                  size="lg"
                  v-model="model.add_to_all_properties"
                >
                  {{ $t('user.edit.key.add_access_to_all_properties') }}
                </b-form-checkbox>
                <small
                  v-if="model.add_to_all_properties"
                  class="form-text text-warning"
                >
                  {{ $t('user.edit.key.accesses_replaced') }}
                </small>
              </b-col>
            </b-form-row>
          </div>
        </template>
      </BFormValidate>
    </b-modal>
    <RelaysGroupsSettingModal
      v-model="model.key.keys_relays_black_lists"
      :district="model.key.district"
      :related-geo-units="model.key.related_geo_units"
      ref="SetupAccessModal"
    />
  </div>
</template>

<script>
import { ApiBackendKeys, ApiKeys, ApiBackendKeySets, fetchDistricts } from '@/api';
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete.vue';
import DistrictsAutocomplete from '@/components/autocompletes/DistrictsAutocomplete';
import RelaysGroupsSettingModal from '@/components/modals/RelaysGroupsSettingModal.vue';
import { EVENTS } from '@/consts';
import EventBus from '@/packs/EventBus';
import _ from 'lodash';
import Vue from 'vue';
import { requiredIf } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

function initData(item) {
  return {
    model:
      item && item.key
        ? {
            key: _.cloneDeep({
              ...item.key,
              district_id: item.key.district?.id || null,
            }),
          }
        : {
            key: {
              id: null,
            },
            type: '',
            keySet: '',
            add_to_all_properties: false,
            district_id: null,
          },
    keySets: [],
    keys: [],
    connects: [],
    isCreate: true,
    cachedRelatedGU: [],
    client: item && item.client ? item.client : '',
    originBlackList: (item?.keys_relays_black_lists || []).map(
      (relay) => relay,
    ),
    isShowDistrictAutocomplete: false,
  };
}
export default Vue.extend({
  name: 'UserModalKey',
  components: {
    GeoUnitsAutocomplete,
    DistrictsAutocomplete,
    RelaysGroupsSettingModal,
  },
  props: {
    clientId: {
      required: true,
      type: Number,
    },
    userId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      ...initData(),
      validations: {
        keySet: {
          required: requiredIf(() => this.isCreate),
        },
        key: {
          required: requiredIf(() => this.isCreate),
        },
      },
      types: [
        { label: this.$t('key.types.service'), type: 'Service' },
        { label: this.$t('key.types.common'), type: 'Common' },
      ],
      keys: [],
      keySets: [],
      formatSearchResult: {
        top: [
          {
            title: 'number',
            // formatter: (val, item) => getUserName(item),
          },
          { title: 'id', formatter: (value) => `#${value}` },
        ],
        bottom: [
          {
            title: 'name',
          },
        ],
      },
    };
  },
  computed: {
    relatedGeoUnits() {
      if (this.model.add_to_all_properties) {
        return [];
      }
      return this.model.key.related_geo_units;
    },
    isDisabledAddGeoUnit() {
      return !this.model.key.id || this.model.add_to_all_properties;
    },
    isDisabledSetupDoorAccessBtn() {
      if (this.model.key.district?.id) return false;
      return !this.relatedGeoUnits.find(({ id }) => id);
    },
  },
  methods: {
    ...mapActions(['showPreloader', 'hidePreloader']),
    onShow(item) {
      Object.assign(this.$data, initData(item));
      this.getKeySets();
      if (item.key) this.isCreate = false;
      if (item.key?.type === 'Service') {
        if (item.key?.district?.id) {
          this.isShowDistrictAutocomplete = true;
        }
      }
      this.$refs.modal.show();
    },
    async getKeySets() {
      this.keySets = await ApiBackendKeySets.getAll({
        q: { client_id_eq: this.clientId },
      });
    },
    getCustomDisplayNameKey(key) {
      return this.$t('intercom.key.title_id', [
        key.id,
        key.name && `(${key.name})`,
      ]);
    },
    onChangeSearch(val) {
      return ApiBackendKeys.getPagination({
        q: {
          // g: [
          //   { m: 'or', id_eq: val, number_cont: val },
          //   { keyset_id_eq: this.model.keySet.id },
          // ],
          number_cont: val,
          keyset_id_eq: this.model.keySet.id,
          user_id_null: true,
        },
        per_page: 10,
      }).then((res) => res.data);
    },
    onSelectKey(key) {
      this.model.key = Object.assign({}, key);
      if (key?.id) {
        this.model.key.related_geo_units = [];
        ApiBackendKeys.getPagination({
          q: { id_eq: key.id },
          includes: ['related_geo_units'],
          per_page: 1,
        }).then(({ data }) => {
          this.model.keySet = this.keySets.find(
            (keySet) => keySet.id === data[0].keyset_id,
          );
          this.model.key = data[0];
          if (
            this.model.key.type == 'Service' &&
            !this.model.key.district?.id
          ) {
            this.model.key.district_id = null;
          }
        });
      }
    },
    onAddRelatedGeoUnit() {
      this.model.key.related_geo_units.push({});
    },
    onRemoveGeoUnit(index) {
      this.model.key.related_geo_units.splice(index, 1);
    },
    onAddDistrict() {
      this.isShowDistrictAutocomplete = true;
    },
    onRemoveDistrict() {
      this.isShowDistrictAutocomplete = false;
      this.model.key.district_id = null;
    },
    onClearDistrict() {
      this.model.key.district_id = null;
      this.model.key.district = null;
    },
    onSelectDistrict(district) {
      this.model.key.district_id = district.id;
      this.model.key.district = district;
    },
    onSelectKeyType(type) {
      if (type != 'Service') {
        this.model.key.district_id = null;
        this.model.key.district = null;
      }
      if (type == 'Service' && !this.model.key.district) {
        this.model.key.district_id = null;
        this.model.key.district = null;
      }
    },
    async onSetupDoorsAccess() {
      const district = await fetchDistricts(this.model.key.district.id);
      this.$set(this.model.key.district, 'buildings', district.buildings);
      this.$set(this.model.key.district, 'territories', district.territories);
      this.$refs.SetupAccessModal.show();
    },
    async onOk(event) {
      if (event && event.type === 'submit') event.preventDefault();
      if (this.$refs.form.validate()) {
        const related_geo_units_ids = this.model.key.related_geo_units.map(
          (geoUnit) => geoUnit.id,
        );

        const blackList = [];
        const keys_relays_black_lists =
          this.model.key.keys_relays_black_lists.forEach((relay) => {
            const existOriginBlackList = this.originBlackList.find(
              (origin) => origin.relay_id === relay.relay_id,
            );
            if (!existOriginBlackList) {
              blackList.push(relay);
            }
          });

        this.originBlackList.forEach((origin) => {
          const hasNotInOriginBlackList = keys_relays_black_lists.every(
            (relay) => relay.relay_id !== origin.relay_id,
          );
          if (hasNotInOriginBlackList) {
            blackList.push({ ...origin, _destroy: true });
          }
        });

        EventBus.$emit(
          EVENTS.preloader.show,
          this.$t('user.edit.key.adding_key'),
        );
        try {
          await ApiKeys.update({
            ...this.model.key,
            related_geo_units_ids,
            user_id: this.userId,
            keys_relays_black_lists_attributes: blackList,
            add_to_all_properties: this.model.add_to_all_properties,
          });
          this.$bvToast.toast(this.$t('user.edit.key.add_key_success'), {
            variant: 'success',
          });
        } catch (err) {
          if (err.errors) {
            const errors = Object.entries(err.errors)
              .flatMap(([_, value]) => value)
              .join(' ');
            this.$bvToast.toast(errors);
          } else {
            this.$bvToast.toast(err);
          }
        }
        EventBus.$emit(EVENTS.preloader.hide);
        this.$refs.modal.hide();
        this.$emit('created');
        this.$emit('Key::refresh');
      } else {
        event.preventDefault();
      }
    },
  },
});
</script>

<style lang="scss" scoped></style>
