<template>
  <div class="keys-container">
    <div class="keys-container__table">
      <div class="table-header">
        <div class="table-header__title">{{ $t('intercom.key.titles') }}</div>
        <div v-if="isShowEditButton" class="table-header__buttons">
          <b-button
            variant="outline-primary"
            size="sm"
            @click="$refs.AddKeyModal.show()"
          >
            {{ $t('button.add') }}
          </b-button>
          <b-button
            variant="outline-primary"
            size="sm"
            @click="onDeleteKey"
            :disabled="!isSelectedKey"
          >
            {{ $t('button.delete') }}
          </b-button>
        </div>
      </div>
      <PTable
        ref="PTableKeys"
        per-page="15"
        :api="ApiBackendKeys"
        :query-params="keyTableQueryParams"
        max-height="300"
        @mounted="selectFirstElInTable"
        show-empty
      >
        <template v-slot:content="{ items }">
          <b-table
            selectable
            select-mode="range"
            :items="items"
            :fields="fields.keys"
            striped
            @row-selected="selectItem"
            ref="BTableKeys"
          >
            <template v-slot:cell(relays_keys)="{ value }">
              <b-badge :variant="value.variant">
                {{ `${value.synced}/${value.count}` }}
              </b-badge>
            </template>
          </b-table>
        </template>
      </PTable>
    </div>
    <div class="keys-container__table">
      <div class="table-header">
        <div class="table-header__title">
          {{ $t('intercom.on_way_to_apartment') }}
        </div>
      </div>
      <PTable
        v-if="selectedKey && selectedKey.id"
        ref="PTableRelays"
        per-page="15"
        :api="ApiBackendRelays"
        :query-params="relayTableQueryParams"
        max-height="239"
        show-empty
      >
        <template v-slot:content="{ items }">
          <b-table :items="items" :fields="fields.relays" striped>
            <template v-slot:cell(parent_geo_units_chain)="{ value }">
              <i class="icon-local-round text-success mr-2"></i>
              {{ value }}
            </template>
            <template v-slot:cell(sync_value)="{ value }">
              <b-badge v-if="value" :variant="IntercomStatusesVariant[value]">
                {{ $t(`global.${value}`) }}
              </b-badge>
            </template>
          </b-table>
        </template>
      </PTable>
    </div>
    <AddKeyModal
      v-if="isEditRole"
      :selected-geo-unit="selectedGeoUnit"
      ref="AddKeyModal"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
// modals
import AddKeyModal from '@app/components/apartment/AddKeyModal.vue';

import { ApiBackendKeys, ApiKeys, ApiBackendRelays } from '@/api';
import { getUserName } from '@/helpers';
import { IntercomStatusesVariant } from '@/helpers/consts';
import EventBus from '@/packs/EventBus';
import { EventBusMixin } from '@app/mixins';

export default {
  name: 'KeysListWithAccesses',
  components: { AddKeyModal },
  mixins: [EventBusMixin],
  props: {
    selectedGeoUnit: {
      required: true,
      type: Object,
    },
    role: {
      type: String,
      validator: (val) => ['read', 'edit'].includes(val),
    },
    area: {
      type: String,
      validator: (val) => ['manage', 'personal'].includes(val),
      required: true,
    },
  },
  data() {
    return {
      selectedKey: {},
      mutation: 'CallSessionsWithIntercom',
      isLoadedPTable: false,
      ApiBackendKeys,
      ApiBackendRelays,
      IntercomStatusesVariant,
    };
  },
  computed: {
    fields() {
      const keys = [
        {
          key: 'user',
          label: this.$t('global.owner'),
          formatter: (user) => getUserName(user),
        },
        {
          key: 'number',
          label: this.$t('global.number'),
        },
        {
          key: 'relays_keys',
          label: this.$t('global.status'),
          formatter: (relays) => {
            // TODO Add real sync data
            const synced = relays.filter((relay) => {
              const { last_request } = relay;
              return last_request?.status === 'Success';
            });
            return {
              count: relays.length,
              synced: synced.length,
              variant: relays.length === synced.length ? 'success' : 'danger',
            };
          },
        },
      ];
      const relays = [
        {
          key: 'geo_unit_short_name',
          label: this.$t('global.location'),
        },
        {
          key: 'last_request',
          label: this.$t('intercom.key.key_syncing'),
        },
      ];
      if (['manage'].includes(this.area)) {
        keys.unshift({
          key: 'id',
          label: 'id',
          class: 'id-col',
        });
        relays.push({
          key: 'date',
          label: this.$t('global.date_and_time'),
          formatter: () => dayjs().format('DD.MM.YYYY hh:mm'),
        });
      }
      return {
        keys,
        relays,
      };
    },
    isEditRole() {
      return this.role === 'edit';
    },
    keyTableQueryParams() {
      return {
        q: {
          related_geo_units_ids_array_contains: this.geoUnitId,
        },
        includes: ['user', 'relays_keys', 'related_geo_units'],
      };
    },
    relayTableQueryParams() {
      return {
        q: { relays_keys_key_id_eq: this.selectedKey.id },
        with_last_request: true,
      };
    },
    isSelectedKey() {
      return this.selectedKey && this.selectedKey.id;
    },
    geoUnitId() {
      return this.$route?.params.geo_unit_id || this.selectedGeoUnit.id;
    },
    isShowEditButton() {
      return this.isLoadedPTable && this.isEditRole;
    },
  },
  mounted() {
    this.EventBusOn(
      ['Apartment::key::added', 'IntercomKeyCreate', 'IntercomKeyModalUpdate'],
      () => {
        this.$refs.PTableKeys.refresh();
        this.$emit('updated-list-key');
      },
    );
  },
  methods: {
    selectItem([item]) {
      this.selectedKey = item;
    },
    onDeleteKey() {
      if (this.selectedKey.id) {
        this.$bvModal
          .msgBoxConfirm(`${this.$t('apartment.delete_key_modal.message')}`, {
            title: this.$t('apartment.delete_key_modal.title'),
            okTitle: this.$t('apartment.delete_key_modal.ok_title'),
            okVariant: 'danger',
            bodyClass: 'modal-body__confirm',
          })
          .then(async (res) => {
            if (res) {
              EventBus.$emit(
                'preloader::show',
                this.$t('intercom.key.deleting'),
              );
              this.selectedKey.related_geo_units_ids =
                this.selectedKey.related_geo_units
                  .map((gu) => gu.id)
                  .filter(
                    (relatedId) => Number(relatedId) !== Number(this.geoUnitId),
                  );
              await ApiKeys.update(this.selectedKey)
                .then(() => {
                  // Refresh keys list
                  this.$refs.PTableKeys.refresh();
                  EventBus.$emit('Apartment::key::deleted');
                  this.selectedKey = {};
                })
                .catch((err) => {
                  this.$bvToast.toast(err.error || err);
                });
              EventBus.$emit('preloader::hide');
            }
          });
      }
    },
    selectFirstElInTable(items) {
      this.$refs.BTableKeys && this.$refs.BTableKeys.selectRow(0);
      this.selectItem(items);
      this.isLoadedPTable = true;
    },
  },
};
</script>

<style scoped></style>
