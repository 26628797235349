<template>
  <div>
    <b-modal
      ref="InfoIntercomModal"
      :title="$t('devices.modal.create')"
      no-stacking
      @hidden="hide"
    >
      <b-form-validate
        ref="infoForm"
        :model="intercom"
        :validations="validations"
      >
        <template slot-scope="{ validateState, v }">
          <div class="modal-body-row with-cols pt-30">
            <b-col cols="12" class="mb-3">
              <GeoUnitsAutocomplete
                v-model="intercom.geo_unit_id"
                @clear="intercom.geo_unit = {}"
                @update:geoUnit="(value) => (intercom.geo_unit = value)"
                :client-id="Number(clientId)"
                :geo-unit="intercom.geo_unit"
                :placeholder="$t('devices.modal.address')"
                show-loading
                class="w-100 mb-0 intercom-autocompletes"
                section="intercom"
                :state="validateState('geo_unit_id')"
                :geo-unit-deep-at="geoUnitDeepAt"
              ></GeoUnitsAutocomplete>
              <BError
                v-if="!v.model.geo_unit_id.required && isSubmit"
                :error="$t('devices.errors.not_empty')"
              />
            </b-col>
            <b-col cols="12">
              <BInputLabel
                :label="$t('devices.modal.name')"
                v-model="intercom.name"
                :state="validateState('name')"
                class="mb-0"
              />
              <BError
                v-if="!v.model.name.required && isSubmit"
                :error="$t('devices.errors.not_empty')"
              />
              <BError
                v-if="!v.model.name.maxLength && isSubmit"
                :error="$t('devices.errors.max_length', [70])"
              />
            </b-col>
          </div>
        </template>
      </b-form-validate>
      <template #modal-footer>
        <div
          class="d-flex align-items-center justify-content-between flex-grow-1"
        >
          <b-button
            @click="openSelectIntercomModelModal"
            size="sm"
            variant="outline-primary"
            class="fast-modal__btn w-auto h-auto"
          >
            {{ $t('devices.modal.back') }}
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="fast-modal__btn w-auto h-auto"
            @click="createIntercom"
          >
            {{ $t('devices.modal.next') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <AccessCreateIntercom
      ref="AccessCreateIntercom"
      id="intercom-access-create"
      :clientId="clientId"
    />
    <ErrorCreateIntercom
      ref="ErrorCreateIntercom"
      id="intercom-error-create-modal"
      :clientId="clientId"
    />
    <NeedSettingIntercom
      ref="NeedSettingIntercom"
      id="intercom-need-setting-modal"
      :clientId="clientId"
    />
  </div>
</template>

<script>
import { GEO_UNITS } from '@/consts';
import { required, maxLength } from 'vuelidate/lib/validators';
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import NeedSettingIntercom from '../final/NeedSettingIntercom.vue';
import ErrorCreateIntercom from '../final/ErrorCreateIntercom.vue';
import AccessCreateIntercom from '../final/AccessCreateIntercom.vue';
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete.vue';
import BError from '@/components/base/BError';

export default {
  name: 'InfoIntercomModal',
  components: {
    NeedSettingIntercom,
    ErrorCreateIntercom,
    AccessCreateIntercom,
    GeoUnitsAutocomplete,
    BError,
  },
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      isSubmit: false,
      onHide: false,
    };
  },
  computed: {
    validations() {
      return {
        name: {
          required,
          maxLength: maxLength(70),
        },
        geo_unit_id: {
          required,
        },
      };
    },
    ...mapState('intercom', [
      'intercom',
      'intercomModel',
      'validateDeviceSetting',
    ]),
    ...mapGetters('intercom', [
      'getIntercom',
      'getIntercomModel',
      'getValidateDeviceSetting',
    ]),

    intercom: {
      get() {
        return this.getIntercom;
      },
      set(value) {
        this.setIntercom(value);
      },
    },
    intercomModel() {
      return this.getIntercomModel;
    },
    validateDeviceSetting() {
      return this.getValidateDeviceSetting;
    },
    geoUnitDeepAt() {
      const baseGeoUnits = [
        GEO_UNITS.Building,
        GEO_UNITS.Entrance,
        GEO_UNITS.Floor,
        GEO_UNITS.ParkingArea,
        GEO_UNITS.Section,
        GEO_UNITS.TechRoom,
        GEO_UNITS.Territory,
      ];
      if (this.intercomModel?.tags?.includes('one_property')) {
        return [
          GEO_UNITS.Apartment,
          GEO_UNITS.Bkfn,
          GEO_UNITS.ParkingPlace,
          GEO_UNITS.StoreRoom,
          GEO_UNITS.TechRoom,
        ];
      }
      if (this.intercomModel?.type === 'access_control') {
        return [...baseGeoUnits, GEO_UNITS.Roof];
      } else return baseGeoUnits;
    },
  },
  methods: {
    ...mapMutations('intercom', ['setIntercom']),
    ...mapActions('intercom', ['validateSettingDevice', 'createDevice']),

    async createIntercom(event) {
      if (event) event.preventDefault();
      this.isSubmit = true;

      if (this.$refs.infoForm.validate()) {
        this.onHide = true;
        await this.createDevice({
          intercom: this.intercom,
          clientId: this.clientId,
        })
          .then(() => {
            this.hide();
            if (this.validateDeviceSetting) {
              this.$refs.AccessCreateIntercom.show();
            } else {
              this.$refs.NeedSettingIntercom.show();
            }
          })
          .catch(() => this.$refs.ErrorCreateIntercom.show());
      }
    },
    show(resetSubmit = true) {
      this.$refs.InfoIntercomModal.show();
      this.onHide = false;
      if (resetSubmit) {
        this.isSubmit = false;
      } else {
        if (this.isSubmit) {
          setTimeout(() => {
            this.$refs.InfoIntercomModal.validate();
          }, 300);
        }
      }
    },
    hide() {
      if (this.onHide) {
        this.$refs.InfoIntercomModal.hide();
      } else {
        this.$bvModal
          .msgBoxConfirm(`${this.$tc('devices.close.create')}`, {
            title: this.$t('devices.close.title'),
            okTitle: this.$t('devices.close.buttons.yes'),
            cancelTitle: this.$t('devices.close.buttons.no'),
            okVariant: 'danger',
            bodyClass: 'modal-body__confirm',
          })
          .then((res) => {
            if (res) {
              this.$refs.InfoIntercomModal.hide();
            } else {
              this.onHide = false;
              this.show(false);
            }
          });
      }
    },
    openSelectIntercomModelModal() {
      this.onHide = true;
      this.hide();
      this.$emit('showSelectModal');
    },
  },
};
</script>

<style lang="scss">
.intercom-autocompletes {
  .autocomplete-results-dropdown {
    margin-bottom: 0 !important;
  }
}
</style>
