<template>
  <b-modal
    id="intercom-key-create-set-from-file-modal"
    ref="modal"
    :ok-title="$t('button.create')"
    @ok="onOk"
  >
    <template v-slot:modal-header>
      <div>
        <h2 class="modal-title">
          {{ $t('intercom.intercom_keys.add_keys_as_list') }}
        </h2>
        <div class="subtitle">
          {{ $t('intercom.intercom_keys.download_keys_table') }}
        </div>
      </div>
    </template>
    <b-form-validate
      ref="form"
      @submit="onOk"
      :model="model"
      :validations="validations"
    >
      <template slot-scope="{ validateState }">
        <div class="modal-body-row with-cols pt-30">
          <b-form-row>
            <b-col cols="12">
              <BSelectLabel
                :label="$t('intercom.intercom_keys.keys_set')"
                :items="mode"
                display="label"
                object-property="key"
                v-model="model.mode"
                :state="validateState('mode')"
                no-padding-label
              />
            </b-col>
            <b-col cols="12" v-if="model.mode === 'create_new'">
              <BInputLabel
                v-model="model.keySetName"
                :label="$t('name')"
                :state="validateState('keySetName')"
              />
            </b-col>
            <b-col cols="12" v-else>
              <SelectKeySet
                id="select-key-modal_set"
                :label="$t('intercom.key.set')"
                v-model="model.keySetId"
                :state="validateState('keySetId')"
                :clientId="clientId"
                class="mb-3"
              />
            </b-col>
            <b-col cols="12">
              <BInputFile
                v-model="model.file"
                id="upload-file"
                :label="$t('input.choose_file_pls')"
                :button-text="$t('input.choose_file')"
                :state="validateState('file')"
                type="file"
                accept=".csv,.xlsx"
              ></BInputFile>
            </b-col>
          </b-form-row>
          <div>
            <b>{{ $t('intercom.intercom_keys.download_example') }}:</b>
            <a class="mr-1" :href="exampleFileUrl('xlsx')" target="_blank">
              Microsoft Excel
            </a>
            <a :href="exampleFileUrl('csv')" target="_blank">CSV</a>
          </div>
        </div>
      </template>
    </b-form-validate>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { ApiBackendKeySets, request2 } from '@/api';
import SelectKeySet from '@/components/intercom/SelectKeySet.vue';
import EventBus from '@/packs/EventBus';
import { BInputFile } from '@/components/base';
import { EVENTS } from '@/consts';
import { generateImportFormData } from '@/helpers/requests';

function initData(keySetId) {
  return {
    model: {
      mode: 'create_new',
      keySetId: keySetId || null,
      keySetName: '',
      file: null,
    },
  };
}

const FILE_LINKS = {
  csv: 'https://storage.yandexcloud.net/storage-iot-rubetek/files/keys-example.xlsx',
  xlsx: 'https://storage.yandexcloud.net/storage-iot-rubetek/files/keys-example.csv',
};

export default {
  name: 'KeysImportFileModal',
  components: {
    BInputFile,
    SelectKeySet,
  },
  props: {
    clientId: {
      required: true,
    },
  },
  data() {
    return {
      ...initData(),
      FILE_LINKS,
      mode: [
        {
          label: this.$t('intercom.intercom_keys.create_new'),
          key: 'create_new',
        },
        {
          label: this.$t('intercom.intercom_keys.add_to_existing'),
          key: 'add_to_set',
        },
      ],
    };
  },
  computed: {
    validations() {
      return {
        keySetName: {
          required: this.model.mode === 'create_new' ? required : '',
        },
        keySetId: {
          required: this.model.mode !== 'create_new' ? required : '',
        },
        file: { required },
      };
    },
  },
  mounted() {
    EventBus.$on('KeysImportFileModal::show', this.show);
  },
  methods: {
    async show() {
      Object.assign(this.$data, initData());
      this.$refs.modal.show();
    },
    async onOk(event) {
      if (event) event.preventDefault();
      if (!this.$refs.form.validate()) return;

      EventBus.$emit(
        EVENTS.preloader.show,
        this.$t(
          'intercom.intercom_keys.import_file_modal.keys_creation_message',
        ),
      );

      try {
        const keySet = await this.getKeySet();
        if (!keySet.errors) {
          try {
            const data = generateImportFormData(
              'Keyset',
              keySet.id,
              this.model.file,
            );
            await request2(
              {
                method: 'POST',
                url: `/api/backend/manage/${this.clientId}/imports`,
              },
              data,
            );
            this.msgBoxOkSuccess();
            this.$refs.modal.hide();
          } catch (err) {
            this.$bvToast.toast(JSON.stringify(err));
          }
        } else {
          this.$bvToast.toast(`${this.$t('global.set')} ${keySet.errors.name}`);
        }
      } catch (err) {
        this.$bvToast.toast(`${this.$t('global.set')} ${err.errors.name}`);
      }

      EventBus.$emit(EVENTS.preloader.hide);
    },
    async getKeySet() {
      let keySet = { id: this.model.keySetId };
      if (this.model.mode === 'create_new') {
        try {
          keySet = await ApiBackendKeySets.create({
            name: this.model.keySetName,
            client_id: this.clientId,
          });
        } catch (e) {
          throw Error(e);
        }
      }
      return keySet;
    },
    msgBoxOkSuccess() {
      this.$bvModal.msgBoxOk(
        this.$t('intercom.intercom_keys.import_file_modal.success_message'),
        {
          modalClass: 'modal-ok',
          okTitle: this.$t('button.ok'),
          title: this.$t('title.successfully'),
        },
      );
    },
    exampleFileUrl(format) {
      return `/manage/${this.clientId}/intercom/upload_keys/upload_example_file?format=${format}`;
    },
  },
};
</script>
