<template>
  <div>
    <div class="row mt-4">
      <div class="col">
        <h5>
          Почтовые адреса для оповещения:
        </h5>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 px-0" style="display: inline-block;">
        <span v-for="(email, index) in currentEmails">
          <a @click="destroyEmail(index)" class="btn btn-secondary ml-4"
                  v-b-tooltip="{
              title: $t('button.delete'),
              placement: 'top',
              trigger: 'hover',
              boundary: 'viewport',
            }"
          >
            {{ email }}
          </a>
        </span>
      </div>
    </div>
    <div class="mt-4 col-8 p-0">
      <a @click="addEmail" class="btn btn-primary ml-4" style="float: right;">
        Добавить новый адрес
      </a>
      <b-form-email-slot ref="email_field" label='Email'>
        <input v-model="email"/>
      </b-form-email-slot>
      <input v-for="email in currentEmails" name="certificate_alert[responsible_emails][]" :value="email" type="hidden">
    </div>
  </div>
</template>

<script>
import BFormEmailSlot from "@/components/base/BFormEmailSlot";
import BFormGroupSlot from "@/components/base/BFormGroupSlot";
import { isEmpty } from 'lodash';

export default {
  name: 'adminMultipleEmail',
  props: {
    responsibleEmails: Array,
    errors: Object,
    state: {},
  },
  components: {
    BFormEmailSlot,
    BFormGroupSlot,
  },
  data() {
    return {
      email: '',
      currentEmails: [],
    };
  },
  mounted() {
    this.currentEmails = this.responsibleEmails
  },
  computed: {
    emailIsEmpty() {
      return isEmpty(this.email);
    }
  },
  methods: {
    addEmail() {
      if (!this.$refs.email_field.error) {
        if (!this.currentEmails.includes(this.email)) {
          this.currentEmails.push(this.email);
        }
        this.resetEmail();
      }
    },
    destroyEmail(index){
      this.currentEmails.splice(index, 1)
    },
    resetEmail() {
      this.email = '';
    },
  },
};
</script>
