<template>
  <div class="keys-autocomplete">
    <BDropdownInput
      v-model="selected_id"
      :description="description"
      :disabled="disabled"
      :label="placeholder"
      :handler="fetch"
      :selected="selectedKey"
      :state="state"
      :showLoading="showLoading"
      :variantSpinner="variantSpinner"
      :debouncedTts="debouncedTts"
      compare-property="id"
      display-property="number"
      @clear="onClear"
      @focus="onFocus"
      @select:item="onSelectItem"
      :popoverText="popoverText"
      :popoverHint="popoverHint"
      :hintId="hintId"
      :minWordLength="minWordLength"
    />
    <input :value="selected_id" type="hidden" :name="name" />
  </div>
</template>

<script>
import { getManageId } from '@/helpers';
import BDropdownInput from '@/components/base/BDropdownInput.vue';

export default {
  name: 'keysAutocomplete',
  components: {
    BDropdownInput,
  },
  props: {
    saveFormId: String,
    model: String,
    name: String,
    description: {
      type: String,
    },
    keyData: Object,
    clientId: {
      type: Number,
    },
    placeholder: String,
    value: {
      type: [String, Number],
    },
    state: {},
    debouncedTts: {
      type: Number,
      default: 200,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    additionalQueryParams: {
      type: Object,
      default() {
        return {};
      },
    },
    minWordLength: {
      type: Number,
      default: 3,
    },
    popoverText: {
      type: String,
      default: 'i',
    },
    popoverHint: {
      type: String,
      default: null,
    },
    hintId: {
      type: String,
      default: null,
    },
    placementPopover: {
      type: String,
      default: 'bottom',
    },
    showLoading: {
      type: Boolean,
      default: true,
    },
    variantSpinner: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      selectedKey: this.keyData,
      selected_id: null,
    };
  },
  mounted() {
    if (this.keyData instanceof Object) {
      this.word = this.keyData.number;
      this.selected_id = this.keyData.id;
    }
  },
  methods: {
    onSelectItem(item) {
      if (item.user_id) {
        this.$bvToast.toast(this.$t('intercom.key.error_tied_key'));
      } else {
        this.selected_id = item.id;
        this.selectedKey = item;
        this.$emit('input', item.id);
        this.$emit('select:item', item);

        this.saveForm();
      }
    },
    saveForm() {
      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    fetch(val) {
      const manageId = getManageId();

      let nameParams = {
        number_cont: val,
      };
      let query = {
        ...nameParams,
        ...this.additionalQueryParams,
      };

      return request2(
        {
          method: 'get',
          url: `/api/backend/manage/${manageId}/keys/`,
        },
        { q: query },
      );
    },
    onClear() {
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
  },
};
</script>
