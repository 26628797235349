<template>
  <div>
    <div class="modal-body-row with-cols border-bottom pt-30">
      <b-form-row>
        <b-col offset-md="6" md="6">
          <b-button variant="outline-primary" @click="onShowCreatePlateModal">
            {{ $t('button.add') }}
          </b-button>
        </b-col>
      </b-form-row>
    </div>
    <div class="modal-body-row with-cols" :key="plates.length">
      <b-form-row>
        <b-col cols="12" class="user-edit-modal-table-wrapper">
          <div
            v-if="isLoading"
            class="h-100 w-100 d-flex justify-content-center align-items-center"
          >
            <b-spinner variant="primary"></b-spinner>
          </div>
          <div v-else-if="plates.length < 1">
            {{ $t('user.edit.plate.no_plates') }}
          </div>
          <b-table
            v-else
            ref="plates"
            class="user-modal-table with-dropdown"
            :fields="fields"
            :items="plates"
            striped
          >
            <template v-slot:cell(car)="{ item }">
              <div>{{item.car_mark.name}} {{item.car_model.name}}</div>
              <div class="text-secondary small">{{item.tr_color}}</div>
            </template>
            <template v-slot:cell(actions)="{ item }">
              <BDropdownMenu :items="dropdownItems.items" :data="item">
                <template v-slot:button-content>
                  <i class="icon-local-cog"></i>
                </template>
              </BDropdownMenu>
            </template>
          </b-table>
        </b-col>
      </b-form-row>
    </div>
    <UserModalPlate
      ref="UserModalPlate"
      @update="getPlates()"
      :client-id="clientId"
      :user-id="user.id"
      :carColors="colors"
      :types="types"
    />
  </div>
</template>

<script>
import UserModalPlate from '../modals/UserModalPlate.vue';
import { request3 } from '@/api/request';
import { API_URLS } from '@/consts';
import { getManageId } from '@/helpers';

export default {
  name: 'UserModalTabPlates',
  components: {
    UserModalPlate,
  },
  inject: ['clientId', 'user'],
  data() {
    return {
      plates: [],
      colors: [],
      types: [],
      isLoading: true,
      stamp: Date.now()
    };
  },
  computed: {
    fields() {
      return [
        { key: 'actions', label: '' },
        {
          key: 'plate',
          label: this.$t('plate.modal.plate'),
          formatter: (value) => {
            return value;
          },
          class: 'col-2'
        },
        {
          key: 'car',
          label: this.$t('plate.modal.car'),
        },
        {
          key: 'geo_units',
          label: this.$t('plate.modal.address'),
          formatter: (value) => {
            let geo_units = []
            value.forEach((gu) => {
              geo_units.push(gu.short_name)
            })
            return geo_units.filter((e) => e).join(', ')
          },
        },
        {
          key: 'type',
          label: this.$t('plate.modal.pass'),
          formatter: (value) => {
            const localize = this.types.filter(type => type.value == value)
            return localize[0].title
          },
        },
      ];
    },
    dropdownItems() {
      return {
        items: [
          {
            title: this.$t('button.edit'),
            icon: 'icon-local-pencil',
            handler: (item) => this.$refs.UserModalPlate.show(item, false),
          },
          {
            title: this.$t('button.delete'),
            icon: ' icon-local-trash text-danger',
            handler: (item) => this.onDeletePlate(item),
          },
        ],
      };
    },
  },
  mounted() {
    this.getCarsColors();
    this.getTypePass();
    this.getPlates();
  },
  methods: {
    async getPlates() {
      try {
        this.isLoading = true;
        request3.get(API_URLS.npr.plates.fetch(this.clientId), {
          params: {
            q: { user_id_eq: this.user.id },
          },
        })
        .then((resp) => {
          this.plates = resp.data
          this.stamp = Date.now();
        })
      } catch (e) {
        console.error(e);
      }
      this.isLoading = false;
    },
    getCarsColors() {
      request3.get(API_URLS.npr.colors.fetch(this.clientId))
      .then((resp) => {
        this.colors = resp.data
      })
    },
    getTypePass() {
      request3.get(API_URLS.npr.types.fetch(this.clientId))
      .then((resp) => {
        this.types = resp.data
      })
    },
    onShowCreatePlateModal() {
      this.$refs.UserModalPlate.show({user_id: this.user.id});
    },
    onDeletePlate(plate) {
      console.log(plate)
      this.$bvModal
        .msgBoxConfirm(
          this.$t('plate.confirm_delete.message', [
            plate.plate,
          ]),
          {
            bodyClass: 'modal-body__confirm',
            footerClass: 'border-0',
            headerClass: 'border-0',
            okTitle: this.$t('button.delete'),
            okVariant: 'danger',
            title: this.$t('plate.confirm_delete.title'),
          },
        )
        .then((res) => {
          if (res) {
            const manageId = getManageId();
            request3.delete(API_URLS.npr.plates.one(plate.id, manageId))
              .then(() => {
                this.getPlates()
              })
          }
        });
    },
  },
};
</script>

<style scoped></style>
