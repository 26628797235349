<template>
  <div>
    <b-dropdown
      class="b-dropdown-menu"
      :menu-class="`b-dropdown-menu-${position}`"
      :class="{ fluid: fluid }"
      ref="dropdown"
      no-caret
    >
      <template slot="button-content">
        <slot name="button-content">
          <b-button>{{ $t('button.add') }}</b-button>
        </slot>
      </template>
      <b-dropdown-item
        v-for="(item, id) in items"
        :key="id"
        :disabled="!item.handler"
      >
        <div class="b-dropdown-menu-item d-flex" @click="clickOnItem(item)">
          <UserAvatar
            v-if="item.avatar"
            :user="item"
            class="mr-2"
            size="60px"
          />
          <i v-if="item.icon" :class="item.icon" />
          <div class="d-flex justify-content-center flex-column gap-2">
            <p class="dropdown-item-text">{{ item.title }}</p>
            <p v-if="item.subtitle" class="dropdown-item-subtitle">
              {{ item.subtitle }}
            </p>
          </div>
          <i class="child-icon icon-local-chevron-right" v-if="item.child" />
        </div>
        <ul class="b-dropdown-menu-child" v-if="item.child">
          <li
            :class="{ disabled: child.disabled }"
            class="b-dropdown-menu-child-item"
            :key="key"
            v-for="(child, key) in item.child"
            @click="clickOnItem(child)"
          >
            <i v-if="child.icon" :class="child.icon" />
            <p class="dropdown-item-text">{{ child.title }}</p>
          </li>
        </ul>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import UserAvatar from '@/components/base/UserAvatar.vue';

export default {
  name: 'BDropdownMenu',
  components: {
    UserAvatar,
  },
  props: {
    items: { type: Array, required: true },
    position: { type: String, default: 'left' },
    fluid: { type: Boolean, default: false },
    data: { type: Object, default: () => {} },
  },
  methods: {
    clickOnItem(item) {
      if (item.handler && !item.disabled) {
        item.handler && item.handler(this.data);
        this.$refs.dropdown.hide();
      }
    },
  },
};
</script>

<style lang="scss">
.b-dropdown-menu-child-item.disabled {
  &:hover {
    border-color: #e5e6eb !important;
  }
  background: #f7f9fa;
  * {
    color: #8b93a6 !important;
  }
}
</style>
