<template>
  <CollapsiblePortletWithTabs
    v-model="activeTab"
    :tabs="tabs"
    :title="$t('intercom.dashboard.intercoms_and_accesses')"
  >
    <template v-slot:data>
      <CallsListWithImage
        :selected-geo-unit="selectedGeoUnit"
        v-if="isActiveComponent($t('intercom.dashboard.calls'))"
      />
      <KeysListWithAccesses
        :area="area"
        :role="role"
        :selected-geo-unit="selectedGeoUnit"
        v-if="isActiveComponent($t('intercom.dashboard.keys'))"
      />
      <GeoUnitsUsersPortlet
        v-if="isActiveComponent('Жители')"
        :geo-unit="selectedGeoUnit"
      ></GeoUnitsUsersPortlet>
    </template>
  </CollapsiblePortletWithTabs>
</template>

<script>
import CollapsiblePortletWithTabs from '@app/components/portlet/CollapsiblePortletWithTabs.vue';
import CallsListWithImage from '@app/components/intercom/CallsListWithImage.vue';
import KeysListWithAccesses from '@app/components/intercom/KeysListWithAccesses.vue';
import GeoUnitsUsersPortlet from '@app/components/apartment/GeoUnitsUsersPortlet.vue';

import { EventBusMixin } from '@app/mixins';
import { ApiBackendUsers, ApiCallSessions, ApiKeys } from '@/api';
import EventBus from '@/packs/EventBus';

export default {
  name: 'IntercomsDashboard',
  components: {
    GeoUnitsUsersPortlet,
    CollapsiblePortletWithTabs,
    CallsListWithImage,
    KeysListWithAccesses,
  },
  props: {
    area: {
      type: String,
      validator: (val) => ['manage', 'personal'].includes(val),
      required: true,
    },
    selectedGeoUnit: {
      required: true,
      type: Object,
    },
    role: {
      default: 'read',
      validator: (val) => ['read', 'edit'].includes(val),
    },
  },
  mixins: [EventBusMixin],
  data() {
    return {
      activeTab: 'Жители',
      tabValues: {
        users: {
          value: '',
          title: 'Жители',
        },
        calls: {
          value: '',
          title: this.$t('intercom.dashboard.calls'),
        },
        keys: {
          value: '',
          title: this.$t('intercom.dashboard.keys'),
        },
      },
    };
  },
  computed: {
    geoUnitId() {
      return this.$route?.params.geo_unit_id || this.selectedGeoUnit.id;
    },
    tabs() {
      return Object.values(this.tabValues);
    },
  },
  mounted() {
    this.EventBusOn(
      ['Apartment::key::added', 'Apartment::key::deleted'],
      () => {
        this.getKeysCount();
      },
    );
    this.EventBusOn(['GeoUnitUser::create', 'GeoUnitUser::delete'], () => {
      this.getUsersCount();
    });
    this.getOverallData();
  },
  methods: {
    getOverallData() {
      this.getCallsCount();
      this.getKeysCount();
      this.getUsersCount();
    },
    isActiveComponent(name) {
      return this.activeTab === name;
    },
    async getCallsCount() {
      this.tabValues.calls.value = await ApiCallSessions.getCount({
        q: { geo_unit_id_eq: this.geoUnitId },
      });
    },
    async getKeysCount() {
      this.tabValues.keys.value = await ApiKeys.getCount({
        q: {
          related_geo_units_ids_array_contains: this.geoUnitId,
          keyset_client_id_eq: this.$route?.params.client_id,
        },
      });
    },
    async getUsersCount() {
      this.tabValues.users.value = await ApiBackendUsers.getCount({
        q: { geo_units_users_geo_unit_id_eq: this.geoUnitId },
      });
    },
  },
};
</script>

<style scoped></style>
