<template>
  <b-form-validate ref="meterForm" :model="device" :validations="validations">
    <template slot-scope="{ validateState, v }">
      <base-form
        ref="meter"
        v-if="baseCounter"
        :validateState="validateState"
        :errors="errors"
        :client-id="clientId"
        :current-area="device.area"
        :areas="areasSet"
        :data="baseCounters"
        :editMode="editMode"
        :isSubmit="isSubmit"
        :showUspdConnection="showUspdConnection"
      ></base-form>
      <pulse-form
        v-else
        ref="pulseCountersDevice"
        :validateState="validateState"
        :errors="errors"
        :areas="areasSet"
        :client-id="clientId"
        :current-area="device.area"
        :data="pulseCounters"
        :v="v"
        :isSubmit="isSubmit"
        :editMode="editMode"
      ></pulse-form>
      <div class="row row-md pb-3 mt-3">
        <div class="col-2">
          <button @click="formSave" class="btn btn btn-primary w-100">
            {{ $t('button.save') }}
          </button>
        </div>
        <div class="col-2">
          <button
            @click="redirectToBackward"
            class="btn btn btn-outline-secondary w-100"
          >
            {{ $t('button.cancel') }}
          </button>
        </div>
      </div>
    </template>
  </b-form-validate>
</template>

<script>
import dayjs from 'dayjs';
import BaseForm from './blocks/BaseForm';
import PulseForm from './blocks/PulseForm';
import { serialize } from 'object-to-formdata';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';

export default {
  name: 'meteringForm',
  components: {
    BaseForm,
    PulseForm,
  },
  props: {
    areas: {
      type: Array,
      required: true,
    },
    backwardLink: {
      type: String,
      required: true,
    },
    baseCounters: {
      type: Object,
      required: true,
    },
    clientId: {
      type: Number,
      required: true,
    },
    deviceData: {
      type: Object,
    },
    pulseCounters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      isSubmit: false,
    };
  },
  computed: {
    ...mapState('meters', ['device']),
    ...mapGetters('meters', ['getDevice']),
    validations() {
      return {
        area: { required },
        geo_unit_id: {
          required: requiredIf(() => !this.baseCounter),
        },
        serial: {
          required,
        },
        meter_model_id: {
          required: requiredIf(() => this.baseCounter),
        },
        pulse_counters_model_id: {
          required: requiredIf(() => !this.baseCounter),
        },
        kind: {
          required: requiredIf(() => this.baseCounter),
        },
        channels: {
          required: requiredIf(() => !this.baseCounter),
          $each: {
            index: { required: requiredIf(() => !this.baseCounter) },
            meter: {
              id: { required: requiredIf(() => !this.baseCounter) },
            },
          },
        },
        uspd_device_id: {
          required: requiredIf(() => !this.baseCounter),
        },
      };
    },
    device: {
      get() {
        return this.getDevice;
      },
      set(value) {
        this.setDevice(value);
      },
    },
    baseCounter() {
      return this.device.area != 'pulse';
    },
    editMode() {
      return Boolean(this.deviceData);
    },
    areasSet() {
      if (this.editMode) {
        if (this.baseCounter) {
          return this.areas.filter((x) => x.id != 'pulse');
        } else {
          return this.areas.filter((x) => x.id == 'pulse');
        }
      } else {
        return this.areas;
      }
    },
    channelsParams() {
      return this.device.channels
        .filter((ch) => ch._destroy !== false)

        .map((ch) => ({
          id: ch.id,
          _destroy: ch._destroy,
          meter_id: ch.meter.id,
          index: ch.index,
        }));
    },
    showUspdConnection() {
      return this.device.area === 'imd' && this.device.kind === 'electro';
    },
  },
  created() {
    if (this.editMode) {
      this.deviceData.uspd_device_id = this.deviceData.uspd_device
        ? this.deviceData.uspd_device.id
        : null;
      this.device = Object.assign({}, this.deviceData);
    } else {
      this.device.area = this.areas[0].id;
    }
  },
  methods: {
    ...mapMutations('meters', ['setDevice']),
    redirectTo(url) {
      url ? (window.location.href = url) : this.redirectToBackward();
    },
    redirectToBackward() {
      window.location.href = this.backwardLink;
    },
    stringifyDate(val) {
      return val ? dayjs(val).format('YYYY-MM-DD') : null;
    },
    formSave() {
      this.isSubmit = true;
      if (!this.$refs.meterForm.validate()) {
        return;
      }

      let url =
        `/api/backend/manage/${this.clientId}/` +
        (this.baseCounter ? 'meters' : 'pulse_counters/devices') +
        (this.editMode ? '/' + this.device.id : '');

      let params = this.device;
      if (this.baseCounter) {
        params = Object.assign(params, {
          issued_on: this.stringifyDate(this.device.issued_on),
          installed_on: this.stringifyDate(this.device.installed_on),
          uspd_device_id: this.device.uspd_device_id
            ? this.device.uspd_device_id
            : null,
        });
      } else {
        params = Object.assign(params, {
          channels_attributes: this.channelsParams,
        });
      }
      let method = this.editMode ? 'patch' : 'post';
      let key = this.baseCounter ? 'meter' : 'pulse_counters_device';

      this.errors = {};
      request2(
        {
          method: method,
          url: url,
        },
        this.serializeParams(params, key),
      )
        .then((r) => {
          if (r.errors === undefined) {
            this.redirectTo(r.redirect_to);
          } else {
            this.errors = r.errors;
          }
        })
        .catch((error) => console.log(error));
    },
    // сериализует параметры (для того, чтобы корректно отправилась картинка)
    serializeParams(data, key) {
      return serialize(data, null, null, key);
    },
  },
};
</script>
