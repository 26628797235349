<template>
  <div>
    <div class="modal-body-row with-cols border-bottom pt-30">
      <b-form-row>
        <b-col offset-md="6" md="6">
          <b-button variant="outline-primary" @click="onShowCreateKeyModal">
            {{ $t('button.add') }}
          </b-button>
        </b-col>
      </b-form-row>
    </div>
    <div class="modal-body-row with-cols">
      <b-form-row>
        <b-col cols="12" class="user-edit-modal-table-wrapper">
          <div
            v-if="isLoading"
            class="h-100 w-100 d-flex justify-content-center align-items-center"
          >
            <b-spinner variant="primary"></b-spinner>
          </div>
          <div v-else-if="keys.length < 1">
            {{ $t('user.edit.key.list.empty') }}
          </div>
          <b-table
            v-else
            ref="keys"
            class="user-modal-table with-dropdown"
            :fields="fields"
            :items="keys"
            striped
          >
            <template v-slot:cell(action)="{ item }">
              <BDropdownMenu :items="dropdownItems.items" :data="item">
                <template v-slot:button-content>
                  <i class="icon-local-cog"></i>
                </template>
              </BDropdownMenu>
            </template>
            <template v-slot:cell(addresses)="{ item }">
              <div
                v-if="
                  item &&
                  item.related_geo_units &&
                  item.related_geo_units.length > 0
                "
              >
                {{ item.related_geo_units[0].full_name || 'none' }}
                <small
                  v-if="item.related_geo_units.length > 1"
                  class="text-gray d-block"
                >
                  {{
                    $tc(
                      `addresses.and_more`,
                      item.related_geo_units.length - 1,
                      [item.related_geo_units.length - 1],
                    )
                  }}
                </small>
              </div>
              <div v-else>
                <small class="text-gray d-block">
                  {{ $t('user.edit.key.list.no_address') }}
                </small>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-form-row>
      <b-form-row v-if="pagination.pages > 1">
        <b-col cols="12" class="mt-2">
          <b-pagination
            @change="onChangePage"
            v-model="pagination.current"
            :total-rows="pagination.count"
            :per-page="perPage"
            aria-controls="my-table"
            align="center"
          />
        </b-col>
      </b-form-row>
    </div>
    <UserModalKey
      ref="UserModalKey"
      @created="getKeys()"
      :client-id="clientId"
      :user-id="user.id"
    />
  </div>
</template>

<script>
import { ApiKeys } from '@/api';
import { STYLES_MODAL } from '@/consts/styles';
import { KeyNameMixin } from '@/mixins';
import { EventBusMixin } from '@app/mixins';
import UserModalKey from '../modals/UserModalKey.vue';
import { BPagination } from 'bootstrap-vue';
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';

export default {
  name: 'TabUserKeys',
  mixins: [EventBusMixin, KeyNameMixin],
  components: {
    UserModalKey,
    BPagination,
  },
  inject: ['clientId', 'user'],

  data() {
    return {
      keys: [],
      isLoading: true,
      pagination: {
        count: 0,
        current: 1,
        pages: 1,
        next: 1,
      },
      perPage: 25,
    };
  },
  computed: {
    fields() {
      return [
        { key: 'action', label: '' },
        {
          key: 'name',
          label: this.$t('global.title'),
          formatter: (value, field, item) => this.formatterKeyName(item),
        },
        {
          key: 'type',
          label: this.$t('global.type'),
          formatter: (value) =>
            this.$t(`user.edit.key.types.${value.toLowerCase()}`),
        },
        { key: 'number', label: this.$t('global.number') },
        { key: 'addresses', label: this.$t('global.address') },
        { key: 'actions', label: '' },
      ];
    },
    dropdownItems() {
      return {
        items: [
          {
            title: this.$t('button.edit'),
            icon: 'icon-local-pencil',
            handler: (key) => this.$refs.UserModalKey.onShow({ key }),
          },
          {
            title: this.$t('button.delete'),
            icon: ' icon-local-trash text-danger',
            handler: (item) => this.onDeleteKey(item),
          },
        ],
      };
    },
  },
  mounted() {
    this.EventBusOn('Key::refresh', this.getKeys);
    this.getKeys();
  },
  methods: {
    onChangePage(page) {
      if (page === this.pagination.current) return;

      this.getKeys(page);
    },

    async getKeys(page = 1) {
      request3
        .get(API_URLS.backendManage.keys.fetch(this.clientId), {
          params: {
            q: {
              user_id_eq: this.user.id,
              keyset_client_id_eq: this.clientId,
            },
            includes: ['related_geo_units', 'district'],
            per_page: this.perPage,
            page,
          },
        })
        .then((response) => {
          this.keys = response.data;
          this.pagination = JSON.parse(response.headers['x-pagination'] || {});
        })
        .catch((error) => {
          console.error(error.data);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onShowCreateKeyModal() {
      this.$refs.UserModalKey.onShow({});
    },
    onDeleteKey(key) {
      this.$bvModal
        .msgBoxConfirm(
          this.$t('user.edit.key.confirm_delete.message', [
            key.name || '',
            key.id,
          ]),
          {
            ...STYLES_MODAL.confirm.delete,
            title: this.$t('user.edit.key.confirm_delete.title'),
            okTitle: this.$t('button.delete'),
          },
        )
        .then(async (res) => {
          if (res) {
            ApiKeys.update(key.id, { ...key, ...{ user_id: null } })
              .then(() => {
                this.EventBusEmmit('Key::refresh');
              })
              .catch((err) => {
                this.$bvToast.toast(err);
              });
          }
        })
        .catch((err) => {
          this.$bvToast.toast(err);
        });
    },
  },
};
</script>

<style scoped></style>
