<template>
  <div class="creator">
    <div class="creator-body">
      <div class="creator-content">
        <div class="creator-content-block p-5">
          <div class="step-title">{{ $t("manage_address.specify_building_address") }}</div>
          <AddressStep
            :clientId="clientId"
            @buildingAddressChange="changeAddress"
            @buildingDistrictChange="changeDistrict"
            :building="building"
            :isUpdate="true"
          />
          <div class="creator-footer-left">
            <b-button
              class="update-button"
              size="lg"
              variant="primary"
              :disabled="isLoadingSpinnerShow"
              @click="updateAddress"
            >
              {{ $t("manage_address.update_address") }}
              <b-spinner v-if="isLoadingSpinnerShow" variant="info"></b-spinner>
            </b-button>
            <b-button
              class="ml-2"
              size="lg"
              variant="secondary"
              @click="onGoBack"
            >
              {{ $t('button.cancel') }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddressStep from 'vue_app/components/building_creat/AddressStep';

export default {
  components: { AddressStep },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      geoUnits: {},
      district: this.building.district_id,
      isLoadingSpinnerShow: false,
      city: null
    };
  },
  methods: {
    changeAddress(val) {
      this.city = val.city
      this.geoUnits = val;
    },
    changeDistrict(val) {
      this.district = val;
    },
    onGoBack() {
      window.location = `/manage/${this.clientId}/home/districts/${this.building.district_id}/buildings/${this.building.id}`;
    },
    updateAddress() {
      this.isLoadingSpinnerShow = true;

      const params = {
        address: {
          geo_units: this.geoUnits,
          district: this.district,
          city: this.city
        },
      };

      request2(
        {
          method: 'patch',
          url: `/api/backend/manage/${this.clientId}/geo_units/${this.building.id}/update_address`,
        },
        params,
      )
        .then((building) => {
          window.location = `/manage/${this.clientId}/home/districts/${building.district_id}/buildings/${building.id}`;
        })
        .catch((err) => {
          this.isLoadingSpinnerShow = false;
          this.$bvToast.toast(err.errors);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'app/javascript/assets/styles/components/variables';

.creator {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 800px;
  min-height: 500px;
  background-color: $white;
  border-radius: 10px;

  &-panel {
    display: flex;
    align-items: center;

    .dropdown {
      width: 50px;
    }

    .btn {
      &:first-child {
        margin: 0 20px;
        width: 290px;
        font-size: $font-size-16px;
      }

      font-size: $font-size-16px;
      height: 50px;
      width: 50px;
      margin: 0 10px;

      &.disabled {
        border-color: $border;
        color: $color-close-button;
      }
    }
  }

  &-title-block {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;

    div {
      font-size: $font-size-24px;
      line-height: $line-height-29px;
    }
  }

  &-body {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: auto;

    .creator-content {
      overflow: auto;
    }
  }

  &-content {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;

    &-block {
      width: 100%;
    }
  }

  &-footer {
    display: flex;
    flex-direction: row;
    height: 90px;
    width: 100%;
    border-top: $gray-border solid 1px;
    padding: 0 20px;

    .btn {
      height: 50px;
      min-width: 200px;
      margin: 0 10px;
    }

    &-left {
      display: flex;
      flex: 4;
      align-items: center;
    }

    &-right {
      display: flex;
      flex: 8;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .update-button {
    gap: 8px;
    align-items: center;
    display: flex;
  }

  .btn-outline-secondary {
    color: $blue;
    border-color: $gray-300;
  }

  .btn-secondary {
    background-color: $gray-300;
    border-color: $gray-300;
  }
}

.step-title {
  font-size: $font-size-20px;
  line-height: $line-height-29px;
  font-weight: 400;
  margin-bottom: 20px;
}
</style>
