<template>
  <div class="barriers-autocomplete">
    <BDropdownInput
      v-model="selected_id"
      :description="description"
      :disabled="disabled"
      :label="placeholder"
      :handler="fetch"
      :selected="barrierData"
      :state="state"
      :showLoading="showLoading"
      :variantSpinner="variantSpinner"
      :debouncedTts="debouncedTts"
      :formattedResult="formattedResult"
      compare-property="id"
      display-property="name"
      @clear="onClear"
      @focus="onFocus"
      @select="onSelect"
      @select:item="onSelectItem"
      :popoverText="popoverText"
      :popoverHint="popoverHint"
      :hintId="hintId"
      :minWordLength="minWordLength"
    />
    <input
      :value="selected_id"
      :id="`${model}_${name}`"
      type="hidden"
      :name="`${model}[${name}]`"
    />
  </div>
</template>

<script>
import BDropdownInput from '@/components/base/BDropdownInput.vue';
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';

export default {
  name: 'BarriersAutocomplete',
  components: {
    BDropdownInput,
  },
  props: {
    saveFormId: String,
    model: String,
    name: String,
    description: {
      type: String,
    },
    barrierData: Object,
    clientId: {
      type: Number,
    },
    placeholder: String,
    value: {
      type: [String, Number],
    },
    state: {},
    debouncedTts: {
      type: Number,
      default: 200,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minWordLength: {
      type: Number,
      default: 3,
    },
    popoverText: {
      type: String,
      default: 'i',
    },
    popoverHint: {
      type: String,
      default: null,
    },
    hintId: {
      type: String,
      default: null,
    },
    placementPopover: {
      type: String,
      default: 'bottom',
    },
    showLoading: {
      type: Boolean,
      default: true,
    },
    variantSpinner: {
      type: String,
      default: 'primary',
    },
    additionalQueryParams: {
      type: Object,
      default() {
        return {};
      },
    },
    geoUnitId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selected_id: null,
    };
  },
  computed: {
    formattedResult() {
      return {
        top: [
          { title: 'name', formatter: (value) => value },
          { title: 'id', formatter: (value) => `#${value}` },
        ],
        bottom: [
          {
            title: 'geo_unit_short_name',
            formatter: (val, item) => item.geo_unit_short_name,
          },
        ],
      };
    },
  },
  mounted() {
    if (this.barrierData instanceof Object) {
      this.selected_id = this.barrierData.id;
    }
  },
  methods: {
    onSelect(value) {
      this.$emit('input', value);
      this.$emit('select', value);
      this.selected_id = value;
      this.saveForm();
    },
    onSelectItem(item) {
      this.$emit('select:item', item);
    },
    saveForm() {
      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    fetch(val) {
      let params = {
        geo_unit_short_name_or_name_cont_any: encodeURIComponent(val),
        geo_unit_id_eq: this.geoUnitId,
      };
      let query = {
        ...params,
        ...this.additionalQueryParams,
      };

      return request3
        .get(API_URLS.backendManage.barriers.fetch(this.clientId), {
          params: {
            q: query,
          },
        })
        .then((res) => res.data);
    },
    onClear() {
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
  },
};
</script>
