<template>
  <div>
    <b-dropdown
      class="b-dropdown-menu"
      :menu-class="`b-dropdown-menu-${position}`"
      :class="{ fluid: fluid }"
      no-caret
    >
      <template slot="button-content" :class="fluid ? 'fluid' : ''">
        <slot name="button-content">
          <b-button>{{ $t('button.add') }}</b-button>
        </slot>
      </template>
      <b-dropdown-item
        v-for="(item, id) in items"
        :key="id"
        :disabled="!item.handler"
      >
        <div
          class="b-dropdown-menu-item"
          @click="item.handler ? item.handler(data) : {}"
        >
          <i v-if="item.icon" :class="item.icon" />
          <p class="dropdown-item-text">{{ item.title }}</p>
          <p v-if="item.subtitle" class="dropdown-item-subtitle">
            {{ item.subtitle }}
          </p>
          <i class="child-icon icon-local-chevron-right" v-if="item.child" />
        </div>
        <ul class="b-dropdown-menu-child" v-if="item.child">
          <li
            class="b-dropdown-menu-child-item"
            :key="key"
            v-for="(child, key) in item.child"
            @click="child.handler ? child.handler() : false"
          >
            <i v-if="child.icon" :class="child.icon" />
            <p class="dropdown-item-text">{{ child.title }}</p>
          </li>
        </ul>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue';

export default {
  name: 'BDropdownMenu',
  components: {
    BDropdown,
    BDropdownItem,
  },
  props: {
    items: { type: Array, required: true },
    position: { type: String, default: 'left' },
    fluid: { type: Boolean, default: false },
    data: { type: Object, default: () => {} },
  },
};
</script>
