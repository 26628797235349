import { Bar, mixins } from 'vue-chartjs';

export default {
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: {
    tooltips: Object,
    kind: String,
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
              },
              scaleLabel: {
                display: true,
                labelString: this.kind,
              },
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.checkTooltip();
    this.renderChart(this.chartData, this.options);
  },
  methods: {
    checkTooltip() {
      if (this.tooltips) {
        this.options.tooltips = this.tooltips;
      }
    },
  },
};
