<template>
  <BDropdownMenu
    id="nav-bar-user-dropdown"
    fluid
    :items="userMenuItems"
    position="right"
  >
    <template v-slot:button-content>
      <div class="user-avatar">
        <UserAvatar :user="user" size="38px" />
      </div>
    </template>
  </BDropdownMenu>
</template>

<script>
import { signOut } from '@/api';
import BDropdownMenu from '@/components/base/redesign/BDropdownMenuRedesign.vue';
import UserAvatar from '@/components/base/UserAvatar.vue';
import bridge from '@/helpers/bridge';
import EventBus from '@/packs/EventBus';

export default {
  name: 'NavBarUserDropdownRedisign',
  mounted() {
    bridge.user = this.user;
  },
  components: {
    BDropdownMenu,
    UserAvatar,
  },
  props: {
    name: {},
    userJson: {
      required: true,
    },
    clientsExists: {
      type: Boolean,
    },
    clientId: {
      type: String,
    },
    clientProfile: {
      type: Boolean,
    },
    clientRequestAccess: {
      type: Boolean,
    },
  },
  computed: {
    user() {
      return this.jsonParse(this.userJson);
    },
    userMenuItems() {
      const personalCard = {
        avatar: this.user.avatar,
      };
      if (this.user.name || this.user.surname) {
        personalCard.title = `${this.user.name} ${this.user.surname}`;
        personalCard.subtitle = this.user.phone
          ? this.user.phone
          : this.user.email;
      } else if (this.user.phone) {
        personalCard.title = this.user.phone;
        personalCard.subtitle = this.user.email || '';
      } else if (this.user.email) {
        personalCard.title = this.user.email;
        personalCard.subtitle = '';
      }
      const items = [
        personalCard,
        // {
        //   title: 'Профиль',
        //   icon: ' icon-local-user-profile',
        //   handler: () => {
        //     this.$root.$emit('UserEditModalShow', this.user);
        //   },
        // },
      ];
      if (this.user) {
        items.push({
          title: this.$t('profile'),
          icon: 'icon-local-user',
          handler: () => this.changeLocation('profile'),
        });
        items.push({
          title: this.$t('panels.resident_section'),
          icon: 'icon-local-home',
          handler: () => this.changeLocation('geo_units'),
        });
      }
      // if (this.clientsExists || (this.user && this.user.is_admin)) {
      // items.push({
      //   title: 'Личный кабинет',
      //   icon: 'icon-local-home',
      //   handler: () => this.changeLocation('personal'),
      // });

      if (this.clientId !== undefined && this.clientProfile) {
        items.push({
          title: this.$t('panels.client_profile'),
          icon: 'icon-local-staff',
          handler: () => this.changeLocation(`manage/${this.clientId}/clients`),
        });
      }

      items.push({
        title: this.$t('panels.client_requests'),
        icon: 'icon-local-news',
        handler: () =>
          this.changeLocation(
            this.clientId !== undefined && this.clientRequestAccess
              ? `manage/${this.clientId}/client_requests`
              : 'client_requests',
          ),
      });

      if (this.clientsExists || this.user?.is_admin) {
        items.push({
          title: this.$t('panels.management_company'),
          icon: 'icon-local-city',
          handler: () => this.changeLocation(`manage/${this.clientId || 1}/`),
        });
      }
      if (this.user && this.user.is_admin) {
        items.push({
          title: this.$t('panels.admin'),
          icon: 'icon-local-safety',
          handler: () => this.changeLocation('admin'),
        });
      }
      if (this.user && this.user.is_admin) {
        items.push({
          title: this.$t('panels.support'),
          icon: 'icon-local-intercom0',
          handler: () => this.changeLocation('support'),
        });
      }
      items.push({
        title: this.$t('auth.sign_out'),
        icon: 'icon-local-door-open',
        handler: () => this.onSignOut(),
      });
      return items;
    },
    shortUserName() {
      if (this.user.name && this.user.name.trim() && this.user.surname) {
        return `${this.user.name} ${this.user.surname}`;
      }
      if (this.user.name && this.user.name.trim()) {
        return `${this.user.name}`;
      }
      if (this.user.surname && this.user.surname.trim()) {
        return this.user.surname;
      }
      if (this.user.name && this.user.phone.trim()) {
        return this.user.phone;
      }
      return this.user.email;
    },
  },
  methods: {
    onSignOut() {
      signOut()
        .then(() => {
          location.replace('/');
        })
        .catch(() => {
          this.$bvToast.toast(this.$t('error.occurred'));
        });
    },
    changeLocation(area) {
      EventBus.$emit('preloader::show');
      this.$nextTick(() => {
        const url = new URL(window.location.origin + `/${area}`);
        window.location.href = url;
      });
    },
    jsonParse(jsonString, defaultValue = {}) {
      if (this.userJson) {
        try {
          return JSON.parse(jsonString);
        } catch (e) {
          this.$bvToast.toast(this.$t('error.get_data'));
        }
      }
      return defaultValue;
    },
  },
};
</script>

<style lang="scss">
#nav-bar-user-dropdown {
  .b-dropdown-menu-right {
    min-width: 300px;
    width: auto;
  }
}
</style>
