<template>
  <div>
    <slot
      :meter="meter"
      :on-select-model="onSelectModel"
      :on-select-uspd="onSelectUspd"
      :on-select-client="onSelectClient"
      :is-show-polling-profile="isShowPollingProfile"
      :client-id="clientId"
      :on-select-kind="onSelectKind"
      :on-select-gu="onSelectGu"
      :on-clear-gu="onClearGu"
      :gu="gu"
    ></slot>
  </div>
</template>
<script>
export default {
  name: 'MeterAdminForm',
  props: {
    meterData: Object,
    uspdDevices: Array,
    meterModels: Array,
    geoUnit: {
      type: Object,
      default: null,
    },
  },
  watch: {
    'meter.uspd_device_id'(val) {
      this.setClientFromUspd(val);
    },
    'meter.client_id'(val) {
      this.setClient(val);
    },
    'meter.meter_model_id'(val) {
      this.setCurrentModel(val);
    },
  },
  computed: {
    isShowPollingProfile() {
      return (
        (this.model && this.model.tag && this.model.tag.startsWith('vzlet')) ||
        this.meter.kind == 'electro'
      );
    },
  },
  mounted() {
    this.setCurrentModel(this.meter.meter_model_id);

    if (this.meter.client_id) {
      this.clientId = this.meter.client_id;
    } else if (this.meter.uspd_device_id) {
      this.clientId = this.findClientFromUspd(this.meter.uspd_device_id);
    };
  },
  data() {
    return {
      meter: this.meterData,
      clientId: null,
      model: undefined,
      gu: this.geoUnit,
    };
  },
  methods: {
    setCurrentModel(id) {
      this.model = this.meterModels.find((m) => m.id == id);
    },
    findClientFromUspd(usdp_id) {
      return this.uspdDevices.find((d) => d.id == usdp_id)?.client_id;
    },
    setClientFromUspd(id) {
      this.setClient(this.findClientFromUspd(id));
    },
    setClient(id) {
      this.clientId = id;
      this.onClearGu();
    },
    onSelectModel(event) {
      this.meter.meter_model_id = event.target.value;
    },
    onSelectUspd(event) {
      this.meter.uspd_device_id = event.target.value;
    },
    onSelectKind(event) {
      this.meter.kind = event.target.value;
    },
    onSelectGu(val) {
      this.meter.geo_unit_id = val.id;
      this.gu = val;
    },
    onClearGu() {
      this.meter.geo_unit_id = null;
      this.gu = null;
    },
    onSelectClient(event) {
      this.meter.client_id = event.target.value;
    },
  },
};
</script>
