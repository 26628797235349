import EVENTS from '@/consts/events';
import GEO_UNITS from '@/consts/geo_unit';
import INTERCOM_TYPES, { INTERCOM_COLORS } from '@/consts/intercom';
import PERMISSIONS from '@/consts/permissions';
import { prettifyAddress, startFirstLet } from '@/helpers';
import i18n from '@app/i18n';

export * from './geo_unit';
export * from './permissions';
export * from './intercom';
export * from './styles';
export * from './api';
export { EVENTS, GEO_UNITS, INTERCOM_TYPES, INTERCOM_COLORS, PERMISSIONS };
export * from './signIn';
export * from './env';

// params for geo unit in district.vue
export const params = {
  all: {
    cities: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Apartment', 5)),
      isLoaded: false,
      type: 'City',
      value: '',
      api: '',
    },
    districts: {
      title: startFirstLet(i18n.tc('plurals.geo_units.District', 5)),
      isLoaded: false,
      type: 'District',
      value: '',
      api: '',
    },
    buildings: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Building', 5)),
      isLoaded: false,
      type: 'Building',
      value: '',
      api: '',
    },
  },
  district: {
    territories: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Territory', 5)),
      isLoaded: false,
      type: 'Territory',
      value: '',
      api: '',
    },
    buildings: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Building', 5)),
      isLoaded: false,
      type: 'Building',
      value: '',
      api: '',
    },
    users: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Habitats', 5)),
      isLoaded: false,
      type: 'Habitats',
      value: '',
      api: '',
    },
  },
  street: {
    territories: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Territory', 5)),
      isLoaded: false,
      type: 'Territory',
      value: '',
      api: '',
    },
    buildings: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Building', 5)),
      isLoaded: false,
      type: 'Building',
      value: '',
      api: '',
    },
    users: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Habitats', 5)),
      isLoaded: false,
      type: 'Habitats',
      value: '',
      api: '',
    },
  },
  territory: {
    buildings: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Building', 5)),
      isLoaded: false,
      type: 'Building',
      value: '',
      api: '',
    },
    users: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Habitats', 5)),
      isLoaded: false,
      type: 'Habitats',
      value: '',
      api: '',
    },
    parkingPlace: {
      title: startFirstLet(i18n.tc('plurals.geo_units.ParkingPlace', 5)),
      isLoaded: false,
      type: 'ParkingPlace',
      value: '',
      api: '',
    },
  },
  building: {
    entrances: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Entrance', 5)),
      isLoaded: false,
      type: 'Entrance',
      value: '',
      api: '',
    },
    floors: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Floor', 5)),
      isLoaded: false,
      type: 'Floor',
      value: '',
      api: '',
    },
    users: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Habitats', 5)),
      isLoaded: false,
      type: 'Habitats',
      value: '',
      api: '',
    },
    parkingPlace: {
      title: startFirstLet(i18n.tc('plurals.geo_units.ParkingPlace', 5)),
      isLoaded: false,
      type: 'ParkingPlace',
      value: '',
      api: '',
    },
  },
  entrance: {
    floors: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Floor', 5)),
      isLoaded: false,
      type: 'Floor',
      value: '',
      api: '',
    },
    apartments: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Apartment', 5)),
      isLoaded: false,
      type: 'Apartment',
      value: '',
      api: '',
    },
    users: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Habitats', 5)),
      isLoaded: false,
      type: 'Habitats',
      value: '',
    },
    storeRooms: {
      title: startFirstLet(i18n.tc('plurals.geo_units.StoreRoom', 5)),
      isLoaded: false,
      type: 'StoreRoom',
      value: '',
      api: '',
    },
  },
  floor: {
    sections: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Section', 5)),
      isLoaded: false,
      type: 'Section',
      value: '',
      api: '',
    },
    apartments: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Apartment', 5)),
      isLoaded: false,
      type: 'Apartment',
      value: '',
      api: '',
    },
    users: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Habitats', 5)),
      isLoaded: false,
      type: 'Habitats',
      value: '',
      api: '',
    },
    storeRooms: {
      title: startFirstLet(i18n.tc('plurals.geo_units.StoreRoom', 5)),
      isLoaded: false,
      type: 'StoreRoom',
      value: '',
      api: '',
    },
  },
  section: {
    apartments: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Apartment', 5)),
      isLoaded: false,
      type: 'Apartment',
      value: '',
      api: '',
    },
    users: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Habitats', 5)),
      isLoaded: false,
      type: 'Habitats',
      value: '',
      api: '',
    },
    storeRooms: {
      title: startFirstLet(i18n.tc('plurals.geo_units.StoreRoom', 5)),
      isLoaded: false,
      type: 'StoreRoom',
      value: '',
      api: '',
    },
  },
  apartment: {
    users: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Habitats', 5)),
      isLoaded: false,
      type: 'Habitats',
      value: '',
      api: '',
    },
  },
  storeroom: {
    users: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Habitats', 5)),
      isLoaded: false,
      type: 'Habitats',
      value: '',
      api: '',
    },
  },
  parkingplace: {
    users: {
      title: startFirstLet(i18n.tc('plurals.geo_units.Habitats', 5)),
      isLoaded: false,
      type: 'Habitats',
      value: '',
      api: '',
    },
  },
};

export const dashboardParams = {
  main: [
    {
      title: i18n.tc('geo_unit.type_only_names.Building', 2),
      direction: 'row',
      cols: 6,
      cards: [
        {
          title: i18n.t('dashboard.card.all'),
          class: 'information-count-card-dashboard-col-4',
          round_color: 'blue',
          api: '',
        },
        {
          title: i18n.t('dashboard.card.not_rented'),
          class: 'information-count-card-dashboard-col-4',
          round_color: 'yellow',
          api: '',
        },
        {
          title: i18n.t('dashboard.card.with_errors'),
          class: 'information-count-card-dashboard-col-4',
          round_color: 'red',
          api: '',
        },
      ],
    },
    {
      title: i18n.t('user.titles'),
      direction: 'row',
      cols: 6,
      cards: [
        {
          title: i18n.t('dashboard.card.all'),
          class: 'information-count-card-dashboard-col-4',
          round_color: 'blue',
          api: '',
        },
        {
          title: i18n.t('dashboard.card.online'),
          class: 'information-count-card-dashboard-col-4',
          round_color: 'yellow',
          api: '',
        },
        {
          title: i18n.t('dashboard.card.active'),
          class: 'information-count-card-dashboard-col-4',
          round_color: 'red',
          api: '',
        },
      ],
    },
    {
      title: i18n.t('dashboard.card.intercom'),
      direction: 'column',
      class: 'information-card-dashboard-col-3',
      cols: 3,
      cards: [
        {
          title: i18n.t('dashboard.card.device.normal_status'),
          round_color: 'green',
          api: '',
        },
        {
          title: i18n.t('dashboard.card.device.shutdown_status'),
          round_color: 'yellow',
          api: '',
        },
        {
          title: i18n.t('dashboard.card.device.error_status'),
          round_color: 'red',
          api: '',
        },
        {
          title: startFirstLet(i18n.tc('intercom.intercom_key_set.key_set', 5)),
          border: true,
          api: '',
        },
        { title: i18n.t('intercom.call_sessions.title_count'), api: '' },
      ],
    },
    {
      title: i18n.t('dashboard.card.CCTV'),
      direction: 'column',
      class: 'information-card-dashboard-col-3',
      cols: 3,
      cards: [
        {
          title: i18n.t('dashboard.card.device.normal_status'),
          round_color: 'green',
          api: '',
        },
        {
          title: i18n.t('dashboard.card.device.shutdown_status'),
          round_color: 'yellow',
          api: '',
        },
        {
          title: i18n.t('dashboard.card.device.error_status'),
          round_color: 'red',
          api: '',
        },
      ],
    },
    {
      title: i18n.t('dashboard.card.meters'),
      direction: 'column',
      class: 'information-card-dashboard-col-3',
      cols: 3,
      cards: [
        {
          title: i18n.t('dashboard.card.device.normal_status'),
          round_color: 'green',
          api: '',
        },
        {
          title: i18n.t('dashboard.card.device.shutdown_status'),
          round_color: 'yellow',
          api: '',
        },
        {
          title: i18n.t('dashboard.card.device.error_status'),
          round_color: 'red',
          api: '',
        },
      ],
    },
    {
      title: i18n.t('dashboard.card.fire_safety'),
      direction: 'column',
      class: 'information-card-dashboard-col-3',
      cols: 3,
      cards: [
        {
          title: i18n.t('dashboard.card.device.normal_status'),
          round_color: 'green',
          api: '',
        },
        {
          title: i18n.t('dashboard.card.device.shutdown_status'),
          round_color: 'yellow',
          api: '',
        },
        {
          title: i18n.t('dashboard.card.device.error_status'),
          round_color: 'red',
          api: '',
        },
      ],
    },
  ],
  geoUnit: {
    selectedUnit: {
      cards: [
        {
          title: i18n.tc('geo_unit.type_only_names.Apartment', 3),
          class: 'information-count-card-dashboard-col-4',
          api: '',
        },
        {
          title: i18n.t('user.role_count'),
          class: 'information-count-card-dashboard-col-4',
          api: '',
        },
        {
          title: i18n.t('global.n_devices'),
          class: 'information-count-card-dashboard-col-4',
          api: '',
        },
      ],
    },
    childUnit: {
      title: '',
      direction: 'column',
      class: 'information-card-dashboard-col-3',
      cols: 3,
      cards: [
        {
          title: i18n.t('dashboard.card.intercom'),
          progress_bar: true,
          api: '',
        },
        {
          title: i18n.t('dashboard.card.electricity_system'),
          progress_bar: true,
          api: '',
        },
        {
          title: i18n.t('dashboard.card.fire_alarm'),
          progress_bar: true,
          api: '',
        },
      ],
    },
    buildingUnit: [
      {
        title: i18n.t('dashboard.card.intercom'),
        direction: 'column',
        class: 'information-card-dashboard-col-3',
        cols: 3,
        cards: [
          {
            title: i18n.t('dashboard.card.device.normal_status'),
            round_color: 'green',
            api: '',
          },
          {
            title: i18n.t('dashboard.card.device.shutdown_status'),
            round_color: 'yellow',
            api: '',
          },
          {
            title: i18n.t('dashboard.card.device.error_status'),
            round_color: 'red',
            api: '',
          },
          { title: i18n.t('intercom.key.title_count'), border: true, api: '' },
          { title: i18n.t('intercom.call_sessions.title_count'), api: '' },
        ],
      },
      {
        title: i18n.t('dashboard.card.CCTV'),
        direction: 'column',
        class: 'information-card-dashboard-col-3',
        cols: 3,
        cards: [
          {
            title: i18n.t('dashboard.card.device.normal_status'),
            round_color: 'green',
            api: '',
          },
          {
            title: i18n.t('dashboard.card.device.shutdown_status'),
            round_color: 'yellow',
            api: '',
          },
          {
            title: i18n.t('dashboard.card.device.error_status'),
            round_color: 'red',
            api: '',
          },
        ],
      },
      {
        title: i18n.t('dashboard.card.meters'),
        direction: 'column',
        class: 'information-card-dashboard-col-3',
        cols: 3,
        cards: [
          {
            title: i18n.t('dashboard.card.device.normal_status'),
            round_color: 'green',
            api: '',
          },
          {
            title: i18n.t('dashboard.card.device.shutdown_status'),
            round_color: 'yellow',
            api: '',
          },
          {
            title: i18n.t('dashboard.card.device.error_status'),
            round_color: 'red',
            api: '',
          },
        ],
      },
      {
        title: i18n.t('dashboard.card.fire_safety'),
        direction: 'column',
        class: 'information-card-dashboard-col-3',
        cols: 3,
        cards: [
          {
            title: i18n.t('dashboard.card.device.normal_status'),
            round_color: 'green',
            api: '',
          },
          {
            title: i18n.t('dashboard.card.device.shutdown_status'),
            round_color: 'yellow',
            api: '',
          },
          {
            title: i18n.t('dashboard.card.device.error_status'),
            round_color: 'red',
            api: '',
          },
        ],
      },
    ],
  },
};

export const appropriateChildGeoUnitTypesByParent = {
  all: [
    {
      type: 'District',
      name: startFirstLet(i18n.tc('plurals.geo_units.District', 2)),
    },
  ],
  district: [
    { type: 'all', name: i18n.t('global.all') },
    {
      type: 'Territory',
      name: startFirstLet(i18n.tc('plurals.geo_units.Territory', 2)),
    },
    {
      type: 'Building',
      name: startFirstLet(i18n.tc('plurals.geo_units.Building', 2)),
    },
  ],
  city: [
    { type: 'all', name: i18n.t('global.all') },
    {
      type: 'Territory',
      name: startFirstLet(i18n.tc('plurals.geo_units.Territory', 2)),
    },
    {
      type: 'Building',
      name: startFirstLet(i18n.tc('plurals.geo_units.Building', 2)),
    },
  ],
  street: [
    { type: 'all', name: i18n.t('global.all') },
    {
      type: 'Territory',
      name: startFirstLet(i18n.tc('plurals.geo_units.Territory', 2)),
    },
    {
      type: 'Building',
      name: startFirstLet(i18n.tc('plurals.geo_units.Building', 2)),
    },
  ],
  territory: [
    { type: 'all', name: i18n.t('global.all') },
    {
      type: 'Building',
      name: startFirstLet(i18n.tc('plurals.geo_units.Building', 2)),
    },
    {
      type: 'StoreRoom',
      name: startFirstLet(i18n.tc('plurals.geo_units.StoreRoom', 2)),
    },
  ],
  building: [
    { type: 'all', name: i18n.t('global.all') },
    {
      type: 'Entrance',
      name: startFirstLet(i18n.tc('plurals.geo_units.Entrance', 2)),
    },
    {
      type: 'ParkingPlace',
      name: startFirstLet(i18n.tc('plurals.geo_units.ParkingPlace', 2)),
    },
    {
      type: 'StoreRoom',
      name: startFirstLet(i18n.tc('plurals.geo_units.StoreRoom', 2)),
    },
  ],
  entrance: [
    { type: 'all', name: i18n.t('global.all') },
    {
      type: 'Floor',
      name: startFirstLet(i18n.tc('plurals.geo_units.Floor', 2)),
    },
    {
      type: 'StoreRoom',
      name: startFirstLet(i18n.tc('plurals.geo_units.StoreRoom', 2)),
    },
  ],
  floor: [
    { type: 'all', name: i18n.t('global.all') },
    {
      type: 'Apartment',
      name: startFirstLet(i18n.tc('plurals.geo_units.Apartment', 2)),
    },
    {
      type: 'Section',
      name: startFirstLet(i18n.tc('plurals.geo_units.Section', 2)),
    },
    {
      type: 'StoreRoom',
      name: startFirstLet(i18n.tc('plurals.geo_units.StoreRoom', 2)),
    },
  ],
  section: [
    { type: 'all', name: i18n.t('global.all') },
    {
      type: 'Apartment',
      name: startFirstLet(i18n.tc('plurals.geo_units.Apartment', 2)),
    },
    {
      type: 'StoreRoom',
      name: startFirstLet(i18n.tc('plurals.geo_units.StoreRoom', 2)),
    },
  ],
  apartment: [
    { type: 'all', name: i18n.t('global.all') },
    {
      type: 'habitats',
      name: startFirstLet(i18n.tc('plurals.geo_units.Habitats', 2)),
    },
  ],
  storeroom: [
    { type: 'all', name: i18n.t('global.all') },
    {
      type: 'habitats',
      name: startFirstLet(i18n.tc('plurals.geo_units.Habitats', 2)),
    },
  ],
  parkingplace: [
    { type: 'all', name: i18n.t('global.all') },
    {
      type: 'habitats',
      name: startFirstLet(i18n.tc('plurals.geo_units.Habitats', 2)),
    },
  ],
};

export const appropriateFields4TabledByType = {
  District: [
    {
      key: 'id',
      label: 'ID',
      class: 'id-col',
    },
    {
      key: 'name',
      label: i18n.t('global.title'),
    },
    {
      key: 'city',
      label: i18n.t('edit.address.city'),
      formatter(val) {
        return val && val.name;
      },
    },
  ],
  Territory: [
    {
      key: 'id',
      label: 'ID',
      class: 'id-col',
    },
    {
      key: 'name',
      label: i18n.t('global.title'),
    },
    {
      key: 'parent_geo_units_chain',
      label: i18n.t('global.address'),
      formatter: (geoUnits) => prettifyAddress(geoUnits).shortAddressPop,
    },
  ],
  Building: [
    { key: 'id', label: 'ID', class: 'id-col' },
    { key: 'name', label: i18n.t('global.number') },
    {
      key: 'parent_geo_units_chain',
      label: i18n.t('global.address'),
      formatter: (geoUnits) => prettifyAddress(geoUnits).shortAddressPop,
    },
  ],
  Entrance: [
    { key: 'id', label: 'ID', class: 'id-col' },
    { key: 'name', label: i18n.t('geo_unit.type_name_column.Entrance') },
    {
      key: 'parent_geo_units_chain',
      label: i18n.t('global.address'),
      formatter: (geoUnits) => prettifyAddress(geoUnits).shortAddressPop,
    },
  ],
  Floor: [
    {
      key: 'id',
      label: 'ID',
      class: 'id-col',
    },
    {
      key: 'name',
      label: i18n.t('geo_unit.type_name_column.Floor'),
    },
    {
      key: 'parent_geo_units_chain',
      label: i18n.t('global.address'),
      formatter: (geoUnits) => prettifyAddress(geoUnits).shortAddressPop,
    },
  ],
  Section: [
    {
      key: 'id',
      label: 'ID',
      class: 'id-col',
    },
    {
      key: 'name',
      label: i18n.t('geo_unit.type_name_column.Section'),
    },
    {
      key: 'parent_geo_units_chain',
      label: i18n.t('global.address'),
      formatter: (geoUnits) => prettifyAddress(geoUnits).shortAddressPop,
    },
  ],
  StoreRoom: [
    {
      key: 'id',
      label: 'ID',
      class: 'id-col',
    },
    {
      key: 'name',
      label: i18n.t('global.title'),
    },
    {
      key: 'parent_geo_units_chain',
      label: i18n.t('global.address'),
      formatter: (geoUnits) => prettifyAddress(geoUnits).shortAddressPop,
    },
  ],
  Apartment: [
    {
      key: 'id',
      label: 'ID',
      class: 'id-col',
    },
    {
      key: 'name',
      label: i18n.t('geo_unit.type_name_column.Section'),
    },
    {
      key: 'parent_geo_units_chain',
      label: i18n.t('global.address'),
      formatter: (geoUnits) => prettifyAddress(geoUnits).shortAddressPop,
    },
  ],
  ParkingPlace: [
    {
      key: 'id',
      label: 'ID',
      class: 'id-col',
    },
    {
      key: 'name',
      label: i18n.t('global.title'),
    },
    {
      key: 'parent_geo_units_chain',
      label: i18n.t('global.address'),
      formatter: (geoUnits) => prettifyAddress(geoUnits).shortAddress,
    },
  ],
  habitats: [
    { key: 'id', label: 'ID', class: 'id-col' },
    { key: 'phone', label: i18n.t('global.phone') },
    { key: 'name', label: i18n.t('global.name') },
  ],
};

export const nonEditableGeoUnitTypes = [
  'City',
  'District',
  'Street',
  'Building',
];

export const lastChildTypes = {
  Territory: ['Territory', 'Building'],
  Entrance: ['Entrance', 'ParkingPlace'],
  Floor: ['Floor'],
  Section: ['Section', 'Apartment', 'StoreRoom'],
  Apartment: ['StoreRoom', 'Apartment', 'ParkingPlace'],
  StoreRoom: ['StoreRoom', 'Apartment', 'ParkingPlace'],
  ParkingPlace: ['Section', 'Apartment', 'StoreRoom', 'ParkingPlace'],
};

export const parentTypes = {
  Territory: ['District', 'Street'],
  Entrance: ['Building'],
  Floor: ['Entrance'],
  Section: ['Floor'],
  Apartment: ['Section', 'Floor'],
  StoreRoom: ['Territory', 'Building', 'Entrance', 'Entrance', 'Section'],
  ParkingPlace: ['Territory', 'Building', 'Floor'],
};

export const fullTypes = {
  City: startFirstLet(i18n.tc('plurals.geo_units.City', 1)),
  District: startFirstLet(i18n.tc('plurals.geo_units.District', 1)),
  Street: startFirstLet(i18n.tc('plurals.geo_units.Street', 1)),
  Territory: startFirstLet(i18n.tc('plurals.geo_units.Territory', 1)),
  Building: startFirstLet(i18n.tc('plurals.geo_units.Building', 1)),
  Entrance: startFirstLet(i18n.tc('plurals.geo_units.Entrance', 1)),
  Floor: startFirstLet(i18n.tc('plurals.geo_units.Floor', 1)),
  Section: startFirstLet(i18n.tc('plurals.geo_units.Section', 1)),
  Apartment: startFirstLet(i18n.tc('plurals.geo_units.Apartment', 1)),
  StoreRoom: startFirstLet(i18n.tc('plurals.geo_units.StoreRoom', 1)),
  ParkingPlace: startFirstLet(i18n.tc('plurals.geo_units.ParkingPlace', 1)),
  Bkfn: startFirstLet(i18n.tc('plurals.geo_units.Bkfn', 1)),
  Roof: startFirstLet(i18n.tc('plurals.geo_units.Roof', 1)),
};
export const shortAddressTypes = {
  City: [],
  District: ['City'],
  Street: ['City'],
  Territory: ['City', 'District'],
  Building: ['City', 'Street'],
  Entrance: ['City', 'Street', 'Building'],
  Floor: ['City', 'Street', 'Building', 'Entrance'],
  Section: ['City', 'Street', 'Building', 'Entrance', 'Floor'],
  Apartment: ['City', 'Street', 'Building'],
  StoreRoom: ['City', 'Street', 'Building'],
  ParkingPlace: ['City', 'Street', 'Building'],
};

export const DATE_FORMATS = {
  last_request: 'YYYY-MM-DD HH:mm:ss ZZ',
};
