<template>
  <div
    v-if="isUpdating"
    class="building-update-structure-status building-update-structure-status__updating"
  >
    <b-spinner small variant="primary"></b-spinner>
    {{ $t('building_create.update_structure.status.in_progress') }}
  </div>
  <div
    v-else-if="isComplete"
    class="building-update-structure-status building-update-structure-status__complete"
  >
    {{ $t('building_create.update_structure.status.complete') }}
    <a href="">{{ $t('button.update_page') }}</a>
  </div>
</template>

<script>
export default {
  name: 'BuildingUpdateStructureStatus',
  props: {
    buildingId: { required: true },
    isUpdating: { type: Boolean, default: false },
    isComplete: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss">
@import '../../assets/styles/components/variables';

.building-update-structure-status {
  height: 32px;
  width: 100%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  margin-bottom: 14px;
  font-weight: 350;
  font-size: 14px;
  line-height: 18px;
}

.building-update-structure-status__updating {
  background-color: #dff5ff;
  color: $blue;
  .spinner-border {
    margin-right: 10px;
  }
}

.building-update-structure-status__complete {
  background-color: #d1e4c9;
  color: #6aa253;
  a {
    margin-left: 5px;
  }
}
</style>
