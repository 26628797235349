<template>
  <div class="relays-of-geo-unit-table">
    <template v-if="relays.length">
      <table class="table">
        <thead>
          <tr>
            <th class="td-name">{{ $t("global.title")}}</th>
            <th class="td-geo-unit-name">{{ $t("global.location") }}</th>
            <th class="td-checked">
              <b-form-checkbox
                size="lg"
                :checked="checkedAll"
                @change="(val) => onInputAll(val)"
              ></b-form-checkbox>
            </th>
          </tr>
        </thead>
      </table>
      <table
        class="table-group table-stripped"
        v-for="(relays, type) in groups"
        :key="type"
      >
        <thead>
          <tr>
            <td colspan="2" class="td-type">
              <i
                class="icon-local-chevron-up"
                @click="onHideBlock(type)"
                :class="{ 'not-collapsed': hiddenBlocks[type] }"
              ></i>

              {{ $tc(`geo_unit.type_only_names.${type}`, 1) }}
            </td>
            <td class="td-checked">
              <b-form-checkbox
                size="lg"
                :checked="checkedGroup(relays)"
                @change="(val) => onInputAll(val, relays)"
              ></b-form-checkbox>
            </td>
          </tr>
        </thead>
        <tbody v-if="!hiddenBlocks[type]">
          <tr v-for="relay in relays" :key="relay.id">
            <td class="td-name">{{ relay.name }}</td>
            <td class="td-geo-unit-name">{{ relay.geo_unit_short_name }}</td>
            <td class="td-checked">
              <b-form-checkbox
                size="lg"
                :checked="checked[relay.id]"
                @change="(val) => onInput(val, relay.id)"
              ></b-form-checkbox>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <div
      v-else
      class="text-center w-100 mt-3 mb-3 font-weight-bolder text-muted"
    >
      {{ $t("global.error_messages.no_data") }}
    </div>
  </div>
</template>

<script>
import { groupBy } from 'lodash';

export default {
  name: 'RelaysOfGeoUnitsTable',
  props: {
    relays: { type: Array, default: () => [] },
    blackList: { type: Array, default: () => [] },
  },
  data() {
    return {
      hiddenBlocks: {},
    };
  },
  computed: {
    groups() {
      return groupBy(this.relays, 'geo_unit_type');
    },
    checked() {
      const result = {};
      for (let relay of this.relays) {
        result[relay.id] = !this.blackList.includes(relay.id);
      }
      return result;
    },
    checkedAll() {
      return (
        Object.values(this.checked).filter((i) => i).length ===
        this.relays.length
      );
    },
  },
  methods: {
    checkedGroup(relays = []) {
      let checkedCount = 0;
      for (let { id } of relays) {
        if (this.checked[id]) {
          checkedCount++;
        }
      }
      return relays.length === checkedCount;
    },
    onHideBlock(type) {
      if (this.hiddenBlocks[type]) {
        this.hiddenBlocks[type] = false;
      } else {
        this.$set(this.hiddenBlocks, type, true);
      }
    },
    onInput(val, id) {
      this.$emit(val ? 'remove' : 'add', id);
    },
    onInputAll(val, relays = this.relays) {
      const eventName = val ? 'remove' : 'add';
      relays.forEach((relay) => {
        this.$emit(eventName, relay.id);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.relays-of-geo-unit-table {
  .table {
    margin-bottom: 0;
  }
  table {
    .td-name {
      width: 30%;
    }
    .td-geo-unit-name {
      width: calc(70% - 50px);
    }
    .td-checked {
      width: 50px;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  .table-group {
    border-top: solid 1px #edeef0;
    width: 100%;
    thead {
      tr {
        td {
          color: #19233c;
          font-weight: 500;
          border-left: none;
          padding: 1rem;
        }
      }
    }
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #fafbfc;
        }
        td {
          border-radius: 0;
          border: none;
          padding: 1rem;
        }
      }
    }
  }

  .td-type {
  }
  .icon-local-chevron-up {
    display: inline-block;
    transition: transform ease-in-out 200ms;
    margin-right: 10px;
    cursor: pointer;
    &.not-collapsed {
      transform: rotate(180deg);
    }
  }
}
</style>
