<template>
  <div>
    <BDropdownInput
      v-model="valueInput"
      :handler="search"
      :canCreate="canCreate"
      :compareProperty="compareProperty"
      :debouncedTts="debouncedTts"
      :description="description"
      :disabled="disabled"
      :displayProperty="displayProperty"
      :label="label"
      :state="state"
      :formattedResult="formattedResult"
      :scrollable="scrollable"
      :autocomplete="autocomplete"
      :popoverText="popoverText"
      :popoverHint="popoverHint"
      :hintId="hintId"
      :placementPopover="placementPopover"
      :showLoading="showLoading"
      :variantSpinner="variantSpinner"
      :minWordLength="minWordLength"
      :maxWordLength="maxWordLength"
      :emptyMessage="emptyMessage"
      :createMessage="createMessage"
      :clearable="clearable"
      :comboBox="comboBox"
      :validator="validator"
      :multiple="true"
      @focus="onFocus"
      @clear="onClear"
      @select="onSelect"
      @hide="onHide"
    />

    <div class="slot-input">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import i18n from '@app/i18n';
import BDropdownInput from '@/components/base/BDropdownInput.vue';
import { isEmpty, isEqual } from 'lodash';

export default {
  name: 'MultiSelectSlot',
  components: {
    BDropdownInput,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    // можно ли из автокомплита создавать элемент
    canCreate: {
      type: Boolean,
    },
    // свойство, которое прокидывается при выборе элемента наверх (например для фильтра)
    compareProperty: {
      type: [String, Function],
      default: 'id',
    },
    debouncedTts: {
      type: Number,
      default: 200,
    },
    description: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    // отображаемое свойство выбранного элемента
    displayProperty: {
      type: [String, Function],
      default: 'title',
    },
    label: {
      type: String,
    },
    state: {
      type: Boolean,
      default: undefined,
    },
    // особое отображение результатов поиска
    formattedResult: { type: Object, default: null },
    // скроллятся ли результаты поиска
    scrollable: { type: Boolean, default: true },
    // автозаполнение
    autocomplete: { type: String, default: 'off' },
    // текст, на который надо навести для подсказки
    popoverText: {
      type: String,
      default: 'i',
    },
    // текст подсказки
    popoverHint: {
      type: String,
      default: null,
    },
    // айди хинта
    hintId: {
      type: String,
      default: null,
    },
    // положение хинта
    placementPopover: {
      type: String,
      default: 'bottom',
    },
    // показываем ли спиннер
    showLoading: {
      type: Boolean,
      default: true,
    },
    // тип спиннера
    variantSpinner: {
      type: String,
      default: 'primary',
    },
    // минимальное количество символов для поиска
    minWordLength: {
      type: Number,
      default: 4,
    },
    maxWordLength: {
      type: Number,
      default: 100,
    },
    // айди выбранного элемента
    value: {
      type: Array,
    },
    // сообщение, если ни один элемент не нашелся
    emptyMessage: {
      type: String,
      default: () => i18n.t('global.not_found'),
    },
    // текст, по клику на который создается новый элемент
    createMessage: {
      type: String,
      default: () => i18n.t('button.add'),
    },
    // очищаемое
    clearable: {
      type: Boolean,
      default: true,
    },
    // комбобокс - если не вбит ни один символ, то отображать все элементы
    comboBox: {
      type: Boolean,
      default: false,
    },
    validator: {
      type: Function,
    },
  },
  data() {
    return {
      input: null,
      initialValue: null,
      valueInput: [],
      hide: false,
    };
  },
  watch: {
    value() {
      this.valueInput = this.value;
    },
  },
  mounted() {
    this.input = this.$el.querySelector('.slot-input input');
    if (this.input && this.input.value) {
      try {
        const value = JSON.parse(this.input.value);
        if (Array.isArray(value)) {
          this.initialValue = value.map((event_array) =>
            JSON.stringify(event_array),
          );
          this.valueInput = this.initialValue;
        }
      } catch (err) {
        console.error(`invalid parse ${this.input}`);
      }
    } else {
      if (this.value) {
        this.valueInput = this.value;
      }
    }
  },
  methods: {
    onHide(value = false) {
      this.hide = value;
      if (this.hide && !isEqual(this.initialValue, this.valueInput)) {
        this.onInput();
      }
    },
    onInput() {
      if (this.input && this.hide) {
        if (!isEmpty(this.valueInput)) {
          this.input.value = JSON.stringify(
            this.valueInput.map((event) => JSON.parse(event)),
          );
        } else {
          this.input.value = null;
        }
        this.input.dispatchEvent(new Event('change'));
      }
      this.$emit('input', this.valueInput);
    },
    search(val) {
      let list = this.items;
      if (val) {
        list = this.items.filter((item) => {
          return item[this.displayProperty]
            .toLowerCase()
            .includes(val.toLowerCase());
        });
      }
      return new Promise((resolve) => resolve(list));
    },
    onBlur() {
      this.$emit('blur');
    },
    onCreateNew(value) {
      this.$emit('create', value);
    },
    onClear() {
      this.$emit('input', null);
      this.$emit('select', null);
      this.$emit('clear');
    },
    onFocus(event) {
      this.$emit('focus', event);
    },
    onSelect(value) {
      this.$emit('input', value);
      this.$emit('select', value);
    },
  },
};
</script>

<style scoped lang="scss">
.slot-input {
  display: none;
}
</style>
