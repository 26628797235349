<template>
  <div
    class="d-flex flex-column justify-content-center align-items-center h-100 w-100 bg-dark preloader"
    v-if="show"
  >
    <div class="d-flex justify-content-center align-items-center">
      <div class="dot dot_1" />
      <div class="dot dot_2" />
      <div class="dot dot_3" />
    </div>
    <div class="preloader-text">{{ msg }}</div>
  </div>
</template>

<script>
import EventBus from '@/packs/EventBus';

export default {
  name: 'Preloader',
  data() {
    return {
      show: false,
      msg: '',
    };
  },
  mounted() {
    EventBus.$on('preloader::show', (msg) => {
      this.turn(true, msg);
    });
    EventBus.$on('preloader::hide', () => {
      this.turn(false);
    });
  },
  methods: {
    turn(value, msg) {
      this.show = value;
      this.msg = value ? msg : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.preloader {
  position: fixed;
  z-index: 9999999;
  opacity: 0.8;
  top: 0;
  left: 0;
}

.spinner-small {
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
}

.spinner-medium {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}

.spinner-large {
  width: 3rem;
  height: 3rem;
}

.preloader-text {
  color: #03a9f4;
  margin-top: 2rem;
  font-size: 2rem;
}

.dot {
  background-color: #03a9f4;
  margin-right: 1rem;
  border-radius: 50%;
  animation: pulse 0.61s linear infinite;

  &_1 {
    width: 1rem;
    height: 1rem;
  }

  &_2 {
    width: 2rem;
    height: 2rem;
  }

  &_3 {
    width: 3rem;
    height: 3rem;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
