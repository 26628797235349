import 'bootstrap';
import Rails from '@rails/ujs';
import NPgoress from 'nprogress/nprogress';
import 'nprogress/nprogress.css';

// File of icons should be css not scss. https://github.com/sass/dart-sass/issues/1219
import '@/assets/styles/icons/_styles.css';
import '@/assets/styles/app.scss';
import '@slim/main';
import '@app/main';

// Add Swiper slider - for refactoring
import '@/custom/swiper';
import '@/custom/fileSizeCheck';
import { startStream, startSession, stopStream } from '@slim/cameras_app';
require('@slim/cameras');

Rails.start();

document.addEventListener('DOMContentLoaded', () => {
  const buttons = document.querySelectorAll('#startStream  ');
  buttons &&
    buttons.forEach((button) => {
      button.addEventListener('click', () => startStream());
    });
});
document.addEventListener('DOMContentLoaded', () => {
  const buttons = document.querySelectorAll('#startSession');
  buttons &&
    buttons.forEach((button) => {
      button.addEventListener('click', () => startSession());
    });
});
document.addEventListener('DOMContentLoaded', () => {
  const buttons = document.querySelectorAll('#stopSession');
  buttons &&
    buttons.forEach((button) => {
      button.addEventListener('click', () => stopSession());
    });
});

document.addEventListener('DOMContentLoaded', () => {
  const currentCookie = ('; ' + document.cookie)
    .split('; time_zone_name=')
    .pop()
    .split(';')
    .shift();
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (currentTimezone && currentCookie !== currentTimezone) {
    document.cookie = `time_zone_name=${currentTimezone}; path=/; secure`;
  }
});

global.$ = require('jquery');

$(function () {
  $('[data-toggle="popover"]').popover();

  $('[data-toggle="popover"]').on('click', function () {
    if (!$(this).attr('aria-describedby')) {
      $(this).popover('show');
    }
  });
  $(document).on('click', ':not(.popover)', function (e) {
    if (
      e.target.closest('.popover') ||
      $(e.target).attr('data-toggle') === 'popover'
    ) {
      return;
    }
    $('[data-toggle="popover"]').each(function () {
      $(this).popover('hide');
    });
  });
});

$(function () {
  $('[data-toggle="tooltip"]').tooltip();
});

require.context('../assets/icons', true);

NPgoress.configure({
  showSpinner: false,
  // parent: '.page-container'
});
