<template></template>

<script>
import BFormGroupSlot from '@/components/base/BFormGroupSlot.vue';
import RelaysAutocomplete from '@/components/autocompletes/relaysAutocomplete';

export default {
  name: 'ManageRelaysGroupForm',
  components: {
    BFormGroupSlot,
    RelaysAutocomplete,
  },
  props: {
    relayRelaysGroupData: {
      type: Array,
    },
    relayGeoUnitsData: {
      type: Array,
    },
  },
  data() {
    return {
      relaysRelaysGroup: this.relayRelaysGroupData,
      relaysGeoUnits: this.relayGeoUnitsData,
    };
  },
};
</script>
