<template>
  <div>
    <b-modal
      ref="readSelected"
      id="read-selected-modal"
      :title="$t('devices.modal.create')"
      no-stacking
      @hidden="hide"
    >
      <div class="modal-body-row with-cols pt-30">
        <b-form-row>
          <b-col cols="12">
            <BInputLabel
              :label="$t('devices.modal.model')"
              v-model="intercom.name"
              disabled
            />
          </b-col>
          <b-col cols="12">
            <BInputLabel label="UUID" v-model="intercom.uuid" disabled />
          </b-col>
          <b-col cols="12">
            <BInputLabel
              :label="$t('devices.modal.serial')"
              v-model="intercom.serial"
              disabled
            />
          </b-col>
        </b-form-row>
      </div>
      <template #modal-footer>
        <div
          class="d-flex align-items-center justify-content-between flex-grow-1"
        >
          <b-button
            @click="openUuidSelectModal"
            size="sm"
            variant="outline-primary"
            class="fast-modal__btn w-auto h-auto"
          >
            {{ $t('devices.modal.back') }}
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="fast-modal__btn w-auto h-auto"
            @click="findDevice"
          >
            {{ $t('devices.modal.next') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <ErrorUuidModal
      :clientId="clientId"
      ref="ErrorUuidModal"
      id="intercom-error-uuid"
    />
    <InfoCreateModal
      :clientId="clientId"
      ref="InfoCreateModal"
      id="intercom-info-create"
    />
  </div>
</template>

<script>
import { EVENTS } from '@/consts';
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import EventBus from '@/packs/EventBus';
import ErrorUuidModal from './ErrorUuidModal.vue';
import InfoCreateModal from './InfoCreateModal.vue';

export default {
  name: 'ReadSelectedModal',
  components: { ErrorUuidModal, InfoCreateModal },
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      onHide: false,
    };
  },
  computed: {
    ...mapState('intercom', ['intercom']),
    ...mapGetters('intercom', ['getIntercom']),
    intercom: {
      get() {
        return this.getIntercom;
      },
      set(value) {
        this.setIntercom(value);
      },
    },
  },
  methods: {
    ...mapMutations('intercom', ['setIntercom']),
    ...mapActions('intercom', ['validParamsIntercom']),

    show() {
      this.$refs.readSelected.show();
      this.onHide = false;
    },
    hide() {
      if (this.onHide) {
        this.$refs.readSelected.hide();
      } else {
        this.$bvModal
          .msgBoxConfirm(`${this.$tc('devices.close.create')}`, {
            title: this.$t('devices.close.title'),
            okTitle: this.$t('devices.close.buttons.yes'),
            cancelTitle: this.$t('devices.close.buttons.no'),
            okVariant: 'danger',
            bodyClass: 'modal-body__confirm',
          })
          .then((res) => {
            if (res) {
              this.$refs.readSelected.hide();
            } else {
              this.show();
            }
          });
      }
    },
    findDevice() {
      this.onHide = true;
      const params = {
        uuid_or_serial: this.intercom.uuid_or_serial,
      };
      this.validParamsIntercom({
        clientId: this.clientId,
        params: params,
      })
        .then(() => {
          this.hide();
          this.openInfoCreateModal();
        })
        .catch((error) => {
          const errors = error.response.data.errors;
          this.hide();
          this.openErrorUuidModal(errors);
        });
    },
    openInfoCreateModal() {
      this.$refs.InfoCreateModal.show();
    },
    openErrorUuidModal(errors) {
      this.$refs.ErrorUuidModal.show(errors);
    },
    openUuidSelectModal() {
      this.onHide = true;
      this.hide();
      EventBus.$emit(EVENTS.intercom.fast.modal.show);
    },
  },
};
</script>

<style></style>
