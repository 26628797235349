<template>
  <div>
    <slot
      :changes="changes"
      :change-model="changeModel"
      :tags="tags"
      :input-tags="inputTags"
    ></slot>
  </div>
</template>
<script>
export default {
  name: 'IntercomModelForm',
  props: {
    intercom_model: Object,
  },
  data() {
    return {
      changes: this.intercom_model,
      tags: this.intercom_model.tags,
    };
  },
  methods: {
    changeModel(event) {
      this.changes.type = event.target.value;
    },
    inputTags(val) {
      this.tags = val;
    },
  },
};
</script>
