<template>
  <div class="buttons-tab">
    <div class="d-grid grid-template-columns-2" :key="apartments.length">
      <b-form-row
        v-for="(value, key) in intercom.settings.correspond_table"
        :key="key"
      >
        <b-col md="12">
          <b-form-group>
            <b-select-label
              object-property="id"
              :label="$t('devices.tabs.buttons.apartment', [+key + 1])"
              v-model="intercom.settings.correspond_table[key]"
              :items="apartments"
              display="name"
              @select="
                (value) => (intercom.settings.correspond_table[key] = value)
              "
            />
          </b-form-group>
        </b-col>
      </b-form-row>
    </div>
  </div>
</template>

<script>
import { ApiGeoUnits } from '@/api';
import BSelectLabel from '@/components/base/BSelectLabel.vue';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'IntercomButtonsTab',
  components: { BSelectLabel },
  props: {
    clientId: {
      type: [Number, String],
      required: true,
    },
    validateState: {
      type: Function,
    },
  },
  data() {
    return {
      apartments: [],
    };
  },
  computed: {
    ...mapState('intercom', ['intercom', 'intercomModel']),
    ...mapGetters('intercom', ['getIntercom', 'getIntercomModel']),
    intercom: {
      get() {
        return this.getIntercom;
      },
      set(value) {
        this.setIntercom(value);
      },
    },
    intercomModel() {
      return this.getIntercomModel;
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapMutations('intercom', ['setIntercom']),
    async fetch() {
      this.apartments = await ApiGeoUnits.getPagination({
        q: { type_eq: 'Apartment', id_family_equal: this.intercom.geo_unit_id },
      }).then((res) => res.data);
    },
  },
};
</script>

<style lang="scss">
.buttons-tab {
  padding: 0 30px 30px 30px;
}
</style>
