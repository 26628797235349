// @ts-nocheck
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes: [
    {
      path: '/users/oauth/',
      name: 'sign_in_oauth',
      component: () => import('../layouts/OautLayout.vue'),
      redirect: { name: 'sign_in_phone' },
      children: [
        {
          path: 'sign_in_phone',
          name: 'sign_in_phone',
          component: () => import('../view/oauth/OauthSignInPhone.vue'),
        },
        {
          path: 'oauth_yandex',
          name: 'oauth_yandex',
          component: () => import('../view/oauth/OauthYandex.vue'),
        },
      ],
    },
    {
      path: '/users',
      name: 'auth_layout',
      component: () => import('../layouts/AuthLayout.vue'),
      redirect: { name: 'sign_in' },
      children: [
        {
          path: 'sign_in',
          name: 'sign_in',
          component: () => import('../view/auth/SignIn.vue'),
        },
      ],
    },
    {
      path: '/manage/:client_id/districts/:building_id/edit',
      name: 'building_edit',
      component: () => import('../components/building_edit/BuildingEdit.vue'),

      props: (route) => ({
        clientId: Number(route.params.client_id),
        buildingId: Number(route.params.building_id),
      }),
    },
    {
      path: '/manage/:client_id/intercom/district/:district_id/relays_groups',
      name: 'relaysGroups',
      component: () => import('../view/manage/intercom/keys/key-group.vue'),
      props: (route) => ({
        districtId: Number(route.params.district_id),
        clientId: Number(route.params.client_id),
      }),
      children: [
        {
          path: '',
          name: 'RelaysGroups',
          component: () =>
            import('../components/relays_groups/RelaysGroups.vue'),
        },
        {
          path: 'new',
          name: 'RelaysGroupsNew',
          component: () =>
            import('../components/relays_groups/RelaysGroupsNew.vue'),
        },
        {
          path: ':relay_groups_id',
          name: 'RelaysGroupPage',
          component: () =>
            import('../components/relays_groups/RelayGroupPage.vue'),
        },
        {
          path: ':relay_groups_id/edit',
          name: 'RelaysGroupEdit',
          component: () =>
            import('../components/relays_groups/RelayGroupEdit.vue'),
        },
      ],
    },
    // {
    //   path: '/manage/:client_id/intercom/district/:district_id/relays_groups',
    //   name: 'relaysGroups',
    //   component: () => import('../view/manage/intercom/keys/key-group.vue'),
    //   children: [
    //     {
    //       path: '',
    //       name: 'RelaysGroups',
    //       component: () =>
    //         import('../components/relays_groups/RelaysGroups.vue'),
    //       children: [
    //         {
    //           path: ':relay_groups_id',
    //           name: 'RelaysGroup',
    //           component: () =>
    //             import('../components/relays_groups/RelayGroupPage.vue'),
    //           children: [
    //             {
    //               path: 'edit',
    //               name: 'RelaysGroupEdit',
    //               component: () =>
    //                 import('../components/relays_groups/RelayGroupEdit.vue'),
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       path: 'new',
    //       name: 'RelaysGroupsNew',
    //       component: () =>
    //         import('../components/relays_groups/RelaysGroupsNew.vue'),
    //     },
    //   ],
    // },
    {
      path: '/manage/:client_id/districts',
      name: 'districts',
      component: () => import('../view/manage/districts/Districts.vue'),
      props: (route) => ({ clientId: Number(route.params.client_id) }),
      redirect: (from) => {
        return {
          name: 'geo_unit',
          params: { ...from.params, geo_unit_id: 'all', type: 'district' },
        };
      },
      children: [
        {
          path: ':type/:geo_unit_id',
          name: 'geo_unit',
          component: () => import('../view/manage/districts/Districts.vue'),
          props: (route) => ({ clientId: Number(route.params.client_id) }),
        },
      ],
    },
    {
      path: '/manage/:client_id/intercom/buildings/:id',
      name: 'intercom-building',
      component: () => import('../view/manage/intercom/building[id].vue'),
    },
    {
      path: '/manage/:client_id/intercom',
      name: 'intercom',
      component: () => import('../view/manage/intercom/layout.vue'),
      redirect(from) {
        return {
          name: 'devices',
          params: { ...from.params, geo_unit_id: 'all', type: 'district' },
        };
      },
      children: [
        {
          path: ':type/:geo_unit_id',
          component: () => import('../layouts/LayoutSimple.vue'),
          redirect: { name: 'devices' },
          children: [
            {
              path: 'call_sessions',
              name: 'call_sessions',
              component: () =>
                import('../view/manage/intercom/call-sessions.vue'),
              children: [
                {
                  path: ':call_session_id',
                  name: 'call_session',
                },
              ],
            },
            {
              path: 'devices',
              name: 'devices',
              component: () => import('../view/manage/intercom/devices.vue'),
              children: [
                {
                  path: ':intercom_id',
                  name: 'device',
                  component: () =>
                    import('../view/manage/intercom/[intercom_id].vue'),
                },
              ],
            },
            {
              path: 'keys',
              name: 'keys',
              component: () =>
                import('../view/manage/intercom/keys/layout.vue'),
              children: [
                {
                  path: 'tied',
                  name: 'tied',
                  component: () =>
                    import('../view/manage/intercom/keys/tied.vue'),
                  children: [
                    {
                      path: 'key/:key_id',
                      name: 'manage_key_tied',
                    },
                  ],
                },
                {
                  path: 'sets',
                  name: 'sets',
                  redirect: 'sets/all',
                  component: () =>
                    import('../view/manage/intercom/keys/sets/index.vue'),
                  meta: {
                    disableGeoUnitsNavMenu: true,
                  },
                  children: [
                    {
                      path: ':keyset_id',
                      name: 'set',
                      component: () =>
                        import(
                          '../view/manage/intercom/keys/sets/[key_id].vue'
                        ),
                      children: [
                        {
                          path: 'key/:key_id',
                          name: 'manage_key_set',
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'services',
                  name: 'services',
                  component: () =>
                    import('../view/manage/intercom/keys/service.vue'),
                  children: [
                    {
                      path: 'key/:key_id',
                      name: 'manage_key_service',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
});
