import Vue from 'vue';
import { request3 } from '@/api/request';
import { API_URLS } from '@/consts';

const state = {
  barrier: {
    district_id: null,
    geo_unit_id: null,
    name: null,
    allow_emergency: false,
    relays_attributes: [],
    cameras_attributes: [],
  },
};

const EMPTY_CAMERA = {
  id: null,
  uid: null,
  uuid: null,
  name: '',
  npr_direction: null,
  npr_mode: 'db',
  attempt_time: 15,
  relay_id: null,
};

const EMPTY_RELAYS = [
  {
    npr_barrier_id: null,
    npr_delay: null,
    npr_action: null,
    id: null,
  },
  {
    npr_barrier_id: null,
    npr_delay: null,
    npr_action: null,
    id: null,
  },
];

const getters = {
  getBarrier() {
    return state.barrier;
  },
  getCameras() {
    return state.barrier.cameras_attributes;
  },
  getRelays() {
    return state.barrier.relays_attributes;
  },
  getCamera: (state) => (index) => {
    return state.barrier.cameras_attributes[index];
  },
  getRelay: (state) => (index) => {
    return state.barrier.relays_attributes[index];
  },
};

const mutations = {
  setBarrier(state, payload) {
    state.barrier = payload;
  },
  setBarrierDelay(state, payload) {
    Vue.set(state.barrier, 'repeat_delay', payload);
  },
  setRelays(state, payload) {
    Vue.set(state.barrier, 'relays_attributes', payload);
  },
  setRelay(state, payload) {
    state.barrier.relays_attributes.splice(payload.index, 1, payload.relay);
  },
  resetRelays(state) {
    Vue.set(state.barrier, 'relays_attributes', EMPTY_RELAYS);
  },
  setCameras(state, payload) {
    Vue.set(state.barrier, 'cameras_attributes', payload);
  },
  setCamera(state, payload) {
    state.barrier.cameras_attributes.splice(payload.index, 1, payload.camera);
  },
  onAddCamera(state) {
    state.barrier.cameras_attributes.push(EMPTY_CAMERA);
  },
  onClearCamera(state, payload) {
    state.barrier.cameras_attributes.splice(payload, 1, EMPTY_CAMERA);
  },
  onRemoveCamera(state, payload) {
    state.barrier.cameras_attributes.splice(payload, 1);
  },
};

const actions = {
  create({ commit, dispatch }, payload) {
    return request3
      .post(API_URLS.backendManage.barrier.fetch(payload.clientId), {
        npr_barrier: payload.barrier,
      })
      .then((res) => {
        commit('setBarrier', res.data);
      });
  },
  update({ commit, dispatch }, payload) {
    return request3
      .put(
        API_URLS.backendManage.barrier.one(
          payload.barrier.id,
          payload.clientId,
        ),
        { npr_barrier: payload.barrier },
      )
      .then((res) => {
        // commit('setBarrier', res.data);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
