<template>
  <div>
    <div class="modal-body-row with-cols border-bottom pt-30">
      <b-form-row>
        <b-col md="6" offset-md="6">
          <b-button
            variant="outline-primary"
            class="mb-3"
            @click="$refs.UserModalProperty.onShow(user.id)"
          >
            {{ $t('button.add') }}
          </b-button>
        </b-col>
      </b-form-row>
    </div>
    <div class="modal-body-row with-cols">
      <b-form-row>
        <b-col cols="12" class="user-edit-modal-table-wrapper">
          <div
            v-if="isLoading"
            class="h-100 w-100 d-flex justify-content-center align-items-center"
          >
            <b-spinner variant="primary"></b-spinner>
          </div>
          <div v-else-if="propertyItems.length < 1">
            {{ this.$t('user.edit.property.not_attached') }}
          </div>
          <b-table
            v-else
            ref="properties"
            class="user-modal-table"
            :fields="propertyFields"
            :items="propertyItems"
            striped
          >
            <template v-slot:cell(actions)="{ item }">
              <div class="trash-action">
                <i class="icon-local-trash" @click="onDeleteProperty(item)"></i>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-form-row>
    </div>
    <UserModalProperty
      ref="UserModalProperty"
      :client-id="clientId"
      @created="getProperties()"
      :owned-property-ids="propertyItems.map((p) => p.geo_unit_id)"
    />
  </div>
</template>

<script>
import { ApiGeoUnits, ApiGeoUnitUser } from '@/api';
import UserModalProperty from '../modals/UserModalProperty.vue';

export default {
  name: 'UserModalTabProperty',
  components: {
    UserModalProperty,
  },
  inject: ['clientId', 'user'],
  data() {
    return {
      isLoading: true,
      properties: [],
      propertyItems: [],
    };
  },
  computed: {
    propertyFields() {
      return [
        {
          key: 'geoUnit',
          label: this.$t('address.title'),
          formatter: (value) => {
            return value.short_name || 'N/A';
          },
        },
        {
          key: 'is_guest',
          tdClass: 'text-secondary',
          label: '',
          formatter: (value) => {
            if (value) {
              return this.$t('geo_unit_user.guest');
            }
          },
        },
        {
          key: 'geoUnit',
          label: this.$t('geo_unit.model_type'),
          formatter: (value) =>
            this.$tc(`geo_unit.type_only_names.${value.type}`, 0),
        },
        { key: 'actions', label: '' },
      ];
    },
  },
  mounted() {
    this.getProperties();
  },
  methods: {
    async getProperties() {
      this.isLoading = true;
      const properties = await ApiGeoUnitUser.getAll({
        q: {
          user_id_eq: this.user.id,
        },
      });
      if (properties.length) {
        const geoUnits = await ApiGeoUnits.getAll({
          q: { id_in: properties.map(({ geo_unit_id }) => geo_unit_id) },
        });
        properties.forEach((elem) => {
          elem.geoUnit = geoUnits.find(({ id }) => id === elem.geo_unit_id);
        });
      }
      this.propertyItems = properties.filter((property) => property.geoUnit);
      this.isLoading = false;
    },
    onDeleteProperty(item) {
      const geoUnitType = this.$tc(
        `geo_unit.type_only_names.${item.geoUnit.type}`,
        10,
      ).toLowerCase();
      this.$bvModal
        .msgBoxConfirm(
          this.$t('user.edit.property.confirm_delete.message', [
            geoUnitType,
            item.geoUnit.short_name,
          ]),
          {
            titleHtml: `
              <h5 class='modal-title'>${this.$t(
                'user.edit.property.confirm_delete.title',
              )}</h5>
              <div style='font-size: 14px'>${this.$t(
                'user.edit.property.confirm_delete.subtitle',
              )}</div>
              ${this.confirmModalContent(item)}
            `,
            bodyClass: 'modal-body__confirm',
            headerClass: 'border-0',
            okTitle: this.$t('button.delete'),
            okVariant: 'danger',
          },
        )
        .then((res) => {
          if (res) {
            ApiGeoUnitUser.delete(item.id)
              .then(() => {
                this.getProperties();
              })
              .catch((err) => {
                this.$bvToast.toast(err);
              });
            this.getProperties();
          }
        });
    },

    confirmModalContent(item) {
      if(item.active_service_plans.length === 0) {
        return ""
      }
      return `<strong><span class="text-danger">${this.$t('user.edit.property.confirm_delete.active_service_plan', [item.active_service_plans.join(', ')])}</span></strong>`
    }
  },
};
</script>
