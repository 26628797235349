<template>
  <form @submit.prevent="onSubmit">
    <div class="portlet">
      <b-form-group label="Загрузить">
        <b-form-radio-group
          v-model="byClient"
          :options="radioOptions"
          stacked
        ></b-form-radio-group>
      </b-form-group>
      <GeoUnitsAutocomplete2
        v-if="byClient === 0"
        v-model="buildingId"
        :type-eq="GEO_UNITS.Building"
      />
      <br />
      <IntercomImageForm v-model="imageBase64" />
    </div>
    <b-button type="submit" :disabled="isDisabledSubmitBtn">
      {{ $t('global.sent') }}
    </b-button>
  </form>
</template>

<script>
import IntercomImageForm from '@/components/forms/IntercomImageForm.vue';
import GeoUnitsAutocomplete2 from '@/components/autocompletes/GeoUnitsAutocomplete2.vue';
import { API_URLS, GEO_UNITS } from '@/consts';
import { request3 } from '@/api/request';
import { BFormRadioGroup } from 'bootstrap-vue';

export default {
  name: 'IntercomImageFormSlim.vue',
  components: {
    BFormRadioGroup,
    GeoUnitsAutocomplete2,
    IntercomImageForm,
  },
  data() {
    return {
      byClient: 0,
      buildingId: null,
      imageBase64: null,
    };
  },
  computed: {
    GEO_UNITS: () => GEO_UNITS,
    radioOptions: () => [
      { text: 'Для всех домофонов управляющей компании', value: 1 },
      { text: 'Для конкретного дома', value: 0 },
    ],
    isDisabledSubmitBtn() {
      if (!this.imageBase64) return true;
      if (this.byClient === 0 && !this.buildingId) return true;
      return false;
    },
  },
  methods: {
    async onSubmit() {
      const data = {
        intercom: {
          image: this.imageBase64,
        },
      };
      if (this.byClient === 1) {
        data.by_client = 1;
      } else {
        data.building_id = this.buildingId;
      }

      try {
        await request3.post(
          API_URLS.manage.intercom.batchSetDisplayImage(),
          data,
        );
        this.$bvToast.toast(this.$t('global.successfully'), {
          variant: 'success',
        });
      } catch (err) {
        this.$bvToast.toast(err.response.data?.error);
      }
    },
  },
};
</script>

<style scoped></style>
