<template>
  <BFormValidate
    :model="validationData"
    :validations="validations"
    @submit="onSave"
  >
    <template v-slot:default="{ validateState }">
      <h3>{{ $t('building_create.addresses.title') }}</h3>
      <b-row>
        <b-col md="6">
          <fieldset class="form-group" :data-title="$t('edit.address.address')">
            <legend class="float-label-active">
              {{ $t('edit.address.address') }}
            </legend>
            <input
              ref="search"
              class="form-control"
              :placeholder="$t('edit.address.start_search')"
            />
          </fieldset>
          <div id="map"></div>
        </b-col>
        <b-col md="6">
          <DistrictsAutocomplete2
            v-model="districtId"
            :client-id="clientId"
            :disabled="isUpdate"
            :placeholder="$t('building_create.addresses.district')"
            :district-data="district"
            :state="validateState('district')"
            @clear="onClearDistrict"
            @select:item="onUpdateDistrict"
            class="mb-1"
            :comboBox="true"
          />
          <GeoUnitsTerritoryAutocomplete
            v-model="territoryId"
            :client-id="clientId"
            :district-id="districtId"
            :disabled="isDisableTerritory"
            :placeholder="$t('edit.address.territory')"
            :territory-data="territory"
            @clear="onClearTerritory"
            @select:item="onUpdateTerritoryName"
            ref="territoryInput"
            class="mb-1"
            comboBox
          />
          <BInputLabel
            v-model="city.name"
            :state="validateState('city.name')"
            :label="$t('edit.address.city')"
            disabled
          />
          <BInputLabel
            v-model="address.street"
            :state="validateState('address.street')"
            :label="$t('edit.address.street')"
            @input="selectNewAddress"
          />
          <BInputLabel
            v-model="address.building"
            :state="validateState('address.building')"
            :label="$t('edit.address.house')"
            @input="selectNewAddress"
          />
          <b-form-checkbox
            v-if="!isUpdate"
            size="lg"
            class="creator-checkbox mt-1"
            v-model="emptyBuilding"
          >
            {{ $t('edit.address.null_struct') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-button type="submit" :disabled="isSaving">
            {{ isUpdate ? $t('button.save') : $t('button.add') }}
            <b-spinner v-if="isSaving" small variant="light"></b-spinner>
          </b-button>
          <a :href="cancelLink" class="btn btn-secondary ml-2">
            {{ $t('button.cancel') }}
          </a>
        </b-col>
      </b-row>
    </template>
  </BFormValidate>
</template>

<script>
import { BSpinner } from 'bootstrap-vue';
import { pick } from 'lodash-es';
import { required, minLength } from 'vuelidate/lib/validators';

import DistrictsAutocomplete2 from '@/components/autocompletes/DistrictsAutocomplete2.vue';
import GeoUnitsTerritoryAutocomplete from '@/components/autocompletes/GeoUnitsTerritoryAutocomplete.vue';
import { API_URLS, GEO_UNITS } from '@/consts';
import {
  getLocale,
  getManageId,
  getDistrictId,
  getBuildingId,
} from '@/helpers';
import { ApiBackendCities, ApiBackendGeoUnits } from '@/api';
import { request3 } from '@/api/request';

async function getAddressFromGeocodeRes(value) {
  let response = await ymaps.geocode(value);
  if (typeof value === 'string') {
    const coords = response.geoObjects.get(0).geometry.getCoordinates();
    response = await ymaps.geocode(coords);
  }

  const geoObjectsArray = response.geoObjects.toArray();
  const targetCoords = geoObjectsArray[0].geometry.getCoordinates();
  const addressComponents = geoObjectsArray[0].properties
    .get('metaDataProperty.GeocoderMetaData.Address.Components')
    .reverse();

  function findNameAndCoordsToCity() {
    for (let kind of ['locality', 'area', 'province']) {
      const value = addressComponents.find(
        (component) => component.kind === kind,
      );
      if (value) {
        const matchedGeoObject = geoObjectsArray.find((geoObject) => {
          return (
            value.name ===
            geoObject.properties
              .get('metaDataProperty.GeocoderMetaData.Address.Components')
              .at(-1).name
          );
        });
        const [latitude, longitude] =
          matchedGeoObject?.geometry.getCoordinates();
        return {
          name: value.name,
          latitude,
          longitude,
        };
      }
    }
    return '';
  }

  function findName(...kinds) {
    for (let kind of kinds) {
      const value = addressComponents.find(
        (component) => component.kind === kind,
      );
      if (value) return value.name;
    }
    return '';
  }

  return {
    city: findNameAndCoordsToCity(),
    address: {
      street: findName('street'),
      building: findName('house'),
    },
    coords: targetCoords,
  };
}

const manageId = getManageId();
const districtId = getDistrictId();
const buildingId = getBuildingId();

export default {
  name: 'BuildingAddressEditor',
  components: {
    BSpinner,
    DistrictsAutocomplete2,
    GeoUnitsTerritoryAutocomplete,
  },
  props: {
    init: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const { building, city, district, parents = [] } = this.init;
    const territory = parents.find(
      (parent) => parent.type === GEO_UNITS.Territory,
    );
    const street = parents.find((parent) => parent.type === GEO_UNITS.Street);

    return {
      isSaving: false,
      emptyBuilding: false,
      districtId: undefined,
      districtName: undefined,
      territoryId: undefined,
      territoryName: undefined,
      city: city
        ? pick(city, ['name', 'latitude', 'longitude'])
        : {
            name: '',
            latitude: null,
            longitude: null,
          },
      address: {
        street: street?.name || '',
        building: building?.name || '',
      },
      district: district,
      territory: territory,
      initIds: {
        city: city?.id,
        territory: territory?.id,
        street: street?.id,
      },
      marker: undefined,
      coords: building && [building.meta.lat, building.meta.lng],
      clientId: manageId,
    };
  },
  computed: {
    cancelLink() {
      if (districtId && buildingId) {
        return `/manage/${manageId}/home/districts/${districtId}/buildings/${buildingId}/edit`;
      } else {
        return `/manage/${manageId}/districts/`;
      }
    },
    isDisableTerritory() {
      return this.isUpdate || !this.isEnteredDistrict;
    },
    isEnteredDistrict() {
      return this.districtId || this.districtName?.trim().length >= 2;
    },
    isUpdate() {
      return Boolean(Object.keys(this.init).length);
    },
    validationData() {
      return {
        address: this.address,
        city: this.city,
        district: this.isEnteredDistrict,
      };
    },
    validations() {
      return {
        district: {
          positive: (val) => (this.isUpdate ? true : val),
        },
        city: {
          name: { required, min: minLength(2) },
        },
        address: {
          street: { required },
          building: { required },
        },
      };
    },
  },
  watch: {
    isEnteredDistrict(val) {
      if (!val) this.clearTerritory();
    },
    districtId() {
      this.$refs.territoryInput.onClear();
    },
  },
  async mounted() {
    ymaps.ready(() => {
      this.createSearch();
      this.createMap();
    });
  },
  methods: {
    selectNewAddress() {
      this.$refs.search.value =
        `${this.address.street}` + ', ' + `${this.address.building}`;
    },
    clearTerritory() {
      this.territoryName = '';
      this.territoryId = null;
    },
    createMap() {
      this.map = new ymaps.Map(
        'map',
        {
          center: [55.76, 37.64],
          controls: ['fullscreenControl', 'zoomControl'],
          zoom: 10,
        },
        {
          suppressMapOpenBlock: true,
          yandexMapDisablePoiInteractivity: true,
        },
      );

      this.map.events.add('click', this.handlerMapClickEvent);
      if (this.coords) {
        this.markerSetCoords(this.coords);
        this.map.setCenter(this.coords, 16);
      }
    },
    createSearch() {
      const search = new ymaps.SuggestView(this.$refs.search);
      search.events.add('select', this.handlerSearchSelectEvent);
      this.$refs.search.focus();
      this.$refs.search.blur();
    },
    clearSearch() {
      this.$refs.search.value = '';
    },
    async handlerMapClickEvent(event) {
      const coords = event.get('coords');

      const { address, city } = await getAddressFromGeocodeRes(coords);

      this.city = city;
      this.address = address;

      this.markerSetCoords(coords);
      this.selectNewAddress();
    },
    async handlerSearchSelectEvent(event) {
      const { value } = event.get('item');

      const { address, city, coords } = await getAddressFromGeocodeRes(value);

      this.city = city;
      this.address = address;

      this.map.setCenter(coords, 16);
      this.markerSetCoords(coords);
    },
    markerSetCoords(coords = []) {
      this.coords = coords;
      if (this.marker) {
        this.marker.geometry.setCoordinates(coords);
      } else {
        this.marker = new ymaps.GeoObject({
          geometry: {
            type: 'Point',
            coordinates: coords,
          },
        });
        this.map.geoObjects.add(this.marker);
      }
    },
    onClearDistrict() {
      this.districtName = null;
      this.districtId = null;
      this.territory = null;
      this.$refs.territoryInput.onClear();
      this.territoryName = null;
      this.territoryId = null;
    },
    onUpdateDistrict(district) {
      this.districtName = district.name;
      this.districtId = district.id;
      this.territory = null;
      this.$refs.territoryInput.onClear();
      this.territoryName = null;
      this.territoryId = null;
    },
    onClearTerritory() {
      this.territoryName = null;
      this.territoryId = null;
    },
    onUpdateTerritoryName(inputValue) {
      this.territoryName = inputValue.name;
    },
    async onSave(invalid) {
      if (invalid) return;
      this.isSaving = true;
      const [lat, lng] = this.coords;
      try {
        if (this.isUpdate) {
          let cityId = this.initIds.city;
          if (!cityId || this.address.city !== this.init.city.name) {
            cityId = await ApiBackendCities.create(this.city).then(
              (res) => res.id,
            );
          }

          let parentId = this.initIds.territory;

          parentId = await ApiBackendGeoUnits.create({
            city_id: cityId,
            name: this.address.street,
            parent_id: parentId,
            type: GEO_UNITS.Street,
            geocoder_locale: getLocale(),
          }).then((res) => res.id);

          await request3.patch(
            API_URLS.backendManage.geoUnits.updateBuildingAddress(
              this.init.building.id,
            ),
            {
              city_id: cityId,
              name: this.address.building,
              meta: { lat, lng },
              parent_id: parentId,
              geocoder_locale: getLocale(),
            },
          );

          location.href = `/manage/${manageId}/home/districts/${this.init.district.id}/buildings/${this.init.building.id}`;
        } else {
          const cityId = await ApiBackendCities.create(this.city).then(
            (res) => res.id,
          );
          let parentId = null;
          if (this.territoryId) {
            parentId = this.territoryId;
          } else if (this.territoryName) {
            parentId = await ApiBackendGeoUnits.create({
              meta: { lat, lng },
              name: this.territoryName,
              type: GEO_UNITS.Territory,
              district_id: this.districtId,
              district_name: this.districtName,
              parent_id: null,
              geocoder_locale: getLocale(),
            }).then((res) => res.id);
          }
          if (this.address.street?.trim()) {
            parentId = await ApiBackendGeoUnits.create({
              city_id: cityId,
              name: this.address.street,
              parent_id: parentId,
              type: GEO_UNITS.Street,
              geocoder_locale: getLocale(),
            }).then((res) => res.id);
          }
          const { district_id = this.districtId, id } =
            await ApiBackendGeoUnits.create({
              city_id: cityId,
              meta: { lat, lng },
              name: this.address.building,
              parent_id: parentId,
              type: GEO_UNITS.Building,
              district_id: this.districtId,
              district_name: this.districtName,
              geocoder_locale: getLocale(),
            });
          if (this.emptyBuilding) {
            location.href = `/manage/${manageId}/home/districts/${district_id}/buildings/${id}`;
          } else {
            location.href = `/manage/${manageId}/districts/${id}/edit`;
          }
        }
      } catch (err) {
        //TODO: разобраться с ответамим фронта с ошибками
        if (err.response) {
          this.$bvToast.toast(Object.values(err.response.data.errors));
        } else {
          this.$bvToast.toast(Object.values(err.errors));
        }
        this.isSaving = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '../../../assets/styles/components/_variables.scss';
#map {
  width: 100%;
  height: 300px;
}

[class*='inner-panes'] {
  border-radius: 10px;
}

[class*='search__suggest-item_selected_yes'] {
  background-color: $body-bg !important;
}
</style>
