<template>
  <div>
    <slot
      :intercom-types="intercomTypes"
      :on-select-type="onSelectType"
      :type="type"
      :intercom="intercom"
      :actual-models="actualModels"
      :on-select-model="onSelectModel"
      :is-panel-queue-fields="isPanelQueueFields"
      :is-show-uuid="isShowUuid"
      :is-face-id-model="isFaceIdModel"
      :current-model="currentModel"
      :filtered-keys-profiles="filteredKeysProfiles"
      :settings-keys-profile-ids="settingsKeysProfileIds"
      :settings-keys-profile-id="settingsKeysProfileId"
      :relays="relays"
      :relay-input-name="relayInputName"
      :remove-relay="removeRelay"
      :relay-form-id="relayFormId"
      :get-relay-error="getRelayError"
      :parent-params="parentParams"
      :remove-relay-gu="removeRelayGu"
      :relay-gu-input-name="relayGuInputName"
      :formatted-type="formattedType"
      :on-select-key-types="onSelectKeyTypes"
      :get-relay-gu-error="getRelayGuError"
      :check-geo-type="checkGeoType"
      :add-relay="addRelay"
      :add-relay-gu="addRelayGu"
      :on-select-client-id="onSelectClientId"
      :on-select-geo-unit="onSelectGeoUnit"
      :on-select-keys-profile="onSelectKeysProfile"
      :is-show-keys-profiles="isShowKeysProfiles"
      :is-multiple-profiles="isMultipleProfiles"
      :is-rubetek="isRubetek"
      :selected-gu-obj="selectedGuObj"
      :selected-client="selectedClient"
      :clear-client="clearClient"
      :on-clear-geo-unit="onClearGeoUnit"
      :clients="clients"
      :disabled-gu="disabledGu"
    ></slot>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';
import i18n from '@app/i18n';

export default {
  name: 'IntercomForm',
  props: {
    intercomData: Object,
    relaysData: Array,
    clients: Array,
    models: Array,
    errors: Object,
    intercomType: String,
    keysProfileIds: Array,
    keysProfiles: Array,
    keyTypes: String,
    geoUnit: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      intercom: cloneDeep(this.intercomData),
      selectedGuObj: this.geoUnit,
      relays: this.relaysData.map((r) =>
        Object.assign({}, r, { _destroy: false }),
      ),
      type: this.intercomType,
      intercomTypes: [
        { label: i18n.t('intercom.title'), type: 'call_panel' },
        {
          label: i18n.t('racs.title_full'),
          type: 'access_control',
        },
      ],
      settingsKeyTypes: this.keyTypes,
      settingsKeysProfileIds: this.keysProfileIds,
      settingsKeysProfileId: null,
      key_types_mapping: {
        sl1_sl3: ['sl1', 'sl3'],
      },
    };
  },
  computed: {
    relaysGeoUnitsData() {
      return this.relays.flatMap((r, ri) => {
        return r.relays_geo_units.map((rgu, rgui) => ({
          ri: ri,
          rgui: rgui,
          rgu_geo_id: rgu.geo_unit_id,
        }));
      });
    },
    parentParams() {
      return { parent_id_or_id_eq: this.intercom.geo_unit_id };
    },
    isPanelQueueFields() {
      return (
        this.currentModel &&
        this.currentModel.type == 'call_panel' &&
        !(this.currentModel.rabbit_queue == 'access_control_queue')
      );
    },
    isFaceIdModel() {
      return (
        this.currentModel?.tags.filter((v) =>
          ['rbfaceid', 'expasoft'].includes(v),
        ).length > 0
      );
    },
    currentModel() {
      return this.models.find((m) => m.id == this.intercom.intercom_model_id);
    },
    isShowUuid() {
      return (
        !!this.currentModel &&
        (!(this.currentModel.type == 'call_panel') ||
          this.currentModel.rabbit_queue == 'access_control_queue')
      );
    },
    // copy of app/models/intercom_model.rb:90 rubetek?
    isRubetek() {
      return (
        this.currentModel &&
        this.currentModel.type == 'call_panel' &&
        this.currentModel.rabbit_queue == 'access_control_queue'
      );
    },
    actualModels() {
      if (this.type) {
        return this.models.filter((model) => model.type == this.type);
      }
      return this.models;
    },
    formattedType() {
      if (this.type == 'call_panel') {
        return 'Intercom::CallPanel';
      } else {
        return 'Intercom::AccessControl';
      }
    },
    filteredKeysProfiles() {
      if (!this.settingsKeyTypes || this.settingsKeyTypes.length == 0)
        return [];

      let keyTypes = this.key_types_mapping[this.settingsKeyTypes] || [
        this.settingsKeyTypes,
      ];
      return this.keysProfiles.filter((profile) =>
        keyTypes.includes(profile.key_types),
      );
    },
    isShowKeysProfiles() {
      return this.currentModel?.tags?.includes('keys_profiled');
    },
    isMultipleProfiles() {
      return this.currentModel?.tags?.includes('multiple_keys_profiles');
    },
    selectedClient() {
      return this.clients.find(
        (client) => client.id == this.intercom.client_id,
      );
    },
    disabledGu() {
      if (
        this.intercom.client_id === null ||
        this.intercom.client_id === undefined
      ) {
        return true;
      }
      return false;
    },
  },
  created() {
    if (
      this.settingsKeysProfileIds &&
      this.settingsKeysProfileIds.length != 0
    ) {
      this.settingsKeysProfileId = this.settingsKeysProfileIds[0];
    }
  },
  methods: {
    checkGeoType(val) {
      let relayIndex;
      let rguIndexes;
      this.relays.forEach((relay, i) => {
        const changedGus = relay.relays_geo_units.filter(
          (rgu) => rgu.geo_unit_id === val.id,
        );
        if (changedGus) {
          relayIndex = i;
          rguIndexes = changedGus.map((gu) =>
            relay.relays_geo_units.indexOf(gu),
          );
        }
      });
      rguIndexes.forEach((rguIndex) => {
        this.$set(
          this.relays[relayIndex].relays_geo_units[rguIndex],
          'geo_unit',
          val,
        );
      });
    },
    removeRelay(i) {
      this.$set(this.relays[i], '_destroy', true);
    },
    removeRelayGu(relayIndex, relayGuIndex) {
      this.$set(
        this.relays[relayIndex].relays_geo_units[relayGuIndex],
        '_destroy',
        true,
      );
    },
    addRelay() {
      this.relays.push({
        name: '',
        index: null,
        geo_unit_id: null,
        geo_unit: {},
        _destroy: false,
        relays_geo_units: [],
      });
    },
    addRelayGu(relayIndex) {
      this.relays[relayIndex].relays_geo_units.push({
        geo_unit: {},
        geo_unit_id: null,
        _destroy: false,
      });
    },
    relayFormId(i, attr) {
      return `intercom_${this.$props.type}_relays_attributes_${i}_${attr}`;
    },
    relayInputName(i, attr) {
      return `intercom[relays_attributes][${i}][${attr}]`;
    },
    getRelayError(i, attr) {
      const errrosArr = this.errors[`relays[${i}].${attr}`];
      if (errrosArr) {
        return errrosArr.join(', ');
      }
    },
    relayGuFormId(i, attr) {
      return `intercom_${this.$props.type}_relays_geo_units_attributes_${i}_${attr}`;
    },
    relayGuInputName(ri, i, attr) {
      return `intercom[relays_attributes][${ri}][relays_geo_units_attributes][${i}][${attr}]`;
    },
    getRelayGuError(i, attr) {
      const errrosArr = this.errors[`relays_geo_units[${i}].${attr}`];
      if (errrosArr) {
        return errrosArr.join(', ');
      }
    },
    onSelectModel(val) {
      this.intercom.intercom_model_id = val;
    },
    onSelectType(val) {
      if (this.type != val) {
        this.type = val;
        this.intercom.intercom_model_id = null;
      }
    },
    onSelectKeyTypes(val) {
      this.settingsKeyTypes = val;
      this.settingsKeysProfileIds = [];
      this.settingsKeysProfileId = null;
    },
    onSelectClientId(value) {
      if (this.intercom.client_id != value) {
        this.onClearGeoUnit();
      }
      this.intercom.client_id = value;
    },
    clearClient() {
      this.intercom.client_id = null;
      this.onClearGeoUnit();
    },
    onSelectGeoUnit(val) {
      this.selectedGuObj = val;
      this.intercom.geo_unit_id = val.id;
    },
    onClearGeoUnit() {
      this.selectedGuObj = null;
      this.intercom.geo_unit_id = null;
    },
    onSelectKeysProfile(val) {
      if (Array.isArray(val)) {
        this.settingsKeysProfileIds = val;
      } else {
        this.settingsKeysProfileIds = new Array();
        this.settingsKeysProfileIds.push(val);
        this.settingsKeysProfileId = val;
      }
    },
  },
};
</script>
