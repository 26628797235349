<template>
  <div class="select-key-group">
    <BDropdownInput
      v-model="selected_id"
      :selected="selected"
      :label="$t('add_button.key_set')"
      :handler="fetch"
      :state="state"
      display-property="name"
      compare-property="id"
      @select="onSelect"
      :comboBox="comboBox"
      :scrollable="keySets.length > 5"
    />
  </div>
</template>

<script>
import { ApiBackendKeySets } from '@/api';
import BDropdownInput from '@/components/base/BDropdownInput.vue';

export default {
  name: 'SelectKeySet',
  components: { BDropdownInput },
  props: {
    label: {
      type: String,
    },
    state: {
      type: Boolean,
    },
    value: {
      type: Number,
      default: null,
    },
    clientId: { type: [Number, String], required: true },
    comboBox: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selected_id: '',
      selected: null,
      keySets: [],
    };
  },
  mounted() {
    this.value && this.getKeyById(this.value);
    this.getSets();
  },
  methods: {
    onSelect(id) {
      this.$nextTick(() => {
        this.$emit('input', id);
      });
    },
    async getKeyById(id) {
      this.selected = await ApiBackendKeySets.getOne(id);
      this.selected_id = this.selected.id;
    },
    async getSets() {
      try {
        const { data } = await ApiBackendKeySets.getPagination({
          q: { client_id_eq: this.clientId },
        });
        this.keySets = data;
      } catch (err) {
        console.log(err);
      }
    },
    fetch(val) {
      return ApiBackendKeySets.getPagination({
        q: { name_cont_all: val },
      }).then((res) => res.data);
    },
  },
};
</script>
