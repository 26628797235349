<template>
  <b-spinner v-if="saving" variant="primary"></b-spinner>
  <i
    v-else-if="editing"
    class="font-size-20px icon-local-ok"
    @click="onSubmit"
  ></i>
  <i
    v-else
    class="font-size-20px icon-local-pencil-outlined"
    @click="onEdit"
  ></i>
</template>

<script>
export default {
  props: {
    editing: {
      type: Boolean,
    },
    saving: {
      type: Boolean,
    },
  },
  methods: {
    onEdit() {
      this.$emit('edit');
    },
    onSubmit() {
      this.$emit('submit');
    },
  },
};
</script>
