var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"portlet w-100 mb-5"},[_c('h5',{staticClass:"col-lg-12"},[_vm._v(_vm._s(_vm.$t('barrier.title')))]),_vm._v(" "),_c('b-form-validate',{ref:"barrierForm",attrs:{"validations":_vm.validations,"model":_vm.barrier},on:{"submit":_vm.onOk},scopedSlots:_vm._u([{key:"default",fn:function({ validateState, v }){return [_c('b-form-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-input-label',{attrs:{"label":"Название","state":validateState('name')},model:{value:(_vm.barrier.name),callback:function ($$v) {_vm.$set(_vm.barrier, "name", $$v)},expression:"barrier.name"}}),_vm._v(" "),(!v.model.name.required && _vm.isSubmit)?_c('b-error',{attrs:{"error":_vm.$t('global.errors.not_empty')}}):_vm._e(),_vm._v(" "),_c('b-form-checkbox',{model:{value:(_vm.barrier.allow_emergency),callback:function ($$v) {_vm.$set(_vm.barrier, "allow_emergency", $$v)},expression:"barrier.allow_emergency"}},[_vm._v("\n            "+_vm._s(_vm.$t('barrier.allow_emergency'))+"\n          ")])],1),_vm._v(" "),_c('b-col',{staticClass:"gap-3 d-grid",attrs:{"cols":"6"}},[_c('div',[_c('geo-units-autocomplete',{staticClass:"mb-0",attrs:{"placeholder":_vm.$t('barrier.geo_unit'),"geo-unit-property":"short_name","additional-query-params":{
                type_in: ['Building', 'Territory', 'ParkingArea'],
                district_id_not_null: true,
              },"context-property":"short_name","value":_vm.barrier.geo_unit_id,"client-id":_vm.clientId,"geo-unit":_vm.selectedGeoUnit,"state":validateState('geo_unit_id')},on:{"update-unit":_vm.selectGeoUnit}}),_vm._v(" "),(!v.model.geo_unit_id.required && _vm.isSubmit)?_c('b-error',{attrs:{"error":_vm.$t('devices.errors.not_empty')}}):_vm._e()],1),_vm._v(" "),_c('div',[_c('b-input-label',{key:_vm.key,ref:"repeatDelayBarrier",staticClass:"mb-0",attrs:{"label":_vm.$t('barrier.repeat_delay'),"value":_vm.barrier.repeat_delay,"state":validateState('repeat_delay')},on:{"input":(val) => _vm.onInputDelay(val)}}),_vm._v(" "),(
                (!v.model.repeat_delay.minValue ||
                  !v.model.repeat_delay.maxValue) &&
                _vm.isSubmit
              )?_c('b-error',{attrs:{"error":_vm.$t('global.errors.error_validate', [0, 300])}}):_vm._e()],1)])],1),_vm._v(" "),_c('b-form-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('relays-list',{attrs:{"value":_vm.intercom,"relays-actions":_vm.relaysActions,"barrier-id":_vm.barrier.id,"client-id":_vm.clientId,"validateState":validateState,"v":v,"isSubmit":_vm.isSubmit}})],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"6"}},[_c('cameras-list',{attrs:{"geo-unit-id":_vm.barrier.geo_unit_id,"barrier-id":_vm.barrier.id,"client-id":_vm.clientId,"driving-directions":_vm.drivingDirections,"mode-recognitions":_vm.modeRecognitions,"validateState":validateState,"v":v,"isSubmit":_vm.isSubmit}})],1)],1)]}}])}),_vm._v(" "),_c('div',{staticClass:"gap-3 d-flex"},[_c('b-button',{on:{"click":_vm.onOk}},[_vm._v(_vm._s(_vm.$t('button.save')))]),_vm._v(" "),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.onCancel}},[_vm._v("\n      "+_vm._s(_vm.$t('button.cancel'))+"\n    ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }