<template>
  <b-button variant="primary" @click="openCreateCameraModal">
    {{ placeholder }}
  </b-button>
</template>

<script>
import EventBus from '@/packs/EventBus';
import i18n from '@app/i18n';
import { EVENTS } from '@/consts';

export default {
  name: 'CameraCreateButton',
  props: {
    placeholder: {
      type: String,
      default: i18n.t('global.add_camera'),
    },
    geoUnit: {
      type: Object,
      default: null,
    },
  },
  methods: {
    openCreateCameraModal() {
      EventBus.$emit(EVENTS.camera.fast.modal.show, false, this.geoUnit);
    },
  },
};
</script>
