<template>
  <div
    class="b-select-input"
    :class="{ selected: !disabled && isActive, multiline }"
  >
    <BInputLabel
      :label="label"
      :value="value"
      :focus-handler="focusHandler"
      :disabled="disabled"
      :state="state"
      @blur="() => $emit('blur', value)"
      @input="(val) => $emit('input', val)"
    />
    <ul v-if="!disabled && isActive" class="b-select-input__list">
      <li
        @click="onSelect(item)"
        v-for="(item, index) in localItems"
        :key="index"
      >
        <a href="#">{{ getTitle(item) }}</a>
      </li>
    </ul>
    <i
      v-show="cleanable && value"
      class="icon-local-times clear-icon"
      @click="onClear"
    ></i>
  </div>
</template>

<script>
import Vue from 'vue/dist/vue.esm';
import BInputLabel from './BInputLabel.vue';

export default Vue.extend({
  name: 'BSelectInputLabel',
  components: { BInputLabel },
  props: {
    label: {
      type: String,
    },
    disabled: {
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    objectProperty: {
      type: String,
    },
    activeSearch: {
      type: Boolean,
      default: false,
    },
    state: {},
    value: {},
    multiline: {
      type: Boolean,
    },
    cleanable: {
      type: Boolean,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    localItems() {
      if (!this.activeSearch) return this.items;
      return this.items.filter((item) => {
        const title = this.getTitle(item).toLocaleLowerCase();
        if (
          typeof title === 'string' ||
          (typeof title === 'number' && !isNaN(title))
        ) {
          return (
            title
              .toString()
              .toLocaleLowerCase()
              .indexOf(this.value.toLowerCase()) > -1
          );
        }
        return false;
      });
    },
  },
  mounted() {
    document.addEventListener('click', this.clickHandler);
  },
  methods: {
    clickHandler({ target }) {
      if (!this.$el.contains(target)) {
        this.isActive = false;
      }
    },
    focusHandler() {
      this.isActive = true;
    },
    getTitle(value) {
      if (!value) return '';
      if (this.objectProperty) {
        try {
          const properties = this.objectProperty.split('.');
          properties.forEach((prop) => {
            value = value[prop];
          });
          return value;
        } catch (e) {
          console.warn('error parse object');
          return value;
        }
      }
      return value;
    },
    onSelect(value) {
      this.isActive = false;
      this.$emit('input', this.getTitle(value));
      this.$emit('select', value);
    },
    onClear() {
      this.$emit('input', '');
      this.$emit('clear');
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickHandler);
  },
});
</script>

<style lang="scss">
@import '../../assets/styles/components/variables';

.b-select-input {
  a {
    color: $color-default;
    text-decoration: none !important;
  }
  position: relative;
  fieldset {
  }
  &.selected {
    fieldset {
      input {
        border-radius: 5px 5px 0 0;
      }
    }
    .b-select-input__list {
      display: block;
    }
  }
  .b-select-input__list {
    position: absolute;
    padding: 0;
    list-style: none;
    width: 100%;
    z-index: 9999;
    background: white;
    border-top: none;
    display: none;
    margin-top: -14px;
    overflow-y: auto;
    max-height: 250px;
    border-bottom: 1px solid $border;
    border-radius: 0 0 5px 5px;
    li {
      height: 50px;
      width: 100%;
      padding: 15px;
      border-bottom: 1px solid $border;
      border-left: 1px solid $border;
      border-right: 1px solid $border;
    }
    li:last-child {
      border-radius: 0 0 5px 5px;
    }
    li:hover {
      border: 1px solid $blue;
      height: 49px;
      margin: 0;
      a {
        color: $blue;
      }
    }
    li:first-child {
      border-top: none;
    }
  }
  &.multiline {
    .b-select-input__list {
      li {
        height: auto;
        padding: 5px 15px;
      }
      li:hover {
        margin-top: -2px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  ::-webkit-scrollbar-track {
    background-color: lawngreen;
  }
  .clear-icon {
    position: absolute;
    cursor: pointer;
    top: 18px;
    right: 18px;
    &:hover {
      color: $red;
    }
  }
}
</style>
