let suuid = $('#suuid').val();
let server;
let source;

let config = {
  iceServers: [
    {
      urls: ['stun:stun.l.google.com:19302'],
    },
  ],
};

const pc = new RTCPeerConnection(config);
pc.onnegotiationneeded = handleNegotiationNeededEvent;

let log = (msg) => {
  document.getElementById('div').innerHTML += msg + '<br>';
};

pc.ontrack = function (event) {
  log(event.track.kind + ' track is delivered');
  var el = document.createElement(event.track.kind);
  el.srcObject = event.streams[0];
  el.muted = true;
  el.autoplay = true;
  el.controls = true;
  el.width = 600;
  document.getElementById('remoteVideos').appendChild(el);
};

pc.oniceconnectionstatechange = (e) => log(pc.iceConnectionState);

async function handleNegotiationNeededEvent() {
  let offer = await pc.createOffer();
  await pc.setLocalDescription(offer);
  getRemoteSdp();
}

$(document).ready(function () {
  //$('#' + suuid).addClass('active');
  //getCodecInfo();
});

export function startStream() {
  suuid = $('#suuid').val();
  server = $('#server').val();
  source = $('#source').val();

  $.get(server + '/start/uuid/' + suuid + '/source/' + source, function (
    data,
  ) {});
}

export function stopStream() {
  $.get(server + '/stop/uuid/' + suuid, function (data) {});
}

export function startSession() {
  suuid = $('#suuid').val();
  server = $('#server').val();
  source = $('#source').val();

  getCodecInfo();
}

function getCodecInfo() {
  $.get(server + '/codec/' + suuid, function (data) {
    try {
      data = JSON.parse(data);
      if (data.length > 1) {
        log('add audio Transceiver');
        pc.addTransceiver('audio', {
          direction: 'sendrecv',
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      log('add video Transceiver');
      pc.addTransceiver('video', {
        direction: 'sendrecv',
      });
      //send ping becouse PION not handle RTCSessionDescription.close()
      sendChannel = pc.createDataChannel('foo');
      sendChannel.onclose = () => console.log('sendChannel has closed');
      sendChannel.onopen = () => {
        console.log('sendChannel has opened');
        sendChannel.send('ping');
        setInterval(() => {
          sendChannel.send('ping');
        }, 1000);
      };
      sendChannel.onmessage = (e) =>
        log(
          `Message from DataChannel '${sendChannel.label}' payload '${e.data}'`,
        );
    }
  });
}

let sendChannel = null;

function getRemoteSdp() {
  $.post(
    server + '/recive',
    {
      suuid: suuid,
      data: btoa(pc.localDescription.sdp),
    },
    function (data) {
      try {
        let desc = new RTCSessionDescription();
        desc.sdp = atob(data);
        desc.type = 'answer';
        pc.setRemoteDescription(desc);
      } catch (e) {
        console.warn(e);
      }
    },
  );
}
