<template>
  <div class="b">
    <BDropdownInput
      v-model="selected_id"
      :disabled="disabled"
      :label="label"
      :handler="fetch"
      :selected="selected"
      :state="state"
      :debouncedTts="deBounceTimeout"
      :formattedResult="formatResult"
      compare-property="id"
      :display-property="displayProperty"
      @clear="onClear"
      @focus="onFocus"
      @select="onSelect"
      @select:item="onSelectItem"
    />
  </div>
</template>

<script>
import i18n from '@app/i18n';
import BDropdownInput from '@/components/base/BDropdownInput.vue';

export default {
  name: 'BSelectSearch',
  components: {
    BDropdownInput,
  },
  props: {
    handler: { type: Function, required: true },
    deBounceTimeout: { type: Number, default: 200 },
    formatResult: { type: Object, required: true },
    label: {
      type: String,
      default: i18n.t('input.name_id_or_phone_number'),
    },
    emptyResultTitle: {
      type: String,
      default: i18n.t('input.nothing_was_found'),
    },
    selected: { type: Object, default: () => {} },
    state: {
      type: [Boolean || undefined],
      default: undefined,
    },
    defaultOptions: { type: Array },
    disabled: {
      type: Boolean,
    },
    displayProperty: {
      type: [String, Function],
      default: 'name',
    },
  },
  data() {
    return {
      selected_id: null,
    };
  },
  mounted() {
    if (this.seleted instanceof Object) {
      this.selected_id = this.selected.id;
    }
  },
  methods: {
    fetch(val) {
      if (val && val.length) {
        return this.handler(val);
      } else return new Promise((resolve) => resolve(this.defaultOptions));
    },
    onSelect(value) {
      this.$emit('input', value);
      this.$emit('select', value);
      this.selected_id = value;
    },
    onSelectItem(item) {
      this.selected_id = item.id;
      this.$emit('select', item.id);
      this.$emit('select:item', item);
    },
    onClear() {
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
  },
};
</script>
