<template>
  <div class="d-flex flex-column">
    <div class="portlet w-100 p-4">
      <div
        class="card information-count-card standart p-3 d-flex justify-content-between flex-row align-items-center"
      >
        <span>{{ intercom.name }}</span>
        <div class="d-flex gap-3 align-items-center">
          <div class="pr-3" :class="colorStatus">
            {{ intercom.status_localized }}
          </div>
          <NprRelayActions
            v-for="relay in openRelays"
            :value="relay"
            :cameras="cameras"
            :key="relay.id"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NprRelayActions from './RelayActions.vue';
import { COLORS_STATUS } from '@/consts';

export default {
  name: 'NprRelays',
  components: {
    NprRelayActions,
  },
  props: {
    relays: {
      type: Array,
      default: new Array(),
    },
    cameras: {
      type: Array,
      default: new Array(),
    },
    intercom: {
      type: Object,
      required: true,
    },
  },
  computed: {
    openRelays() {
      if (this.relays?.length > 0) {
        return this.relays.filter((relay) => relay.npr_action == 'open');
      } else return [];
    },
    colorStatus() {
      return COLORS_STATUS[this.intercom.status];
    },
  },
};
</script>
