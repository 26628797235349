<template>
  <div v-if="geoUnitComplex">
    <input
      :value="geo_united_id"
      :id="`${model}_${name}`"
      type="hidden"
      :name="`${model}[${name}]`"
    />
    <div class="row mt-3">
      <div class="col-6">
        <geo-units-autocomplete
          :geo-unit-deep-at="['Building']"
          :geo-unit="geoUnitComplex ? geoUnitComplex.building : null"
          :client-id="clientId"
          :disabled="true"
          geo-unit-property="short_name"
          :placeholder="$t('geo_unit.installation_address')"
        />
      </div>
      <div class="col-3">
        <geo-units-autocomplete
          @select:item="saveEntrance"
          :geo-unit-deep-at="['Entrance']"
          :geo-unit="geoUnitComplex ? geoUnitComplex.entrance : null"
          :client-id="clientId"
          :context="geoUnit ? [entrance_context] : []"
          :disabled="wo_geo_unit"
          :searchByName="true"
          :searchTypeNotIn="searchTypeNotIn"
          :minWordLength="1"
          comboBox
          display-property="name"
          geo-unit-property="name"
          :placeholder="$tc('geo_unit.type_only_names.Entrance', 0)"
        />
      </div>
      <div class="col-3">
        <geo-units-autocomplete
          :key="keys.floor"
          @select:item="saveFloor"
          :geo-unit-deep-at="['Floor', 'Roof']"
          :geo-unit="geoUnitComplex ? geoUnitComplex.floor : null"
          :client-id="clientId"
          :context="geoUnit ? [floor_context] : []"
          :disabled="wo_geo_unit"
          :searchByName="true"
          :searchTypeNotIn="searchTypeNotIn"
          :minWordLength="1"
          display-property="name"
          geo-unit-property="name"
          :placeholder="$tc('geo_unit.type_only_names.Floor', 0)"
        />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <geo-units-autocomplete
          @select:item="saveSpace"
          :key="keys.space"
          :geo-unit-deep-at="space_deep_at"
          :geo-unit="geoUnitComplex ? geoUnitComplex.space : null"
          :client-id="clientId"
          :context="geoUnit ? [space_context] : []"
          :disabled="wo_geo_unit"
          :showInContext="true"
          :searchByName="true"
          :searchTypeNotIn="searchTypeNotIn"
          context-property="building_name"
          geo-unit-property="short_name"
          :placeholder="$tc('geo_unit.type_only_names.Room', 0)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete';
import { isNull } from 'lodash';
import { cloneDeep } from 'lodash';

export default {
  name: 'GeoUnitsAutocompleteComplex',
  components: {
    GeoUnitsAutocomplete,
  },
  props: {
    // форма сохраняется если сделан выбор
    saveFormId: String,
    // id гео юнитов в контексте которых идёт поиск
    context: Array,
    model: String,
    name: String,
    spaceTypesList: {
      type: Array,
      default() {
        return [
          'Entrance',
          'Floor',
          'Roof',
          'ParkingArea',
          'Section',
          'Bkfn',
          'TechRoom',
          'StoreRoom',
        ];
      },
    },
    geoUnitComplexStart: Object,
    geoUnit: Object,
    clientId: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchTypeNotIn: ['City', 'Street', 'Building, Territory'],
      geo_united_id: null,
      geo_unit_children_confirm: false,
      keys: {
        floor: 0,
        space: 0,
      },
      geoUnitComplex: null,
    };
  },
  computed: {
    entrance_context() {
      return this.geoUnitComplex.building.id;
    },
    floor_context() {
      return isNull(this.geoUnitComplex.entrance) ||
        isNull(this.geoUnitComplex.entrance.id)
        ? this.entrance_context
        : this.geoUnitComplex.entrance.id;
    },
    geo_unit_changed() {
      let changed;
      if (!this.geo_unit_children_confirm) {
        changed = false;
      } else if (this.wo_geo_unit) {
        changed = !isNull(this.geo_united_id);
      } else {
        changed = this.geoUnit.id !== this.geo_united_id;
      }

      return changed;
    },
    space_context() {
      return isNull(this.geoUnitComplex.floor) ||
        isNull(this.geoUnitComplex.floor.id)
        ? this.floor_context
        : this.geoUnitComplex.floor.id;
    },
    space_deep_at() {
      let types_list = this.spaceTypesList;

      if (_.isUndefined(this.geoUnit)) return types_list;

      switch (this.geoUnit.type) {
        case 'Floor':
        case 'Roof':
        case 'ParkingArea':
        case 'Section':
          types_list = _.slice(
            types_list,
            _.indexOf(types_list, 'Section') + 1,
          );
          break;
        case 'Entrance':
          types_list = _.slice(
            types_list,
            _.indexOf(types_list, 'Entrance') + 1,
          );
          break;
      }

      return types_list;
    },
    wo_geo_unit() {
      return _.isUndefined(this.geoUnit);
    },
  },
  methods: {
    saveEntrance(val) {
      this.geoUnitComplex.entrance = Object.assign({}, val);
      let id = val?.id;
      if (!id && this.geoUnitComplex.building) {
        id = this.geoUnitComplex.building.id;
      }
      this.resetFrom('entrance');
      this.saveForm(id);
    },
    saveFloor(val) {
      this.geoUnitComplex.floor = Object.assign({}, val);
      let id = val?.id;
      if (!id && this.geoUnitComplex.entrance) {
        id = this.geoUnitComplex.entrance.id || this.geoUnitComplex.building.id;
      }
      this.resetFrom('floor');
      this.saveForm(id);
    },
    saveSpace(val) {
      this.geoUnitComplex.space = Object.assign({}, val);
      let id = val?.id;
      if (!id) {
        id =
          this.geoUnitComplex.floor.id ||
          this.geoUnitComplex.entrance.id ||
          this.geoUnitComplex.building.id;
      }
      this.saveForm(id);
    },
    saveForm(id) {
      this.geo_united_id = id;

      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    resetFrom(type) {
      switch (type) {
        case 'floor':
          this.geoUnitComplex.space = null;
          this.keys.space += 1;
          break;
        default:
          this.geoUnitComplex.floor = null;
          this.geoUnitComplex.space = null;
          this.keys.floor += 1;
          this.keys.space += 1;
          break;
      }
    },
  },
  mounted() {
    this.geoUnitComplex = cloneDeep(this.geoUnitComplexStart);
    if (!this.wo_geo_unit) {
      this.geo_united_id = this.geoUnit.id;
    }
  },
};
</script>
