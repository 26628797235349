<template>
  <div>
    <slot
      :geo-unit-data="geoUnitData"
      :geo-unit-id="geoUnitId"
      :barrier-data="barrierData"
      :client-id="clientId"
      :user-data="userData"
      :user-id="userId"
      :plate-data="plateData"
      :car-data="carData"
    ></slot>
  </div>
</template>
<script>
export default {
  name: 'FiltersEventsFormSlim',
  props: {
    userData: {
      type: Object,
      default: {},
    },
    plateData: {
      type: Object,
      default: {},
    },
    barrierData: {
      type: Object,
      default: {},
    },
    clientId: {
      type: Number,
      default: null,
    },
    geoUnitData: {
      type: Object,
      default: null,
    },
    optionCars: {
      type: Object,
      default: null,
    },
    car: {},
  },
  computed: {
    userId() {
      return this.userData?.id ? this.userData.id : null;
    },
    geoUnitId() {
      return this.geoUnitData?.id ? this.geoUnitData.id : null;
    },
    carData() {
      return this.car ? JSON.parse(this.car) : null;
    },
  },
};
</script>
