<template>
  <b-row class="address-step">
    <b-col md="6" class="address-block">
      <form @submit.prevent>
        <BSelectInputLabel
          v-model="addressSearchInput"
          :label="$t('edit.address.address')"
          :items="addressSearchItems"
          class="address-search"
          @input="onInputAddress"
          @select="onSelectAddress"
          object-property="addressName"
          multiline
          cleanable
        />
        <BInputLabel
          :value="ymAddress.city && ymAddress.city.name"
          :label="$t('edit.address.city')"
          disabled
        />
        <DistrictsAutocomplete
          @update:input="districtInputChange"
          :placeholder="$t('abbreviations.ZHK')"
          :district="building && building.district"
          v-model="districtNameSelected"
          :client-id="clientId"
        />
        <BInputLabel
          v-model="ymAddress.street.name"
          :label="$t('edit.address.street')"
        />
        <BInputLabel
          v-model="ymAddress.house.name"
          :label="$t('edit.address.house')"
        />
        <b-form-checkbox
          v-if="!isUpdate"
          size="lg"
          class="creator-checkbox"
          @change="$emit('change', $event)"
        >
          {{ $t('edit.address.null_struct') }}
        </b-form-checkbox>
      </form>
    </b-col>
    <b-col cols="6" class="map-container">
      <yandex-map
        @click="onClickOnMap"
        :zoom="map.zoom"
        :settings="{ lang: yaMapLang }"
        class="ymap-container"
        :placemarks="map.coords.length ? map.coords : []"
        :coords="map.coords"
        :controls="['fullscreenControl', 'zoomControl']"
        @map-was-initialized="initYamap"
      >
        <ymap-marker
          :coords="map.coords"
          markerId="yaMarker"
          :hint-content="addressSearchInput"
        />
      </yandex-map>
    </b-col>
  </b-row>
</template>

<script>
/**
 * TODO: Should be delete this component in the next Release
 */

import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watchEffect,
} from '@vue/composition-api';

import { ApiGeoUnits, geocode } from '@/api';
import DistrictsAutocomplete from '@/components/autocompletes/DistrictsAutocomplete.vue';
import { BSelectInputLabel } from '@/components/base';
import { getManageId } from '@/helpers';
import i18n from '@app/i18n';
import { debounce, isNull } from 'lodash';
import { YaMapLangMixin } from '@app/mixins';

// ym coords res: [Longitude, Latitude], [37.64 55.76]

const findByAddress = debounce(async (input, saveRef) => {
  const res = await geocode.findByAddress({
    geocode: input,
    results: 5,
  });
  saveRef.value = res.map(({ GeoObject }) => ({
    coords: GeoObject.Point.pos.split(' '),
    addressName: GeoObject.metaDataProperty.GeocoderMetaData.Address.formatted,
  }));
}, 200);

async function findGeoUnitsByChain(geoUnits, type) {
  const [lastGeoUnit] = await ApiGeoUnits.findGeoBuyAddress(geoUnits);
  if (lastGeoUnit) {
    return ApiGeoUnits.getAll({
      q: {
        type_eq: type,
        parent_id_eq: lastGeoUnit.id,
        geo_units_clients_client_id_eq: getManageId(),
      },
    });
  }
  return [];
}

export default defineComponent({
  name: 'AddressStep',
  components: {
    BSelectInputLabel,
    DistrictsAutocomplete,
  },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
    isUpdate: {
      default: false,
    },
    building: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      mainDistrict: null,
    };
  },
  mixins: [YaMapLangMixin],
  methods: {
    districtInputChange(value) {
      this.districtNameInput = value.trim();
      this.districtNameSelected = null;
    },
  },
  watch: {
    ymAddress(newVal) {
      this.$emit('buildingAddressChange', newVal);
    },
    'districtNameUnited.value'(newVal) {
      this.$emit('buildingDistrictChange', newVal);
    },
  },
  setup(props, { root }) {
    const ymAddress = ref({
      city: {},
      street: {},
      house: {},
      districts: [],
    });
    const addressSearchInput = ref(props.building?.full_name);
    const addressSearchItems = ref([]);

    const districtsOnServer = ref([]);
    const districtNameSelected = ref(props.building?.district_id);
    const districtNameInput = ref(props.building?.district?.name);
    const districtNameUnited = computed(() => {
      return isNull(districtNameSelected.value)
        ? districtNameInput
        : districtNameSelected;
    });

    const userDistrict = ref(null);
    const territoriesOnServer = ref([]);
    const userTerritory = ref(null);

    const geoUnitsList = computed(() => {
      const { city, street, districts } = ymAddress.value;

      const untilUserDistrict = [];

      const untilUserTerritory = [...untilUserDistrict];
      if (street?.name?.trim())
        untilUserTerritory.push({ type: 'Street', name: street.name });

      const all = [...untilUserTerritory];
      if (userTerritory.value)
        all.push({
          type: 'Territory',
          name: userTerritory.value,
        });
      return { untilUserDistrict, untilUserTerritory, all };
    });

    async function onInputAddress(input) {
      if (input) {
        findByAddress(input, addressSearchItems);
      } else {
        addressSearchItems.value = [];
      }
    }

    function onSelectAddress({ coords: [lng, lat] }) {
      addressSearchItems.value = [];
      selectAddress(lng, lat);
    }

    function selectAddress(lng, lat) {
      map.value.coords = [lat, lng];
      geocode.findParamsByCoords(lng, lat).then((response) => {
        ymAddress.value = response.address;
        findGeoUnitsByChain(
          geoUnitsList.value.untilUserDistrict,
          'District',
        ).then((res) => {
          districtsOnServer.value = res;
        });
        // refreshTerritoriesItems();
      });
    }

    // function refreshTerritoriesItems() {
    //   if (isSelectedDistrict.value) {
    //     findGeoUnitsByChain(
    //       geoUnitsList.value.untilUserTerritory,
    //       'Territory',
    //     ).then((res) => {
    //       territoriesOnServer.value = res;
    //     });
    //   }
    // }

    // function onInputUserDistrict() {
    //   refreshTerritoriesItems();
    // }

    // Yandex Map
    const map = ref({
      coords: [],
      zoom: 17,
    });

    function onClickOnMap(event) {
      const [lat, lng] = event.get('coords');
      selectAddress(lng, lat);
    }

    function initYamap(yamap) {
      if (isNaN(yamap.getCenter()[0])) {
        yamap.setCenter([55.76, 37.64], 14);
      }
    }
    onMounted(() => {
      if (props.building?.geo_unit_profile?.longitude) {
        selectAddress(
          props.building?.geo_unit_profile?.longitude,
          props.building?.geo_unit_profile?.latitude,
        );
      } else {
        navigator.geolocation.getCurrentPosition(({ coords }) => {
          map.value.coords = [coords.latitude, coords.longitude];
        });
      }
    });

    function getData() {
      return new Promise((resolve, reject) => {
        validation().then((res) => {
          if (res.type === 'success') resolve(res.data);
          if (res.type === 'error') {
            root.$bvToast.toast(res.message);
            reject(Error('invalid data'));
          }
        });
      });
    }

    async function validation() {
      if (!(districtNameInput.value?.trim() || districtNameSelected.value)) {
        return { type: 'error', message: i18n.t('edit.address.search_mc') };
      }

      const { city, street, house } = ymAddress.value; // TODO change to building
      if (!city?.name?.trim()) {
        return {
          type: 'error',
          message: i18n.t('edit.address.not_found_city'),
        };
      }
      if (!house.name?.trim()) {
        return {
          type: 'error',
          message: i18n.t('edit.address.select_number_house'),
        };
      }

      if (!map.value.coords.length) {
        return {
          type: 'error',
          message: i18n.t('edit.address.not_set_map_coords'),
        };
      }

      const City = {
        name: city.name,
        latitude: city.meta?.lat,
        longitude: city.meta?.lng,
      };
      const [Building] = await ApiGeoUnits.findGeoBuyAddress([
        ...(street ? [{ type: 'Street', name: street.name, city: City }] : []),
        { type: 'Building', name: house.name, city: City },
      ]);
      if (Building)
        return { type: 'error', message: i18n.t('edit.address.house_added') };

      const building = {
        name: house.name,
        type: 'Building',
        district_name: isNull(districtNameInput)
          ? null
          : districtNameInput.value,
        district_id: isNull(districtNameSelected)
          ? null
          : districtNameSelected.value,
      };

      // Add coords for building
      if (house.meta?.lng) {
        building.meta = house.meta;
      } else {
        const [lat, lng] = map.value.coords;
        building.meta = { lat, lng };
      }

      return {
        type: 'success',
        data: {
          geoUnitsList: geoUnitsList.value.all,
          address: {
            house: house.name, // For support previous version. TODO delete this
          },
          building,
          city: City,
        },
      };
    }

    return {
      addressSearchInput,
      addressSearchItems,
      onInputAddress,
      onSelectAddress,
      districtNameSelected,
      districtNameInput,
      districtNameUnited,

      ymAddress,
      userDistrict,
      districtsOnServer,
      territoriesOnServer,
      userTerritory,

      map,
      initYamap,
      onClickOnMap,

      getData,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'app/javascript/assets/styles/components/variables';

.address-step {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

.address-block {
  flex: 1;
  position: relative;
}

.ymap-container {
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.map-container {
  display: flex;
  border-radius: 10px;
  [class*='ymap'] {
    border-radius: 5px;
    overflow: hidden;
  }
}

.list-addresses {
  position: absolute;
  width: calc(100% - 30px);
  z-index: 20;
  top: 180px;
  background: white;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
  box-shadow: 0 0 5px rgba(55, 61, 72, 0.2);

  &-item {
    padding: 0 16px;
    margin: 10px 0;
    font-size: $font-size-16px;
    line-height: $line-height-24px;
  }

  &-empty {
    text-align: center;
    margin: 10px 0;
    font-size: $font-size-16px;
    line-height: $line-height-24px;

    p {
      margin: 0;
    }
  }

  &-add {
    font-size: $font-size-12px;
    line-height: $line-height-24px;
    color: $blue;
    text-decoration-line: underline;
  }
}
</style>
