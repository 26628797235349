<template>
  <div>
    <b-form-row>
      <b-col md="12">
        <b-form-group>
          <label>
            {{ $t('intercom.modal_display.label') }}
          </label>
          <b-form-radio-group
            v-model="mode"
            :options="options"
            stacked
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col class="intercom-image-modal__image-container">
        <img v-if="imageSrc" :src="imageSrc" alt="" />
        <template v-else>
          <label class="btn btn-primary" for="file">
            {{ $t('input.choose_file_pls') }}
          </label>
          <input
            accept=".bmp"
            class="btn btn-primary"
            hidden
            id="file"
            type="file"
            @input="onInputFile"
          />
        </template>
        <a
          v-if="isShowDeletePreview"
          href="javascript:void(0)"
          @click="onDeleteImage"
        >
          {{ $t('global.delete') }}
        </a>
        <span v-if="mode === MODE_TYPES.user" class="mt-2 mb-2">
          {{ $t('intercom.modal_display.image_description') }}
        </span>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { BInputFile } from '@/components/base';
import { request3 } from '@/api/request';
import { BFormRadioGroup } from 'bootstrap-vue';

const DEFAULT_IMAGE_URL = '/default_intercom_image.bmp';

const MODE_TYPES = {
  default: 'default',
  user: 'user',
};

export default {
  components: {
    BFormRadioGroup,
    BInputFile,
  },
  data() {
    return {
      imageBase64: null,
      imageSrc: null,
      intercomId: null,
      mode: MODE_TYPES.user,
    };
  },
  computed: {
    MODE_TYPES: () => MODE_TYPES,
    isShowDeletePreview() {
      return this.imageSrc && this.mode === MODE_TYPES.user;
    },
    options() {
      return [
        {
          text: this.$t('intercom.modal_display.selectMode.default'),
          value: MODE_TYPES.default,
        },
        {
          text: this.$t('intercom.modal_display.selectMode.user'),
          value: MODE_TYPES.user,
        },
      ];
    },
  },
  watch: {
    mode(val) {
      this.imageSrc = '';
      if (val === MODE_TYPES.default) {
        request3
          .get(DEFAULT_IMAGE_URL, {
            responseType: 'arraybuffer',
          })
          .then((res) => {
            const base64 = Buffer.from(res.data, 'binary').toString('base64');
            const imageSrc = `data:image/bmp;base64,${base64}`;
            this.previewImage(imageSrc);
          });
      } else {
        this.$emit('input', null);
      }
    },
  },
  methods: {
    onInputFile(event) {
      const [file] = event.target.files;
      if (file.type !== 'image/bmp') {
        this.$bvToast.toast(
          this.$t('intercom.modal_display.error_invalid_file_format'),
        );
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.previewImage(reader.result);
      };
    },
    previewImage(imageSrc) {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        if (img.width > 320 || img.height > 200) {
          this.$bvToast.toast(
            this.$t('intercom.modal_display.error_invalid_image_resolution'),
          );
          return;
        }
        this.imageSrc = imageSrc;
        this.$emit('input', imageSrc);
      };
      img.onerror = () => {
        this.$bvToast.toast(
          this.$t('intercom.modal_display.error_file_upload'),
        );
      };
    },
    onDeleteImage() {
      this.imageSrc = null;
    },
  },
};
</script>

<style lang="scss">
.intercom-image-modal {
  &__image-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    a {
      margin-top: 10px;
      color: gray;
      text-decoration: none;
      &:hover {
        color: red;
      }
    }
  }
  &__image-description {
    margin-bottom: 10px;
  }
}
</style>
