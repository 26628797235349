var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-12 px-0",staticStyle:{"display":"inline-block"}},_vm._l((_vm.currentEmails),function(email,index){return _c('span',[_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
            title: _vm.$t('button.delete'),
            placement: 'top',
            trigger: 'hover',
            boundary: 'viewport',
          }),expression:"{\n            title: $t('button.delete'),\n            placement: 'top',\n            trigger: 'hover',\n            boundary: 'viewport',\n          }"}],staticClass:"btn btn-secondary ml-4",on:{"click":function($event){return _vm.destroyEmail(index)}}},[_vm._v("\n          "+_vm._s(email)+"\n        ")])])}),0)]),_vm._v(" "),_c('div',{staticClass:"mt-4 col-8 p-0"},[_c('a',{staticClass:"btn btn-primary ml-4",staticStyle:{"float":"right"},on:{"click":_vm.addEmail}},[_vm._v("\n      Добавить новый адрес\n    ")]),_vm._v(" "),_c('b-form-email-slot',{ref:"email_field",attrs:{"label":"Email"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_vm._v(" "),_vm._l((_vm.currentEmails),function(email){return _c('input',{attrs:{"name":"certificate_alert[responsible_emails][]","type":"hidden"},domProps:{"value":email}})})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col"},[_c('h5',[_vm._v("\n        Почтовые адреса для оповещения:\n      ")])])])
}]

export { render, staticRenderFns }