<template>
  <div class="relays-autocomplete mb-3">
    <BDropdownInput
      v-model="selected_id"
      :description="description"
      :disabled="disabled"
      :label="placeholder"
      :handler="fetch"
      :selected="barrierData"
      :state="state"
      :showLoading="showLoading"
      :variantSpinner="variantSpinner"
      :debouncedTts="debouncedTts"
      :formattedResult="formattedResult"
      compare-property="id"
      display-property="name_with_address"
      @clear="onClear"
      @focus="onFocus"
      @select="onSelect"
      @select:item="onSelectItem"
    />
  </div>
</template>

<script>
import BDropdownInput from '@/components/base/BDropdownInput.vue';
import { request3 } from '@/api/request';

export default {
  name: 'RelaysAutocomplete',
  components: {
    BDropdownInput,
  },
  props: {
    hiddenFieldName: String,
    clientId: {
      type: Number,
      required: true,
    },
    relayData: Object,
    placeholder: String,
    value: {
      type: Number,
    },
    debouncedTts: {
      type: Number,
      default: 200,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected_id: null,
    };
  },
  mounted() {
    if (this.relayData instanceof Object) {
      this.selected_id = this.relayData.id;
    }
  },
  methods: {
    onSelect(value) {
      this.$emit('input', value);
      this.$emit('select', value);
      this.selected_id = value;
    },
    onSelectItem(item) {
      this.$emit('select:item', item);
    },
    onClear() {
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
    fetch(val) {
      const params = {
        q: {
          by_name_or_geo_unit_name: val,
        },
        limit: 8,
      };
      return request3
        .get(`/api/backend/manage/${this.clientId}/autocomplete/relays`, {
          params: params,
        })
        .then((res) => res.data);
    },
  },
};
</script>
