<template>
  <div class="districts-autocomplete">
    <BDropdownInput
      v-model="selected_id"
      :description="description"
      :disabled="disabled"
      :label="placeholder"
      :handler="fetch"
      :selected="district"
      :state="state"
      :showLoading="showLoading"
      :variantSpinner="variantSpinner"
      :debouncedTts="debouncedTts"
      compare-property="id"
      display-property="name"
      @clear="onClear"
      @focus="onFocus"
      @select="onSelect"
      @select:item="onSelectItem"
      @create="openModal"
      :popoverText="popoverText"
      :popoverHint="popoverHint"
      :hintId="hintId"
      :minWordLength="minWordLength"
      :comboBox="comboBox"
      canCreate
    />
    <DistrictModal ref="districtModal" @entityCreated="onDistrictCreated" />
  </div>
</template>

<script>
import BDropdownInput from '@/components/base/BDropdownInput';
import DistrictModal from '@/components/modals/DistrictModal.vue';
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';

export default {
  name: 'DistrictsAutocomplete2',
  components: {
    BDropdownInput,
    DistrictModal,
  },
  props: {
    description: {
      type: String,
    },
    districtData: Object,
    clientId: [String, Number],
    placeholder: String,
    state: {},
    debouncedTts: {
      type: Number,
      default: 200,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minWordLength: {
      type: Number,
      default: 0,
    },
    popoverText: {
      type: String,
      default: 'i',
    },
    popoverHint: {
      type: String,
      default: null,
    },
    hintId: {
      type: String,
      default: null,
    },
    placementPopover: {
      type: String,
      default: 'bottom',
    },
    showLoading: {
      type: Boolean,
      default: true,
    },
    variantSpinner: {
      type: String,
      default: 'primary',
    },
    comboBox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected_id: null,
      district: this.districtData,
    };
  },
  mounted() {
    if (this.districtData instanceof Object) {
      this.selected_id = this.districtData.id;
    }
  },
  methods: {
    onSelect(value) {
      this.$emit('input', value);
      this.$emit('select', value);
      this.selected_id = value;
    },
    onSelectItem(item) {
      this.district = item;
      this.$emit('select:item', item);
    },
    fetch(val) {
      return request3
        .get(API_URLS.backendManage.districts.fetch(this.clientId), {
          params: {
            q: { name_cont_all: val },
            limit: 8,
          },
        })
        .then((res) => res.data);
    },
    onClear() {
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
    openModal() {
      this.$refs.districtModal.show();
    },
    onDistrictCreated(district) {
      this.onSelectItem(district);
    },
  },
};
</script>
