<template>
  <div class="calls-container">
    <div class="calls-container__table">
      <PTable
        ref="PTable"
        per-page="15"
        :api="ApiCallSessions"
        :query-params="getQueryParams"
        mutation="CallSessionsWithIntercom"
        max-height="309"
        @mounted="selectFirstElInTable"
        @refresh="selectFirstElInTable"
        show-empty
      >
        <template v-slot:content="{ items }">
          <b-table
            selectable
            responsive
            select-mode="range"
            :items="items"
            :fields="fields"
            striped
            @row-selected="selectItem"
            ref="table"
          >
            <template v-slot:cell(status)="{ value }">
              <b-badge :variant="value.variant">
                {{ $tc(`intercom.call.status.${value.status}`) }}
              </b-badge>
            </template>
          </b-table>
          <div v-if="!items.length" class="no-items">
            <div>{{ $t('intercom.call_sessions.not_registers') }}</div>
          </div>
        </template>
      </PTable>
    </div>
    <div class="calls-container__details">
      <div class="call">
        <img
          class="call-session__image"
          :src="selectedItem && selectedItem.snapshot_url"
          onerror="this.src='/img/call-session-not-found1x1.svg'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

import { ApiCallSessions } from '@/api';
import { formatterCallSessionStatus, getImageSrc } from '@/helpers';

export default {
  name: 'CallsListWithImage',
  props: {
    selectedGeoUnit: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      selectedItem: {},
      mutation: 'CallSessionsWithIntercom',
      fields: [
        {
          key: 'intercom_name',
          label: this.$t('intercom.title'),
        },
        {
          key: 'created_at',
          label: this.$t('global.date'),
          formatter: (created_at) => dayjs(created_at).format('DD.MM'),
        },
        {
          key: 'time',
          label: this.$t('global.time'),
          formatter: (value, key, row) =>
            dayjs(row.created_at).format('HH:mm'),
        },
        {
          key: 'status',
          label: this.$t('global.status'),
          formatter: (value, key, row) => formatterCallSessionStatus(row),
        },
      ],
      ApiCallSessions,
    };
  },
  computed: {
    geoUnitId() {
      return this.$route?.params.geo_unit_id || this.selectedGeoUnit.id;
    },
    getQueryParams() {
      return {
        q: {
          s: 'created_at desc',
          geo_unit_id_eq: this.geoUnitId,
        },
      };
    },
  },
  methods: {
    getImageSrc,
    selectItem([item]) {
      if (item) {
        this.selectedItem = item;
      }
    },
    selectFirstElInTable() {
      if (this.$refs.table) this.$refs.table.selectRow(0);
    },
  },
};
</script>

<style lang="scss">
.call-session__image {
  width: 360px;
  object-fit: cover;
}
</style>
