<template>
  <b-modal
    id="intercom-key-set-modal"
    :title="getTitle()"
    @ok="onOk"
    ref="keySetModal"
  >
    <b-form-validate
      ref="form"
      @submit="onOk"
      :validations="validations"
      :model="model"
    >
      <template slot-scope="{ validateState }">
        <div class="modal-body-row with-cols pt-30">
          <b-form-row>
            <b-col cols="12">
              <b-input-label
                id="input-key-set-modal_name"
                :state="validateState('name')"
                :label="$t('name')"
                autocomplete="off"
                v-model="model.name"
                class="mb-0"
                @change="changeName"
              />
              <b-error
                :error="error"
                v-for="error in errors.name"
                :key="error"
              />
            </b-col>
          </b-form-row>
        </div>
      </template>
    </b-form-validate>
    <template #modal-footer>
      <b-button @click="close" variant="outline-primary">
        {{ $t('button.cancel') }}
      </b-button>
      <b-button variant="primary" @click="onOk">
        {{ $t('button.save') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { EventBusMixin } from '@app/mixins';
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';
import BError from '../base/BError.vue';

function initData(keySet) {
  return {
    model: {
      ...(keySet || {
        name: '',
      }),
    },
  };
}

export default {
  components: { BError },
  name: 'KeySetModal',
  mixins: [EventBusMixin],
  props: {
    clientId: {},
  },
  data() {
    return {
      model: {
        name: '',
      },

      isCreate: true,
      errors: [],
      nameValid: true,
    };
  },
  mounted() {
    this.EventBusOn('KeySetModal::show', this.show);
  },
  computed: {
    validations() {
      return {
        name: {
          required,
          nameValid: () => {
            return this.nameValid;
          },
        },
      };
    },
  },
  methods: {
    async show(keySet) {
      this.$refs.keySetModal.show();
      Object.assign(this.$data, initData(keySet));
      this.isCreate = !keySet;
      this.errors = [];
    },
    close() {
      this.$refs.keySetModal.hide();
    },
    getTitle() {
      return this.isCreate
        ? this.$t('intercom.intercom_key_set.create_key_set_title')
        : this.$t('intercom.intercom_key_set.update_key_set_title');
    },
    changeName() {
      this.nameValid = true;
    },
    async onOk() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.isCreate) {
        this.createKeyset();
      } else {
        this.updateKeySet();
      }
    },
    refresh(val) {
      this.EventBusEmmit('Key::refresh');
      this.EventBusEmmit('KeySet::refresh');
      this.EventBusEmmit('KeySet::create', val);
    },
    createKeyset() {
      return request3
        .post(API_URLS.backendManage.keysets.fetch(this.clientId), {
          name: this.model.name,
          client_id: Number(this.clientId),
        })
        .then((res) => {
          this.refresh(res.data);
          this.close();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.nameValid = false;
        });
    },
    updateKeySet() {
      return request3
        .put(API_URLS.backendManage.keysets.one(this.model.id, this.clientId), {
          name: this.model.name,
          client_id: Number(this.clientId),
        })
        .then((res) => {
          this.refresh(res.data);
          this.close();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.nameValid = false;
        });
    },
  },
};
</script>
