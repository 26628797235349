<template>
  <b-modal
    id="npr-camera-zone"
    :title="$t('camera.setting_zone')"
    ref="modal"
    size="xl"
    class="zone"
  >
    <div class="zone__inner">
      <div
        class="d-flex zone__wrap flex-grow-1 justify-content-center align-items-center"
        id="zone-wrap"
      >
        <resize-observer @notify="resizeModal" />
        <svg id="zone" width="100%" height="100%" class="zone__svg"></svg>
        <div
          class="dropdown-menu zone__context-circle"
          id="zone-circle-context-menu"
        >
          <div class="dropdown-item" id="zone-circle-remove">
            {{ $t('button.delete') }}
          </div>
        </div>
        <img
          :src="live_snapshot_url"
          height="auto"
          class="zone__picture img-placeholder d-flex flex-grow-1 justify-content-center align-items-center"
          style="min-height: 400px"
        />
      </div>
    </div>
    <template v-slot:modal-footer="{ ok }">
      <b-button variant="primary" @click="onOk" :disabled="disabledSubmit">
        {{ $t('button.save') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { request3 } from '@/api/request';
import { API_URLS } from '@/consts';
import * as d3 from 'd3';
import { point, circle, Polygon } from 'flatten-js';
import _ from 'lodash';

function contexted(points) {
  const fullData = points;
  function inner(event) {
    event.preventDefault();
    const select_circle = d3.select(this);
    const [x, y] = d3.pointer(event);
    d3.select(document.getElementById('zone-circle-context-menu'))
      .style('display', 'block')
      .style('top', `${y + 5}px`)
      .style('left', `${x + 5}px`);

    d3.select(document.getElementById('zone-circle-remove')).on(
      'click',
      removePoint(fullData, select_circle),
    );
  }
  return inner;
}

function removePoint(points, circle) {
  let fullData = points;
  const selected_element = circle;
  function inner() {
    const removedElementIdx = fullData.findIndex(
      (point) =>
        point.x == selected_element.attr('cx') &&
        point.y == selected_element.attr('cy'),
    );
    fullData.splice(removedElementIdx, 1); // remove point
    resetAll();
    hiddenContext();
    createElements(fullData);
  }

  return inner;
}

function update(elem, data, fullData) {
  // for update x,y circle
  // Update current circle element
  elem.attr('cx', data.x).attr('cy', data.y);

  d3.selectAll('.polygon').remove();
  let polygon = new Polygon();

  const sortingPoints = sortPoints(fullData);
  polygon.addFace(sortingPoints);

  const zone = d3.select(document.getElementById('zone'));
  zone.insert('svg', '.circle').html(
    polygon.svg({
      className: 'polygon',
      fill: '#03A9F4',
      fillOpacity: 0.6,
      stroke: '#03A9F4',
      strokeWidth: 5,
    }),
  );
}

function createElements(points) {
  // create all elements
  const circles = points.map((p) => circle(p, 7));
  // Create new polygon
  const polygon = new Polygon();
  const sortingPoints = sortPoints(points);

  polygon.addFace(sortingPoints);

  const zone = d3.select(document.getElementById('zone'));
  // Add svg element to svg zone container
  const circles_svg = circles.reduce(
    (acc, shape) =>
      (acc += shape.svg({
        className: 'circle',
        fill: '#03A9F4',
        stroke: '#03A9F4',
      })),
    '',
  );

  zone.html(
    polygon.svg({
      className: 'polygon',
      fill: '#03A9F4',
      fillOpacity: 0.6,
      stroke: '#03A9F4',
      strokeWidth: 5,
    }) + circles_svg,
  );

  zone
    .selectAll('.circle') // Select all elements to be dragged
    .data(points)
    .call(d3.drag().on('drag', dragged(points))); // Attach callback to drag event

  zone.selectAll('.circle').data(points).on('contextmenu', contexted(points));

  zone.on('click', clicked(points)); // Attach callback to click event

  return zone.node();
}

function resetAll() {
  // remove all elements
  d3.selectAll('.polygon').remove();
  d3.selectAll('.circle').remove();
}

function hiddenContext() {
  // for hiding contextmenu for point
  d3.select(document.getElementById('zone-circle-context-menu')).style(
    'display',
    'none',
  );
}

function dragged(points) {
  // drag circle event
  const fullData = points;

  function inner(event, data) {
    // Update model.point by new mouse coordinate
    const width = document.getElementById('zone-wrap').offsetWidth;
    const height = document.getElementById('zone-wrap').offsetHeight;

    let x = event.x;
    let y = event.y;

    if (x > width) {
      x = width;
    }
    if (x < 0) {
      x = 0;
    }
    if (y > height) {
      y = height;
    }
    if (y < 0) {
      y = 0;
    }

    data.x = x;
    data.y = y;
    let selected_element = d3.select(this);
    update(selected_element, data, fullData); // Update zone
  }
  return inner;
}

function clicked(points) {
  // click zone event
  const fullData = points;
  function inner(event) {
    if (fullData.length >= 10) return;
    const [x, y] = d3.pointer(event);
    fullData.push(point(x, y));
    resetAll();
    hiddenContext();
    createElements(fullData); // Create point
  }
  return inner;
}

function sortPoints(value) {
  let points = _.cloneDeep(value);

  // Get "centre of mass"
  const centre = {
    x: points.reduce((sum, point) => sum + point.x, 0) / points.length,
    y: points.reduce((sum, point) => sum + point.y, 0) / points.length,
  };

  // Sort by polar angle and distance, centered at this centre of mass.
  const needSort = [];
  for (let point of points) {
    const polar = squaredPolar(point, centre);
    point.angle = polar.angle;
    point.distance = polar.distance;
    needSort.push(point);
  }
  needSort.sort((a, b) => a.angle - b.angle || a.distance - b.distance);
  needSort.forEach((point) => {
    delete point.angle;
    delete point.distance;
  });

  return needSort;
}

function squaredPolar(point, centre) {
  return {
    angle: Math.atan2(point.x - centre.x, point.y - centre.y),
    distance: (point.x - centre.x) ** 2 + (point.y - centre.y) ** 2, // Square of distance
  };
}

function initData(camera) {
  return {
    camera: _.cloneDeep(camera) || {
      cameras_model_id: null,
      channel_id: null,
      client_id: null,
      created_at: null,
      fw_ver: null,
      geo_unit_id: null,
      id: null,
      ip_address: null,
      is_hidden: null,
      macaddr: null,
      name: null,
      npr_barrier_id: null,
      npr_camera_id: null,
      npr_direction: null,
      npr_mode: null,
      npr_zone: null,
      relay_id: null,
      serial: null,
      settings: {},
      status: null,
      status_at: null,
      uid: null,
      updated_at: null,
      uuid: null,
    },
    live_snapshot_url: '',
    points: [],
    circles: [],
    height: 0,
    width: 0,
  };
}

export default {
  name: 'NprCameraZone',
  data() {
    return {
      ...initData(),
    };
  },

  computed: {
    disabledSubmit() {
      if (this.formattedPoints.length < 3) {
        return true;
      } else {
        if (this.formattedPoints.length == 3) {
          if (
            (this.formattedPoints[2].x - this.formattedPoints[0].x) /
              (this.formattedPoints[1].x - this.formattedPoints[0].x) ==
            (this.formattedPoints[2].y - this.formattedPoints[0].y) /
              (this.formattedPoints[1].y - this.formattedPoints[0].y)
          )
            return true;
        } else return false;
      }
    },

    formattedPoints() {
      if (!this.width || !this.height) return [];
      return this.points.map((point) => {
        return {
          x: this.pointRound(point.x / this.width, 10),
          y: this.pointRound(point.y / this.height, 10),
        };
      });
    },
  },

  methods: {
    async show(camera) {
      Object.assign(this.$data, initData(camera));
      this.$refs.modal.show();
      await this.updateSnapshot();

      this.$nextTick(() => {
        this.width = document.getElementById('zone-wrap').offsetWidth;
        this.height = document.getElementById('zone-wrap').offsetHeight;

        if (this.camera.npr_zone) {
          this.camera.npr_zone = JSON.parse(this.camera.npr_zone);
        }
        if (!Array.isArray(this.camera.npr_zone) || !this.camera.npr_zone) {
          this.camera.npr_zone = [
            [0, 0],
            [1, 0],
            [1, 1],
            [0, 1],
          ];
        }

        this.points = this.camera.npr_zone.map((el) => {
          return point(el[0] * this.width, el[1] * this.height);
        });
        createElements(this.points);
      });
    },

    resizeModal() {
      const serializePoints = this.points.map((point) => {
        return [
          this.pointRound(point.x / this.width),
          this.pointRound(point.y / this.height),
        ];
      });

      this.width = document.getElementById('zone-wrap').offsetWidth;
      this.height = document.getElementById('zone-wrap').offsetHeight;

      this.points = serializePoints.map((el) => {
        return point(el[0] * this.width, el[1] * this.height);
      });

      resetAll();
      createElements(this.points);
    },

    dragged() {
      // dragged circle event
      const fullData = this.points;
      function inner(event, data) {
        // Update model.point by new mouse coordinate
        data.x = event.x;
        data.y = event.y;
        let selected_element = d3.select(this);
        update(selected_element, data, fullData); // Update zone
      }
      return inner;
    },

    clicked() {
      // click zone event
      const fullData = this.points;

      function inner(event) {
        fullData.push(point(event.x, event.y));
        resetAll();
        createElements(fullData); // Create point
      }
      return inner;
    },

    onOk() {
      let sorting = sortPoints(this.points);

      let serializePoints = sorting.map((point) => {
        return [
          this.pointRound(point.x / this.width),
          this.pointRound(point.y / this.height),
        ];
      });
      serializePoints = _.uniqWith(serializePoints, _.isEqual);

      this.camera.npr_zone = JSON.stringify(serializePoints);
      this.$emit('updateSelectZone', this.camera);

      this.$refs.modal.hide();
    },

    pointRound(num, step = 100) {
      // rounding coordinate
      return Math.round(num * step) / step;
    },

    async updateSnapshot() {
      this.live_snapshot_url = await request3
        .get(API_URLS.backendManage.cctv.cameras.devices.fetch(this.clientId), {
          params: {
            q: {
              id_in: [this.camera.id],
            },
          },
        })
        .then((res) => res.data.cameras[0].live_snapshot_url);
    },
  },
};
</script>

<style lang="scss">
.zone {
  position: relative;
  &__inner {
    margin: 30px;
  }
  &__wrap {
    position: relative;
  }
  &__svg {
    position: absolute;
  }
  &__context-circle {
    display: none;
    width: 130px;
  }

  &__picture {
    object-fit: cover;
    max-width: 100%;
  }

  .dropdown-menu {
    overflow-x: auto;
    .dropdown-item {
      border: 1px solid #edeef0;
    }
  }
}
</style>
