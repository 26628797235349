<template>
  <div>
    <BDropdownInput
      v-model="selected_id"
      :disabled="disabled"
      :label="label"
      :handler="fetch"
      :selected="deviceData"
      :state="state"
      :debouncedTts="debouncedTts"
      :combo-box="comboBox"
      compare-property="id"
      :display-property="itemName"
      @clear="onClear"
      @focus="onFocus"
      @select="onSelect"
      @select:item="onSelectItem"
    />
    <input
      :value="selected_id"
      :id="`${model}_${name}`"
      type="hidden"
      :name="`${model}[${name}]`"
    />
  </div>
</template>

<script>
import axios from 'axios';
import BDropdownInput from '@/components/base/BDropdownInput.vue';

export default {
  name: 'adminUspdDevicesAutocomplete',
  components: {
    BDropdownInput,
  },
  props: {
    // форма сохраняется если сделан выбор
    saveFormId: String,
    model: String,
    name: String,
    deviceData: Object,
    clientId: {
      type: Number,
    },
    label: String,
    value: {
      type: Number,
    },
    state: {},
    debouncedTts: {
      type: Number,
      default: 200,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    items: Array,
    comboBox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected_id: null,
    };
  },
  mounted() {
    if (this.deviceData instanceof Object) {
      this.selected_id = this.deviceData.id;
    }
  },
  methods: {
    onSelect(result) {
      if (result) {
        this.selected_id = result;
        this.$emit('input', result);
        this.saveForm();
      }
    },
    onSelectItem(result) {
      if (result.id) {
        this.selected_id = result.id;
        this.$emit('input', result.id);
        this.$emit('select', result.id);
        this.$emit('select:item', result);
        this.saveForm();
      }
    },
    onClear() {
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
    saveForm() {
      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    itemName(item) {
      if (item) {
        return item.serial || item.uuid;
      }
      return '';
    },
    fetch(val) {
      let devices = this.items;
      if (val) {
        devices = this.items.filter((item) => {
          return this.itemName(item).toLowerCase().includes(val.toLowerCase());
        });
      }
      return new Promise((resolve) => resolve(devices));
      // let prms = {
      //   q: val,
      //   limit: 8,
      // };
      // return axios
      //   .get(``, {
      //     params: prms,
      //   })
      //   .then((response) => {
      //     if (response.data != null) {
      //       return response.data.devices;
      //     } else {
      //       return null;
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },
  },
};
</script>
