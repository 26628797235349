<template>
  <div>
    <h2>Архив</h2>
    <b-row>
      <b-col md="8">
        <div class="portlet">
          <video ref="video" width="100%" controls autoplay></video>
        </div>
      </b-col>
      <b-col md="4">
        <div class="portlet">
          <div>
            <h5>Месяц</h5>
            <b-input
              type="month"
              v-model="selectedMonth"
              @input="onSelectMonth"
            />
          </div>
          <div class="mt-3">
            <h5>День</h5>
            <div class="d-flex">
              <div
                v-for="day in days"
                class="day"
                :class="{ select: day === selectedDay }"
                @click="onSelectDay(day)"
              >
                {{ day }}
              </div>
            </div>
          </div>
          <div class="mt-3">
            <h5>Период</h5>
            <div>
              <div
                v-for="period in timelines"
                class="day"
                :class="{
                  select:
                    JSON.stringify(period) === JSON.stringify(selectedPeriod),
                }"
                @click="onSelectPeriod(period)"
              >
                {{ dayjs(period.start).format('HH:mm') }} -
                {{ dayjs(period.end).format('HH:mm') }}
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Hls from 'hls.js';
import dayjs from 'dayjs';
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';

const DATE_FORMATS = {
  playlistTime: 'YYYY-MM-DDTHH:mm',
};

let hls;

function getEndTime({ start, end } = {}) {
  const timeAfter3Hours = dayjs(start).add(3, 'h');
  const endTime = timeAfter3Hours.isBefore(end) ? timeAfter3Hours : dayjs(end);
  return endTime.utc().format(DATE_FORMATS.playlistTime);
}

function get3HoursPeriod({ start, end }, periods = []) {
  const timeAfter3Hours = dayjs(start).add(3, 'h');
  const endTime = timeAfter3Hours.isBefore(end) ? timeAfter3Hours : dayjs(end);
}

function slicePeriods({ start, end }, periods = []) {
  const startPlus3Hours = dayjs(start).add(3, 'h');
  if (startPlus3Hours.isBefore(end)) {
    periods.push({
      start,
      end: startPlus3Hours.valueOf(),
    });
    return slicePeriods(
      {
        start: startPlus3Hours.add(1, 's').valueOf(),
        end,
      },
      periods,
    );
  } else {
    return [...periods, { start, end }];
  }
}

export default {
  props: {
    clientId: {
      type: [Number, String],
    },
    deviceId: {
      type: String,
    },
  },
  data() {
    return {
      days: [],
      timelines: [],
      selectedDay: undefined,
      selectedMonth: dayjs().format('YYYY-MM'),
      selectedPeriod: undefined,
    };
  },
  computed: {
    dayjs: () => dayjs,
  },
  async mounted() {
    this.getDays();
  },
  methods: {
    getDays() {
      request3
        .get(
          API_URLS.backendManage.cctv.cameras.device.records.days(
            this.clientId,
            this.deviceId,
          ),
          {
            params: { month: this.selectedMonth },
          },
        )
        .then((res) => {
          this.days = res.data.days.map((day) => String(day).padStart(2, '0'));
        });
    },
    createHls() {
      if (hls) return;
      hls = new Hls();
      hls.attachMedia(this.$refs.video);
    },
    async onSelectDay(day) {
      this.selectedDay = day;
      this.timelines = [];

      const params = {
        date: `${this.selectedMonth}-${day}`,
      };

      const responseTimelines = await request3
        .get(
          API_URLS.backendManage.cctv.cameras.device.records.timeline(
            this.clientId,
            this.deviceId,
          ),
          { params },
        )
        .then((res) => res.data);
      this.timelines = responseTimelines.reduce((acc, time) => {
        return acc.concat(slicePeriods(time));
      }, []);
    },
    onSelectMonth() {
      this.getDays();
    },
    async onSelectPeriod(period) {
      this.selectedPeriod = period;

      function formatDate(date) {
        return dayjs(date).utc().format(DATE_FORMATS.playlistTime);
      }

      const startTime = formatDate(period.start);
      const endTime = formatDate(period.end);

      const url = API_URLS.backendManage.cctv.cameras.device.records.playlist(
        this.clientId,
        this.deviceId,
        { endTime, startTime },
      );

      this.createHls();
      hls.loadSource(url);
    },
  },
};
</script>

<style scoped>
video {
  border-radius: 8px;
}

.day {
  padding: 5px 8px;
  border: 1px solid #cacbd0;
  border-radius: 3px;
  margin-right: 5px;
  margin-bottom: 2px;
  font-variant-numeric: tabular-nums;
}

.day:hover {
  background-color: #03a9f4;
  border-color: #03a9f4;
  color: white;
  cursor: pointer;
}

.day.select {
  background-color: #03a9f4;
  border-color: #03a9f4;
  color: white;
}
</style>
