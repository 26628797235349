<template>
  <b-form-group
    :id="id"
    class="b-selector-with-label multiselect"
    style="height: 50px !important"
    :data-title="label"
  >
    <legend :class="labelClass">
      {{ label }}
    </legend>
    <div class="d-flex align-items-center justify-content-center w-100">
      <div
        id="1"
        class="dropdown b-dropdown w-100 btn-group"
        :class="{ show: isOpened }"
      >
        <!---->
        <button
          id="1__BV_toggle_"
          aria-haspopup="true"
          aria-expanded="false"
          type="button"
          class="btn dropdown-toggle btn-transparent dropdown-toggle-no-caret"
          @click="onClickDropDown"
          :class="getState"
        >
          {{ dropdownText }}

          <div class="icon-local-shevron-down dropdown-caret"></div>
        </button>
        <ul
          role="menu"
          tabindex="-1"
          class="dropdown-menu"
          :class="{ show: isOpened }"
        >
          <li
            v-for="(item, index) in items"
            :key="index"
            role="presentation"
            @click="onSelect(item)"
          >
            <a role="menuitem" href="#" target="_self" class="dropdown-item">
              <div class="multiselect-item__checkbox">
                <i v-if="getCheckboxValue(item)" class="icon-local-ok"></i>
              </div>
              <div class="multiselect-item__title">
                {{ getTitleValue(item) }}
              </div>
            </a>
          </li>
          <li v-if="Array.isArray(items) && !items.length" role="presentation">
            <a
              role="menuitem"
              href="#"
              target="_self"
              tabindex="-1"
              aria-disabled="true"
              class="dropdown-item disabled"
            >
              empty
            </a>
          </li>
          <!---->
        </ul>
      </div>
    </div>
  </b-form-group>
</template>

<script>
import { get, isString, isObject, isNumber } from 'lodash';
import { SubscribeToEvents } from '@/mixins';

export default {
  name: 'BMultiSelect',
  mixins: [SubscribeToEvents],
  props: {
    disabled: {
      type: Boolean,
    },
    displayProperty: {
      type: String,
      default: 'title',
    },
    id: {
      type: [Number, String],
    },
    label: {
      type: [Number, String],
    },
    items: {
      type: Array,
      required: true,
    },
    modelProperty: {
      type: String,
      default: 'id',
    },
    value: {
      type: Array,
      required: true,
    },
    state: {
      type: [Boolean || undefined],
      default: undefined,
    },
  },
  data() {
    return {
      isOpened: false,
    };
  },
  computed: {
    dropdownText() {
      return this.items
        .filter((item) => this.value.includes(this.getModelValue(item)))
        .map((item) => this.getTitleValue(item))
        .join(', ');
    },
    labelClass() {
      const active = this.valueIsEmpty
        ? 'float-label-inactive'
        : 'float-label-active';
      const disabled = this.disabled ? 'disabled' : '';
      return [active, disabled];
    },
    valueIsEmpty() {
      return !(Array.isArray(this.value) && this.value.length);
    },
    getState() {
      if (this.state === undefined) return '';
      if (this.state) return 'is-valid';
      return 'is-invalid';
    },
  },
  mounted() {
    document.addEventListener('click', this.checkIsHideDropdown);
    this.$root.$on('bv::dropdown::show', this.hideDropdown);
  },
  methods: {
    getCheckboxValue(item) {
      const value = this.getModelValue(item);
      return this.value.find((modelValue) => modelValue === value);
    },

    getModelValue(item) {
      const value = this.getValue(item, this.modelProperty);
      if (!value) {
        console.error(
          'Failed get model value from item, you should check props model-property',
        );
      }
      return value;
    },
    getValue(item, property = '') {
      if (isString(item) || isNumber(item)) return item;
      if (isObject(item)) {
        return get(item, property);
      }
      throw new Error(
        `Invalid value from items, should be String/Number or Object`,
      );
    },
    getTitleValue(value) {
      return this.getValue(value, this.displayProperty);
    },
    onClickDropDown() {
      this.isOpened = !this.isOpened;
    },
    onSelect(item) {
      const value = this.getModelValue(item);
      const index = this.value.findIndex((modelValue) => modelValue === value);
      if (index > -1) {
        const clonedValue = [...this.value];
        clonedValue.splice(index, 1);
        this.$emit('input', clonedValue);
      } else {
        this.$emit('input', [...this.value, value]);
      }
    },
    checkIsHideDropdown(event) {
      if (!this.$el.contains(event.target)) {
        this.hideDropdown();
      }
    },
    hideDropdown() {
      if (this.isOpened) {
        this.isOpened = false;
        this.$emit('hidden');
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.checkIsHideDropdown);
  },
};
</script>

<style lang="scss" scoped>
.b-selector-with-label.multiselect {
  .multiselect-item__checkbox {
    border: 1px solid #edeef0;
    border-radius: 4px;
    color: #03a9f4;
    height: 20px;
    margin-right: 8px;
    width: 20px;

    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dropdown-toggle {
    text-align: inherit;
    padding-top: 15px;
    padding-left: 14px;
    padding-right: 35px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .dropdown-item {
    display: flex !important;
    align-items: center;
    &:focus {
      background: none !important;
    }
    &:hover {
      .multiselect-item__checkbox {
        border-color: #03a9f4;
      }
    }
    .multiselect-item__title {
      margin-bottom: 3px;
    }
  }
}
</style>
