<template>
  <div class="keys-tab">
    <b-form-row>
      <b-col v-if="isShowKeyTypes" class="mb-3">
        <b-select-label
          id="select-intercom-modal_key_types"
          :items="keyTypes"
          display="label"
          object-property="type"
          :label="$t('devices.tabs.keys.type')"
          v-model="intercom.settings.key_types"
          @input:item="onInputKeyTypes"
          :state="
            validateState ? validateState('settings.key_types') : undefined
          "
          class="my-0"
        />
        <b-error
          v-if="!errors.key_types.required && isSubmit"
          :error="$t('devices.errors.not_empty')"
        />
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col
        v-if="isShowSelectProfile && intercom.settings.key_types"
        class="mb-3"
      >
        <b-select-label
          id="select-intercom-modal_keys_profile_id"
          name="intercom-keys-profiles"
          :items="intercomKeysProfiles"
          display="name"
          object-property="id"
          :label="$t('devices.tabs.keys.keys_profile')"
          v-model="keyProfile"
          :state="
            validateState
              ? validateState('settings.keys_profile_ids')
              : undefined
          "
          @input="selectProfile"
          class="my-0"
          zero-val-active
        />
        <b-error
          v-if="!errors.keys_profile_ids.required && isSubmit"
          :error="$t('devices.errors.not_empty')"
        />
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col
        v-if="isShowMultiSelectProfile && intercom.settings.key_types"
        class="mb-3"
      >
        <b-multi-select
          id="select-intercom-modal_keys_profile_id"
          name="intercom-keys-profiles"
          :items="intercomKeysProfiles"
          display-property="name"
          object-property="id"
          :label="$t('devices.tabs.keys.keys_profile')"
          v-model="intercom.settings.keys_profile_ids"
          :state="
            validateState
              ? validateState('settings.keys_profile_ids')
              : undefined
          "
          class="my-0"
        />
        <b-error
          v-if="!errors.keys_profile_ids.required && isSubmit"
          :error="$t('devices.errors.not_empty')"
        />
        <b-error
          v-if="!errors.keys_profile_ids.maxLength && isSubmit"
          :error="$t('devices.errors.max_length_arr')"
        />
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col v-if="isShowKeysProfiles && intercom.settings.key_types">
        <b-select-label
          id="select-intercom-modal_code_reverse_order"
          name="intercom-code-reverse-order"
          :items="intercomCodeReverseOrders"
          display="label"
          object-property="value"
          :label="$t('devices.tabs.keys.reverse_order.title')"
          v-model="intercom.settings.code_reverse_order"
          boolean-val-active
        />
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import BError from '@/components/base/BError';
import BMultiSelect from '@/components/base/BMultiSelect.vue';
import { ApiIntercomKeysProfiles } from '@/api';

export default {
  name: 'IntercomKeysTab',
  components: {
    BError,
    BMultiSelect,
  },
  props: {
    clientId: {
      type: [Number, String],
      required: true,
    },
    validateState: {
      type: Function,
    },
    isShowKeysProfiles: {
      type: Boolean,
    },
    isMultipleProfiles: {
      type: Boolean,
      default: false,
    },
    isShowKeyTypes: {
      type: Boolean,
      default: false,
    },
    isSubmit: {
      type: Boolean,
    },
    errors: {},
  },
  data() {
    return {
      intercomKeysProfiles: [],
      intercomCodeReverseOrders: [
        { label: this.$t('intercom.code_reverse_order.direct'), value: false },
        { label: this.$t('intercom.code_reverse_order.reverse'), value: true },
      ],
      key_types_mapping: {
        sl1_sl3: ['sl1', 'sl3'],
      },
    };
  },
  computed: {
    ...mapState('intercom', ['intercom', 'intercomModel', 'keyProfile']),
    ...mapGetters('intercom', [
      'getIntercom',
      'getIntercomModel',
      'getKeyProfile',
    ]),
    intercomModel() {
      return this.getIntercomModel;
    },
    intercom: {
      get() {
        return this.getIntercom;
      },
      set(value) {
        this.setIntercom(value);
      },
    },
    keyProfile: {
      get() {
        return this.getKeyProfile;
      },
      set(value) {
        this.setKeyProfile(value);
      },
    },
    keyTypes() {
      if (Number(this.clientId) === 1) {
        return [
          { label: 'SL 1', type: 'sl1' },
          { label: 'SL 3', type: 'sl3' },
          { label: 'SL 1 & SL 3', type: 'sl1_sl3' },
          { label: 'SL 0', type: 'sl0' },
        ];
      } else {
        return [
          { label: 'SL 3', type: 'sl3' },
          { label: 'SL 0', type: 'sl0' },
        ];
      }
    },
    isShowSelectProfile() {
      if (this.isShowKeysProfiles && !this.isMultipleProfiles) {
        if (this.intercom.settings.key_types != 'sl0') {
          return true;
        } else {
          if (this.intercomModel.rabbit_queue === 'access_control_queue') {
            return true;
          }
        }
      }
      return false;
    },
    isShowMultiSelectProfile() {
      if (this.isShowKeysProfiles && this.isMultipleProfiles) {
        if (this.intercom.settings.key_types != 'sl0') {
          return true;
        } else {
          if (this.intercomModel.rabbit_queue === 'access_control_queue') {
            return true;
          }
        }
      }
      return false;
    },
  },
  created() {
    this.getIntercomKeysProfiles();
    if (this.isShowKeysProfiles && !this.isMultipleProfiles) {
      this.keyProfile = this.intercom.settings.keys_profile_ids[0];
    }
  },
  methods: {
    ...mapMutations('intercom', ['setIntercom', 'setKeyProfile']),

    async getIntercomKeysProfiles() {
      let key_types =
        this.key_types_mapping[this.intercom.settings.key_types] ||
        this.intercom.settings.key_types;
      await ApiIntercomKeysProfiles.get({
        q: { key_types_in: key_types },
      })
        .allAsync()
        .then((res) => {
          this.intercomKeysProfiles = res;
        });
    },

    selectProfile(val) {
      this.intercom.settings.keys_profile_ids = new Array();
      this.intercom.settings.keys_profile_ids.push(val);
    },

    async onInputKeyTypes() {
      await this.getIntercomKeysProfiles();

      this.intercom.settings.keys_profile_ids = [];
      this.keyProfile = null;
    },
  },
};
</script>

<style lang="scss">
.keys-tab {
  padding: 0 30px 30px 30px;
}
</style>
