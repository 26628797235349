<template>
  <div
    class="p-table-container2"
    :class="responsive ? 'p-table-container2_responsive' : ''"
    ref="table"
  >
    <div class="p-table-container2__loading" v-if="isLoading">
      <b-spinner variant="primary"></b-spinner>
    </div>
    <slot v-if="items.length" name="content" :items="items"></slot>
    <div v-if="isShowEmptyText" class="empty-items-text">
      <span class="font-weight-bolder">{{ $t('table.no_data') }}</span>
      <a
        @click="onResetFilter"
        v-if="showResetFilter"
        class="ml-2 text-primary pointer"
      >
        {{ $t('table.reset_filter') }}
      </a>
    </div>
    <div
      v-if="pagination.pages > 1"
      class="footer-pagination"
      :class="responsive ? 'footer-pagination_responsive' : ''"
    >
      <b-button
        variant="outline-primary"
        @click="onDownloadNextPage"
        :disabled="isDisabledDownloadNext"
      >
        {{ $t('table.load_more') }}
      </b-button>
      <b-pagination
        @change="onChangePage"
        v-model="pagination.current"
        :total-rows="pagination.count"
        :per-page="perPage"
        aria-controls="my-table"
        :disabled="isLoading"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import _, { sortBy } from 'lodash';
import { BPagination } from 'bootstrap-vue';

function defaultPagination() {
  return {
    count: 0,
    current: 1,
    pages: 1,
    next: 1,
  };
}

export default {
  name: 'PTable2',
  components: {
    BPagination,
  },
  props: {
    api: { type: Object, required: true },
    queryParams: Object,
    searchParams: [Object || String],
    mutation: String,
    perPage: { type: String, default: '25' },
    selectedId: {
      type: Number,
    },
    searchDebounce: {
      type: Number,
      default: 200,
    },
    showResetFilter: {
      type: Boolean,
      default: false,
    },
    useRouteQuery: {
      type: Boolean,
      default: false,
    },
    namePageQuery: {
      type: String,
      default: 'page',
    },
    localSortBy: {
      type: [String, Array],
    },
    converter: {
      type: Function,
      default: null,
    },
    responsive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const pagination = defaultPagination();
    return {
      items: [],
      pagination,
      isFirstLoad: false,
      isConcat: false,
      isLoading: false,
      searchTimeout: null,
    };
  },
  computed: {
    isDisabledDownloadNext() {
      return (
        !(this.pagination.current < this.pagination.pages) || this.isLoading
      );
    },
    getQueryParams() {
      if (this.queryParams && this.queryParams.q) {
        return this.queryParams;
      }
      return { q: { ...this.queryParams } };
    },
    isShowEmptyText() {
      return this.isFirstLoad && this.items.length === 0;
    },
  },
  async mounted() {
    await this.getItems();
    this.isFirstLoad = true;
  },
  methods: {
    onChangePage(page) {
      if (page === this.pagination.current) return;
      if (this.useRouteQuery) {
        this.$router.push({
          query: { ...this.$route.query, ...{ [this.namePageQuery]: page } },
        });
      } else {
        this.getItems(page);
      }
    },
    onDownloadNextPage() {
      this.isConcat = true;
      this.onChangePage(this.pagination.current + 1);
    },
    async getItems(
      page = this.$route.query[this.namePageQuery] || 1,
      isConcat,
    ) {
      this.isLoading = true;
      await this.$nextTick();
      try {
        const params = _.cloneDeep(this.getQueryParams);
        if (this.searchParams instanceof Object) {
          Object.assign(params.q, this.searchParams);
        }
        const { data, pagination } = await this.api.getPagination({
          ...params,
          per_page: this.perPage,
          page,
        });
        const items = this.isConcat ? this.items.concat(data) : data;
        this.items = this.localSortBy ? sortBy(items, this.localSortBy) : items;
        this.isConcat = false;
        this.pagination = pagination;
      } catch (e) {
        this.$bvToast.toast(e, { variant: 'danger' });
      }
      this.isLoading = false;
      await this.$nextTick();
      this.selectRow();
      this.$emit('loaded', this.items);
    },
    onResetFilter() {
      this.$emit('reset-filter');
    },
    selectRow() {
      if (this.selectedId) {
        const index = this.items.findIndex(
          (item) => item.id === this.selectedId,
        );
        if (index > -1 && this.$children[0])
          this.$children.find((children) => {
            if (children.selectRow) {
              children.selectRow(index);
              return true;
            }
            return false;
          });
      }
    },
    refresh() {
      // this.items = [];
      this.pagination = defaultPagination();
      this.getItems();
    },
  },
  watch: {
    queryParams: {
      deep: true,
      handler(value, oldValue) {
        if (!_.isEqual(value, oldValue)) {
          this.refresh();
        }
      },
    },
    searchParams: {
      deep: true,
      handler(value, oldValue) {
        if (this.searchTimeout) clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          if (!_.isEqual(value, oldValue)) {
            this.refresh();
          }
        }, this.searchDebounce);
      },
    },
    items: {
      deep: true,
      handler() {
        if (this.converter) {
          return this.converter(this.items);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/components/variables';

.table-preloader {
  position: absolute;
  height: 100%;
  width: 100%;
  background: silver;
  opacity: 0.1;
  z-index: 9;
}
.footer-pagination {
  display: flex;
  justify-content: center;
  .btn {
    height: 33px;
    margin-right: 20px;
    border-radius: 5px;
  }
  &_responsive {
    flex-wrap: wrap;
    gap: 10px;
  }
}
.empty-items-text {
  color: $color-table-th;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-table-container2 {
  min-height: 35px;
  position: relative;
  .p-table-container2__loading {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.6);
  }
  &_responsive {
    overflow-x: auto;
  }
}
</style>
