export const PERMISSIONS = {
  Owner: 'Owner', // should be first
  NprManager: 'NprManager',
  CctvManager: 'CctvManager',
  CctvViewer: 'CctvViewer',
  FaManager: 'FaManager',
  FaViewer: 'FaViewer',
  IntercomManager: 'IntercomManager',
  IntercomViewer: 'IntercomViewer',
  MeteringManager: 'MeteringManager',
  MeteringViewer: 'MeteringViewer',
  SaManager: 'SaManager',
  SaViewer: 'SaViewer',
};

export const PERMISSIONS_ARRAY = Object.values(PERMISSIONS);

export default PERMISSIONS;
