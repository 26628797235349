<template>
  <div>
    <slot
      :device="device"
      :camera-model="model"
      :select-model="selectModel"
      :clear-model="clearModel"
      :camera-model-error="cameraModelError"
      :geo-unit="geoUnit"
      :channel-id-required="channelIdRequired"
      :select-gu="selectGu"
      :clear-gu="clearGu"
      :geo-unit-error="geoUnitError"
      :mqtt-supported="mqttSupported"
    ></slot>
  </div>
</template>
<script>
import { isEmpty } from 'lodash';
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete';
import CamerasModelsAutocomplete from '@/components/autocompletes/CamerasModelsAutocomplete';
import BFormGroupSlot from '@/components/base/BFormGroupSlot';

export default {
  name: 'CctvCamerasForm',
  components: {
    GeoUnitsAutocomplete,
    CamerasModelsAutocomplete,
    BFormGroupSlot,
  },
  props: {
    deviceData: Object,
    clientIdData: Number,
    errors: Object,
    cameraModelData: {},
    modelData: {},
    geoUnit: {},
  },
  data() {
    return {
      device: this.deviceData,
      clientId: this.clientIdData,
      model: this.cameraModelData,
    };
  },
  async mounted() {
    if (!isEmpty(this.modelData))
      this.model = await request3
        .get(API_URLS.backendManage.camerasModels.fetch(this.clientId), {
          params: {
            q: {
              id_eq: this.modelData.id,
            },
          },
        })
        .then((res) => res.data[0]);
  },
  computed: {
    mqttSupported() {
      if (!this.model) {
        return false;
      } else {
        return Boolean(this.model.mqtt_supported);
      }
    },
    channelIdRequired() {
      if (!this.model) {
        return false;
      } else {
        return Boolean(this.model.channel_id_required);
      }
    },
    cameraModelError() {
      if (isEmpty(this.errors)) {
        return undefined;
      } else {
        if (this.errors.cameras_model_id) {
          return !this.errors.cameras_model_id.length > 0;
        }
        if (this.errors.model) {
          return !this.errors.model.length > 0;
        }
      }
      return undefined;
    },
    geoUnitError() {
      if (isEmpty(this.errors)) {
        return undefined;
      } else {
        if (this.errors.geo_unit) {
          return !this.errors.geo_unit.length > 0;
        }
      }
      return undefined;
    },
  },
  methods: {
    selectModel(model) {
      this.model = model;
      this.device.cameras_model_id = this.model.id;
    },
    clearModel() {
      this.model = null;
      this.device.cameras_model_id = null;
    },
    selectGu(id) {
      this.device.geo_unit_id = id;
    },
    clearGu() {
      this.device.geo_unit_id = null;
    },
  },
};
</script>
