<template>
  <div class="empty-items-text portlet">
    <span class="font-weight-bolder">{{ $t("table.no_data") }}</span>
    <a
      @click="onResetFilter"
      v-if="showResetFilter"
      class="ml-2 text-primary pointer"
    >
      {{ $t("table.reset_filter") }}
    </a>
  </div>
</template>

<script>
import _ from 'lodash';
import { BPagination } from 'bootstrap-vue';

function defaultPagination() {
  return {
    count: 0,
    current: 1,
    pages: 1,
    next: 1,
  };
}

export default {
  name: 'ResetFilters',
  components: {
    BPagination,
  },
  props: {
    queryParams: Object,
    searchDebounce: {
      type: Number,
      default: 200,
    },
    showResetFilter: {
      type: Boolean,
      default: false,
    },
    saveFormId: String,
    requireQuery: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      url: new URL(location.href),
    }
  },
  computed: {
    urlSearchParams() {
      return new URLSearchParams(window.location.search)
    },
    params() {
      return Object.fromEntries(this.urlSearchParams.entries())
    },
    filtered() {
      if(this.requireQuery?.length > 0 && this.params) {
        return Object.keys(this.params)
          .filter(key => this.requireQuery.includes(key))
          .reduce((obj, key) => {
              obj[key] = this.params[key];
              return obj;
        }, {});
      } else 
        return null
    },
  },
  methods: {
    onResetFilter() {
      const url = new URL(window.location.origin + window.location.pathname)
      if(this.filtered) {
        url.search = new URLSearchParams(this.filtered)
      }

      window.location.replace(url)
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/components/variables';

.empty-items-text {
  color: $color-table-th;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
