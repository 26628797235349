import _ from 'lodash';
import { Request } from '../request';

// eslint-disable-next-line import/no-cycle
import { ApiGeoUnitsClients } from '../index';

export default class ApiGeoUnitsRequest extends Request {
  /**
   * @param {GeoUnit} data
   * @param {number|string} [clientId]
   * */
  async findOrCreate(data, clientId) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const findResponse = await this.getPagination({
        q: {
          type_eq: data.type,
          name_eq: data.name,
          parent_id_eq: data.parent_id,
        },
      });
      if (findResponse.data.length > 1) {
        reject(Error('error in params'));
      }
      if (findResponse.data[0]) {
        const [geo] = findResponse.data;
        if (clientId) {
          ApiGeoUnitsClients.getPagination({
            q: { client_id_eq: clientId, geo_unit_id_eq: geo.id },
          })
            .then((connect) => {
              if (connect.data.length) {
                resolve(geo);
              } else {
                ApiGeoUnitsClients.create({
                  client_id: clientId,
                  geo_unit_id: geo.id,
                })
                  .then(() => resolve(geo))
                  .catch(() => reject(Error('error connection to found geo')));
              }
            })
            .catch((error) => reject(error));
        }
      } else {
        this.create(data)
          .then((createdGeo) => {
            if (clientId) {
              ApiGeoUnitsClients.create({
                client_id: clientId,
                geo_unit_id: createdGeo.id,
              })
                .then(() => resolve(createdGeo))
                .catch(() => reject(Error('error connection to create geo')));
            }
          })
          .catch((error) => reject(error));
      }
    });
  }

  /**
   * @param {GeoUnit[]} addresses
   * @param {number|string} [clientId]
   * */
  async findGeoBuyAddress(addresses = [], clientId) {
    const filteredAddresses = addresses.filter((address) => address);
    // console.log('filteredAddresses', filteredAddresses);
    let parents = [filteredAddresses[0]];
    for (let i = 0; i < filteredAddresses.length; i += 1) {
      const requests = parents.map((val) =>
        this.getAll({
          q: {
            name_eq: filteredAddresses[i].name,
            type_eq: filteredAddresses[i].type,
            city_name_eq: filteredAddresses[i].city,
            parent_id_family_equal: val.id,
            geo_units_clients_client_id_eq: clientId,
          },
        }),
      );
      // eslint-disable-next-line no-await-in-loop
      const geoUnitsGroups = await Promise.all(requests);
      parents = _(geoUnitsGroups).flatten().uniqBy('id').value();
    }
    return parents;
  }

  async updateUnitStructure(unit, client) {
    const body = {
      client_id: client,
      data: unit,
    };
    return this.update(unit.id, body, 'structure');
  }
}
