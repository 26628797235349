<template>
  <div class="uspd-devices-autocomplete">
    <BDropdownInput
      v-model="selected_id"
      :description="description"
      :disabled="disabled"
      :label="placeholder"
      :handler="fetch"
      :selected="uspd"
      :state="state"
      :showLoading="showLoading"
      :variantSpinner="variantSpinner"
      :debouncedTts="debouncedTts"
      compare-property="id"
      display-property="serial"
      @clear="onClear"
      @focus="onFocus"
      @select="onSelect"
      @select:item="onSelectItem"
      :popoverText="popoverText"
      :popoverHint="popoverHint"
      :hintId="hintId"
      :minWordLength="minWordLength"
    />
  </div>
</template>

<script>
import BDropdownInput from '@/components/base/BDropdownInput.vue';

export default {
  name: 'uspdDevicesAutocomplete',
  components: {
    BDropdownInput,
  },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
    debouncedTts: {
      type: Number,
      default: 200,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    popoverText: {
      type: String,
      default: 'i',
    },
    popoverHint: {
      type: String,
      default: null,
    },
    hintId: {
      type: String,
      default: null,
    },
    placementPopover: {
      type: String,
      default: 'bottom',
    },
    minWordLength: {
      type: Number,
      default: 3,
    },
    geoUnitId: Number,
    placeholder: String,
    state: {},
    uspd: Object,
    showLoading: {
      type: Boolean,
      default: true,
    },
    variantSpinner: {
      type: String,
      default: 'primary',
    },
    description: {
      type: String,
    },
  },
  data() {
    return {
      selected_id: null,
    };
  },
  mounted() {
    if (this.uspd instanceof Object) {
      this.selected_id = this.uspd.id;
    }
  },
  methods: {
    saveForm() {
      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    fetch(val) {
      let prms = {
        geo_unit_id: this.geoUnitId,
        serial: val,
        limit: 8,
      };

      return request2(
        {
          method: 'get',
          url: `/api/backend/manage/${this.clientId}/uspd_devices`,
        },
        prms,
      ).then((r) => {
        return r.uspd_devices;
      });
    },
    onClear() {
      this.selected_id = null;
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
    onSelectItem(item) {
      this.selected_id = item.id;
      this.$emit('select:item', item);
    },
    onSelect(value) {
      this.selected_id = value;
      this.$emit('input', value);
      this.$emit('select', value);
    },
  },
  watch: {
    value(val) {
      if (!val) {
        this.geo_unit = null;
        this.selected_id = null;
      }
    },
  },
};
</script>
