<template>
  <div class="admin-global-search">
    <form autocomplete="off" class="dropdown admin-global-search-input">
      <BInputLabel
        v-model="word"
        @keyup="onKeyUp"
        type="text"
        :label="input_label"
        ref="word"
        class="admin-global-search-results-dropdown mb-0"
      />
      <div class="close-icon-container">
        <i class="icon-local-times" v-if="!empty_word" @click="word = ''"></i>
      </div>
      <div v-show="word.length > 0" class="dropdown-menu py-0">
        <div v-if="!user_loading && !geo_unit_loading">
          <h3 class="bg-light border-bottom m-0 py-3 px-4">Пользователи</h3>
          <div v-if="users_results && users_results.length > 0">
            <div
              v-for="user in users_results"
              :key="user.id"
              @click="choosed('users', user)"
              :data-id="user.id"
              :data-name="user.name"
              class="dropdown-item"
            >
              <span class="text-truncate">
                {{ `#${user.id} ${user.short_name} ${user.phone || ''}` }}
              </span>
            </div>
          </div>
          <div v-else class="d-flex justify-content-left m-2 ml-4">Не найдено</div>
          <h3 class="bg-light border-bottom m-0 py-3 px-4">Гео Юниты</h3>
          <div v-if="geo_unit_results && geo_unit_results.length > 0">
            <div
              v-for="geo_unit in geo_unit_results"
              :key="'geo_unit' + geo_unit.id"
              @click="choosed('geo_units', geo_unit)"
              :data-id="geo_unit.id"
              :data-name="geo_unit.short_name"
              class="dropdown-item"
            >
              <span class="text-truncate">{{ geo_unit.post_name }}</span>
            </div>
          </div>
          <div v-else class="d-flex justify-content-left m-2 ml-4">Не найдено</div>
        </div>
        <div v-else class="d-flex justify-content-center m-3">
          <b-spinner></b-spinner>
        </div>
      </div>
    </form>
    <div
      v-show="!empty_results"
      @click="word = ''"
      class="admin-global-search-wrapper"
    ></div>
  </div>
</template>

<script>
import { isEmpty, isNull, debounce } from 'lodash';
import BInputLabel from '@/components/base/BInputLabel';
import { request2 } from '@/api';

export default {
  name: 'adminGlobalSearch',
  components: {
    BInputLabel,
  },
  props: {
    input_label: {
      type: String,
      default: 'Global search',
    },
  },
  data() {
    return {
      word: '',
      users_results: [],
      geo_unit_results: [],
      geo_unit_loading: false,
      user_loading: false,
    };
  },
  computed: {
    empty_results() {
      return isEmpty(this.results);
    },
    empty_word() {
      return this.word < 1;
    },
    results() {
      return this.users_results.concat(this.geo_unit_results);
    },
    encodedWord() {
      return encodeURIComponent(this.word);
    },
    encodedNumber() {
      const numbers = this.word.replace(/[^\d]/g, '');
      return numbers.length === 0 ? undefined : encodeURIComponent(numbers);
    },
  },
  mounted() {
    this.initDebounce();
  },
  methods: {
    choosed(path, result) {
      const win = window.open(`/admin/${path}/${result.id}`, '_blank');
      win.focus();
    },
    onKeyUp() {
      this.debouncedSearch();
    },
    resultsReset() {
      this.users_results = [];
      this.geo_unit_results = [];
    },
    async getGeoUnits() {
      this.geo_unit_loading = true;
      let params = {
        q: {
          post_name_cont_all: this.encodedWord,
          limit: 5,
        },
      };
      const geo_unit_results = await request2(
        {
          method: 'get',
          url: `/api/backend/admin/autocomplete/geo_units/`,
        },
        params,
      );
      this.geo_unit_results = geo_unit_results;
      this.geo_unit_loading = false;
    },
    async getUsers() {
      this.user_loading = true;
      const params = {
        q: {
          sep_id_eq: this.encodedNumber,
          phone_cont: this.encodedNumber,
          name_or_surname_or_patronymic_cont_any: this.word.split(' '),
          m: 'or',
          limit: 5,
        },
      };
      const users_results = await request2(
        {
          method: 'get',
          url: `/api/backend/admin/autocomplete/users/`,
        },
        params,
      );

      this.users_results = users_results;
      this.user_loading = false;
    },
    search() {
      if (isEmpty(this.word)) return;
      this.getUsers();
      this.getGeoUnits();
    },
    resultsOpen() {
      $('.admin-global-search-results-dropdown').dropdown('show');
      this.$refs.word.focus();
    },
    resultsClose() {
      $('.admin-global-search-results-dropdown').dropdown('hide');
      this.$refs.word.focus();
    },
    initDebounce() {
      this.debouncedSearch = debounce(() => {
        this.search();
      }, 400);
    },
  },
  watch: {
    word(value) {
      if (isNull(value) || isEmpty(value)) {
        this.resultsReset();
      }
    },
    results(value) {
      this.resultsOpen();
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/styles/components/variables';
.admin-global-search {
  width: 100%;
  position: relative;

  @media (min-width: 1024px) {
    width: 75%;
  }

  @media (min-width: 1440px) {
    width: 50%;
  }

  .close-icon-container {
    padding: 5px;
    cursor: pointer;
    position: absolute;
    right: 13px;
    top: 13px;
  }

  &-input {
    &-active {
      position: relative;
      z-index: 101;
    }
  }

  &-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    height: 100vh;
    cursor: pointer;
    background: #6d7e86;
    opacity: 0.2;
    transition: opacity 0.2s ease-in-out;
  }

  fieldset {
    width: 100%;
  }
  .dropdown-menu {
    overflow-y: auto;
    max-height: calc(100vh - 70px - 30px);
    .dropdown-item {
      border-top: 1px solid #edeef0;
      height: 44px;
      cursor: pointer;
      display: flex;
      align-items: center;
      &:first-child {
        border-top: none;
      }
      &:hover {
        background: $table-hover-bg;
      }
    }
  }
}
</style>
