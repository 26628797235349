import Vue from 'vue/dist/vue.esm';
import { ToastPlugin } from 'bootstrap-vue';

const configBootstrapVue = {
  BToast: {
    toaster: 'b-toaster-bottom-right',
    variant: 'danger',
  },
};

Vue.use(ToastPlugin, configBootstrapVue);
const EventBus = new Vue();
export default EventBus;
