<template>
  <div>
    <slot
      :docs-to-delete="docsToDelete"
      :coords="coords"
      :change-coords="changeCoords"
      :ymaps-coords="ymapsCoords"
      :ya-map-lang="yaMapLang"
    ></slot>
  </div>
</template>
<script>
import BFormGroupSlot from '@/components/base/BFormGroupSlot.vue';
import SelectSlot from '@/vue_slim/components/base/SelectSlot.vue';
import { YaMapLangMixin } from '@app/mixins';

export default {
  name: 'ManageGeoUnitForm',
  components: {
    BFormGroupSlot,
    SelectSlot,
  },
  mixins: [YaMapLangMixin],
  props: {
    coordsData: Object,
  },
  computed: {
    ymapsCoords() {
      return [this.coords.lat, this.coords.lng];
    },
  },
  data() {
    return {
      coords: this.coordsData,
      docsToDelete: [],
    };
  },
  methods: {
    changeCoords(event) {
      const [lat, lng] = event.get('coords');
      this.coords = { lat: lat, lng: lng };
    },
    deleteDoc(id) {
      this.docsToDelete.push(id);
    },
  },
};
</script>
