<template>
  <div>
    <b-modal
      :title="typeTitle"
      id="intercom-edit-modal"
      modal-class="fast-modal"
      header-border-variant="transparent"
      ref="editModal"
      size="lg"
      @hidden="hide"
    >
      <b-form-validate
        ref="IntercomForm"
        :model="intercom"
        :validations="validations"
      >
        <template slot-scope="{ validateState, v }">
          <IntercomTabs
            v-if="!loading"
            :validate-state="validateState"
            :v="v"
            :intercom-models="actualModels"
            :client-id="clientId"
            :actual-servers="sipServers"
            :is-show-keys-profiles="isShowKeysProfiles"
            :is-requred-uuid="isRequredUuid"
            :is-required-ip-address="isRequiredIpAddress"
            :is-required-mac-address="isRequiredMacAddress"
            :is-required-select-sip-server="isRequiredSelectSipServer"
            :is-show-control-panel="isRequiredControlPanel"
            :is-show-call-button="isShowCallButton"
            :is-multiple-profiles="isMultipleProfiles"
            :is-show-key-types="isRequiredKeyTypes"
            :is-submit="isSubmit"
            :backend-errors="backendErrors"
          />
          <div v-else class="fast-modal__spinner">
            <b-spinner variant="primary"></b-spinner>
          </div>
        </template>
      </b-form-validate>
      <template #modal-footer>
        <div
          class="d-flex align-items-center justify-content-between flex-grow-1"
        >
          <b-button
            @click="onCancel"
            size="sm"
            variant="outline-primary"
            class="fast-modal__btn w-auto h-auto"
          >
            {{ $t('devices.modal.cancel') }}
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="fast-modal__btn w-auto h-auto"
            @click="onOk"
          >
            {{ $t('devices.modal.save') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import EventBus from '@/packs/EventBus';
import IntercomTabs from './tabs/IntercomTabs.vue';
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import { EVENTS } from '@/consts';
import {
  required,
  requiredIf,
  requiredUnless,
  helpers,
  ipAddress,
  maxLength,
} from 'vuelidate/lib/validators';
import { ApiBackendIntercoms } from '@/api';
import { isDigits } from '@/helpers/lodash';

export default {
  name: 'IntercomEditModal',
  components: {
    IntercomTabs,
  },
  props: {
    clientId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      isSubmit: false,
      prevIntercom: {},
      loading: true,
      backendErrors: null,
    };
  },
  computed: {
    ...mapState('intercom', ['intercomModel', 'intercomModels', 'sipServers']),
    ...mapGetters('intercom', [
      'getIntercom',
      'getIntercomModel',
      'getIntercomModels',
      'getActualSipServers',
    ]),
    validations() {
      return {
        name: { required, maxLength: maxLength(70) },
        intercom_model_id: { required },
        sip_server_id: {
          required: requiredIf(() => this.isRequiredSelectSipServer),
        },
        macaddr: {
          required: requiredIf(() => this.isRequiredMacAddress),
        },
        ip_address: {
          required: requiredIf(() => this.isRequiredIpAddress),
          ipAddress,
        },
        control_panel_id: {
          required: requiredIf(() => this.isRequiredControlPanel),
        },
        serial: {
          required: requiredIf(
            () =>
              this.isRequredUuid &&
              (!this.intercom.uuid || !this.intercom.uuid.length),
          ),
        },
        geo_unit_id: { required },
        uuid: {
          required: requiredIf(
            () =>
              this.isRequredUuid &&
              (!this.intercom.serial || !this.intercom.serial.length),
          ),
        },
        settings: {
          password: {
            required: requiredIf(() => this.isPanelQueueFields),
          },
          username: {
            required: requiredIf(() => this.isPanelQueueFields),
          },
          key_types: {
            required: requiredIf(() => this.isRequiredKeyTypes),
          },
          // С бэка: key_profiles_required = intercom.keys_profiled? && !intercom.settings.key_types_sl0? && intercom.model.access_control_queue?
          keys_profile_ids: {
            required: requiredIf(
              () =>
                this.intercom.key_profiles_required ||
                (this.intercom.settings.key_types != 'sl0' &&
                  this.isShowKeysProfiles),
            ),
            maxLength: this.isMultipleProfiles ? maxLength(6) : false,
          },
          proxy_schema: {
            $each: {
              prefix: {
                isInteger: (val) => {
                  return !helpers.req(val) || isDigits(val);
                },
              },
              range: {
                required,
                isRange: (value) => {
                  let range = value.split('-').map((i) => Number(i.trim()));
                  if (range.length == 2) {
                    range = range.filter((i) => isDigits(i));
                  } else return false;
                  return range[0] <= range[1];
                },
              },
              ip: {
                required,
                ipAddress,
              },
            },
          },
        },
        relays_attributes: {
          required,
          $each: {
            index: {
              required,
              isUnique: this.isUniqueRelaysIndex,
            },
            geo_unit_id: { required: requiredUnless((val) => val._destroy) },
            name: {
              required: requiredUnless((val) => val._destroy),
              isUnique: this.isUniqueRelaysName,
            },
            relays_geo_units_attributes: {
              $each: {
                geo_unit_id: {
                  required: requiredUnless((val) => val._destroy),
                },
              },
            },
          },
        },
      };
    },
    intercom() {
      return this.getIntercom;
    },
    intercomModel() {
      return this.getIntercomModel;
    },
    intercomModels() {
      return this.getIntercomModels;
    },
    sipServers() {
      return this.getActualSipServers;
    },
    isRequiredSelectSipServer() {
      return Boolean(
        this.sipServers.length && this.intercomModel?.type === 'call_panel',
      );
    },
    isRequiredMacAddress() {
      return this.intercomModel?.tags?.includes('required_macaddr');
    },
    isRequredUuid() {
      return this.intercomModel?.tags?.includes('required_uuid_or_serial');
    },
    isShowKeysProfiles() {
      return this.intercomModel?.tags?.includes('keys_profiled');
    },
    isMultipleProfiles() {
      return this.intercomModel?.tags?.includes('multiple_keys_profiles');
    },
    isShowIpAddress() {
      return (
        this.isRequiredIpAddress ||
        this.intercomModel?.tags?.includes('ip_address')
      );
    },
    isPanelQueueFields() {
      return (
        this.intercomModel?.type === 'call_panel' &&
        !(this.intercomModel?.rabbit_queue === 'access_control_queue')
      );
    },
    isRequiredIpAddress() {
      return [
        'ip_address_required',
        'config_with_exchange',
        'correspond_table',
      ].some((tag) => this.intercomModel?.tags?.includes(tag));
    },
    isRequiredControlPanel() {
      return this.intercomModel?.tags?.includes('rusguard_cloud');
    },
    isRequiredKeyTypes() {
      return !this.intercomModel?.tags?.includes('no_key_types');
    },
    isShowCallButton() {
      return this.intercomModel?.tags?.some((tag) =>
        ['is_analog', 'proxy_call'].includes(tag),
      );
    },
    actualModels() {
      if (this.intercomModel?.type) {
        return this.intercomModels.filter(
          (model) => this.intercomModel.type === model.type,
        );
      } else return this.intercomModels;
    },
    typeTitle() {
      if (this.intercomModel?.type) {
        if (this.intercomModel.type == 'call_panel') {
          return this.$t('devices.modal.settings.title.call_panel');
        } else return this.$t('devices.modal.settings.title.access_control');
      } else return this.$t('devices.modal.settings.title.device');
    },
    getRelayIndexes() {
      if (this.intercom.relays_attributes) {
        return this.intercom.relays_attributes.map((val) =>
          val._destroy ? null : Number(val.index),
        );
      } else return [];
    },
    getRelayNames() {
      if (this.intercom.relays_attributes) {
        return this.intercom.relays_attributes.map((val) =>
          val._destroy ? null : val.name,
        );
      } else return [];
    },
  },
  methods: {
    ...mapMutations('intercom', [
      'setIntercom',
      'setIntercomModel',
      'setInitRelays',
      'convertProxy',
      'parseProxySchema',
      'setMode',
    ]),
    ...mapActions('intercom', ['update', 'getSipServers']),

    onOk(event) {
      if (event) event.preventDefault();
      this.isSubmit = true;

      if (this.$refs.IntercomForm.validate()) {
        this.updateDevice();
      }
    },
    async show(device) {
      if (device?.id) {
        this.backendErrors = null;
        this.loading = true;
        this.$refs.editModal.show();

        const intercomPromise = new Promise((resolve, reject) =>
          ApiBackendIntercoms.getOne(device.id)
            .then((resp) => {
              this.setIntercom(resp);
              this.setIntercomModel(resp.model);
              this.initRelays(Object.assign([], resp.relays));
              this.parseProxySchema();
              resolve();
            })
            .catch(() => reject()),
        );
        const sipPromise = new Promise((resolve, reject) =>
          this.getSipServers()
            .then(() => {
              resolve();
            })
            .catch(() => reject()),
        );

        Promise.all([intercomPromise, sipPromise])
          .then(() => {
            if (!this.intercom.configured) {
              this.isSubmit = true;
              if (this.$refs.IntercomForm) {
                this.$refs.IntercomForm.validate();
              }
            } else {
              this.isSubmit = false;
            }
            this.prevIntercom = JSON.parse(JSON.stringify(this.intercom));
            this.loading = false;
          })
          .catch((error) => {
            this.$bvToast.toast('Произошла ошибка! Попробуйте еще раз.');
          });
      }
    },
    hide() {
      this.isSubmit = false;
      if (
        JSON.stringify(this.prevIntercom) === JSON.stringify(this.intercom) ||
        this.loading
      ) {
        this.$refs.editModal.hide();
      } else {
        this.$bvModal
          .msgBoxConfirm(`${this.$tc('devices.close.setting')}`, {
            title: this.$t('devices.close.title'),
            okTitle: this.$t('devices.close.buttons.yes'),
            cancelTitle: this.$t('devices.close.buttons.no'),
            okVariant: 'danger',
            bodyClass: 'modal-body__confirm',
          })
          .then((res) => {
            if (res) {
              this.$refs.editModal.hide();
              this.prevIntercom = {};
            } else {
              this.$refs.editModal.show();
              setTimeout(() => {
                if (!this.intercom.configured) {
                  this.isSubmit = true;
                  this.$refs.IntercomForm.validate();
                }
              }, 300);
            }
          });
      }
    },
    onCancel() {
      this.$refs.editModal.hide();
    },
    updateDevice() {
      EventBus.$emit(
        EVENTS.preloader.show,
        this.$t('devices.tabs.messages.wait'),
      );
      this.convertProxy();
      this.update(this.intercom)
        .then(() => {
          this.$bvModal.msgBoxOk(this.$t('devices.tabs.messages.success'), {
            modalClass: 'modal-ok',
            okTitle: this.$t('button.ok'),
            title: this.$t('title.successfully'),
          });
          this.prevIntercom = JSON.parse(JSON.stringify(this.intercom));
          EventBus.$emit(EVENTS.intercom.update);
          this.hide();
        })
        .catch((response) => {
          this.parseProxySchema();

          this.backendErrors = response.errors;
          if (this.backendErrors['base']) {
            this.$bvToast
              .toast(Object.values(this.backendErrors['base']))
              .join('. ');
          }
        })
        .finally(() => {
          EventBus.$emit(EVENTS.preloader.hide);
        });
    },
    initRelays(relays) {
      this.setInitRelays(relays);
    },
    isUniqueRelaysIndex(index) {
      const duplicates = this.getDuplicates(this.getRelayIndexes);
      return !duplicates.includes(index);
    },
    isUniqueRelaysName(name) {
      const duplicates = this.getDuplicates(this.getRelayNames);
      return !duplicates.includes(name);
    },
    getDuplicates(arr) {
      const map = new Map();
      for (const el of arr) {
        const count = map.get(el);
        map.set(el, (count ?? 0) + 1);
      }

      return Array.from(map.entries()).reduce((res, [el, count]) => {
        if (count > 1) {
          res.push(el);
        }
        return res;
      }, []);
    },
  },
};
</script>
