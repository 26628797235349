<template>
  <b-button variant="primary" @click="openPlateModal">{{ placeholder }}</b-button>
</template>

<script>
import EventBus from '@/packs/EventBus';
import i18n from '@app/i18n';

export default {
  name: 'PlateCreateLink',
  props: {
    plate: {
      type: Object,
      default: null
    },
    placeholder: {
      type: String,
      default: i18n.t('global.address')
    }
  },
  methods: {
    openPlateModal() {
      EventBus.$emit('PlateModal::show', this.plate, true);
    }
  }
}
</script>