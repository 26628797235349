<template>
  <div class="file-uploader">
    <div class="d-flex justify-content-center align-items-center gap-3">
      <label
        :for="inputId"
        class="btn col-3 btn-primary file-uploader__btn btn-xs d-flex justify-content-center align-items-center mb-0"
      >
        <span>{{ $t('button.select') }}</span>
      </label>
      <div class="w-100" v-if="!isUploaded">
        {{ $t('file.file_not_chosen') }}
      </div>
      <div class="w-100 file-uploader__name" v-else>
        {{ fileName }}
      </div>
    </div>
    <input
      class="hidden"
      :id="inputId"
      type="file"
      :name="name"
      @change="handleFile"
      @click="clearInput"
    />
    <div class="small text-danger" v-if="!!errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BFormFileImg',
  props: {
    inputId: {
      type: String,
      default: 'image-upload',
    },
    name: String,
    defaultSrc: {
      type: String,
      default: '/img/Img.svg',
    },
    currentSrc: String,
    size: {
      default: '150',
    },
    //макс размер в МБ
    maxSize: {
      default: '5',
    },
    //мин размер в Б
    minSize: {
      default: '10',
    },
  },
  data() {
    return {
      srcImg: '',
      errorMessage: '',
      isUploaded: false,
      fileName: '',
    };
  },
  computed: {
    maxSizeInByte() {
      return this.maxSize * 1048576;
    },
  },
  mounted() {
    this.srcImg = this.currentSrc || this.defaultSrc;
    if (this.currentSrc) {
      this.isUploaded = true;
      this.fileName = this.currentSrc;
    }
  },
  methods: {
    handleFile(event) {
      let file = event.target.files[0];
      if (file) {
        if (!['image/png', 'image/jpeg'].includes(file.type)) {
          this.errorMessage = this.$t('file.error_format');
          this.clearInput();
        } else if (file.size > this.maxSizeInByte) {
          this.errorMessage = this.$t('file.error_size_too_big', [
            this.maxSize,
          ]);
          this.clearInput();
        } else if (file.size < this.minSize) {
          this.errorMessage = this.$t('avatar.error_size_too_small', [
            this.minSize,
          ]);
          this.clearInput();
        } else {
          this.errorMessage = '';
          this.isUploaded = true;
          this.srcImg = URL.createObjectURL(file);
          this.fileName = file.name;
        }
      }
    },
    removeImage() {
      this.srcImg = this.defaultSrc;
      this.errorMessage = '';
      this.isUploaded = false;
    },
    clearInput() {
      const input = this.$el.querySelector('input[type="file"]');
      input.value = '';
    },
  },
};
</script>

<style lang="scss">
.file-uploader {
  &__btn {
    width: 40 !important;
    height: 35px !important;

    span {
      line-height: 30px;
    }
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.bolder {
  font-weight: bolder;
}
</style>
