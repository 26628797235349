var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-tabs',{attrs:{"nav-wrapper-class":"intercom-tabs-wrapper","active-tab-class":"intercom-tabs__tab_active","active-nav-item-class":"intercom-tabs__tab_active","nav-class":"intercom-tabs"}},[_c('b-tab',{attrs:{"title-link-class":[
      {
        'intercom-tabs__error': _vm.mainTabErrors && _vm.isSubmit,
      },
      'intercom-tabs__tab',
    ],"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center gap-2"},[_c('i',{staticClass:"icon-local-intercom-tablet"}),_vm._v(" "),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('intercom.table_titles.main')))])])]},proxy:true}])},[_vm._v(" "),_c('intercom-main-setting-tab',{attrs:{"errors":_vm.errors,"client-id":_vm.clientId,"geo-unit-deep-at":_vm.geoUnitDeepAt,"intercom-models":_vm.intercomModels,"validate-state":_vm.validateState,"is-requred-uuid":_vm.isRequredUuid,"is-required-ip-address":_vm.isRequiredIpAddress,"is-required-mac-address":_vm.isRequiredMacAddress,"is-show-control-panel":_vm.isShowControlPanel,"is-submit":_vm.isSubmit,"backend-errors":_vm.backendErrors}})],1),_vm._v(" "),_c('b-tab',{attrs:{"title-link-class":[
      {
        'intercom-tabs__error': _vm.relaysErrors.$invalid && _vm.isSubmit,
      },
      'intercom-tabs__tab',
    ],"lazy":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center gap-2"},[_c('i',{staticClass:"icon-local-door-open"}),_vm._v(" "),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('intercom.table_titles.doors')))])])]},proxy:true}])},[_vm._v(" "),_c('intercom-relays-tab',{attrs:{"errors":_vm.relaysErrors,"client-id":_vm.clientId,"geo-unit-deep-at":_vm.geoUnitDeepAt,"disabled":!_vm.intercomModel,"validate-state":_vm.validateState,"is-submit":_vm.isSubmit}})],1),_vm._v(" "),(_vm.isShowCallButton)?_c('b-tab',{attrs:{"title-link-class":[
      {
        'intercom-tabs__error':
          (_vm.proxyErrors.$invalid || _vm.sipErrors.$invalid) && _vm.isSubmit,
      },
      'intercom-tabs__tab',
    ],"lazy":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center gap-2"},[_c('i',{staticClass:"icon-local-intercom0"}),_vm._v(" "),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('intercom.table_titles.calls')))])])]},proxy:true}],null,false,3013181824)},[_vm._v(" "),_c('intercom-calls-tab',{attrs:{"proxy-errors":_vm.proxyErrors,"sip-errors":_vm.sipErrors,"client-id":_vm.clientId,"validate-state":_vm.validateState,"actual-servers":_vm.actualServers,"is-required-select-sip-server":_vm.isRequiredSelectSipServer,"is-submit":_vm.isSubmit}})],1):_vm._e(),_vm._v(" "),_c('b-tab',{attrs:{"title-link-class":[
      {
        'intercom-tabs__error': _vm.keysErrors && _vm.isSubmit,
      },
      'intercom-tabs__tab',
    ],"lazy":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center gap-2"},[_c('i',{staticClass:"icon-local-key"}),_vm._v(" "),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('intercom.table_titles.keys')))])])]},proxy:true}])},[_vm._v(" "),_c('intercom-keys-tab',{attrs:{"client-id":_vm.clientId,"validate-state":_vm.validateState,"is-show-keys-profiles":_vm.isShowKeysProfiles,"is-multiple-profiles":_vm.isMultipleProfiles,"is-show-key-types":_vm.isShowKeyTypes,"errors":_vm.settingErrors,"is-submit":_vm.isSubmit}})],1),_vm._v(" "),(_vm.intercomButtonsCount)?_c('b-tab',{attrs:{"title-link-class":"intercom-tabs__tab","lazy":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center gap-2"},[_c('i',{staticClass:"icon-local-dots-dots-vertical"}),_vm._v(" "),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('intercom.table_titles.buttons')))])])]},proxy:true}],null,false,4102746099)},[_vm._v(" "),_c('intercom-buttons-tab',{attrs:{"client-id":_vm.clientId,"validate-state":_vm.validateState}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }