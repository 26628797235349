import Store from '@app/store';
import {
  GetFirstUnitInPath,
  TryAppendDummy,
  AddUnit,
  GetDummyParent,
  getUnitById,
} from '@app/components/building_edit/helpers/helpers';

const dummy = document.createElement('div');
dummy.id = 'dummy';
dummy.className = 'dummy';

let listeners;

function addMouseMoveListener(event) {
  if (!Store.state.edit_building.addType) return;
  const path = event.path || (event.composedPath && event.composedPath());
  const firstElement = GetFirstUnitInPath(path);
  if (!firstElement || event.target.id === 'dummy') {
    return;
  }
  const cursorPosition = { x: event.clientX, y: event.clientY };
  const targetType = firstElement.getAttribute('type');
  const elementType = Store.state.edit_building.addType;

  const targetUnit = getUnitById(
    Number(firstElement.id),
    Store.state.edit_building.building,
  );
  if (targetUnit.type === targetUnit.parentType) {
    return;
  }

  TryAppendDummy(firstElement, cursorPosition, elementType, targetType, dummy);
  firstElement.addEventListener('mouseleave', addMouseLeaveListener);
}

function addMouseLeaveListener(event) {
  event.stopPropagation();
  const isTechRoom =
    event.target.getAttribute &&
    event.target.getAttribute('type') === 'TechRoom';
  if (!isTechRoom) {
    removeMouseLeaveListener(event.target);
    return;
  }

  const childContainer = event.target.querySelector(
    '.unit__children-container',
  );
  if (!childContainer) {
    removeMouseLeaveListener(event.target);
    return;
  }

  const children = Array.from(childContainer.children);
  const hasViableChildren =
    children.findIndex(({ id }) => id !== 'dummy') !== -1;

  if (!hasViableChildren) {
    event.target.classList.replace('unit_grouping', 'unit_smallest');
    dummy.remove();
  }
}

function removeMouseLeaveListener(element) {
  element.removeEventListener('mouseleave', addMouseLeaveListener);
}

function addClickListener(event) {
  const path = event.path || (event.composedPath && event.composedPath());
  const clickedParent = GetFirstUnitInPath(path);
  const dummyParent = GetDummyParent(dummy);
  if (!clickedParent || !dummyParent || clickedParent.id !== dummyParent.id) {
    return;
  }

  const nextElement = dummy.nextElementSibling;
  AddUnit(
    Number(dummyParent.id),
    nextElement ? Number(nextElement.id) : null,
    // eslint-disable-next-line no-use-before-define
    addMoveListeners,
  );
  dummy.remove();
  // eslint-disable-next-line no-use-before-define
  removeMoveListeners();
}

export function removeDummy() {
  if (!dummy || !dummy.parentNode) return;
  dummy.remove();
}

export function addMoveListeners() {
  if (listeners) return;
  const element = document.querySelector('.building__container');

  element.addEventListener('mousemove', addMouseMoveListener);
  element.addEventListener('click', addClickListener);
  listeners = true;
}

export function removeMoveListeners() {
  const element = document.querySelector('.building__container');
  if (element) {
    element.removeEventListener('mousemove', addMouseMoveListener);
    element.removeEventListener('click', addClickListener);
  }
  removeDummy();
  listeners = false;
}
