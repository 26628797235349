<template>
  <div class="geo-units-autocomplete-2">
    <BDropdownInput
      v-model="selected_id"
      :description="description"
      :display-property="displayProperty"
      :disabled="disabled"
      :label="label"
      :handler="fetch"
      :selected="geo_unit"
      :state="state"
      :validator="validator"
      :showLoading="showLoading"
      :variantSpinner="variantSpinner"
      :debouncedTts="debouncedTts"
      compare-property="id"
      @clear="onClear"
      @focus="onFocus"
      @select="onSelect"
      @select:item="onSelectItem"
      :popoverText="popoverText"
      :popoverHint="popoverHint"
      :hintId="hintId"
      :minWordLength="minWordLength"
      :comboBox="combo"
    />
  </div>
</template>

<script>
import { ApiBackendGeoUnits } from '@/api';
import BDropdownInput from '@/components/base/BDropdownInput.vue';
import { GEO_UNIT_TYPES_ARRAY } from '@/consts/geo_unit';
import i18n from '@app/i18n';
import { request3 } from '@/api/request';
import { API_URLS } from '@/consts';

export default {
  name: 'GeoUnitsAutocomplete2',
  components: { BDropdownInput },
  props: {
    clientId: {
      type: [Number, String],
    },
    description: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    displayProperty: {
      type: [String, Function],
      default: 'short_name',
    },
    geoUnitDeepAt: {
      type: Array,
    },
    geoUnit: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: () => i18n.t('global.address'),
    },
    hideResetInput: {
      type: Boolean,
    },
    q: {
      type: Object,
    },
    resetInput: {
      type: Boolean,
    },
    state: {
      type: Boolean,
      default: undefined,
    },
    typeEq: {
      type: String,
      validator: (val) => GEO_UNIT_TYPES_ARRAY.includes(val),
    },
    validator: {
      type: Function,
    },
    value: {
      type: Number,
    },
    npr: {
      type: Boolean,
      default: false,
    },
    popoverText: {
      type: String,
      default: 'i',
    },
    popoverHint: {
      type: String,
      default: null,
    },
    hintId: {
      type: String,
      default: null,
    },
    minWordLength: {
      type: Number,
      default: 2,
    },
    showLoading: {
      type: Boolean,
      default: true,
    },
    variantSpinner: {
      type: String,
      default: 'primary',
    },
    debouncedTts: {
      type: Number,
      default: 200,
    },
    comboBox: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      selected_id: null,
      geo_unit: null,
      user_id: this.userId,
    };
  },
  computed: {
    combo() {
      return this.comboBox || Boolean(this.user_id);
    },
  },
  watch: {
    value(val) {
      if (!val) {
        this.geo_unit = null;
        this.selected_id = null;
      }
    },
  },
  async mounted() {
    await this.initInputValue();
  },
  methods: {
    fetch(val) {
      if (!this.user_id && !(val?.trim().length > this.minWordLength - 1))
        return;

      const q = {
        [`${this.displayProperty}_cont_all`]: val,
      };

      if (this.npr && this.user_id) {
        q.geo_units_users_user_id_eq = this.user_id;
      }

      if (this.typeEq) q.type_eq = this.typeEq;
      if (this.geoUnitDeepAt) q.type_in = this.geoUnitDeepAt;
      if (this.q) Object.assign(q, this.q);

      const address = this.npr
        ? API_URLS.backendManage.nprGeoUnits
        : API_URLS.backendManage.geoUnits;

      return request3
        .get(address.fetch(this.clientId), {
          params: { q, limit: 8 },
        })
        .then((res) => res.data.geo_units);
    },
    initInputValue() {
      if (this.geoUnit) {
        this.geo_unit = this.geoUnit;
        this.selected_id = this.geoUnit.id;
      } else if (this.value) {
        ApiBackendGeoUnits.getOne(this.value).then((res) => {
          this.geo_unit = res.geo_unit;
          this.selected_id = this.geo_unit.id;
        });
      }
    },
    onClear() {
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
    onSelectItem(item) {
      this.$emit('select:item', item);
    },
    onSelect(value) {
      this.$emit('input', value);
      this.$emit('select', value);
    },
  },
};
</script>
