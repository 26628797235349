document.addEventListener('DOMContentLoaded', () => {
  const fileInputs = document.getElementsByClassName('file-size-validate');
  Array.from(fileInputs).forEach((input) => {
    input.addEventListener('change', () => {
      const fileSize = input.files[0].size / 1024 / 1024;
      const { maxSize } = input.dataset;
      if (fileSize > maxSize) {
        // eslint-disable-next-line no-alert
        alert(`Размер файла не должен превышать ${maxSize} МБ`);
        $(input).val('');
      }
    });
  });
});
