<template>
  <div class="geo-units-autocomplete">
    <BDropdownInput
      v-model="selected"
      :description="description"
      :display-property="displayProperty"
      :disabled="disabled"
      :label="placeholder"
      :handler="fetch"
      :selected="geoUnit"
      :state="correctVal"
      :validator="validator"
      :show-loading="showLoading"
      :variant-spinner="variantSpinner"
      :debouncedTts="debouncedTts"
      compare-property="id"
      @hide="onHide"
      @clear="onClear"
      @focus="onFocus"
      @select="onSelect"
      @select:item="onSelectItem"
      :popover-text="popoverText"
      :popover-hint="popoverHint"
      :hintId="hintId"
      :min-word-length="minWordLength"
      :combo-box="comboBox"
      :multiple="multiple"
    />
    <input
      :value="inputValue"
      :id="`${model}_${name}`"
      type="hidden"
      :name="`${model}[${name}]`"
    />
  </div>
</template>

<script>
import { isEmpty, isEqual } from 'lodash';
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';
import BDropdownInput from '@/components/base/BDropdownInput.vue';
import i18n from '@app/i18n';

export default {
  name: 'GeoUnitsAutocomplete',
  components: {
    BDropdownInput,
  },
  props: {
    // форма сохраняется если сделан выбор
    saveFormId: String,
    // id гео юнитов в контексте которых идёт поиск
    context: {
      type: Array,
      default() {
        return [];
      },
    },
    model: String,
    name: String,
    description: {
      type: String,
    },
    geoUnit: {
      type: Object,
    },
    clientId: {
      type: [Number, String],
    },
    placeholder: {
      type: String,
      default: () => i18n.t('global.address'),
    },
    value: {
      type: Number,
    },
    state: {
      type: Boolean,
      default: undefined,
    },
    debouncedTts: {
      type: Number,
      default: 200,
    },
    geoUnitDeepAt: Array,
    blockedGeoUnit: {
      type: Boolean,
    },
    geoUnitProperty: {
      type: String,
      default: 'short_name',
    },
    searchTypeNotIn: {
      type: Array,
      default() {
        return [];
      },
    },
    showInContext: {
      type: Boolean,
      default: false,
    },
    displayProperty: {
      type: String,
      default: 'short_name',
    },
    additionalQueryParams: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: null,
    },
    section: {
      type: String,
    },
    showLoading: {
      type: Boolean,
      default: true,
    },
    variantSpinner: {
      type: String,
      default: 'primary',
    },
    npr: {
      type: Boolean,
      default: false,
    },
    popoverHint: {
      type: String,
      default: null,
    },
    popoverText: {
      type: String,
      default: 'i',
    },
    hintId: {
      type: String,
      default: null,
    },
    validator: {
      type: Function,
    },
    minWordLength: {
      type: Number,
      default: 2,
    },
    admin: {
      type: Boolean,
      default: false,
    },
    comboBox: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  watch: {
    value(val, oldVal) {
      this.selected = val;
    },
  },
  computed: {
    in_context() {
      return !isEmpty(this.context);
    },
    blockedGeoUnitVal() {
      if (this.blockedGeoUnit) {
        return this.geoUnit && this.geoUnit[this.geoUnitProperty];
      }
      return '';
    },
    correctVal() {
      if (typeof this.state !== 'undefined') {
        return this.state;
      } else {
        if (this.isInvalid) {
          return !this.isInvalid;
        }
      }
      return undefined;
    },
    inputValue() {
      if (Array.isArray(this.selected) && this.multiple) {
        return JSON.stringify(this.selected.map((value) => JSON.parse(value)));
      } else {
        return this.selected;
      }
    },
  },
  mounted() {
    if (this.multiple) {
      this.selected = this.geoUnit;
    } else {
      if (this.geoUnit instanceof Object) {
        this.selected = this.geoUnit.id;
      }
    }
  },
  methods: {
    onSelect(value) {
      this.$emit('input', value);
      this.$emit('select', value);
      this.selected = value;
      this.saveForm();
    },
    onSelectItem(item) {
      this.$emit('update-unit', item);
      this.$emit('select:item', item);
    },
    saveForm() {
      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        if (document.getElementById('submit_ack')) {
          document.getElementById('submit_ack').value = vm.submitAck;
        }

        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    fetch(val) {
      let nameParams = {
        [`${this.geoUnitProperty}_cont_all`]: val,
      };
      let query = {
        ...nameParams,
        ...this.additionalQueryParams,
      };
      if (this.geoUnitDeepAt) {
        query = { ...query, type_in: this.geoUnitDeepAt };
      } else {
        if (!isEmpty(this.searchTypeNotIn)) {
          query = { ...query, type_not_in: this.searchTypeNotIn };
        }
      }
      if (this.in_context) {
        query = _.merge(query, { for_parents: this.context });
      }
      let prms = {
        section: this.section,
        q: query,
        limit: 8,
      };

      if (!this.admin) {
        if (this.npr) {
          return request3
            .get(API_URLS.backendManage.nprGeoUnits.fetch(this.clientId), {
              params: prms,
            })
            .then((r) => r.data.geo_units);
        } else {
          return request3
            .get(`/api/backend/manage/${this.clientId}/geo_units`, {
              params: prms,
            })
            .then((r) => r.data.geo_units);
        }
      } else {
        return request3
          .get(`/api/backend/admin/autocomplete/geo_units/`, {
            params: prms,
          })
          .then((r) => r.data);
      }
    },
    onClear() {
      this.$emit('update-unit', {});
      this.selected = null;
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
    onHide(value = false) {
      if (value && !isEqual(this.geoUnit, this.selected)) {
        this.$emit('input', this.selected);
        this.saveForm();
      }
    },
  },
};
</script>
