<template>
  <div class="portlet mb-5" v-if="this.calls.length || this.keys.length">
    <h4>{{ $t("intercom.dashboard.intercoms_and_accesses") }}</h4>

    <div class="row row-md mt-3">
      <div
        v-for="tab in tabs"
        v-if="tab.value"
        :key="tab.id"
        class="col-md-6 col-lg-4 mb-1 mb-md-0"
      >
        <div
          class="card information-count-card px-4 py-3 card-link"
          :class="{ 'card-active': selectTab === tab.id }"
          @click="selectTab = tab.id"
        >
          <div class="text-muted mb-1">{{ tab.title }}</div>
          <div class="h4 m-0">{{ tab.value }}</div>
        </div>
      </div>
    </div>

    <!-- Вызовы -->
    <div
      v-show="selectTab === '1'"
      class="row row-md mt-5"
      v-if="this.calls.length"
    >
      <div class="col-lg" v-if="this.calls.length">
        <vue-custom-scrollbar class="scroll-area mh-350">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>{{ $t("intercom.dashboard.time") }}</th>
                  <th>{{ $t("intercom.title") }}</th>
                  <th>{{ $t("global.status") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="call in calls"
                  :key="call.id"
                  :class="{ 'b-table-row-selected table-active': call.active }"
                >
                  <td>{{ call.time }}</td>
                  <td>{{ call.device }}</td>
                  <td>
                    <span v-if="call.status" class="text-success">{{ $t("intercom.call.status.accepted") }}</span>
                    <span v-else class="text-danger">{{ $t("intercom.call.status.missed") }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </vue-custom-scrollbar>
      </div>
      <div v-else class="col">
        <div class="lead text-center text-muted minh-250">{{ $t("intercom.dashboard.calls_not_found") }}</div>
      </div>
    </div>

    <!-- Ключи -->
    <div
      v-show="selectTab === '2'"
      class="row row-md mt-5"
      v-if="this.keys.length"
    >
      <div class="col" v-if="this.keys.length">
        <vue-custom-scrollbar class="scroll-area mh-350">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>{{ $t("global.key") }}</th>
                  <th>{{ $t("global.owner") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="key in keys"
                  :key="key.id"
                  :class="{ 'b-table-row-selected table-active': key.active }"
                >
                  <td>{{ key.key_number }}</td>
                  <td>{{ key.owner }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </vue-custom-scrollbar>
      </div>
      <div v-else class="col">
        <div class="lead text-center text-muted minh-250">
          {{ $t("intercom.dashboard.keys_not_found") }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import vueCustomScrollbar from 'vue-custom-scrollbar'
export default {
  components: {
    vueCustomScrollbar
  },
  props: {
    selectedGeoUnitId: {
      required: true,
      type: Number,
    },
    calls: {
      required: true,
      type: Array,
    },
    keys: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      tabs: [
        { id: '1', title: this.$t("intercom.dashboard.calls"), value: this.calls.length },
        { id: '2', title: this.$t("intercom.dashboard.keys"), value: this.keys.length },
      ],
      selectTab: this.selectedTab(),
    };
  },
  computed: {
    geoUnitId() {
      return this.$route?.params.geo_unit_id || this.selectedGeoUnitId;
    },
  },
  methods: {
    selectedTab() {
      return this.calls.length ? '1' : '2';
    },
    isActiveComponent(title) {
      return this.activeTab === title;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/components/variables';

.portlet-call {
  //margin-top: 41px;
  height: 320px;
  background-color: $color-default;
}

.portlet-grey {
  //margin-top: 41px;
  background-color: $bg-card;
}
</style>
