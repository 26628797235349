<template>
  <div>
    <slot
      :clients="clients"
      :selected-client-obj="selectedClientObj"
      :selected-client-id="selectedClientId"
      :select-client="selectClient"
      :clear-client="clearClient"
      :selected-gu-id="selectedGuId"
      :selected-gu="selectedGu"
      :disabled-gu-autocomplete="disabledGuAutocomplete"
      :selected-gu-obj="selectedGuObj"
      :clear-gu="clearGu"
    ></slot>
  </div>
</template>
<script>
import adminClientsAutocomplete from '@/components/autocompletes/adminClientsAutocomplete';
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete';
import BFormGroupSlot from '@/components/base/BFormGroupSlot';
import SelectSlot from '@/vue_slim/components/base/SelectSlot';

export default {
  name: 'DevicesForm',
  components: {
    adminClientsAutocomplete,
    GeoUnitsAutocomplete,
    BFormGroupSlot,
    SelectSlot,
  },
  props: {
    clientId: {
      type: [String, Number],
      default: null,
    },
    geoUnit: {
      type: Object,
      default: null,
    },
    clients: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      selectedClientId: this.clientId,
      selectedGuId: null,
      selectedGuObj: this.geoUnit,
    };
  },
  created() {
    if (this.geoUnit?.id) {
      this.selectedGuId = this.geoUnit.id;
    }
  },
  computed: {
    disabledGuAutocomplete() {
      return !this.selectedClientId;
    },
    selectedClientObj() {
      return this.clients.find((client) => client.id == this.selectedClientId);
    },
  },
  methods: {
    selectClient(val) {
      this.selectedClientId = val;
      this.clearGu();
    },
    clearClient() {
      this.selectedClientId = null;
      this.selectedGuId = null;
      this.selectedGuObj = null;
    },
    selectedGu(val) {
      this.selectedGuId = val.id;
      this.selectedGuObj = Object.assign({}, val);
    },
    clearGu() {
      this.selectedGuObj = null;
      this.selectedGuId = null;
    },
  },
};
</script>
