<template>
  <svg :viewBox="viewBox" class="pie-chart">
    <circle
      cx="0"
      cy="0"
      r="1"
      stroke="#fcfcfc"
      :stroke-width="strokeWidthForTemplate"
    />
    <path
      v-if="!circle"
      :d="path"
      :stroke="color"
      :stroke-width="strokeWidthForTemplate"
      :opacity="opacity"
    />
    <circle
      v-if="circle"
      cx="0"
      cy="0"
      r="1"
      :stroke="color"
      :stroke-width="strokeWidthForTemplate"
      :opacity="opacity"
    />
    <text
      x="0"
      y="0"
      text-anchor="middle"
      alignment-baseline="middle"
      :style="labelStyle()"
    >
      {{ label || labelPercent }}
    </text>
    <text
      x="0"
      :y="width / 10"
      text-anchor="middle"
      alignment-baseline="middle"
      :style="labelStyle(true)"
    >
      {{ labelSmall }}
    </text>
  </svg>
</template>

<script>
export default {
  name: 'PieChart',
  props: {
    ratio: Number,
    percent: {
      type: Number,
      default: 42,
    },
    strokeWidth: {
      type: Number,
      default: 1,
      validator: (v) => v > 0 && v <= 10,
    },
    label: String,
    labelSmall: String,
    color: {
      type: String,
      default: '#40a070',
    },
    opacity: {
      type: Number,
      default: 0.7,
    },
  },
  computed: {
    strokeWidthForTemplate() {
      return this.strokeWidth / 5;
    },
    width() {
      return 2 + this.strokeWidthForTemplate;
    },
    viewBox() {
      const c = 1 + this.strokeWidthForTemplate / 2;
      const w = this.width;
      return `${-c} ${-c} ${w} ${w}`;
    },
    circle() {
      return this.ratioPercent % 1 === 0;
    },
    ratioPercent() {
      const r = this.percent / 100;
      return isFinite(this.ratio) ? this.ratio : r;
    },
    labelPercent() {
      const p = (this.ratioPercent * 100).toFixed(0);
      return `${p} %`;
    },
    arc() {
      const r = this.ratioPercent;
      const deg = 2 * Math.PI * r;
      const x = 1 * Math.sin(deg);
      const y = -1 * Math.cos(deg);
      const negative = r < 0;
      const rotation = negative ? 0 : 1;
      let large = 0;
      if ((negative && x > 0) || (!negative && x < 0)) {
        large = 1;
      }
      return `A 1 1 0 ${large} ${rotation} ${x} ${y}`;
    },
    path() {
      return `M 0 -1 ${this.arc}`;
    },
  },
  methods: {
    labelStyle(small) {
      const size = small ? this.width / 13 : this.width / 10;
      return `font-size:${size}pt`;
    },
  },
};
</script>

<style scoped lang="scss">
.pie-chart {
  path,
  circle {
    fill: transparent;
  }
}
</style>
