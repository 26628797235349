<template>
  <b-modal
    id="modal"
    ref="modal"
    modal-class="modal-request"
    header-class="modal-request__head modal-request__head_create"
    footer-class="d-none"
    centered
  >
    <template #modal-header="{ close }">
      <div class="title modal-request__title">
        {{ $t('client_request.success_created.title') }}
      </div>
      <button class="close" @click="close()">
        <i class="icon-local-times"></i>
      </button>
    </template>
    <div class="modal-body-row with-cols pt-20">
      <div class="modal-request__text">
        {{ $t('client_request.success_created.text') }}
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ClientRequestSuccessCreateModal',
  methods: {
    show() {
      this.$refs.modal.show();
    },
  },
};
</script>

<style></style>
