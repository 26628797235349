<template>
  <div class="users-autocomplete">
    <BDropdownInput
      v-model="selected_id"
      :description="description"
      :disabled="disabled"
      :label="placeholder"
      :handler="fetch"
      :selected="userData"
      :state="state"
      :showLoading="showLoading"
      :variantSpinner="variantSpinner"
      :debouncedTts="debouncedTts"
      :formattedResult="formattedResult"
      compare-property="user_id"
      display-property="short_name"
      @clear="onClear"
      @focus="onFocus"
      @select="onSelect"
      @select:item="onSelectItem"
      :popoverText="popoverText"
      :popoverHint="popoverHint"
      :hintId="hintId"
      :minWordLength="minWordLength"
    />
    <input :value="selected_id" type="hidden" :name="hiddenFieldName" />
  </div>
</template>

<script>
import { getManageId } from '@/helpers';
import BDropdownInput from '@/components/base/BDropdownInput.vue';

export default {
  name: 'userClientsAutocomplete',
  components: {
    BDropdownInput,
  },
  props: {
    hiddenFieldName: String,
    saveFormId: String,
    userData: Object,
    placeholder: String,
    additionalQueryParams: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {},
    description: {
      type: String,
    },
    debouncedTts: {
      type: Number,
      default: 200,
    },
    popoverText: {
      type: String,
      default: 'i',
    },
    popoverHint: {
      type: String,
      default: null,
    },
    hintId: {
      type: String,
      default: null,
    },
    placementPopover: {
      type: String,
      default: 'bottom',
    },
    showLoading: {
      type: Boolean,
      default: true,
    },
    variantSpinner: {
      type: String,
      default: 'primary',
    },
    minWordLength: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      selected_id: null,
    };
  },
  computed: {
    formattedResult() {
      return {
        top: [
          {
            title: 'name',
            formatter: (val, item) => `${item.short_name || ''}`,
          },
          { title: 'id', formatter: (value) => `#${value}` },
        ],
        bottom: [
          {
            title: 'contact',
            formatter: (val, item) =>
              [item.phone || item.user?.phone, item.email || item.user?.email]
                .filter((e) => e)
                .join(', '),
          },
        ],
      };
    },
  },
  mounted() {
    if (this.userData) {
      this.selected_id = this.userData.user_id;
    }
  },
  methods: {
    onSelect(result) {
      this.$emit('input', result);
      this.$emit('select', result);
      this.selected_id = result;
      this.saveForm();
    },
    onSelectItem(result) {
      this.$emit('select', result);
      this.saveForm();
    },
    onClear() {
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
    saveForm() {
      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    fetch(val) {
      let qParams = {
        q: {
          name_or_surname_or_patronymic_or_user_phone_or_user_email_cont: val,
        },
      };
      let query = {
        ...qParams,
        ...this.additionalQueryParams,
      };

      return request2(
        {
          method: 'get',
          url: `/api/backend/manage/${getManageId()}/users_clients/`,
        },
        query,
      );
    },
  },
};
</script>
