<template>
  <div class="events">
    <b-table
      :fields="fields"
      :items="updateEvents"
      striped
      primary-key="stamp"
      :tbody-transition-props="transProps"
    >
      <template v-slot:cell(npr_direction)="{ item }">
        <div class="d-flex align-items-center">
          <i
            v-if="item.enum_npr_direction"
            :class="
              item.enum_npr_direction == 'in'
                ? 'icon-local-small-arrow-up events__icon events__icon_in'
                : 'icon-local-small-arrow-down events__icon events__icon_out'
            "
          />
          {{ item.human_npr_direction }}
        </div>
      </template>

      <template v-slot:cell(car)="{ item }">
        <div :class="!item.plate_number ? 'text-danger' : null">
          {{ item.car.label }}
        </div>
        <div class="small text-secondary">
          {{ item.car.value }}
        </div>
      </template>

      <template v-slot:cell(owner)="{ item }">
        <div>
          {{ item.owner.name }}
        </div>
        <div class="small text-secondary" v-if="item.owner.phone">
          {{ item.owner.phone }}
        </div>
        <div class="small text-secondary" v-else>
          {{ item.owner.email }}
        </div>
      </template>

      <template v-slot:cell(photo)="{ item }">
        <EventImage
          :url="item.photo_url"
          :thumb-url="item.thumb_photo_url"
          :barrierAddress="barrierAddress"
          :barrierName="barrierName"
          :enumNprDirection="item.enum_npr_direction"
          :humanNprDirection="item.human_npr_direction"
          :plateNumber="item.plate_number"
          height="45"
        />
      </template>
    </b-table>
    <div class="d-flex justify-content-center pt-2">
      {{ $t('event.info', [events.length]) }}
      <a
        :href="`/manage/${manageId}/npr/events?q[barrier_id_eq]=${barrierId}`"
        class="ml-1"
      >
        {{ $t('event.link') }}
      </a>
    </div>
  </div>
</template>

<script>
import { getManageId } from '@/helpers';
import EventBus from '@/packs/EventBus';
import EventImage from './EventImage.vue';

export default {
  name: 'NprEvents',
  components: {
    EventImage,
  },
  props: {
    startEvents: {
      type: Array,
      default: () => [],
    },
    barrierId: {
      type: [String, Number],
      required: true,
    },
    barrierName: {
      type: String,
      default: null,
    },
    barrierAddress: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      events: [],
      transProps: {
        name: 'bounce',
      },
      manageId: null,
    };
  },
  mounted() {
    this.events = this.events.concat(this.startEvents);
    this.manageId = getManageId();
    this.$cable.subscribe({
      channel: 'NprEventsChannel',
    });
  },
  channels: {
    NprEventsChannel: {
      received(data) {
        if (data.barrier.id != this.barrierId) return;
        this.events.unshift(data.event);
        if (this.events.length > 10) this.events.pop();

        EventBus.$emit('NprEvents::update');
      },
    },
  },
  computed: {
    fields() {
      return [
        {
          key: 'npr_direction',
          label: this.$t('event.type_direction'),
        },
        {
          key: 'plate_number',
          label: this.$t('event.plate'),
          formatter: (value) => {
            return value;
          },
        },
        {
          key: 'car',
          label: this.$t('event.car'),
        },
        {
          key: 'owner',
          label: this.$t('event.owner'),
        },
        // {
        //   key: 'human_pass',
        //   label: this.$t('event.pass'),
        //   formatter: (value) => {
        //     return value;
        //   },
        // },
        {
          key: 'photo',
          label: this.$t('event.photo'),
        },
        {
          key: 'formatted_created_at',
          label: this.$t('event.date'),
          formatter: (value) => {
            return value;
          },
        },
      ];
    },
    updateEvents() {
      return this.events.map((event, index) => {
        return Object.assign({}, event, {
          stamp: `${event.formatted_created_at}_${event.plate_number}_${event.human_npr_direction}`,
        });
      });
    },
  },
};
</script>

<style lang="scss">
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.events {
  &__icon {
    font-size: 22px;
    &_in {
      color: #6aa253;
    }
    &_out {
      color: #039be0;
    }
  }
}
</style>
