<template>
  <GeoUnitsList
    ref="GeoUnitsList"
    :value="restructuringValue"
    :required="required"
    :add-title="addTitle"
    :client-id="clientId"
    disable-add-check-property="id"
    :type-eq="typeEq"
    :q="q"
    :npr="npr"
    @input:origin="onInput"
    @clear="onClear"
    @focus="onFocus"
    :userId="userId"
    :comboBox="comboBox"
    :disabled-btn="disabledBtn"
  />
</template>
<script>
import GeoUnitsList from '@/components/lists/GeoUnitsList.vue';
import { replaceInArrayByIndex } from '@/helpers';
import i18n from '@app/i18n';

export default {
  name: 'BuildingsList',
  components: { GeoUnitsList },
  props: {
    addTitle: {
      type: String,
      default: i18n.t('geo_unit.add_address'),
    },
    clientId: {
      type: [Number, String],
    },
    disableAddCheckProperty: {
      type: String,
      required: true,
    },
    initGeoUnitNameProperty: {
      type: String,
      default: 'short_name',
    },
    typeEq: {
      type: String,
    },
    value: {
      type: Array,
      default: () => [],
    },
    q: {
      type: Object,
    },
    required: {
      type: Boolean,
      default: false,
    },
    npr: {
      type: Boolean,
      default: false,
    },
    comboBox: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: [String, Number],
      default: null,
    },
    disabledBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list: this.value,
    };
  },
  computed: {
    restructuringValue() {
      return this.value.map((unit) => {
        return {
          id: unit.building_id,
          short_name: unit.building_name || unit.short_name,
          state:
            this.required && !Boolean(unit.building_id) ? false : undefined,
        };
      });
    },
  },
  methods: {
    onFocus() {
      this.$emit('focus');
    },
    onInput(list) {
      const value = list.map((unit) => ({
        building_id: unit.id,
        building_name: unit.short_name,
      }));
      this.$emit('input', value);
    },
    onClear(index) {
      this.$emit(
        'input',
        replaceInArrayByIndex(this.value, index, {
          building_id: null,
          building_name: '',
          state: this.required ? false : undefined,
        }),
      );
      this.$emit('clear');
    },
  },
};
</script>
