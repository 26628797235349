<template>
  <div :class="{ inHeader: 'clients-autocomplete' }">
    <BDropdownInput
      v-model="selected_id"
      :description="description"
      :disabled="disabled"
      :label="placeholder"
      :handler="fetch"
      :selected="currentClient"
      :state="state"
      :showLoading="showLoading"
      :variantSpinner="variantSpinner"
      :debouncedTts="debouncedTts"
      compare-property="id"
      display-property="name"
      @clear="onClear"
      @focus="onFocus"
      @select="onSelect"
      @select:item="onSelectItem"
      :popoverText="popoverText"
      :popoverHint="popoverHint"
      :hintId="hintId"
      :minWordLength="minWordLength"
      :clearable="clearable"
      :comboBox="true"
      :clearOnFocus="inHeader"
    />
    <input :value="selected_id" type="hidden" :name="name" />
  </div>
</template>

<script>
import BDropdownInput from '@/components/base/BDropdownInput.vue';

export default {
  name: 'adminClientsAutocomplete',
  components: {
    BDropdownInput,
  },
  props: {
    saveFormId: String,
    currentClient: {
      type: Object,
    },
    name: {
      type: String,
    },
    description: {
      type: String,
    },
    clients: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: function () {
        return this.$t('abbreviations.MC');
      },
    },
    debouncedTts: {
      type: Number,
      default: 200,
    },
    state: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    minWordLength: {
      type: Number,
      default: 0,
    },
    popoverText: {
      type: String,
      default: 'i',
    },
    popoverHint: {
      type: String,
      default: null,
    },
    hintId: {
      type: String,
      default: null,
    },
    placementPopover: {
      type: String,
      default: 'bottom',
    },
    showLoading: {
      type: Boolean,
      default: true,
    },
    variantSpinner: {
      type: String,
      default: 'primary',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    inHeader: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
    },
  },
  data() {
    return {
      selected_id: null,
    };
  },
  mounted() {
    if (this.currentClient) {
      this.selected_id = this.currentClient.id;
    }
  },
  methods: {
    onSelect(value) {
      this.$emit('input', value);
      this.$emit('select', value);
      this.selected_id = value;
      this.saveForm();
    },
    onSelectItem(item) {
      this.selected_id = item.id;
      this.$emit('select', item.id);
      this.$emit('select:item', item);

      if (this.inHeader) {
        this.changeClientPath(item);
      } else {
        this.saveForm();
      }
    },
    fetch(val) {
      let items = this.clients;
      if (val) {
        items = this.clients.filter((client) =>
          client.name.toLowerCase().includes(val.toLowerCase()),
        );
      }
      return new Promise((resolve) => resolve(items));
    },
    onClear() {
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
    saveForm() {
      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    changeClientPath(result) {
      let splitedUrl = window.location.toString().split('/');

      const pathMap = {
        clients: 'clients',
        home: 'home/districts',
        intercom: 'intercom/district/all/devices',
        npr: 'npr/districts',
        metering: 'metering/devices',
        fa: 'fa/devices',
        sa: 'sa/devices',
        cctv: 'cctv',
        accounts: 'accounts',
        users: 'users',
      };

      let section = splitedUrl.find((i) =>
        [
          'clients',
          'home',
          'intercom',
          'npr',
          'metering',
          'fa',
          'sa',
          'cctv',
          'accounts',
          'users',
        ].includes(i),
      );

      const newPath = pathMap[section]
        ? `/manage/${result.id}/${pathMap[section]}`
        : `/manage/${result.id}`;

      if (location.href.includes('?')) {
        history.pushState({}, null, location.href.split('?')[0]);
      }

      location.pathname = newPath;
    },
  },
};
</script>
<style lang="scss">
@import '../../assets/styles/components/variables';
.clients-autocomplete {
  position: relative;
  width: 255px;
}
</style>
