import Vue from 'vue';
import Vuex from 'vuex';
import intercom from '@app/store/intercom';
import geo_units from '@app/store/geo_units';
import edit_building from '@app/store/edit_building';
import auth from '@app/store/auth';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    geo_units,
    edit_building,
    intercom,
    auth,
  },
});
