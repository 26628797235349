<template>
  <div
    class="p-table-container"
    ref="table"
    :style="`max-height:${maxHeight}px`"
  >
    <slot
      v-if="isShowEmpty"
      name="content"
      :items="items"
      :body-height="maxHeight - 50"
    >
    </slot>
    <div class="loader" ref="loadingContainer"></div>
  </div>
</template>

<script>
import _ from 'lodash';
import { PreloaderMixin } from '@app/mixins';

/**
   * @description
   Для корректной работы компоонента, необходимо что бы ref из родительского элемента был передан через mount (так как на момент создания PTable он еще не существует)

   ПРИМЕР родительского компонента:

   <template>
   <div class="portlet overflow-auto" ref="containerTable" style="max-height: 700px">
   <PTable :ref-scroll="refForTable"></PTable>
   </div>
   </template>

   export default {
   mounted() {
     this.refForTable = this.$refs.containerTable;
   }
 }
   * */

export default {
  name: 'PTable',
  mixins: [PreloaderMixin],
  props: {
    api: { type: Object, required: true },
    queryParams: Object,
    searchParams: [Object || String],
    maxHeight: {
      type: [String || Number],
      default: '600',
    },
    mutation: String,
    perPage: { type: String, default: '25' },
    selectedId: {
      type: Number,
    },
    showEmpty: {
      type: Boolean,
    },
  },
  data() {
    return {
      items: [],
      fields: [],
      currentPage: 0,
      nextPage: 1,
      itemHeight: 50,
      firstLoad: true,
      scrollItem: null,
      loaderType: 'dots',
    };
  },
  computed: {
    getTableHeight() {
      return this.items.length * this.getItemHeight();
    },
    getScrollItemHeight() {
      return (
        (Math.floor(this.maxHeight / this.getItemHeight) - 1) *
        this.getItemHeight
      );
    },
    getScrollLoadOffset() {
      return this.getTableHeight - this.maxHeight;
    },
    getRefScroll() {
      return this.$refs.table;
    },
    getQueryParams() {
      if (this.queryParams && this.queryParams.q) {
        return this.queryParams;
      }
      return { q: { ...this.queryParams } };
    },
    isShowEmpty() {
      if (this.showEmpty) return true;
      return this.items.length;
    },
  },
  async mounted() {
    await this.getItems();
    /* вешаем высоту для tbody, +1px необходим для корректного отображения (ширина border) */
    this.$emit('mounted', this.items);
    this.$nextTick(() => {
      if (this.selectedId) {
        const index = this.items.findIndex(
          (item) => item.id === this.selectedId,
        );
        if (index > -1 && this.$children[0]) this.$children[0].selectRow(index);
      }
    });
  },
  methods: {
    async getItems(items = []) {
      const params = _.cloneDeep(this.getQueryParams);
      if (this.searchParams instanceof Object) {
        Object.assign(params.q, this.searchParams);
      }
      const { data, next } = await this.api.getPagination(
        {
          ...params,
          per_page: this.perPage,
          page: this.nextPage,
        },
        this.mutation,
      );
      this.stopLoading();
      items.push(...data);
      this.items = items;
      this.getScrollItem(this.getRefScroll);
      if (this.scrollItem) {
        if (next) {
          this.nextPage = next;
          this.scrollItem.addEventListener('scroll', this.handleScroll);
        }
        this.stopLoading();
        this.$nextTick(() => {
          // this.scrollItem.style.height = `${this.getTableHeight + 1}px`;
          this.scrollItem.style.maxHeight = `${this.maxHeight - 50}px`;
        });
      }
    },
    getScrollItem(element) {
      if (element) {
        if (element && element.localName === 'tbody') {
          this.scrollItem = element;
        } else {
          Array.from(element.children).forEach((child) => {
            this.getScrollItem(child);
          });
        }
      }
    },
    getItemHeight() {
      return this.scrollItem.children.length > 0
        ? this.scrollItem.children[0].clientHeight
        : 0;
    },
    async handleScroll() {
      const top = this.scrollItem.scrollTop;
      if (top > this.getScrollLoadOffset) {
        // this.startLoading();
        this.getItems(this.items);
        if (this.scrollItem.removeEventListener) {
          this.scrollItem.removeEventListener('scroll', this.handleScroll);
        }
      }
    },
    refresh() {
      this.currentPage = 0;
      this.nextPage = 1;
      this.getItems().then(() => this.$emit('refresh'));
    },
    updateItem(updatedItem) {
      const index = this.items.findIndex((item) => item.id === updatedItem.id);
      if (index > -1) {
        this.$set(
          this.items,
          index,
          Object.assign(this.items[index], updatedItem),
        );
      }
    },
  },
  watch: {
    queryParams: {
      deep: true,
      handler(value, oldValue) {
        if (!_.isEqual(value, oldValue)) {
          this.refresh();
        }
      },
    },
    searchParams: {
      deep: true,
      handler() {
        this.refresh();
      },
    },
    perPage() {
      this.refresh();
    },
    api: {
      deep: true,
      handler(value, oldValue) {
        if (!_.isEqual(value, oldValue)) {
          this.refresh();
        }
      },
    },
  },
};
</script>
