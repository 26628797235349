<template>
  <img
    :src="srcAvatar"
    alt=""
    class="img-circle"
    :onerror="imgOnError"
    :style="style"
  />
</template>

<script>
export default {
  name: 'UserAvatar',
  props: {
    user: {
      type: Object,
      required: true,
    },
    size: {
      default: '32px',
    },
    defaultAvatar: {
      default: '/img/icon-no-avatar.svg'
    },
  },
  computed: {
    style() {
      return { height: this.size, width: this.size };
    },
    srcAvatar() {
      return (
        this.user.avatar || this.defaultAvatar
      );
    },
    imgOnError() {
      return `this.src='${this.defaultAvatar}'`
    }
  },
};
</script>
