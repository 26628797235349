<template>
  <b-modal id="SetupAccessModal" ref="SetupAccessModal" size="lg" @ok="onOk">
    <template #modal-header="{ close }">
      <div>
        <div class="title">
          {{ $t('intercom.intercom_keys.doors_access') }}
        </div>
        <div class="subtitle">{{ $t('intercom.key.title') }}:</div>
        <button class="close" @click="close()">×</button>
      </div>
    </template>
    <div class="modal-body-row">
      <div class="d-flex">
        <GeoUnitsAutocomplete2
          v-model="geoUnitFilter"
          display-property="post_name"
          class="flex-grow-1 relay-geo-unit"
          :label="$t('intercom.relays_groups.search_placeholder')"
        />
        <div class="relay-only-black-list">
          <b-form-checkbox v-model="onlyBlackListFilter">
            {{ $t('intercom.relays_groups.only_black_list') }}
          </b-form-checkbox>
        </div>
      </div>
      <template v-if="district">
        <div v-if="isFilterList">
          <div v-if="isLoading" class="w-100 d-flex justify-content-center">
            <b-spinner variant="primary"></b-spinner>
          </div>
          <RelaysOfGeoUnitsTable
            v-else
            :relays="relaysWithFilter"
            :black-list="blackList"
            @add="onAddInBlackList"
            @remove="onRemoveInBlackList"
          />
        </div>
        <div v-else class="geo-units_accordion" role="tablist">
          <GeoUnitCollapse
            v-for="item in relatedGeoUnitsList"
            :index="item.id"
            :geo-unit="item"
            :key="`geo-unit-collapse-${item.id}-${item.id}`"
            :black-list="blackList"
            @add="onAddInBlackList"
            @remove="onRemoveInBlackList"
          />
          <template v-if="district.id">
            <h4 class="mt-3">{{ $t("abbreviations.ZHK") }}: {{ district.name }}</h4>
            <GeoUnitCollapse
              v-for="(elem, id) in districtChildren"
              :index="id"
              :geo-unit="elem"
              :key="`geo-unit-relatedGeoUnitsList-${id}-${id}`"
              :black-list="blackList"
              @add="onAddInBlackList"
              @remove="onRemoveInBlackList"
            />
          </template>
        </div>
      </template>
    </div>
  </b-modal>
</template>

<script>
import { ApiBackendRelays, ApiGeoUnits } from '@/api';
import GeoUnitsAutocomplete2 from '@/components/autocompletes/GeoUnitsAutocomplete2';
import GeoUnitCollapse from '@app/components/relays_groups/GeoUnitCollapse.vue';
import RelaysOfGeoUnitsTable from '@app/components/relays_groups/RelaysOfGeoUnitsTable.vue';

export default {
  name: 'RelaysGroupsSettingModal',
  components: {
    GeoUnitsAutocomplete2,
    GeoUnitCollapse,
    RelaysOfGeoUnitsTable,
  },
  props: {
    value: { type: Array, default: () => [] },
    district: {
      type: Object,
      default: () => ({ buildings: [], territories: [] }),
    },
    startBlackList: { type: Array, default: () => [] },
    relatedGeoUnits: { type: Array, default: () => [] },
  },
  data() {
    return {
      blackList: [],
      relatedGeoUnitsList: [],
      geoUnitFilter: null,
      relaysWithFilter: [],
      onlyBlackListFilter: false,
      isLoading: false,
    };
  },
  computed: {
    isFilterList() {
      return !!(this.onlyBlackListFilter || this.geoUnitFilter);
    },
    districtChildren() {
      if (!this.district.id) return [];
      const { buildings = [], territories = [] } = this.district;
      return buildings.concat(territories);
    },
  },
  watch: {
    geoUnitFilter: 'onFiltersChanges',
    onlyBlackListFilter: 'onFiltersChanges',
  },
  methods: {
    async show() {
      if (this.relatedGeoUnits.length) {
        const ids = this.relatedGeoUnits.map(({ id }) => id).filter((id) => id);
        if (ids.length) {
          this.relatedGeoUnitsList = await ApiGeoUnits.getAll({
            q: { id_in: ids },
          });
        }
      }
      this.$refs.SetupAccessModal.show();
      this.blackList = this.value.map((item) => item.relay_id);
      this.initFilters();
    },
    initFilters() {
      this.geoUnitFilter = null;
      this.onlyBlackListFilter = false;
    },
    onFiltersChanges() {
      if (this.isFilterList) {
        if (this.onlyBlackListFilter && !this.blackList.length) {
          this.relaysWithFilter = [];
          return;
        }
        this.isLoading = true;
        const geoUnitParams = this.geoUnitFilter
          ? { available_for_geo_unit_id_exclude_buildings: this.geoUnitFilter }
          : {};
        const blackListParams = this.onlyBlackListFilter
          ? { id_in: this.blackList }
          : {};
        ApiBackendRelays.getAll({
          q: { ...geoUnitParams, ...blackListParams },
        })
          .then((res) => {
            this.relaysWithFilter = res;
          })
          .catch((err) => console.error(err))
          .finally(() => (this.isLoading = false));
      } else this.relaysWithFilter = [];
    },
    onOk() {
      const formattedBlackList = this.blackList.map((item) => {
        return {
          relay_id: item,
        };
      });
      this.$emit('input', formattedBlackList);
    },
    onAddInBlackList(id) {
      const alreadyInBlackList = !!this.blackList.find((item) => item === id);
      !alreadyInBlackList && this.blackList.push(id);
    },
    onRemoveInBlackList(id) {
      this.blackList = this.blackList.filter((item) => item !== id);
    },
  },
};
</script>
<style lang="scss">
.relay-geo-unit {
  margin-right: 10px;
}
.relay-only-black-list {
  display: flex;
  align-items: center;
  height: 50px;
}
.geo-units_accordion {
  .accordion-item {
    margin-bottom: 0.5rem;
    cursor: pointer;
    padding: 1rem;
    color: #000;
    background-color: #f2f4f9;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    i {
      display: flex;
      align-items: center;
      transition: transform ease-in-out 200ms;
    }
    &.collapsed {
    }
    &.not-collapsed {
      i {
        transform: rotate(-180deg);
      }
    }
    &__controls {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
}
</style>
