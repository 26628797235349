<template>
  <div>
    <b-form-row class="mb-3">
      <b-col cols="6">
        <div class="title">
          {{ $t('client_request.logo') }}
        </div>
        <div
          class="modal-request__img"
          :style="`background-image: url(${request.request_file_url});`"
        ></div>
      </b-col>
    </b-form-row>

    <div class="title">
      {{ $t('client_request.company_data.title') }}
    </div>

    <b-form-row class="mb-3">
      <b-col cols="6">
        <b-input-label
          :label="$t('client_request.company_data.name')"
          v-model="request.name"
          disabled
        />
      </b-col>
      <b-col cols="6">
        <b-input-label
          :label="$t('client_request.company_data.inn')"
          v-model="request.inn"
          disabled
        />
      </b-col>

      <b-col cols="6" v-if="!isEdit">
        <b-input-label
          :label="$t('client_request.company_data.owner')"
          :value="ownerRequest"
          disabled
        />
      </b-col>
    </b-form-row>

    <div v-if="isEdit">
      <div class="title">
        {{ $t('client_request.manager_data.title') }}
      </div>

      <b-form-row class="mb-3">
        <b-col cols="6">
          <b-input-label
            :label="$t('client_request.company_data.owner')"
            :value="ownerRequest"
            disabled
          />
        </b-col>
        <b-col cols="6">
          <b-input-label
            :label="$t('client_request.manager_data.email')"
            :value="request.applicant.email"
            disabled
          />
        </b-col>
        <b-col cols="6">
          <b-input-label
            :label="$t('client_request.manager_data.phone')"
            :value="request.applicant.phone"
            disabled
          />
        </b-col>
      </b-form-row>

      <div v-if="request.status == 'denied' && !isEdit">
        <div class="title">
          {{ $t('client_request.service_data.reason_denied') }}
        </div>
        <b-form-row class="mb-3">
          <b-col cols="12">
            <b-input-label
              :label="$t('client_request.service_data.comment')"
              :value="request.comment"
              disabled
            />
          </b-col>
        </b-form-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RequestInfo',
  props: {
    request: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ownerRequest() {
      let val = '';
      if (this.request.applicant) {
        val += this.request.applicant.surname
          ? this.request.applicant.surname + ' '
          : '';

        val += this.request.applicant.name
          ? this.request.applicant.name + ' '
          : '';

        val += this.request.applicant.patronymic
          ? this.request.applicant.patronymic
          : '';
      }
      return val;
    },
  },
};
</script>
