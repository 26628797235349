<template>
  <b-form @submit.prevent="onSubmit" :autocomplete="autocomplete ? '' : 'off'">
    <slot v-bind:validateState="validateState" v-bind:v="$v"></slot>
    <b-button class="d-none" type="submit"></b-button>
  </b-form>
</template>

<script>
import { BButton, BForm } from 'bootstrap-vue';
import { validationMixin } from 'vuelidate';

export default {
  name: 'BFormValidate',
  components: { BForm, BButton },
  props: {
    autocomplete: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Object,
    },
    validations: {
      type: [Object, Function],
    },
  },
  mixins: [validationMixin],
  data() {
    return {
      validateForm: false,
    };
  },
  validations() {
    if (this.validations instanceof Object) {
      return {
        model: this.validations,
      };
    }
    return {
      model: this.validations(),
    };
  },
  methods: {
    onSubmit() {
      this.validateForm = true;
      this.$emit('submit', this.$v.$invalid);
    },
    validate(full) {
      this.validateForm = true;
      if (full) {
        return [!this.$v.$invalid, this.$v];
      }
      return !this.$v.$invalid;
    },
    reset() {
      this.validateForm = false;
    },
    validateState(property) {
      if (this.validateForm) {
        const properties = property.split('.');
        let validateObject = this.$v.model;
        try {
          properties.forEach((prop) => {
            validateObject = validateObject[prop];
          });
          if (
            validateObject instanceof Object &&
            validateObject.required === undefined &&
            ((validateObject.$model instanceof String &&
              validateObject.$model.trim() === '') ||
              validateObject.$model === null)
          ) {
            return undefined;
          }
          return validateObject && !validateObject.$invalid;
        } catch (e) {
          console.error(e);
        }
      }
      return undefined;
    },
  },
};
</script>
