<template>
  <div class="collapsible-portlet">
    <div class="portlet-head">
      <span v-if="title">{{ title }}</span>
      <span v-else>
        <slot name="title"></slot>
      </span>
      <div class="right-side">
        <slot name="right-side"></slot>
      </div>
      <div class="portlet-head__chevron">
        <i
          v-if="isActiveTab"
          class="icon-local-chevron-up"
          @click="onCollapseBody"
        ></i>
        <i v-else class="icon-local-chevron-down" @click="onShowBody"></i>
      </div>
    </div>
    <div class="portlet-tabs">
      <Tab
        v-for="tab in tabs"
        :key="tab.title"
        :tab="tab"
        :active="isActiveThisTab(tab)"
        @click.native="onActiveTab(tab)"
      ></Tab>
    </div>
    <div class="portlet-data" v-if="isActiveTab">
      <slot name="data"></slot>
    </div>
  </div>
</template>

<script>
import Tab from '@app/components/portlet/Tab.vue';

export default {
  name: 'CollapsiblePortletWithTabs',
  components: {
    Tab,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: String,
    },
    title: {
      type: String,
    },
    tabs: {
      type: Array,
      required: true,
      validator(tabs) {
        return !tabs.find((tab) => !tab.title);
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    isActiveTab() {
      return this.tabs.find((tab) => tab.title === this.value);
    },
  },
  methods: {
    onActiveTab(tab) {
      this.$emit('input', tab.title);
    },
    onCollapseBody() {
      this.$emit('input', '');
    },
    onShowBody() {
      this.$emit('input', this.tabs[0].title);
    },
    isActiveThisTab(tab) {
      return this.isActiveTab && this.isActiveTab.title === tab.title;
    },
  },
};
</script>
<style lang="scss" scoped>
.right-side {
  display: flex;
  margin-left: auto;
  margin-right: 36px;
}
</style>
