var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-3"},[_c('b-form-group-slot',{ref:"max_sms_per_day_limit",attrs:{"label":"Суточный лимит СМС"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.config.max_sms_per_day_limit),expression:"config.max_sms_per_day_limit"}],domProps:{"value":(_vm.config.max_sms_per_day_limit)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.config, "max_sms_per_day_limit", $event.target.value)}}})])],1)]),_vm._v(" "),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-3"},[_c('b-form-group-slot',{ref:"breakpoints_limit",attrs:{"label":"Шаг повторной отправки письма"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.config.breakpoints_limit),expression:"config.breakpoints_limit"}],domProps:{"value":(_vm.config.breakpoints_limit)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.config, "breakpoints_limit", $event.target.value)}}})])],1)]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-12 px-0",staticStyle:{"display":"inline-block"}},_vm._l((_vm.config.alert_emails),function(email,index){return _c('span',[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
            title: _vm.$t('button.delete'),
            placement: 'top',
            trigger: 'hover',
            boundary: 'viewport',
          }),expression:"{\n            title: $t('button.delete'),\n            placement: 'top',\n            trigger: 'hover',\n            boundary: 'viewport',\n          }"}],staticClass:"btn btn-secondary ml-4",on:{"click":function($event){return _vm.destroyEmail(index)}}},[_vm._v("\n          "+_vm._s(email)+"\n        ")])])}),0)]),_vm._v(" "),_c('div',{staticClass:"mt-4 col-4 p-0"},[_c('button',{staticClass:"btn btn-primary ml-4",staticStyle:{"float":"right"},attrs:{"disabled":_vm.emailIsEmpty},on:{"click":_vm.addEmail}},[_vm._v("\n      Добавить новый адрес\n    ")]),_vm._v(" "),_c('b-form-email-slot',{ref:"email_field",attrs:{"label":"Email"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})])],1),_vm._v(" "),_c('button',{staticClass:"btn btn-primary mt-4",on:{"click":_vm.save}},[_vm._v("\n    Сохранить\n  ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col"},[_c('h5',[_vm._v("\n        Почтовые адреса для оповещения:\n      ")])])])
}]

export { render, staticRenderFns }