<template>
  <div>
    <h5 class="mb-5">{{ $t('barrier.controller') }}</h5>
    <intercom-autocomplete
      :placeholder="$t('barrier.placeholder_autocomplete')"
      :clientId="clientId"
      v-model="intercom.id"
      :intercom-data="intercom"
      :additional-query-params="{ npr_relays_free: true, npr_supported: true }"
      @clear="onClearIntercom"
      @select:item="(value) => onSelectIntercom(value)"
      class="flex-grow-1"
      :state="intercomState"
    />
    <b-error
      v-if="!intercom.id && isSubmit"
      :error="$t('global.errors.not_empty')"
    />
    <relay
      v-for="(relay, index) in relays"
      :key="relay.id"
      :index="index"
      :relays-actions="relaysActions"
      :barrier-id="barrierId"
      :relays-errors="relaysErrors"
      :is-submit="isSubmit"
      :errors="errors"
    />
  </div>
</template>

<script>
import BError from '@/components/base/BError';
import SelectSlot from '@/vue_slim/components/base/SelectSlot';
import IntercomAutocomplete from '@/components/autocompletes/IntercomAutocomplete';
import Relay from '@/vue_slim/manage_components/npr/form/Relay.vue';
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';

const EMPTY_RELAY = [
  {
    npr_barrier_id: null,
    npr_delay: null,
    npr_action: null,
    id: null,
  },
  {
    npr_barrier_id: null,
    npr_delay: null,
    npr_action: null,
    id: null,
  },
];

export default {
  name: 'RelaysList',
  components: {
    BError,
    Relay,
    SelectSlot,
    IntercomAutocomplete,
  },
  props: {
    clientId: {
      type: [Number, String],
    },
    value: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    relaysActions: {
      type: Array,
      default: null,
    },
    barrierId: {
      type: [Number, String],
      default: null,
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
    validateState: {
      type: Function,
    },
    v: {},
  },
  data() {
    return {
      intercom: this.value,
    };
  },
  computed: {
    ...mapState('barrier', ['relays']),
    ...mapGetters('barrier', ['getRelays']),

    relays: {
      get() {
        return this.getRelays;
      },
      set(value) {
        return this.setRelays(value);
      },
    },

    relaysErrors() {
      return this.v?.model?.relays_attributes;
    },

    intercomState() {
      if (this.isSubmit) {
        return this.intercom.id ? true : false;
      }
      return undefined;
    },
  },

  created() {
    if (this.value && this.value.id) {
      this.intercom = Object.assign({}, this.value);
      this.onSelectIntercom(
        Object.assign({}, this.value, {
          relays: this.relays,
        }),
      );
    } else {
      this.onSelectIntercom(
        Object.assign(
          {},
          { id: null },
          {
            relays: this.relays,
          },
        ),
      );
    }
  },
  methods: {
    ...mapMutations('barrier', ['resetRelays', 'setRelays']),

    onSelectIntercom(item) {
      this.intercom = Object.assign({}, item);

      if (item?.relays) {
        item.relays.forEach((relay) => {
          if (
            (!relay.npr_barrier_id || relay.npr_barrier_id == this.barrierId) &&
            !relay.npr_action
          ) {
            relay.npr_action = 'unused';
          }
        });

        this.setRelays(item.relays);
      } else {
        this.resetRelays();
      }
    },
    onClearIntercom() {
      this.intercom = Object.assign(
        {},
        {
          id: null,
          relays: EMPTY_RELAY,
        },
      );

      this.resetRelays();
    },
  },
};
</script>

<style lang="scss">
@import 'app/javascript/assets/styles/components/variables';

.btn-trash {
  color: $blue;
  &:hover {
    color: $white;
  }
}
</style>
