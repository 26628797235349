<template>
  <div>
    <b-modal
      :title="$t('building_create.addresses.district_modal.title')"
      ref="createDistrictModal"
      modal-class="fast-modal"
    >
      <b-form-validate
        ref="form"
        @submit="onOk"
        :validations="validations"
        :model="model"
      >
        <template slot-scope="{ validateState }">
          <div class="modal-body-row with-cols pt-30">
            <b-form-row>
              <b-col cols="12">
                <b-input-label
                  id="input-district-modal_name"
                  :state="validateState('name')"
                  :label="$t('name')"
                  autocomplete="off"
                  v-model="model.name"
                  :class="!backend_valid && errors ? 'mb-0' : 'mb-small'"
                  autofocus
                />
                <b-error
                  v-if="!backend_valid && errors"
                  :error="errors"
                  class="d-block"
                />
              </b-col>
            </b-form-row>
          </div>
        </template>
      </b-form-validate>
      <template #modal-footer>
        <div
          class="d-flex align-items-center justify-content-between flex-grow-1"
        >
          <b-button
            @click="hide"
            size="sm"
            variant="outline-primary"
            class="fast-modal__btn w-auto h-auto"
          >
            {{ $t('devices.modal.cancel') }}
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="fast-modal__btn w-auto h-auto"
            @click="onOk"
          >
            {{ $t('devices.modal.save') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <access-district
      ref="AccessDistrictModal"
      :clientId="clientId"
      :districtId="districtId"
    />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { ApiBackendDistricts } from '@/api';
import AccessDistrict from './AccessDistrict.vue';
import BError from '@/components/base/BError';

function initData() {
  return {
    model: {
      name: '',
    },
    errors: null,
    districtId: null,
    backend_valid: true,
  };
}

export default {
  name: 'CreateDistrictModal',
  components: {
    AccessDistrict,
    BError,
  },
  props: {
    clientId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      ...initData(),
    };
  },
  mounted() {
    EventBus.$on('createDistrictModal::show', this.show);
  },
  computed: {
    validations() {
      return {
        name: {
          required,
          backend_valid: () => {
            return this.backend_valid;
          },
        },
      };
    },
  },
  methods: {
    async onOk(event) {
      if (event.preventDefault) {
        event.preventDefault();
      }

      if (this.$refs.form.validate()) {
        ApiBackendDistricts.create({
          name: this.model.name.trim(),
        })
          .then((entity) => {
            this.$refs.createDistrictModal.hide();
            this.districtId = entity.id;
            this.$refs.AccessDistrictModal.show();
          })
          .catch((error) => {
            this.backend_valid = false;
            if (error.errors) {
              this.errors = error.errors.join(', ');
            } else {
              this.$bvToast.toast(error);
            }
          });
      }
    },
    show() {
      Object.assign(this.$data, initData());
      this.$refs.createDistrictModal.show();
    },
    hide() {
      this.$refs.createDistrictModal.hide();
    },
  },
  watch: {
    'model.name': {
      handler: function () {
        this.backend_valid = true;
      },
    },
  },
};
</script>
<style lang="scss">
.mb-small {
  margin-bottom: 0.875em;
}
</style>
