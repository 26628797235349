<template>
  <div class="p-3">
    <div v-if="!users.length" class="small-portlet">
      <div class="small-portlet__body">
        <div class="habitats-list-container">
          <div class="no-habitats">
            <div class="message">
              {{
                this.$t(
                  'apartment.GeoUnitsUsersPortlet.inhabitants_not_registered',
                )
              }}
            </div>
            <b-button @click="$refs.AddUserModal.show()">
              {{ this.$t('apartment.GeoUnitsUsersPortlet.invite') }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <PTable2
      v-show="users.length"
      :api="ApiBackendUsers"
      local-sort-by="is_guest"
      :query-params="queryParams"
      ref="PTable2"
      @loaded="onLoadedUsers"
    >
      <template v-slot:content="{ items, bodyHeight }">
        <b-table
          :fields="fields"
          :items="items"
          :tbody-class="`mh-${bodyHeight}`"
        >
          <template v-slot:cell(contact)="{ item }">
            <div v-if="item.phone">{{ item.phone }}</div>
            <div v-if="item.email">{{ item.email }}</div>
          </template>
          <template v-slot:cell(faces_count)="{ item, value }">
            <template v-if="value.count">
              <span>
                {{ value.count }}
              </span>
              <span>
                <IconHug
                  :id="`sync_status_user_id_${item.id}`"
                  :color="value.color"
                />
                <b-popover
                  :target="`sync_status_user_id_${item.id}`"
                  triggers="hover"
                  placement="top"
                >
                  {{ value.alt }}
                </b-popover>
              </span>
            </template>
            <span v-else>-</span>
          </template>
          <template v-slot:cell(actions)="{ item }">
            <b-dropdown right toggle-class="dropdown-icon">
              <template v-slot:button-content>
                <i class="icon-local-dots"></i>
              </template>
              <b-dropdown-item @click="onOpenUserModal(item)">
                {{ $t('button.edit') }}
              </b-dropdown-item>
              <b-dropdown-item @click="onDeleteGeoUnitUser(item)">
                {{ $t('button.delete') }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </template>
    </PTable2>
    <AddUserModal
      :selected-geo-unit="geoUnit"
      ref="AddUserModal"
    ></AddUserModal>
  </div>
</template>

<script>
import i18n from '@app/i18n';
import BDropdownMenu from '@/components/BDropdownMenu.vue';
import UserCard from '@app/components/apartment/UserCard.vue';
import AddUserModal from '@app/components/apartment/AddUserModal.vue';
import { ApiGeoUnitUser, ApiBackendUsers } from '@/api';
import { RegEventBusMixin } from '@/mixins';
import EventBus from '@/packs/EventBus';
import { getUserFullName } from '@app/components/building_edit/helpers/helpers';
import IconHug from '@/components/icons/IconHug.vue';
import dayjs from 'dayjs';

const SYNC_STATUSES = {
  green: i18n.t('intercom.apartment.users.photo_sync_status.green'),
  red: i18n.t('intercom.apartment.users.photo_sync_status.red'),
  yellow: i18n.t('intercom.apartment.users.photo_sync_status.yellow'),
};

export default {
  name: 'GeoUnitsUsersPortlet',
  props: {
    geoUnit: {
      required: true,
    },
  },
  components: {
    IconHug,
    BDropdownMenu,
    UserCard,
    AddUserModal,
  },
  mixins: [RegEventBusMixin],
  data() {
    return {
      isLoadedUsers: false,
      users: [],
      userEditDropdown: {
        items: [
          {
            title: this.$t('button.edit'),
            icon: 'icon-local-pencil',
            handler: (user) =>
              EventBus.$emit('UserEditModal::show', {
                ...user,
                editingUser: true,
              }),
          },
          {
            title: this.$t('button.delete'),
            icon: ' icon-local-trash text-danger',
            handler: (user) => this.onDeleteGeoUnitUser(user),
          },
        ],
      },
    };
  },
  computed: {
    ApiBackendUsers: () => ApiBackendUsers,
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('intercom.apartment.users.table.name'),
          formatter: (value, field, row) => getUserFullName(row),
        },
        {
          key: 'contact',
          label: this.$t('intercom.apartment.users.table.contact'),
        },
        {
          key: 'is_guest',
          label: this.$t('global.status'),
          formatter: (value) =>
            value
              ? this.$t('intercom.apartment.users.status.guest')
              : this.$t('intercom.apartment.users.status.owner'),
        },
        {
          key: 'geo_unit_user_created_at',
          label: this.$t('intercom.apartment.users.table.created_at'),
          formatter: (value) => dayjs(value).format('L'),
        },
        {
          key: 'faces_count',
          label: this.$t('intercom.apartment.users.table.photo'),
          formatter: (value, field, row) => ({
            alt: SYNC_STATUSES[row.faces_icon],
            count: value,
            color: row.faces_icon,
          }),
        },
        {
          key: 'actions',
          label: '',
        },
      ];
    },
    queryParams() {
      return {
        q: {
          geo_units_users_geo_unit_id_eq: this.geoUnit.id,
          s: 'is_guest+asc',
        },
      };
    },
  },
  mounted() {
    EventBus.$on(['GeoUnitUser::create'], () => {
      this.$refs.PTable2.refresh();
    });
  },
  methods: {
    onDeleteGeoUnitUser(user) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('apartment.delete_user_modal.message')}`, {
          okTitle: this.$t('apartment.delete_user_modal.ok_title'),
          titleHtml: `
              <h5 class='modal-title'>${this.$t(
                'user.edit.property.confirm_delete.title',
              )}</h5>
              <div style='font-size: 14px'>${this.$t(
                'user.edit.property.confirm_delete.subtitle',
              )}</div>
            `,
          okVariant: 'danger',
          bodyClass: 'modal-body__confirm',
          headerClass: 'border-0',
        })
        .then(async (res) => {
          if (res) {
            const [relation] = await ApiGeoUnitUser.getAll({
              // TODO replace to one method
              q: {
                geo_unit_id_eq: this.geoUnit.id,
                user_id_eq: user.id,
              },
            });
            ApiGeoUnitUser.delete(relation.id)
              .then(() => {
                this.$refs.PTable2.refresh();
                EventBus.$emit('GeoUnitUser::delete');
              })
              .catch((err) => {
                this.$bvToast.toast(err);
              });
          }
        });
    },
    onLoadedUsers(users) {
      this.isLoadedUsers = true;
      this.users = users;
    },
    onOpenUserModal(user) {
      EventBus.$emit('UserEditModal::show', user, true);
    },
  },
};
</script>

<style lang="scss">
.dropdown-icon {
  background: none !important;
  border: none !important;
  &:focus {
    box-shadow: none !important;
  }
  &:after {
    display: none;
  }
}
</style>
