<template>
  <div>
    <slot :password-sign="passwordSign"></slot>
  </div>
</template>
<script>
import i18n from '@app/i18n';

export default {
  name: 'AcceptInvitation',
  computed: {
    passwordSign() {
      return this.$refs.password
        ? i18n.t('user.set_password')
        : i18n.t('user.enter_password');
    },
  },
};
</script>
