<template>
  <div
    id="dropdown"
    class="b-dropdown-input"
    @focusout="onBlur"
    :class="{ show: isShowItems }"
  >
    <BInputLabel
      ref="word"
      :autocomplete="autocomplete"
      :class="[{ 'have-items': isShowItems }]"
      :description="description"
      :disabled="isDisabled"
      :label="label"
      :state="state"
      v-model="word"
      @focus="onFocus"
      @keyup="onKeyUp"
      @focusout.native="onBlurInput"
      class="mb-0"
      type="text"
      :data-title="label"
    />
    <div class="icon-container d-flex align-items-center">
      <i class="icon-local-search search-icon" v-if="!isShowClear"></i>
      <i class="icon-local-times clear-icon" v-else @click="onClear"></i>
      <BHintPopover
        v-if="hintId"
        :popover-text="popoverText"
        :popover-hint="popoverHint"
        :hintId="hintId"
        :placement="placementPopover"
      />
    </div>

    <ul v-if="loading" class="list-group">
      <li
        class="list-group-item d-flex justify-content-center align-items-center"
      >
        <b-spinner :variant="variantSpinner"></b-spinner>
      </li>
    </ul>
    <ul v-else class="list-group" :class="{ scrollable }">
      <li
        v-if="canCreate"
        class="list-group-item list-group-item_create"
        @focusout="onBlurCreate"
        tabindex="0"
        @click="onCreateNew"
        @keyup.enter="onCreateNew"
      >
        <div>
          {{ createMessage }}
        </div>
      </li>
      <template v-if="formattedResult">
        <li
          :key="item.id"
          v-for="(item, index) in results"
          class="list-group-item"
          @click="onSelectItem(item)"
          @keyup.enter="onSelectItem(item)"
          @focusout="(e) => onBlurInResults(e, index)"
          tabindex="0"
        >
          <div
            :key="key"
            v-for="(position, key) of formattedResult"
            :class="`list-group-item-${key}`"
          >
            <div
              :key="idx"
              v-for="({ title, formatter }, idx) of position"
              :class="`list-group-item-${key}-text`"
              v-html="
                getOverlapText(
                  typeof formatter === 'function'
                    ? formatter(item[title], item)
                    : item[title],
                )
              "
            ></div>
          </div>
        </li>
      </template>
      <template v-else>
        <li
          v-for="(item, index) in results"
          :key="index"
          class="list-group-item"
          @click="onSelectItem(item)"
          @keyup.enter="onSelectItem(item)"
          @focusout="(e) => onBlurInResults(e, index)"
          tabindex="0"
        >
          {{ formattedTitle(item) }}
        </li>
      </template>

      <li v-if="results && results.length === 0" class="list-group-item">
        {{ emptyMessage }}
      </li>
    </ul>
  </div>
</template>

<script>
import i18n from '@app/i18n';
import { debounce, get, isFunction, isString, isEmpty } from 'lodash';
import BInputLabel from '@/components/base/BInputLabel.vue';
import BHintPopover from '@/components/base/BHintPopover.vue';
import { onBlurInResults, onBlurCreate, onBlurInput } from './helper.js';

export default {
  name: 'BAutocomplete',
  components: { BInputLabel, BHintPopover },
  props: {
    // функция для поиска элементов (промис, возвращает элементы)
    handler: {
      type: Function,
      required: true,
    },
    // можно ли из автокомплита создавать элемент
    canCreate: {
      type: Boolean,
    },
    // свойство, которое прокидывается при выборе элемента наверх (например для фильтра)
    compareProperty: {
      type: [String, Function],
      default: 'id',
    },
    debouncedTts: {
      type: Number,
      default: 200,
    },
    description: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    // отображаемое свойство выбранного элемента
    displayProperty: {
      type: [String, Function],
    },
    label: {
      type: String,
    },
    state: {
      type: Boolean,
      default: undefined,
    },
    // особое отображение результатов поиска
    formattedResult: { type: Object, default: null },
    // скроллятся ли результаты поиска
    scrollable: { type: Boolean, default: true },
    // автозаполнение
    autocomplete: { type: String, default: 'off' },
    // текст, на который надо навести для подсказки
    popoverText: {
      type: String,
      default: 'i',
    },
    // текст подсказки
    popoverHint: {
      type: String,
      default: null,
    },
    // айди хинта
    hintId: {
      type: String,
      default: null,
    },
    // положение хинта
    placementPopover: {
      type: String,
      default: 'bottom',
    },
    // показываем ли спиннер
    showLoading: {
      type: Boolean,
      default: true,
    },
    // тип спиннера
    variantSpinner: {
      type: String,
      default: 'primary',
    },
    // минимальное количество символов для поиска
    minWordLength: {
      type: Number,
      default: 4,
    },
    maxWordLength: {
      type: Number,
      default: 100,
    },
    // айди выбранного элемента
    value: {
      type: [Number, String],
    },
    // выбранный элемент
    selected: {
      type: Object,
      default: null,
    },
    // сообщение, если ни один элемент не нашелся
    emptyMessage: {
      type: String,
      default: () => i18n.t('global.not_found'),
    },
    // текст, по клику на который создается новый элемент
    createMessage: {
      type: String,
      default: () => i18n.t('button.add'),
    },
    // очищаемое
    clearable: {
      type: Boolean,
      default: true,
    },
    // комбобокс - если не вбит ни один символ, то отображать все элементы
    comboBox: {
      type: Boolean,
      default: false,
    },
    validator: {
      type: Function,
    },
    clearOnFocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      word: '',
      debouncedSearch: null,
      inputFocus: false,
      isLoading: false,
      results: null,
    };
  },
  watch: {
    selected: {
      deep: true,
      handler(val) {
        this.word = this.formattedTitle(val);
        this.selected_id = this.getItemId(val);
      },
    },
    isDisabled: {
      handler(val) {
        if (val) {
          this.word = null;
          this.selected_id = null;
        }
      },
    },
  },
  computed: {
    isShowClear() {
      return !this.isDisabled && this.word?.length > 0 && this.clearable;
    },
    isShowCreate() {
      return (
        this.canCreate && this.results?.length === 0 && this.word?.length >= 2
      );
    },
    isShowItems() {
      return (
        (this.results || this.canCreate || this.loading) && this.inputFocus
      );
    },
    isDisabled() {
      return this.disabled;
    },
    loading() {
      return this.showLoading && this.isLoading;
    },
  },
  mounted() {
    if (this.debouncedTts) {
      this.initDebounce();
    }
    if (this.selected) {
      this.word = this.formattedTitle(this.selected);
    }

    document.addEventListener('click', this.eventClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.eventClick);
  },
  methods: {
    onBlurInResults,
    onBlurCreate,
    onBlurInput,
    onBlur() {
      this.inputFocus = false;
    },
    eventClick(event) {
      if (!this.$el.contains(event.target)) {
        if (this.clearOnFocus) {
          this.word = this.formattedTitle(this.selected);
        }
        this.inputFocus = false;
        this.results = null;
        this.$emit('hide');
      } else {
        if (this.comboBox) {
          if (this.inputFocus) {
            this.onKeyUp();
          }
        }
      }
    },
    formattedTitle(item) {
      if (isString(this.displayProperty)) {
        return get(item, this.displayProperty);
      }
      if (isFunction(this.displayProperty)) {
        return this.displayProperty(item);
      }
      return item;
    },
    getItemId(item) {
      if (isFunction(this.compareProperty)) {
        return this.compareProperty(item);
      }
      if (isString(this.compareProperty)) {
        return this.compareProperty ? get(item, this.compareProperty) : item;
      }
      return item;
    },
    onCreateNew() {
      this.results = null;
      this.inputFocus = false;
      this.$emit('create', this.selected);
    },
    onClear() {
      this.word = '';
      this.inputFocus = false;
      this.results = null;
      this.$emit('clear');
    },
    onFocus(event) {
      this.inputFocus = true;
      this.$emit('focus', event);
      this.$refs.word.focus();
      if (this.clearOnFocus) {
        this.word = '';
        this.$emit('clear');
      }
    },
    onKeyUp() {
      if (this.debouncedSearch) {
        this.debouncedSearch();
      } else {
        this.search();
      }
    },
    search() {
      if (!this.inputFocus) {
        return;
      }
      if (
        !this.comboBox &&
        (isEmpty(this.word) ||
          this.word.length < this.minWordLength ||
          this.word.length > this.maxWordLength)
      ) {
        this.results = null;
        return;
      }

      this.isLoading = true;
      this.handler(this.word)
        .then((response) => {
          this.results = response;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    initDebounce() {
      this.debouncedSearch = debounce(() => {
        this.search();
      }, this.debouncedTts);
    },
    onSelectItem(value) {
      if (isFunction(this.validator)) {
        if (!this.validator(this.getItemId(value))) {
          return;
        }
      }
      this.word = this.formattedTitle(value);
      this.results = null;
      this.$emit('select', this.getItemId(value));
      this.$emit('select:item', value);
      this.$emit('input', this.getItemId(value));
    },
    getOverlapText(value) {
      return value
        ? String(value).replace(this.word, (change) => `<u>${change}</u>`)
        : '';
    },
  },
};
</script>
