import Swiper from 'swiper/js/swiper.min.js';
import 'swiper/css/swiper.min.css';

$(document).ready(function () {
  var titles = [];
  $('.swiper-container .swiper-slide').each(function (i) {
    titles.push($(this).data('title'));
  });

  var chessApartments = new Swiper('.swiper-container', {
    slidesPerView: 'auto',
    centeredSlides: true,
    spaceBetween: 28,
    freeMode: true,
    scrollbar: true,

    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        return (
          '<a role="button" class="btn text-md ml-1 ' +
          className +
          '">' +
          titles[index] +
          '</a>'
        );
      },
    },

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
});
