<template>
  <div>
    <slot
      :device="device"
      :client-id="clientId"
      :channels="channels"
      :max-channles="maxChannles"
      :active-channels-count="activeChannelsCount"
      :add-channel="addChannel"
      :channel-input-name="channelInputName"
      :remove-channel="removeChannel"
      :errors="errors"
      :channel-form-id="channelFormId"
      :get-channel-error="getChannelError"
      :on-select-gu="onSelectGu"
      :on-clear-gu="onClearGu"
      :selected-gu="selectedGu"
      :on-select-model="onSelectModel"
      :on-select-uspd="onSelectUspd"
      :uspd-devices="uspdDevices"
      :selected-device="selectedDevice"
      :on-clear-uspd="onClearUspd"
      :disabled-gu="disabledGu"
      :state-gu="stateGu"
      :state-uspd="stateUspd"
      :state-model="stateModel"
    ></slot>
  </div>
</template>
<script>
export default {
  name: 'PulseCountersDeviceForm',
  props: {
    deviceData: Object,
    uspdDevices: Array,
    models: Array,
    clientIdData: Number,
    channelsData: Array,
    errors: Object,
    geoUnit: {
      type: Object,
      default: null,
    },
  },
  data() {
    let modelId = this.deviceData.pulse_counters_model_id;
    let maxChannles = modelId
      ? this.models.find((m) => m.id == modelId).inputs_count
      : 0;
    return {
      device: this.deviceData,
      clientId: this.clientIdData,
      channels: this.channelsData,
      maxChannles: maxChannles,
      selectedGu: this.geoUnit,
    };
  },
  watch: {
    'device.pulse_counters_model_id'(val) {
      this.maxChannles = this.models.find((m) => m.id == val).inputs_count;
    },
  },
  computed: {
    activeChannelsCount() {
      return this.channels.filter((c) => !c._destroy).length;
    },
    selectedDevice() {
      if (this.uspdDevices) {
        return this.uspdDevices.find(
          (device) => device.id == this.device.uspd_device_id,
        );
      }
      return null;
    },
    disabledGu() {
      if (this.clientId === null || this.clientId === undefined) {
        this.onClearGu();
        return true;
      } else return false;
    },
    stateGu() {
      if (this.errors.geo_unit) {
        return false;
      }
      return null;
    },
    stateUspd() {
      if (this.errors.uspd_device) {
        return false;
      }
      return null;
    },
    stateModel() {
      if (this.errors.model) {
        return false;
      }
      return undefined;
    },
  },
  methods: {
    removeChannel(i) {
      if (this.channels[i].id) {
        this.$set(this.channels[i], '_destroy', true);
      } else {
        const index = this.channels.indexOf(i);
        this.channels.splice(index, 1);
      }
      this.reIndex();
    },
    addChannel() {
      this.channels.push({
        weight: '',
        index: null,
        _destroy: false,
      });
      this.reIndex();
    },
    channelFormId(i, attr) {
      return `pulse_counters_device_channels_attributes_${i}_${attr}`;
    },
    reIndex() {
      this.channels.forEach((c, i) => (c.index = i + 1));
    },
    channelInputName(i, attr) {
      return `pulse_counters_device[channels_attributes][][${attr}]`;
    },
    getChannelError(i, attr) {
      let errrosArr = this.errors[`channels[${i}].${attr}`];
      if (errrosArr) {
        return errrosArr.join(', ');
      }
    },
    onSelectGu(val) {
      this.selectedGu = val;
      this.device.geo_unit_id = val.id;
    },
    onClearGu() {
      this.device.geo_unit_id = null;
      this.selectedGu = null;
    },
    onSelectModel(event) {
      this.device.pulse_counters_model_id = event.target.value;
    },
    onSelectUspd(val) {
      this.device.uspd_device_id = val;
      this.clientId = this.uspdDevices.find(
        (d) => d.id == this.device.uspd_device_id,
      ).client_id;
    },
    onClearUspd() {
      this.device.uspd_device_id = null;
      this.clientId = null;
    },
  },
};
</script>
