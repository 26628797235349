<template>
  <span class="small text-danger" v-if="error">
    {{ error }}
  </span>
</template>

<script>
export default {
  name: 'BError',
  props: {
    error: {
      type: String,
      default: null,
    },
  },
};
</script>

<style></style>
