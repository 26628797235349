<template>
  <div class="user-card">
    <img
      :src="getAvatar(user)"
      onerror="this.src='/img/icon-no-avatar.svg'"
      style="object-fit: cover"
    />
    <div class="user-data">
      <div class="user-name">{{ getUserName(user) }}</div>
      <!--      <div class="user-role">Owner</div>-->
      <!--      <div class="user-devices">2 devices</div>-->
    </div>
    <div class="small text-muted" v-if="user.is_guest">{{ this.$t('user.is_guest') }}</div>
    <div class="user-action" v-if="edit">
      <slot name="action">
        <i class="icon-local-dots-vertical"></i>
      </slot>
    </div>
  </div>
</template>

<script>
import { getUserName } from '@/utils';
import { getAvatar } from '@/helpers';

export default {
  name: 'UserCard',
  props: {
    user: {
      type: Object,
      required: true,
    },
    edit: {
      type: Boolean,
    },
  },
  methods: { getUserName, getAvatar },
};
</script>

<style scoped></style>
