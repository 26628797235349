export const BOOTSTRAP_COLORS = {
  danger: 'danger',
  primary: 'primary',
};

export const STYLES_MODAL = {
  confirm: {
    primary: {
      bodyClass: 'modal-body__confirm',
      footerClass: 'border-0',
      headerClass: 'border-0',
      okVariant: BOOTSTRAP_COLORS.primary,
    },
    delete: {
      bodyClass: 'modal-body__confirm',
      footerClass: 'border-0',
      headerClass: 'border-0',
      okVariant: BOOTSTRAP_COLORS.danger,
    },
  },
};

export const COLORS_STATUS = {
  online: 'text-success',
  offline: 'text-danger',
}
