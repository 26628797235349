import axios from 'axios';
import { BModal } from 'bootstrap-vue';
import Vue from 'vue';
import BFormGroupSlot from '@/components/base/BFormGroupSlot.vue';
import geoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete.vue';
import EventBus from '@/packs/EventBus';

export default {
  name: 'ModalSlot',
  components: { BModal, BFormGroupSlot, geoUnitsAutocomplete },
  props: {
    url: {},
    reload: {
      type: Boolean,
    },
    emit: {},
  },
  data() {
    return {
      templateRender: '',
      form: '',
      method: '',
      action: '',
      attrs: '',
    };
  },
  mounted() {
    const slot = this.$el.childNodes[0];
    if (slot) {
      slot.onclick = () => {
        this.show();
      };
    }
  },
  methods: {
    compile(data) {
      const html = $.parseHTML(data);
      const form = $(html).find('form')[0];
      const parent = $(form).parent()[0];
      const textHtml = $(parent).html();
      const { render, staticRenderFns } = Vue.compile(textHtml);
      this.templateRender = render;
      this.$options.staticRenderFns = [];
      for (const staticRenderFunction of staticRenderFns) {
        this.$options.staticRenderFns.push(staticRenderFunction);
      }
      this.action = form.getAttribute('action');
      this.method = form.getAttribute('method');
    },
    show() {
      this.$refs.modal.show();
      axios.get(this.url).then((res) => {
        this.compile(res.data);
      });
    },
    onSubmit(event) {
      event.preventDefault();
      const form = $('form');
      const formData = new FormData(form[0]);
      axios[this.method](this.action, formData).then((res) => {
        this.compile(res.data);
        const html = $.parseHTML(res.data);
        const resForm = $(html).find('form')[0];
        if (!resForm) {
          if (this.reload) location.reload();
          if (this.emit) EventBus.$emit(this.emit.toString());
        }
      });
    },
  },
  render(h) {
    console.log(this.$attrs);
    return h('div', [
      this.$slots.default,
      h(
        'b-modal',
        {
          ref: 'modal',
          on: {
            ok: this.onSubmit,
          },
          attrs: this.attrs,
        },
        [this.templateRender && this.templateRender()],
      ),
    ]);
  },
};
