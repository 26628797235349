<template>
  <div @mouseover="show" @mouseout="hide" v-html="signal"/>
</template>

<script>
export default {
  name: 'SignalStatus',
  props: {
    signal: String,
  },
  methods: {
    show(e) {
      $(e.target.closest('.btn')).popover('show');
    },
    hide(e) {
      $(e.target.closest('.btn')).popover('hide');
    },
  },
};
</script>

<style lang="scss" scoped></style>
