<template>
  <div>
    <p>{{ $t('user.edit.permission.provide_access_to_buildings') }}:</p>
    <BuildingList
      :value="buildingsPermissions"
      :required="true"
      :clientId="clientId"
      :disabled-btn="disabledAddBtn"
      :add-title="$t('user.edit.permission.add_building')"
      disable-add-check-property="building_id"
      init-geo-unit-name-property="building_name"
      type-eq="Building"
      @input="inputBuildings"
    />
    <p class="mt-3">
      {{ $t('user.edit.permission.provide_access_to_districts') }}:
    </p>
    <DistrictsList
      :value="districtsPermissions"
      :required="true"
      :clientId="clientId"
      :add-title="$t('user.edit.permission.add_district')"
      :disabled-btn="disabledAddBtn"
      disable-add-check-property="district_id"
      init-geo-unit-name-property="district_name"
      @input="inputDistricts"
    ></DistrictsList>
  </div>
</template>

<script>
import DistrictsList from '@/components/lists/DistrictsList.vue';
import BuildingList from '@/components/lists/BuildingsList.vue';

export default {
  name: 'FormPermissionsBuildings',
  components: { DistrictsList, BuildingList },
  props: {
    buildingsPermissions: {
      type: Array,
    },
    clientId: {
      type: [Number, String],
    },
    districtsPermissions: {
      type: Array,
    },
    disabledAddBtn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    inputBuildings(event) {
      this.$emit('input-buildings', event);
    },
    inputDistricts(event) {
      this.$emit('input-districts', event);
    },
  },
};
</script>

<style lang="scss" scoped></style>
