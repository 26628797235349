export const RAILS_ENV = {
  development: 'development',
  stage: 'stage',
  production: 'production',
};

export const currentEnv = process.env.RAILS_ENV;

export function isCurrentRailsEnv(...envs) {
  return envs.includes(currentEnv);
}

export function isDevelopmentEnv() {
  return isCurrentRailsEnv(RAILS_ENV.development);
}
export function isStageEnv() {
  return isCurrentRailsEnv(RAILS_ENV.stage);
}
export function isProductionEnv() {
  return isCurrentRailsEnv(RAILS_ENV.production);
}
