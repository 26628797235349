<template>
  <b-modal
    id="district-modal"
    :title="this.$t('building_create.addresses.district_modal.title')"
    @ok="onOk"
    ref="entityModal"
    modal-class="fast-modal"
  >
    <b-form-validate
      ref="form"
      @submit="onOk"
      :validations="validations"
      :model="model"
    >
      <template slot-scope="{ validateState }">
        <div class="modal-body-row with-cols pt-30">
          <b-form-row>
            <b-col cols="12">
              <b-input-label
                id="input-district-modal_name"
                :state="validateState('name')"
                :label="$t('name')"
                autocomplete="off"
                v-model="model.name"
                :class="!backend_valid && errors ? 'mb-0' : 'mb-small'"
                autofocus
              />
              <b-error
                v-if="!backend_valid && errors"
                :error="errors"
                class="d-block"
              />
            </b-col>
          </b-form-row>
        </div>
      </template>
    </b-form-validate>
    <template #modal-footer>
      <div
        class="d-flex align-items-center justify-content-between flex-grow-1"
      >
        <b-button
          @click="hide"
          size="sm"
          variant="outline-primary"
          class="fast-modal__btn w-auto h-auto"
        >
          {{ $t('devices.modal.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          class="fast-modal__btn w-auto h-auto"
          @click="onOk"
        >
          {{ $t('devices.modal.save') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { ApiBackendDistricts } from '@/api';
import BError from '@/components/base/BError';

function initData() {
  return {
    model: {
      name: '',
    },
    errors: null,
    districtId: null,
    backend_valid: true,
  };
}

export default {
  name: 'DistrictModal',
  components: {
    BError,
  },
  data() {
    return {
      ...initData(),
    };
  },
  computed: {
    validations() {
      return {
        name: {
          required,
          backend_valid: () => {
            return this.backend_valid;
          },
        },
      };
    },
  },
  methods: {
    async onOk(event) {
      if (event.preventDefault) {
        event.preventDefault();
      }

      if (this.$refs.form.validate()) {
        ApiBackendDistricts.create({
          name: this.model.name.trim(),
        })
          .then((entity) => {
            this.$emit('entityCreated', entity);
            this.$refs.entityModal.hide();
          })
          .catch((error) => {
            this.backend_valid = false;
            if (error.errors) {
              this.errors = error.errors.join(', ');
            } else {
              this.$bvToast.toast(error);
            }
          });
      }
    },
    show() {
      Object.assign(this.$data, initData());
      this.$refs.entityModal.show();
    },
    hide() {
      this.$refs.entityModal.hide();
    },
  },
  watch: {
    'model.name': {
      handler: function () {
        this.backend_valid = true;
      },
    },
  },
};
</script>
