<template>
  <div class="row">
    <div class="col-lg-6">
      <div class="portlet">
        <div class="h5 mt-1">
          {{ $t('global.device') }}
        </div>

        <div class="row pt-2">
          <div class="col-lg-6">
            <b-select-label
              v-model="device.area"
              :label="$t('meters.form.device_type')"
              :items="areas"
              display="name"
              objectProperty="id"
              @selected="areaChanged"
              :state="validateState('area')"
            />
          </div>
          <div class="col-lg-6">
            <b-input-label
              v-model="device.serial"
              :label="$t('meters.form.serial')"
              :is-invalid="Boolean(errors['serial'])"
              :state="validateState('serial')"
            />
            <b-error
              v-if="errors['serial'] && isSubmit"
              :error="$t('global.serial') + ' ' + errors['serial'].join(', ')"
            />
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-lg-6">
            <b-select-label
              v-model="device.pulse_counters_model_id"
              :label="$t('meters.form.model')"
              :items="data.models"
              display="name"
              objectProperty="id"
              :state="validateState('pulse_counters_model_id')"
              @input:item="modelChanged"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="portlet">
        <div class="h5 mt-1">
          {{ $t('global.location') }}
        </div>
        <div class="mt-3">
          <geo-units-autocomplete
            v-model="device.geo_unit_id"
            :placeholder="$t('global.address')"
            :additional-query-params="{
              type_in: [
                'Building',
                'Apartment',
                'Bkfn',
                'TechRoom',
                'ParkingPlace',
              ],
            }"
            :client-id="clientId"
            :disabled="noArea"
            :geo-unit="device.geo_unit"
            :state="validateState('geo_unit_id')"
            @select="geoUnitChanged"
            @select:item="(val) => (device.geo_unit = val)"
            @clear="clearGu"
          ></geo-units-autocomplete>
        </div>
      </div>
      <div v-if="showUspdConnection" class="portlet">
        <div class="h5 mt-1">
          {{ $t('meters.form.binding_to_USPD') }}
        </div>
        <div class="mt-3">
          <uspd-devices-autocomplete
            v-model="device.uspd_device_id"
            :placeholder="$t('meters.form.serial_USPD')"
            ref="uspd_device"
            :client-id="clientId"
            :geo-unit-id="device.geo_unit_id"
            :uspd="device.uspd_device"
            :disabled="uspdDisabled"
            :state="validateState('uspd_device_id')"
            @select:item="(val) => (device.uspd_device = val)"
            @clear="device.uspd_device = null"
          ></uspd-devices-autocomplete>
        </div>
      </div>
      <div v-if="!noModel" class="portlet">
        <div class="h5 mt-1">
          {{ $t('meters.form.channels') }}
        </div>
        <b-button
          :variant="channelError ? 'danger' : 'outline-primary'"
          @click="channelAdd"
          :disabled="allChannelsApplied"
          class="btn-block mb-3 mt-3"
        >
          {{ channelsAddButtonName }}
        </b-button>
        <b-error
          v-if="channelError"
          :error="$t('meters.form.channels_error')"
        />
        <div v-for="(channel, index) in appliedChannels" :key="index">
          <div class="container mb-3 p-0">
            <div class="row align-items-center">
              <div class="col-10">
                <div class="short-div">
                  <BSelectLabel
                    :value="device.channels[channel.indexArray].index"
                    display="name"
                    objectProperty="id"
                    :label="$t('meters.form.channel_number')"
                    :items="
                      getUnusedChannels(
                        device.channels[channel.indexArray].index,
                      )
                    "
                    :key="`channel${index}`"
                    :state="channelState('index', channel.indexArray)"
                    @select="
                      (val) => selectIndexChannel(val, channel.indexArray)
                    "
                  />
                </div>
                <div class="short-div">
                  <meters-autocomplete
                    v-model="device.channels[channel.indexArray].meter.id"
                    :placeholder="$t('meters.form.serial_PU')"
                    :clientId="clientId"
                    :geo-unit-id="device.geo_unit_id"
                    :meter-data="device.channels[channel.indexArray].meter"
                    @select="
                      (val) => (device.channels[channel.indexArray].meter = val)
                    "
                    @clear="
                      (val) => (device.channels[channel.indexArray].meter = {})
                    "
                    :state="channelState('meter', channel.indexArray)"
                  ></meters-autocomplete>
                </div>
              </div>
              <div class="col-2">
                <button
                  type="button"
                  @click="
                    channelRemove(
                      device.channels[channel.indexArray],
                      channel.indexArray,
                    )
                  "
                  class="btn btn-lg btn-outline-primary"
                >
                  {{ $t('button.delete') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import BError from '@/components/base/BError';
import BInputLabel from '@/components/base/BInputLabel';
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete';
import MetersAutocomplete from '@/components/autocompletes/metersAutocomplete';
import UspdDevicesAutocomplete from '@/components/autocompletes/uspdDevicesAutocomplete';

export default {
  name: 'meteringMeterPulseForm',
  components: {
    BError,
    BInputLabel,
    GeoUnitsAutocomplete,
    MetersAutocomplete,
    UspdDevicesAutocomplete,
  },
  props: {
    areas: {
      type: Array,
      required: true,
    },
    clientId: {
      type: Number,
      required: true,
    },
    currentArea: null,
    data: {
      type: Object,
    },
    errors: {
      type: Object,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    validateState: {
      type: Function,
    },
    v: {},
    isSubmit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('meters', ['device']),
    ...mapGetters('meters', ['getDevice']),
    device: {
      get() {
        return this.getDevice;
      },
      set(value) {
        this.setDevice(value);
      },
    },

    allChannelsApplied() {
      if (this.appliedChannels) {
        return this.appliedChannels.length === this.channelsCapacity;
      } else return false;
    },
    appliedChannels() {
      if (this.channels) {
        return this.channels.filter((ch) => ch._destroy !== true);
      } else return null;
    },
    destroyedChannels() {
      if (this.channels) {
        return this.channels.filter((ch) => ch._destroy == true);
      } else return null;
    },
    channels() {
      if (this.device.channels) {
        return this.device.channels.map((item, idx) => ({
          ...item,
          indexArray: idx,
        }));
      }
      return [];
    },
    availableChannels() {
      let indexes = [];
      for (let i = 1; i <= this.device.model.inputs_count; i++) {
        indexes.push(i);
      }
      return indexes;
    },
    channelsCapacity() {
      return this.device.model.inputs_count;
    },
    channelsAddButtonName() {
      return !this.allChannelsApplied
        ? this.$t('meters.form.add_channel')
        : this.$t('meters.form.all channels_added');
    },
    noArea() {
      return this.device.area == null;
    },
    noModel() {
      return this.device.pulse_counters_model_id == null;
    },
    uspdDisabled() {
      if (this.device.geo_unit_id != null) {
        return false;
      } else return true;
    },
    showUspdConnection() {
      return !this.noArea;
    },
    usedChannelIndexes() {
      return this.device.channels
        .filter((ch) => ch.index != null && ch._destroy !== true)
        .map((ch) => ({ id: ch.index, name: String(ch.index) }));
    },
    unusedChannels() {
      return this.availableChannels
        .filter(
          (index) =>
            !this.usedChannelIndexes
              .map((channel) => channel.id)
              .includes(index),
        )
        .map((index) => ({ id: index, name: String(index) }));
    },
    channelError() {
      return this.device.channels.length == 0 && this.isSubmit;
    },
  },
  methods: {
    ...mapMutations('meters', [
      'setDevice',
      'removeChannel',
      'addChannel',
      'resetChannels',
    ]),

    areaChanged() {
      this.device = Object.assign({}, this.device, {
        pulse_counters_model_id: null,
        meter_model_id: null,
        uspd_device_id: null,
        geo_unit_id: null,
        geo_unit: null,
        model: null,
        channels: [],
      });
    },
    channelAdd(index) {
      this.addChannel(index);
    },
    channelRemove(channel, index) {
      this.removeChannel({
        id: channel.id,
        index: index,
      });
    },
    channelsDeploy() {
      if (this.noModel) return;

      this.resetChannels();
    },
    geoUnitChanged() {
      this.uspdDeviceIdReset();
    },
    clearGu() {
      this.device = Object.assign({}, this.device, {
        geo_unit: null,
        geo_unit_id: null,
      });
      this.uspdDeviceIdReset();
    },
    modelChanged(val) {
      if (this.device.model && val.id == this.device.model.id) {
        return;
      } else {
        this.device.model = Object.assign({}, val);
        this.channelsDeploy();
      }
    },
    uspdDeviceIdReset() {
      this.device = Object.assign({}, this.device, {
        uspd_device: null,
        uspd_device_id: null,
      });
    },
    channelState(field, idx) {
      if (this.isSubmit) {
        return !this.v?.model?.channels.$each.$iter[idx][field].$invalid;
      }
    },
    selectIndexChannel(val, indexArray) {
      let duplicateByChannelIndex = this.device.channels.findIndex(
        (channel) => channel.index == val && channel._destroy,
      );

      const originalChannel = this.device.channels[indexArray];
      const newChannel = Object.assign({}, originalChannel);
      // если нет удалённых только на фронте дублей с таким же каналом
      if (duplicateByChannelIndex < 0) {
        // то просто обновляем канал
        newChannel.index = val;
        this.device.channels.splice(indexArray, 1, newChannel);
        return;
      }

      let duplicateByChannel = this.device.channels[duplicateByChannelIndex];
      // если поменяли канал на уже существующий и удалённый (скрытый на фронте),
      // то заменить айди на айди скрытого, скрытый дропнуть из массива
      // и дестройнуть текущий, если есть айди

      newChannel.id = duplicateByChannel.id;
      newChannel.index = val;

      // если текущий есть в базе, то надо его удалить
      if (originalChannel.id) {
        originalChannel._destroy = true;
        // и поставить на место удаленного тк он скрыт
        this.device.channels.splice(
          duplicateByChannelIndex,
          1,
          originalChannel,
        );
      } else {
        // просто удаляем скрытый
        this.device.channels.splice(duplicateByChannelIndex, 1);
        if (duplicateByChannelIndex < indexArray) {
          indexArray -= 1;
        }
      }

      this.device.channels.splice(indexArray, 1, newChannel);
    },
    getUnusedChannels(selectedChannelIndex) {
      if (!selectedChannelIndex) return this.unusedChannels;
      const isSelectedChannelIdx = this.unusedChannels.findIndex(
        (channel) => channel.id == selectedChannelIndex,
      );
      let arrayChannels = this.unusedChannels;
      if (isSelectedChannelIdx > -1) {
        return arrayChannels;
      } else {
        return _.sortBy(
          arrayChannels.concat([
            {
              id: selectedChannelIndex,
              name: String(selectedChannelIndex),
            },
          ]),
          ['id'],
        );
      }
    },
  },
};
</script>
