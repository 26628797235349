<template>
  <div class="d-grid grid-template-columns grid-template-columns-2 gap-4 mb-4">
    <div
      class="portlet pt-0 mb-0 d-flex flex-column flex-grow-1"
      v-for="camera in camerasSnapshots"
      :key="camera.id"
    >
      <div class="d-flex justify-content-between pt-3 pb-2">
        <span>{{ camera.name }}</span>
        <div
          :class="camera.status == 'online' ? 'text-success' : 'text-danger'"
          v-if="camera.status"
        >
          {{ $t(`camera.${camera.status}`) }}
        </div>
      </div>
      <div
        v-if="camera.webrtc_supported"
        class="img img-fluid d-block w-100 h-100 camera-img"
      >
        <CameraPlayer
          :background="camera.live_snapshot_url"
          :deviceId="camera.id"
        />
      </div>
      <template v-else>
        <div
          class="d-flex justify-content-center align-items-center h-100 w-100"
        >
          <img
            v-if="camera.live_snapshot_url"
            class="h-auto w-auto mw-100 mh-100 rounded-lg"
            :src="camera.live_snapshot_url"
          />

          <div v-else class="img-placeholder w-100 camera-img" />
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import EventBus from '@/packs/EventBus';
import { request3 } from '@/api/request';
import { API_URLS } from '@/consts';
import StreamVideo from '@/components/intercom/StreamVideo.vue';
import CameraPlayer from '@slim/manage_components/npr/CameraPlayer.vue';

export default {
  name: 'NprCameras',
  components: { CameraPlayer, StreamVideo },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      camerasSnapshots: [],
    };
  },
  mounted() {
    EventBus.$on('NprEvents::update', () => {
      this.updateSnapshots();
    });
  },
  created() {
    this.updateSnapshots();
  },
  computed: {
    uuidCameras() {
      return this.value.map((camera) => {
        return camera.id;
      });
    },
  },
  methods: {
    async updateSnapshots() {
      if (this.uuidCameras.length === 0) return;
      this.camerasSnapshots = await request3
        .get(API_URLS.backendManage.cctv.cameras.devices.fetch(this.clientId), {
          params: {
            q: {
              id_in: this.uuidCameras,
            },
          },
        })
        .then((res) => res.data.cameras);
    },
  },
};
</script>
<style>
.camera-img {
  /* min-height: 455px; */
  object-fit: cover;
}
</style>
