import { groupBy } from 'lodash';
import { getValuesFromStringWithNumbers } from '@/vue_app/components/building_creat/utils';
import { AppendUnitAnimation } from '@app/components/building_edit/helpers/helpers';

import Store from '@app/store';

const unitsWithoutChildren = [
  'Apartment',
  'TechRoom',
  'Bkfn',
  'StoreRoom',
  'Roof',
  'ParkingPlace',
];

export function generateUnit(id, name, type, structure_index, parent) {
  const newUnit = {
    id,
    name,
    type,
    structure_index,
    parentType: parent.type,
    parentId: parent.id,
    parent,
    editing: false,
    _create: true,
  };
  if (!unitsWithoutChildren.includes(type)) {
    newUnit.children = [];
    // Vue.set(newUnit, 'children', []);
  }
  AppendUnitAnimation(newUnit);

  return newUnit;
}

export function generateName(unitNumber, parent, prefix = null) {
  if (!prefix) return String(unitNumber);
  const newPrefix = prefix.replace('{parent_name}', parent.name);
  return `${newPrefix}${unitNumber}`;
}

export function getExcludedNumbers(rules) {
  const allExcludedRules = rules.map((rule) => rule.exclude);
  return getValuesFromStringWithNumbers(allExcludedRules.join(','));
}

export function getNextIndex(parent) {
  if (!parent.children) {
    throw new Error(
      `Cant find next child structure_index for unit type ${parent.type}`,
    );
  }
  if (!parent.children.length) return 1000;
  return parent.children[parent.children.length - 1].structure_index + 1000;
}

export function getAdditiveRules(rules, parentType, parentNumber) {
  return rules.filter((rule) => {
    const properParentType = rule.parentType === parentType;

    if (parentType === 'Building') return properParentType;
    if (!rule.parentIndexes) return properParentType;

    const parentNumbers = getValuesFromStringWithNumbers(
      String(rule.parentIndexes),
    );
    return properParentType && parentNumbers.includes(parentNumber);
  });
}

export function getRuleOrException(rules, parentNumber) {
  const exception = rules.find((rule, index) => {
    if (!index) return false;
    const ruleExclusions = getValuesFromStringWithNumbers(
      String(rule.parentIndexes),
    );
    return ruleExclusions.includes(parentNumber);
  });

  return exception || rules[0];
}

function generatePlaceRule(parentIndex, parentName, parentType) {
  return {
    parent: parentName,
    parentType,
    parentIndex,
    quantity: '',
    exclude: '',
    prefix: '',
  };
}

function updateEntranceRule(rule, entrancesNumber) {
  const result = [];
  const unitIndexes = rule.parentIndexes
    ? getValuesFromStringWithNumbers(rule.parentIndexes)
    : Array(entrancesNumber)
        .fill()
        .map((i, index) => index + 1);
  const validIndexes = unitIndexes.filter((index) => index <= entrancesNumber);

  if (!validIndexes.length) {
    return [];
  }

  validIndexes.forEach((parentIndex) => {
    const parentName = `${rule.prefix ? rule.prefix : ''}${parentIndex}`;
    const updatedRule = generatePlaceRule(
      parentIndex,
      parentName,
      rule.parentType,
    );

    const existingRules = rule.structure.filter((oldRule) => {
      return oldRule.parentIndex === parentIndex;
    });

    for (let i = 0; i < rule.quantity; i += 1) {
      if (existingRules[i]) {
        existingRules[i].parentType = updatedRule.parentType;
        existingRules[i].parent = updatedRule.parent;
        existingRules[i].parentIndex = updatedRule.parentIndex;
      }
      result.push(existingRules[i] || { ...updatedRule });
    }
  });
  return result;
}

function updateBuildingRule(rule, startIndex) {
  const { building } = Store.state.edit_building;
  const result = [];
  let unitIndex = startIndex;

  for (let i = 0; i < rule.quantity; i += 1) {
    const parentName = generateName(unitIndex, building, rule.prefix);
    const currentRule = rule.structure[i];
    const updatedRule = generatePlaceRule(i + 1, parentName, rule.parentType);
    if (currentRule) {
      currentRule.parentType = updatedRule.parentType;
      currentRule.parent = updatedRule.parent;
      currentRule.parentIndex = updatedRule.parentIndex;
    }
    result.push(currentRule || { ...updatedRule });
    unitIndex += 1;
  }
  return result;
}

export function updatePlaceRule(rule) {
  // Clear rule structure if rule is for entrances, and no entrances expected
  if (rule.parentType === 'none') {
    rule.structure = [];
    return;
  }

  // Clear rule structure if rule is for entrances, and no entrances expected
  const { Entrance } = Store.state.edit_building.building.meta.structure;
  if (rule.parentType === 'Entrance') {
    if (!Entrance || !Entrance.quantity) {
      rule.structure = [];
      return;
    }
  }

  rule.structure =
    rule.parentType === 'Entrance'
      ? updateEntranceRule(rule, Entrance.quantity)
      : updateBuildingRule(rule);
}

export function updateParkingPlaceRules() {
  const { building } = Store.state.edit_building;
  const { ParkingArea, Entrance } = building.meta.structure;
  if (!ParkingArea || !ParkingArea.length) return;

  const grouped = groupBy(ParkingArea, 'parentType');

  const entrances = getEntrancesNumbers();
  if (!entrances.length) delete grouped.Entrance;

  if ('Entrance' in grouped) {
    grouped.Entrance.forEach((placeRule) => {
      const ruleParents = getValuesFromStringWithNumbers(
        placeRule.parentIndexes,
      );
      placeRule.parentIndexesArray = placeRule.parentIndexes
        ? entrances.filter((el) => ruleParents.includes(el))
        : [...entrances];
    });

    const updatedAreaRules = [];
    grouped.Entrance.forEach((rule, ruleIndex) => {
      updatedAreaRules[ruleIndex] = [];
    });

    const createdPerEntrance = [];
    entrances.forEach((entranceIndex) => {
      createdPerEntrance[entranceIndex] = 0;
    });

    entrances.forEach((entranceIndex) => {
      grouped.Entrance.forEach((placeRule, ruleIndex) => {
        const ruleIsViableForEntrance =
          placeRule.parentIndexesArray.includes(entranceIndex);
        if (!ruleIsViableForEntrance) return;

        const entranceName = generateName(
          entranceIndex,
          building,
          Entrance.prefix,
        );

        const existingRules = placeRule.structure.filter(
          ({ parentIndex }) => parentIndex === entranceIndex,
        );

        for (let i = 0; i < placeRule.quantity; i += 1) {
          const parkingName = generateName(
            createdPerEntrance[String(entranceIndex)] + 1,
            { name: entranceName },
            placeRule.prefix,
          );

          if (existingRules[i]) {
            existingRules[i].parentType = placeRule.parentType;
            existingRules[i].parent = parkingName;
            existingRules[i].parentIndex = entranceIndex;
          }

          updatedAreaRules[String(ruleIndex)].push(
            existingRules[i] ||
              generatePlaceRule(
                entranceIndex,
                parkingName,
                placeRule.parentType,
              ),
          );
          createdPerEntrance[String(entranceIndex)] += 1;
        }
      });
    });

    grouped.Entrance.forEach((placeRule, ruleIndex) => {
      placeRule.structure.splice(
        0,
        placeRule.structure.length,
        ...updatedAreaRules[String(ruleIndex)],
      );
    });
  }
  if ('Building' in grouped) {
    let startIndex = 1;
    grouped.Building.forEach((rule) => {
      rule.structure = updateBuildingRule(rule, startIndex);
      startIndex += rule.quantity;
    });
  }
  if ('none' in grouped) {
    grouped.none.forEach((rule) => {
      rule.structure = [];
    });
  }
}

function getEntrancesNumbers() {
  const { Entrance } = Store.state.edit_building.building.meta.structure;
  const result = [];

  if (Entrance.quantity) {
    const excluded = getValuesFromStringWithNumbers(Entrance.exclude);
    let i = 1;
    while (result.length < Entrance.quantity) {
      if (!excluded.includes(i)) result.push(i);
      i += 1;
    }
  }
  return result;
}
