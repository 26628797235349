<template>
  <div class="d-flex gap-2 flex-wrap">
    <building-copy-button v-if="show" />
    <b-button
      :href="`/manage/${clientId}/home/districts/${districtId}/buildings/${buildingId}/edit`"
      type="button"
      v-if="show"
    >
      {{ $t('building.edit.building') }}
    </b-button>
  </div>
</template>

<script>
import { getManageId } from '@/helpers';
import BuildingCopyButton from './BuildingCopyButton.vue';

export default {
  name: 'BuildingButtons',
  components: { BuildingCopyButton },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    clientId: {
      type: String,
      default: getManageId(),
    },
    buildingId: {
      type: String,
      required: true,
    },
    districtId: {
      type: String,
      required: true,
    },
  },
};
</script>
