<template>
  <div>
    <slot
      :alfred-api-access="alfredApiAccess"
      :alfred-meters-api-access="alfredMetersApiAccess"
      :change-alfred-api-access="changeAlfredApiAccess"
      :change-alfred-meters-api-access="changeAlfredMetersApiAccess"
    ></slot>
  </div>
</template>
<script>
export default {
  name: 'AdminClientForm',
  props: {
    clientData: Object,
  },
  computed: {},
  data() {
    return {
      alfredApiAccess: this.clientData.alfred_api_access,
      alfredMetersApiAccess: this.clientData.alfred_meters_api_access,
    };
  },
  methods: {
    changeAlfredApiAccess(event) {
      this.alfredApiAccess = event.target.checked;

      if (!this.alfredApiAccess) {
        this.alfredMetersApiAccess = false;
      }
    },
    changeAlfredMetersApiAccess(event) {
      this.alfredMetersApiAccess = event.target.checked;
    },
  },
};
</script>
