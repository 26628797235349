import { fullTypes, shortAddressTypes } from '@/consts';
import i18n from '@app/i18n';
import { cloneDeep, differenceBy, intersectionBy, pick } from 'lodash';
import qs from 'qs';
import Vue from 'vue';

export function getAuthToken() {
  const token = JSON.parse(localStorage.getItem('token'));
  if (token && token.access_token) {
    return token;
  }
  return false;
}

export function getSelectedRolePanel() {
  return window.location.pathname.match(/\/[a-z]*/)[0].replace('/', '');
}

export function getUserName(user) {
  if (user instanceof Object && Object.keys(user).length) {
    const name = user.name ? `${user.surname || ''} ${user.name}`.trim() : null;
    return name || user.phone || user.email || '';
  }
  // console.warn('getUserName: Provided data is invalid');
  return '';
}

export function getImageSrc(url) {
  return process.env.VUE_APP_SERVER_URL + url;
}

export function sortedGeoUnits(items = []) {
  const sorted = [];
  const parent = items.find((item) => !item.parent_id);
  if (parent) sorted.push(parent);
  while (sorted.length !== items.length) {
    sorted.push(
      items.find((item) => item.parent_id === sorted[sorted.length - 1].id),
    );
  }
  return sorted;
}

export function shortAddress(geoUnits) {
  const targetGeoUnit = geoUnits[geoUnits.length - 1];
  return geoUnits.reduce((acc, cur) => {
    if (
      shortAddressTypes[targetGeoUnit.type].includes(cur.type) ||
      cur.type === targetGeoUnit.type
    ) {
      const name = i18n.t(`geo_unit.type_short.${cur.type}`, [cur.name]);
      return acc.trim() ? `${acc}, ${name}` : name;
    }
    return acc;
  }, '');
}

export function prettifyAddress(items = []) {
  const parentsChain = items.parent_geo_units_chain || items;
  if (!Array.isArray(parentsChain)) return 'No address chain provided';
  let sorted = sortedGeoUnits(parentsChain);

  const getByType = (type) => {
    return shortAddress(sorted.filter((el) => el.type === type));
  };

  return {
    get fullAddress() {
      return parentsChain.reduce((acc, cur) => {
        const name = i18n.t(`geo_unit.type_short.${cur.type}`, [cur.name]);
        return acc.trim() ? `${acc}, ${name}` : name;
      }, '');
    },
    get shortAddress() {
      return shortAddress(sorted).trim();
    },
    get shortAddressPop() {
      return shortAddress(sorted.slice(0, -1));
    },
    limit(type) {
      const limitIndex = sorted.findIndex((el) => el.type === type);
      if (limitIndex) {
        sorted = sorted.splice(0, limitIndex);
        return this;
      }
      return 'Not found type';
    },
    get last() {
      const geoUnit = sorted.pop();
      return i18n.t(`geo_unit.type_short.${geoUnit.type}`, [geoUnit.name]);
    },
    get city() {
      return getByType('City');
    },
    get building() {
      return getByType('Building');
    },
    get entrance() {
      return getByType('Entrance');
    },
    get section() {
      return getByType('Section');
    },
    get floor() {
      return getByType('Floor');
    },
    get apartment() {
      return getByType('Apartment');
    },
  };
}

/**
 * @param {Object} geoUnit
 * @param {Array} allowedTypes
 */
export function validateGeoUnitByType(geoUnit, allowedTypes) {
  const response = { error: false };
  if (geoUnit instanceof Object && Array.isArray(allowedTypes)) {
    if (allowedTypes && !allowedTypes.includes(geoUnit.type)) {
      let typesList = '';
      if (allowedTypes.length > 1) {
        allowedTypes.forEach((type, index) => {
          if (index === 0) {
            typesList += `${fullTypes[type].toLowerCase()}`;
            return;
          }
          if (index + 1 === allowedTypes.length) {
            typesList += ` или ${fullTypes[type].toLowerCase()}`;
            return;
          }
          typesList += `, ${fullTypes[type].toLowerCase()}`;
        });
      } else {
        typesList += `только ${fullTypes[allowedTypes[0]].toLowerCase()}`;
      }
      const message = `Типом целевого геообъекта может быть ${typesList}`;
      Object.assign(response, { error: true }, { message });
    }
    return response;
  }
  console.warn('Provided data is invalid');
  return false;
}

export function filterGeoUnitsToTarget(geoUnits = [], targetGeoUnitId) {
  const addressGeoUnits = [];
  const targetGeoUnit = geoUnits.find(({ id }) => id === targetGeoUnitId);
  if (targetGeoUnit) addressGeoUnits.push(targetGeoUnit);
  while (true) {
    const geoUnit = geoUnits.find(
      ({ id }) => id === addressGeoUnits[addressGeoUnits.length - 1].parent_id,
    );
    if (geoUnit) {
      addressGeoUnits.push(geoUnit);
    } else {
      break;
    }
  }
  return addressGeoUnits.reverse();
}

export function getAvatar(user) {
  return (
    user.avatar_url ||
    (user.avatar && user.avatar.url) ||
    '/img/icon-no-avatar.svg'
  );
}

export function formatterCallSessionStatus({ pickedup_at, finished_at }) {
  if (pickedup_at && finished_at)
    return { status: 'success', variant: 'success' };
  if (finished_at) return { status: 'missed', variant: 'danger' };
  return { status: 'processing', variant: 'primary' };
}

export function getDistrictCardCounts(types, counts = {}, isGetResponse) {
  const data = {};
  types.forEach((type) => {
    data[type] = isGetResponse ? counts[type] || 0 : null;
  });
  return data;
}

export function getManageId() {
  const manage = location.pathname.match(/\/manage\/\d*/);
  if (manage && manage.length) {
    return manage[0] && manage[0].split('/')[2];
  }
  return false;
}

export function getDistrictId() {
  const district = location.pathname.match(/\/districts?\/\d*/);
  if (district && district.length) {
    return district[0] && district[0].split('/')[2];
  }
  return false;
}

export function getBuildingId() {
  const building = location.pathname.match(/\/buildings\/\d*/);
  if (building && building.length) {
    return building[0] && building[0].split('/')[2];
  }
  return false;
}

export function convertToNumber(id) {
  const number = Number(id);
  if (isNaN(number)) return undefined;
  return number;
}

export const getUrlParams = {
  search: () => qs.parse(location.search.replace('?', '')),
  hash: () => qs.parse(location.hash.replace('#', '')),
};

export function SubmitSignInFormToRedirect(rootEl) {
  const params = new URL(document.location).searchParams;
  if (params.get('client_id') === 'yandex_home') {
    location.href = `/users/oauth/oauth_yandex${location.search}`;
  } else {
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', '/users/sign_in');
    rootEl.append(form);
    form.submit();
  }
}

export function isDemoInstance() {
  return process.env.APP_NAME === 'iot-demo';
}

export function replaceInArrayByIndex(array = [], index = 0, newValue) {
  return array.map((item, i) => (index === i ? newValue : item));
}

export function removeInArrayByIndex(array = [], index = 0) {
  return array.filter((item, i) => i !== index);
}

/**
 * @function NestedAttributes Подготавливает список nestedAttributes для отправки на сервер
 * @param {Array} serverList - Список пришедший с сервера
 * @param {string} property - Свойство по которому будет производится сравнение
 * @param {string[]} pickPropertiesInResult - Список отправляемых полей на сервер
 * */
export class NestedAttributes {
  constructor(serverList = [], property = 'id', pickPropertiesInResult = []) {
    this.pickPropertiesInResult = pickPropertiesInResult;
    this.serverList = cloneDeep(serverList);
    this.list = Vue.observable(serverList);
    this.property = property;
  }

  get compare() {
    const notChangedItems = intersectionBy(
      this.serverList,
      this.list,
      this.property,
    );
    const newItems = differenceBy(this.list, this.serverList, this.property);
    const deletedItems = differenceBy(
      this.serverList,
      this.list,
      this.property,
    ).map((item) => ({ _destroy: true, ...item }));
    const result = [...notChangedItems, ...deletedItems, ...newItems];
    if (
      Array.isArray(this.pickPropertiesInResult) &&
      this.pickPropertiesInResult.length
    ) {
      return result.map((item) =>
        pick(item, [
          ...this.pickPropertiesInResult,
          'id',
          '_destroy',
          this.property,
        ]),
      );
    }
    return result;
  }
}

export function concatString(values = [], delimiter = '\n') {
  const string = values.filter(
    (value) => value !== null && value !== undefined,
  );
  return string.join(delimiter);
}

export function startFirstLet(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getLocale() {
  let locale;

  for (let param of document.cookie.split('; ')) {
    const [key, value] = param.split('=');
    if (key === 'locale') {
      locale = value;
      break;
    }
  }

  if (!['ru', 'en'].includes(locale)) {
    locale = 'ru';
  }

  return locale;
}
