import _ from 'lodash';
import axios from 'axios';
import axiosJsonp from 'axios-jsonp';
import {getLocale} from "@/helpers";

const headers = {
  ContentType: 'application/json',
  Accept: 'application/json',
};

const geocoderLocales = {
  'ru': 'ru_RU',
  'en': 'en_US',
  'tr': 'tr_TR',
};

function pushGeoUnit(array, object, type) {
  if (object) {
    array.push({ type, name: object.name, meta: object.meta });
  }
}

export default {
  /** @return {Promise.<YandexAddressFeatureMember[]>} */
  findByAddress(params) {
    return new Promise((resolve, reject) => {
      axios
        .get('https://geocode-maps.yandex.ru/1.x/', {
          adapter: axiosJsonp,
          params: {
            apikey: '9fe5aca9-99e6-48c7-8c70-8f43ec71759c',
            format: 'json',
            lang: geocoderLocales[getLocale()],
            ...params,
          },
          headers,
        })
        .then(({ data }) => {
          resolve(data.response.GeoObjectCollection.featureMember);
        })
        .catch((err) => reject(err));
    });
  },
  /** @return {Promise.<YandexAddressFeatureMember[]>} */
  findByCoords(params) {
    return axios
      .get('https://geocode-maps.yandex.ru/1.x/', {
        adapter: axiosJsonp,
        params: {
          apikey: '9fe5aca9-99e6-48c7-8c70-8f43ec71759c',
          format: 'json',
          lang: geocoderLocales[getLocale()],
          ...params,
        },
        headers,
      })
      .then(({ data }) => {
        // console.log('res data', data);
        return data.response.GeoObjectCollection.featureMember;
      });
  },

  /**
   * @typedef {Object} GeoUnit
   * @property {string} name
   * @property {string} king
   * @property {{
   *   lng: string,
   *   lat: string
   * }} meta
   * */

  /**
   * @typedef {Object} responseFindParamsByCoords
   * @property {{
   *   city: undefined | GeoUnit
   *   street: undefined | GeoUnit
   *   house: undefined | GeoUnit
   *   districts: GeoUnit[]
   * }} address
   * @property {string} formatted
   * */

  /**
   * @return {Promise<(responseFindParamsByCoords | Boolean)>}
   * */
  async findParamsByCoords(lng, lat) {
    const suggestions = await this.findByCoords({
      geocode: `${lng},${lat}`,
    });
    return new Promise((resolve, reject) => {
      if (suggestions.length) {
        const structure = _(suggestions)
          .map(
            (item) =>
              item.GeoObject.metaDataProperty.GeocoderMetaData.Address
                .Components,
          )
          .reverse()
          .flatten()
          .uniqBy('name')
          .reverse()
          .value();

        // console.log('findParamsByCoords structure', structure);

        const house = structure.find(({ kind }) => kind === 'house');
        const street = structure.find(({ kind }) => kind === 'street');
        const city =
          structure.find(({ kind }) => kind === 'locality') ||
          structure.find(({ kind }) => kind === 'area') ||
          structure.find(({ kind }) => kind === 'province');
        const districts = structure
          .filter(({ kind }) => kind === 'district')
          .reverse();

        [city, ...districts].forEach((geo) => {
          const GeoObject = suggestions.find(
            (item) => item.GeoObject.name === geo?.name,
          )?.GeoObject;
          const geoCoords = GeoObject?.Point?.pos?.split(' ');
          if (geoCoords) {
            geo.meta = { lat: geoCoords[1], lng: geoCoords[0] };
          }
        });

        if (house) {
          // eslint-disable-next-line no-shadow
          const [lng, lat] = suggestions[0].GeoObject.Point.pos.split(' ');
          house.meta = { lng, lat };
        }

        const geoUnits = [];

        pushGeoUnit(geoUnits, city, 'City');
        districts.forEach((district) =>
          pushGeoUnit(geoUnits, district, 'District'),
        );
        pushGeoUnit(geoUnits, street, 'Street');
        pushGeoUnit(geoUnits, house, 'Building');

        resolve({
          address: {
            city,
            street: street || {},
            house: house || {},
            districts,
          },
          formatted:
            suggestions[0].GeoObject.metaDataProperty.GeocoderMetaData.Address
              .formatted,
        });
      }
      reject(Error('not found'));
    });
  },
};
