export const GEO_UNITS = {
  Apartment: 'Apartment',
  Bkfn: 'Bkfn',
  Building: 'Building',
  City: 'City',
  District: 'District',
  Entrance: 'Entrance',
  Floor: 'Floor',
  ParkingArea: 'ParkingArea',
  ParkingPlace: 'ParkingPlace',
  Roof: 'Roof',
  Section: 'Section',
  StoreRoom: 'StoreRoom',
  Street: 'Street',
  TechRoom: 'TechRoom',
  Territory: 'Territory',
  Users: 'Users',
};

export const GEO_UNIT_TYPES_ARRAY = Object.values(GEO_UNITS);
export default GEO_UNITS;

export const isEditBuildingPermission = (() => {
  const onlyView = document.querySelector('#user-district-viewer')?.dataset
    .viewer;
  return onlyView === 'false';
})();
