<template>
  <div id="dropdown" class="b-dropdown-input" :class="{ show: inputFocus }">
    <b-form-group
      class="b-selector-with-label multiselect"
      @focusout="onBlur"
      :data-title="label"
    >
      <legend :class="labelClass">
        {{ label }}
      </legend>
      <div class="d-flex align-items-center justify-content-center w-100">
        <div class="dropdown b-dropdown w-100 btn-group h-auto">
          <div
            class="btn multiselect__dropdown dropdown-toggle btn-transparent dropdown-toggle-no-caret h-auto"
            @click="onFocus"
            tabindex="0"
            :class="[{ 'have-items': inputFocus }, getState]"
          >
            <div class="d-flex gap-3 flex-wrap multiselect__wrapper">
              <div
                class="multiselect__selected selected-tag"
                v-for="(select, index) in objItems"
                :key="`select${index}`"
                @click="onSelect(select)"
              >
                <span class="selected-tag__text">
                  {{ getTitleValue(select) }}
                </span>
                <i
                  class="icon-local-times multiselect__clear selected-tag__icon"
                ></i>
              </div>
              <input
                ref="word"
                :disabled="isDisabled"
                @keyup="onKeyUp"
                @focus="onFocus"
                v-model="word"
                type="text"
                class="multiselect__input mb-0"
                tabindex="-1"
                :class="!inputFocus ? 'multiselect__input_hidden' : ''"
              />
            </div>

            <div class="icon-local-shevron-down dropdown-caret"></div>
          </div>
          <ul v-if="loading" class="list-group">
            <li
              class="list-group-item d-flex justify-content-center align-items-center"
            >
              <b-spinner :variant="variantSpinner"></b-spinner>
            </li>
          </ul>
          <ul
            v-else
            role="menu"
            tabindex="-1"
            class="list-group"
            :class="{ scrollable }"
          >
            <li
              v-if="canCreate"
              class="list-group-item list-group-item_create"
              @focusout="onBlurCreate"
              tabindex="0"
              @click="onCreateNew"
              @keyup.enter="onCreateNew"
            >
              <div>
                {{ createMessage }}
              </div>
            </li>
            <template v-if="formattedResult">
              <li
                :key="item.id"
                v-for="(item, index) in fetchedItems"
                @click="onSelect(item)"
                @keyup.enter="onSelect(item)"
                @focusout="(e) => onBlurInResults(e, index)"
                class="list-group-item"
                tabindex="0"
              >
                <div class="list-group-item__checkbox">
                  <i v-if="getCheckboxValue(item)" class="icon-local-ok"></i>
                </div>
                <div
                  :key="key"
                  v-for="(position, key) of formattedResult"
                  :class="`list-group-item-${key}`"
                >
                  <div
                    :key="idx"
                    v-for="({ title, formatter }, idx) of position"
                    class="mlist-group-item__title"
                    :class="`list-group-item-${key}-text`"
                    v-html="
                      getOverlapText(
                        typeof formatter === 'function'
                          ? formatter(item[title], item)
                          : item[title],
                      )
                    "
                  ></div>
                </div>
              </li>
            </template>
            <template v-else>
              <li
                v-for="(item, index) in fetchedItems"
                :key="index"
                @click="onSelect(item)"
                @keyup.enter="onSelect(item)"
                @focusout="(e) => onBlurInResults(e, index)"
                class="list-group-item"
                tabindex="0"
              >
                <div class="list-group-item__checkbox">
                  <i v-if="getCheckboxValue(item)" class="icon-local-ok"></i>
                </div>
                <div class="mlist-group-item__title">
                  {{ getTitleValue(item) }}
                </div>
              </li>
            </template>
            <li
              v-if="Array.isArray(fetchedItems) && !fetchedItems.length"
              class="list-group-item"
            >
              {{ emptyMessage }}
            </li>
          </ul>
        </div>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import {
  debounce,
  get,
  isString,
  isFunction,
  isObject,
  isNumber,
} from 'lodash';
import { onBlurInResults, onBlurCreate, onBlurInput } from './helper.js';

export default {
  name: 'BMultiAutocomplete',
  props: {
    // функция для поиска элементов (промис, возвращает элементы)
    handler: {
      type: Function,
      required: true,
    },
    // можно ли из автокомплита создавать элемент
    canCreate: {
      type: Boolean,
    },
    // свойство, которое прокидывается при выборе элемента наверх (например для фильтра)
    compareProperty: {
      type: [String, Function],
      default: 'id',
    },
    debouncedTts: {
      type: Number,
      default: 200,
    },
    disabled: {
      type: Boolean,
    },
    // отображаемое свойство выбранного элемента
    displayProperty: {
      type: [String, Function],
    },
    label: {
      type: String,
    },
    state: {
      type: Boolean,
      default: undefined,
    },
    // особое отображение результатов поиска
    formattedResult: { type: Object, default: null },
    // скроллятся ли результаты поиска
    scrollable: { type: Boolean, default: true },
    // автозаполнение
    autocomplete: { type: String, default: 'off' },
    // текст, на который надо навести для подсказки
    popoverText: {
      type: String,
      default: 'i',
    },
    // текст подсказки
    popoverHint: {
      type: String,
      default: null,
    },
    // айди хинта
    hintId: {
      type: String,
      default: null,
    },
    // положение хинта
    placementPopover: {
      type: String,
      default: 'bottom',
    },
    // показываем ли спиннер
    showLoading: {
      type: Boolean,
      default: true,
    },
    // тип спиннера
    variantSpinner: {
      type: String,
      default: 'primary',
    },
    // минимальное количество символов для поиска
    minWordLength: {
      type: Number,
      default: 0,
    },
    maxWordLength: {
      type: Number,
      default: 100,
    },
    // массив выбранных элементов
    value: {
      type: Array,
      default: () => [],
    },
    // сообщение, если ни один элемент не нашелся
    emptyMessage: {
      type: String,
    },
    // текст, по клику на который создается новый элемент
    createMessage: {
      type: String,
    },
    validator: {
      type: Function,
    },
    isObj: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fetchedItems: null,
      objItems: [],
      word: '',
      debouncedSearch: null,
      inputFocus: false,
      isLoading: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.getSelected();
      },
    },
  },
  computed: {
    labelClass() {
      const active =
        !this.inputFocus && this.valueIsEmpty
          ? 'float-label-inactive'
          : 'float-label-active';
      const disabled = this.disabled ? 'disabled' : '';
      return [active, disabled];
    },
    valueIsEmpty() {
      if (!this.value) {
        return true;
      } else {
        return this.value.length == 0;
      }
    },
    getState() {
      if (this.state === undefined) return '';
      if (this.state) return 'is-valid';
      return 'is-invalid';
    },
    isDisabled() {
      return this.disabled;
    },
    loading() {
      return (
        this.showLoading &&
        this.isLoading &&
        (this.objItems.length == 0 || this.word)
      );
    },
  },
  mounted() {
    if (this.debouncedTts) {
      this.initDebounce();
    }
    this.getSelected();
    document.addEventListener('click', this.eventClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.eventClick);
  },
  methods: {
    onBlurInResults,
    onBlurCreate,
    onBlurInput,
    getSelected() {
      if (this.value?.length > 0) {
        this.fetchItems().then(() => {
          if (this.fetchedItems) {
            this.objItems = this.fetchedItems.filter((item) =>
              this.value.includes(this.getItemId(item)),
            );
          }
        });
      } else {
        this.objItems = [];
      }
    },
    onFocus(event) {
      event.preventDefault();
      this.inputFocus = true;
      this.$emit('focus', event);
      this.$refs.word.focus();
    },
    onKeyUp() {
      if (this.debouncedSearch) {
        this.debouncedSearch();
      } else {
        this.search();
      }
    },
    onBlur() {
      this.inputFocus = false;
    },
    getCheckboxValue(item) {
      const value = this.getItemId(item);
      return this.value?.find((modelValue) => modelValue === value);
    },
    getItemId(item) {
      const value = this.getValue(item, this.compareProperty);
      if (!value) {
        return;
      }
      return value;
    },
    getValue(item, property = '') {
      if (isFunction(this.compareProperty)) {
        return this.compareProperty(item);
      }
      if (isString(item) || isNumber(item)) return item;
      if (isObject(item)) {
        return get(item, property);
      }
      return;
    },
    getTitleValue(value) {
      if (isFunction(this.displayProperty)) {
        return this.displayProperty(value);
      } else return this.getValue(value, this.displayProperty);
    },
    onSelect(item) {
      const value = this.getItemId(item);
      if (isFunction(this.validator)) {
        if (!this.validator(value)) {
          return;
        }
      }
      this.word = null;
      if (this.valueIsEmpty) {
        const newArr = [];
        newArr.push(value);

        this.$emit('input', newArr);

        this.objItems.push(item);
      } else {
        const index = this.value.findIndex(
          (modelValue) => modelValue === value,
        );
        if (index > -1) {
          const clonedValue = [...this.value];
          this.objItems.splice(index, 1);
          clonedValue.splice(index, 1);
          this.$emit('input', clonedValue);
        } else {
          this.objItems.push(item);
          this.$emit('input', [...this.value, value]);
        }
      }

      this.$emit('input:obj', this.objItems);
    },
    fetchItems() {
      this.isLoading = true;
      return this.handler(this.word)
        .then((response) => {
          this.fetchedItems = response;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    search() {
      if (!this.inputFocus) {
        return;
      }
      this.fetchItems();
    },
    getOverlapText(value) {
      return value
        ? String(value).replace(this.word, (change) => `<u>${change}</u>`)
        : '';
    },
    initDebounce() {
      this.debouncedSearch = debounce(() => {
        this.search();
      }, this.debouncedTts);
    },
    eventClick(event) {
      if (
        !this.$el.contains(event.target) &&
        !event.target.className.includes('selected-tag')
      ) {
        if (this.inputFocus) {
          this.$emit('hide', true);
        }
        this.inputFocus = false;
        this.fetchedItems = null;
      } else {
        this.$emit('hide', false);
        this.onKeyUp();
      }
    },
    onCreateNew() {
      this.fetchedItems = null;
      this.inputFocus = false;
      this.$emit('create');
    },
  },
};
</script>
