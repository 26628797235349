<template>
  <div class="buildings">
    <div
      v-if="geoUnit"
      class="accordion-item"
      :class="{ 'not-collapsed': isOpen }"
      role="tab"
      @click="onClick"
    >
      <span>{{ geoUnit.short_name }}</span>
      <div class="accordion-item__controls">
        <b-spinner v-if="isLoading" variant="primary" small></b-spinner>
        <i class="icon-local-chevron-up"></i>
      </div>
    </div>
    <b-collapse
      v-model="isOpen"
      :id="`sub-accordion-${index}`"
      :accordion="`sub-accordion-${parent.id}`"
    >
      <GeoUnitCollapse
        v-for="(childrenGeoUnit, index) in children"
        :key="childrenGeoUnit.id"
        :geoUnit="childrenGeoUnit"
        :index="`${childrenGeoUnit.id}_${index}`"
        :parent="geoUnit"
        :blackList="blackList"
        style="padding-left: 15px"
        @add="onAddInBlackList"
        @remove="onRemoveInBlackList"
      ></GeoUnitCollapse>
      <RelaysOfGeoUnitsTable
        :key="`table-${geoUnit.id}-${geoUnit.type}-${index}`"
        :relays="relays"
        :black-list="blackList"
        @add="onAddInBlackList"
        @remove="onRemoveInBlackList"
      />
    </b-collapse>
  </div>
</template>

<script>
import { ApiBackendRelays, ApiGeoUnits } from '@/api';
import { GEO_UNITS } from '@/consts';
import RelaysOfGeoUnitsTable from '@app/components/relays_groups/RelaysOfGeoUnitsTable.vue';

export default {
  name: 'GeoUnitCollapse',
  components: { RelaysOfGeoUnitsTable },
  props: {
    geoUnit: { type: Object, default: () => {} },
    parent: { type: Object, default: () => ({ id: 0 }) },
    index: { type: Number, default: 0 },
    blackList: { type: Array, default: () => [] },
  },
  data() {
    return {
      children: [],
      relays: [],
      isLoaded: false,
      isLoading: false,
      isOpen: false,
    };
  },
  methods: {
    onAddInBlackList(id) {
      this.$emit('add', id);
    },
    onRemoveInBlackList(id) {
      this.$emit('remove', id);
    },
    onClick() {
      if (!this.isLoaded) {
        this.getRelays();
        return;
      }
      this.isOpen = !this.isOpen;
      if (this.isOpen) this.getRelays();
    },
    getChildren() {
      if (this.geoUnit.type === GEO_UNITS.Territory) {
        ApiGeoUnits.getAll({
          q: { parent_id_eq: this.geoUnit.id, type_eq: GEO_UNITS.Building },
        }).then((res) => {
          this.children = res;
        });
      }
    },
    getRelays() {
      if (this.isLoaded) return;
      this.isLoading = true;

      const promises = [];

      if (this.geoUnit.type === GEO_UNITS.Territory) {
        const getChildren = ApiGeoUnits.getAll({
          q: { parent_id_eq: this.geoUnit.id, type_eq: GEO_UNITS.Building },
        })
          .then((res) => {
            this.children = res;
          })
          .catch((err) => console.error(err));
        promises.push(getChildren);
      }

      const q = {};
      if (this.geoUnit.geo_unit_type === GEO_UNITS.Territory) {
        q.available_for_geo_unit_id_exclude_buildings = this.geoUnit.id;
      } else {
        q.available_for_geo_unit_id = this.geoUnit.id;
      }

      const getRelays = ApiBackendRelays.getAll({
        q,
      })
        .then((res) => {
          // ВРЕМЕННО | Сервер отдаёт список всех реле на пути к GeoUnit, если у нас дом, скрываем все что находится на территории
          if (this.geoUnit.type === GEO_UNITS.Building) {
            this.relays = res.filter(
              (relay) => relay.geo_unit_type !== GEO_UNITS.Territory,
            );
          } else {
            this.relays = res;
          }
        })
        .catch((err) => console.error(err));

      promises.push(getRelays);

      Promise.all(promises).finally(() => {
        this.isLoaded = true;
        this.isLoading = false;
        this.isOpen = true;
      });
    },
  },
};
</script>

<style lang="scss"></style>
