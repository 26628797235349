<template>
  <div class="intercom-autocomplete">
    <BDropdownInput
      v-model="selected_id"
      :description="description"
      :display-property="displayProperty"
      :disabled="disabled"
      :label="placeholder"
      :handler="fetch"
      :selected="intercomData"
      :state="state"
      :showLoading="showLoading"
      :variantSpinner="variantSpinner"
      :debouncedTts="debouncedTts"
      :compareProperty="compareProperty"
      :formattedResult="formattedResult"
      @clear="onClear"
      @focus="onFocus"
      @select="onSelect"
      @select:item="onSelectItem"
      :popoverText="popoverText"
      :popoverHint="popoverHint"
      :hintId="hintId"
      :minWordLength="minWordLength"
    />
  </div>
</template>

<script>
import BDropdownInput from '@/components/base/BDropdownInput';
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';

export default {
  name: 'IntercomAutocomplete',
  components: {
    BDropdownInput,
  },
  props: {
    saveFormId: String,
    model: String,
    name: String,
    intercomData: Object,
    clientId: {
      type: Number,
    },
    placeholder: String,
    value: {
      type: [String, Number],
    },
    state: {},
    debouncedTts: {
      type: Number,
      default: 200,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    additionalQueryParams: {
      type: Object,
      default() {
        return {};
      },
    },
    description: {
      type: String,
    },
    minWordLength: {
      type: Number,
      default: 3,
    },
    showLoading: {
      type: Boolean,
      default: true,
    },
    variantSpinner: {
      type: String,
      default: 'primary',
    },
    compareProperty: {
      type: String,
      default: 'id',
    },
    displayProperty: {
      type: String,
      default: 'uuid',
    },
    popoverText: {
      type: String,
      default: 'i',
    },
    popoverHint: {
      type: String,
      default: null,
    },
    hintId: {
      type: String,
      default: null,
    },
    placementPopover: {
      type: String,
      default: 'bottom',
    },
  },
  data() {
    return {
      selected_id: null,
    };
  },
  computed: {
    formattedResult() {
      return {
        top: [
          {
            title: 'uuid',
            formatter: (value) => value,
          },
        ],
        bottom: [
          {
            title: 'geo_unit_short_name',
            formatter: (val, item) => item.geo_unit_short_name,
          },
          {
            title: 'name',
            formatter: (val, item) => item.name,
          },
        ],
      };
    },
  },
  mounted() {
    if (this.intercomData instanceof Object) {
      if (this.intercomData.id) {
        this.selected_id = this.intercomData.id;
      }
    }
  },
  methods: {
    onSelect(value) {
      this.$emit('input', value);
      this.$emit('select', value);
      this.selected_id = value;
      this.saveForm();
    },
    onSelectItem(item) {
      this.$emit('select:item', item);
    },
    saveForm() {
      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    fetch(val) {
      let params = {
        uuid_or_name_or_geo_unit_short_name_cont_any: val,
      };
      let query = {
        ...params,
        ...this.additionalQueryParams,
      };

      return request3
        .get(API_URLS.backendManage.intercoms.fetch(this.clientId), {
          params: {
            q: query,
          },
        })
        .then((res) => res.data);
    },
    onClear() {
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
  },
};
</script>
