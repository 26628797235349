<template>
  <div class="camera-play-stream" @click="onStartPlay" id="stream">
    <resize-observer @notify="resizeBlock" />
    <div v-if="!playing" class="camera-play-stream__circle">
      <div class="camera-play-stream__button"></div>
    </div>
    <StreamVideo
      autoplay
      device-type="camera"
      :background="background"
      :device-id="deviceId"
      :name="name"
      :height="heightBlock"
      ref="StreamVideo"
    />
  </div>
</template>

<script>
import StreamVideoModal from '@/components/modals/StreamVideoModal.vue';
import StreamVideo from '@/components/intercom/StreamVideo.vue';

export default {
  components: {
    StreamVideo,
    StreamVideoModal,
  },
  props: {
    deviceId: {
      required: true,
    },
    background: {
      type: String,
      default: '',
    },
    name: {},
  },
  data() {
    return {
      playing: false,
      block: null,
      heightBlock: null,
    };
  },
  mounted() {
    this.block = document.getElementById('stream');
    if (this.block) {
      this.resizeBlock();
    }
  },
  methods: {
    onStartPlay() {
      if (!this.playing) {
        this.playing = true;
        this.$refs.StreamVideo.play();
      }
    },
    resizeBlock() {
      this.heightBlock = (this.block.offsetWidth / 16) * 9 + 'px';
    },
  },
};
</script>

<style lang="scss">
.camera-play-stream {
  // height: calc(100% - 4rem);
  // width: calc(100% - 4rem);
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .camera-play-stream__circle {
    position: absolute;
    display: flex;
    border-radius: 100px;
    height: 100px;
    width: 100px;
    background-color: rgb(0%, 0%, 0%, 0.5);

    justify-content: center;
    align-items: center;
    transition: opacity 0.3s;

    z-index: 9;
  }

  &:hover .camera-play-stream__circle {
    opacity: 1;
    transition: opacity 0.3s;
    background-color: #000;
  }

  .camera-play-stream__button {
    margin-left: 13px;
    box-sizing: border-box;
    width: 43px;
    height: 50px;
    border-style: solid;
    border-color: transparent transparent transparent white;
    border-width: 25px 0 25px 43px;
  }
}
</style>
