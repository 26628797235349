<template>
  <div
    class="col-sm-6 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mb-2 mb-md-4 disctrict-card"
  >
    <a
      :href="`/manage/${clientId}/home/districts/${district.id}`"
      class="text-decoration-none d-block w-100 portlet mb-2 p-0 h-100"
    >
      <div class="position-relative text-left">
        <div
          v-if="district.image"
          class="rounded-top-lg bg-secondary bg-cover district-card__photo"
          :style="`background-image: url(${district.image});`"
        ></div>
        <div
          v-else
          class="rounded-top-lg district-card__photo district-card__photo_default"
        ></div>
      </div>
      <div class="my-1 d-flex px-4 pt-4 pb-4">
        <div>
          <div
            v-if="district.health !== null"
            class="object-condition"
            :class="`object-condition-${getDistrictColor()} object-condition-${
              district.health
            }`"
          >
            {{ district.health }}
            <pie-chart
              :percent="district.health"
              :stroke-width="1"
              :stroke-opacity="0.8"
              :opacity="0.8"
              label=" "
              label-small=" "
              class="object-condition-segment"
            />
          </div>
          <div v-else class="rounded-circle district-card__circle"></div>
        </div>
        <div class="pl-3 mb-0 object-condition-title">
          <h4 class="mb-0 mt-1">{{ district.name }}</h4>
          <span class="text-muted">
            {{
              district.buildings_cnt +
              ' ' +
              $tc('district.home', district.buildings_cnt)
            }}
          </span>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import PieChart from '@/components/base/PieChart.vue';

export default {
  name: 'DisctrictCard',
  components: { PieChart },
  props: {
    district: {
      type: Object,
      required: true,
    },
    clientId: {
      type: [Number, String],
      required: true,
    },
  },
  methods: {
    getDistrictColor() {
      if (0 <= this.district.health && this.district.health <= 34) {
        return 'red';
      }
      if (35 <= this.district.health && this.district.health <= 74) {
        return 'yellow';
      }
      if (75 <= this.district.health && this.district.health <= 100) {
        return 'green';
      }
      return 'gray';
    },
  },
};
</script>

<style lang="scss">
.district-card {
  &__photo {
    background-position: center;
    height: 230px;
    background-repeat: no-repeat;
    background-size: cover;

    &_default {
      background-image: url('/img/illustrations/empty_buildings.svg');
      background-size: 60%;
      background-color: #f7f9fa;
    }
  }

  &__circle {
    height: 48px;
    width: 48px;
    background-color: #ececec;
  }
}
</style>
