import _ from 'lodash';

export function checkStringWithNumbers(str) {
  if (str.trim() === '') return true;
  if (str.indexOf('.') > -1) return false;
  if (str.indexOf('-') === 0) return false;
  if (/[0-9, -]+/g.test(str)) {
    const items = str.split(',');
    return items.every((item) => {
      if (item.indexOf('-') > -1) {
        const distance = item.split('-');
        if (distance.length > 2 || distance[1].trim() === '') return false;
        return Number(distance[0]) < Number(distance[1]);
      }
      return !Number.isNaN(Number(item));
    });
  }
  return false;
}

export function getValuesFromStringWithNumbers(str) {
  const items = str.split(',');
  const values = items.map((item) => {
    if (item.indexOf('-') > -1) {
      const [start, end] = item.split('-');
      if (start.trim() === '') return Number(-end);
      return _.range(Number(start), Number(end) + 1);
    }
    if (item.trim() === '') return false;
    return Number(item);
  });
  // console.log('values', values);
  return _(values)
    .flatten()
    .filter((value) => value !== false)
    .uniq()
    .sortBy()
    .value();
}
