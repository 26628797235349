<template>
  <div>
    <BInputLabel
      v-model="valueModel"
      :type="type"
      :label="placeholder"
      :disabled="disabled"
      :state="state"
      ref="word"
    >
      <template #icon>
        <BHintPopover
          :hintId="hintId"
          :popover-text="popoverText"
          :popover-hint="popoverHint"
          :placement="placementPopover"
        />
      </template>
    </BInputLabel>
    <span class="small text-danger" v-if="error">{{ error }}</span>
  </div>
</template>
<script>
import BInputLabel from '@/components/base/BInputLabel';
import BHintPopover from '@/components/base/BHintPopover';
export default {
  name: 'BInputHintLabel',
  components: {
    BInputLabel,
    BHintPopover,
  },
  props: {
    value: [String, Number],
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    popoverText: {
      type: String,
      default: 'i',
    },
    popoverHint: {
      type: String,
      default: null,
    },
    placementPopover: {
      type: String,
      default: 'bottom',
    },
    hintId: {
      type: String,
      default: 'binput-info',
    },
    type: {
      type: String,
      default: 'text',
    },
    state: {
      type: Boolean,
      default: undefined,
    },
    error: {
      type: String,
      default: null,
    },
  },
  computed: {
    valueModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('update:value', newValue);
      },
    },
  },
  methods: {
    onFocus() {
      this.$refs.word.focus();
    },
  },
};
</script>
