var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal-body-row with-cols border-bottom pt-30"},[_c('b-form-row',[_c('b-col',{attrs:{"offset-md":"6","md":"6"}},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.onShowCreateKeyModal}},[_vm._v("\n          "+_vm._s(_vm.$t('button.add'))+"\n        ")])],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"modal-body-row with-cols"},[_c('b-form-row',[_c('b-col',{staticClass:"user-edit-modal-table-wrapper",attrs:{"cols":"12"}},[(_vm.isLoading)?_c('div',{staticClass:"h-100 w-100 d-flex justify-content-center align-items-center"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):(_vm.keys.length < 1)?_c('div',[_vm._v("\n          "+_vm._s(_vm.$t('user.edit.key.list.empty'))+"\n        ")]):_c('b-table',{ref:"keys",staticClass:"user-modal-table with-dropdown",attrs:{"fields":_vm.fields,"items":_vm.keys,"striped":""},scopedSlots:_vm._u([{key:"cell(action)",fn:function({ item }){return [_c('BDropdownMenu',{attrs:{"items":_vm.dropdownItems.items,"data":item},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"icon-local-cog"})]},proxy:true}],null,true)})]}},{key:"cell(addresses)",fn:function({ item }){return [(
                item &&
                item.related_geo_units &&
                item.related_geo_units.length > 0
              )?_c('div',[_vm._v("\n              "+_vm._s(item.related_geo_units[0].full_name || 'none')+"\n              "),(item.related_geo_units.length > 1)?_c('small',{staticClass:"text-gray d-block"},[_vm._v("\n                "+_vm._s(_vm.$tc(
                    `addresses.and_more`,
                    item.related_geo_units.length - 1,
                    [item.related_geo_units.length - 1],
                  ))+"\n              ")]):_vm._e()]):_c('div',[_c('small',{staticClass:"text-gray d-block"},[_vm._v("\n                "+_vm._s(_vm.$t('user.edit.key.list.no_address'))+"\n              ")])])]}}])})],1)],1),_vm._v(" "),(_vm.pagination.pages > 1)?_c('b-form-row',[_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('b-pagination',{attrs:{"total-rows":_vm.pagination.count,"per-page":_vm.perPage,"aria-controls":"my-table","align":"center"},on:{"change":_vm.onChangePage},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}})],1)],1):_vm._e()],1),_vm._v(" "),_c('UserModalKey',{ref:"UserModalKey",attrs:{"client-id":_vm.clientId,"user-id":_vm.user.id},on:{"created":function($event){return _vm.getKeys()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }