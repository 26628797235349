import { getRolePrefix, request } from '../request';

export default async function (data, params = {}) {
  const { delay } = params;
  const { id } = await request('post', getRolePrefix('/batches'), null, {
    batch: data,
  });
  console.log('id', id);
  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      const { state, result } = await request(
        'get',
        getRolePrefix('/batches/:id'),
        id,
      );
      if (state === 'done') {
        clearInterval(interval);
        if (result.error) {
          reject(result.error);
        } else {
          resolve(result);
        }
      }
    }, delay || 1000);
  });
}
