<template>
  <div>
    <div v-if="isAdmin" class="modal-body-row with-cols border-bottom pt-30">
      <b-form-row>
        <b-col offset-md="6" md="6">
          <b-button
            class="mb-3"
            variant="outline-primary"
            @click="onShowAddRoleModal"
          >
            {{ $t('button.add') }}
          </b-button>
        </b-col>
      </b-form-row>
    </div>
    <div class="modal-body-row with-cols">
      <b-form-row>
        <b-col cols="12" class="user-edit-modal-table-wrapper">
          <div
            v-if="isLoading"
            class="h-100 w-100 d-flex justify-content-center align-items-center"
          >
            <b-spinner variant="primary"></b-spinner>
          </div>
          <div v-else-if="permissions.length < 1">
            {{ $t('user.edit.permission.no_permissions') }}
          </div>
          <b-table
            v-else
            ref="keys"
            class="user-modal-table with-dropdown"
            :fields="fields"
            :items="permissions"
            striped
          >
            <template v-slot:cell(actions)="{ item }">
              <BDropdownMenu :items="dropdownItems(item)" :data="item">
                <template v-slot:button-content>
                  <i class="icon-local-cog"></i>
                </template>
              </BDropdownMenu>
            </template>
          </b-table>
        </b-col>
      </b-form-row>
    </div>
    <UserModalPermissionAdd
      ref="UserModalPermissionRef"
      :permissions="permissionsToAdd"
      @success="getPermissions"
    />
    <UserModalPermissionEdit
      ref="UserModalPermissionEditRef"
      @success="getPermissions"
    />
  </div>
</template>

<script>
import { ApiPermissions } from '@/api';
import { PERMISSIONS, PERMISSIONS_ARRAY } from '@/consts';
import UserModalPermissionAdd from '../modals/UserModalPermissionAdd.vue';
import UserModalPermissionEdit from '../modals/UserModalPermissionEdit.vue';

export default {
  components: {
    UserModalPermissionAdd,
    UserModalPermissionEdit,
  },
  name: 'UserModalTabPermissions',
  props: {
    isAdmin: {
      type: Boolean,
    },
  },
  inject: ['clientId', 'user'],
  data() {
    return {
      isLoading: true,
      permissions: [],
    };
  },
  computed: {
    fields() {
      return [
        { key: 'actions', label: '' },
        {
          formatter: (value) => this.$t(`permissions.${value}`),
          key: 'type',
          label: this.$t('global.type'),
        },
      ];
    },
    permissionsToAdd() {
      const permissionsOnlyTitles = this.permissions.map(
        (permission) => permission.type,
      );
      return PERMISSIONS_ARRAY.filter(
        (permission) => !permissionsOnlyTitles.includes(permission),
      );
    },
  },
  mounted() {
    this.getPermissions();
  },
  methods: {
    async getPermissions() {
      try {
        this.isLoading = true;
        this.permissions = await ApiPermissions.getAll({
          q: { client_id_eq: this.clientId, user_id_eq: this.user.id },
        });
      } catch (e) {
        console.error(e);
      }
      this.isLoading = false;
    },
    dropdownItems(permission) {
      const items = [
        {
          handler: () => this.onDeletePermission(permission),
          icon: 'icon-local-trash text-danger',
          title: this.$t('button.delete'),
        },
      ];
      if (permission.type !== PERMISSIONS.Owner) {
        items.unshift({
          handler: () => this.onEditPermission(permission),
          icon: 'icon-local-pencil',
          title: this.$t('button.edit'),
        });
      }
      return items;
    },
    onDeletePermission({ id, type }) {
      this.$bvModal
        .msgBoxConfirm(
          this.$t('user.edit.permission.confirm_delete.message', [
            this.$t(`permissions.${type}`),
          ]),
          {
            bodyClass: 'modal-body__confirm',
            footerClass: 'border-0',
            headerClass: 'border-0',
            okTitle: this.$t('button.delete'),
            okVariant: 'danger',
            title: this.$t('user.edit.permission.confirm_delete.title'),
          },
        )
        .then((res) => {
          if (res) {
            ApiPermissions.delete(id).then(() => this.getPermissions());
          }
        });
    },
    onEditPermission(value) {
      this.$refs.UserModalPermissionEditRef.show(value);
    },
    onShowAddRoleModal() {
      this.$refs.UserModalPermissionRef.show();
    },
  },
};
</script>

<style scoped></style>
