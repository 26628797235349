<template>
  <div class="form-group">
    <BInputLabel
      class="m-0"
      v-bind="$attrs"
      v-model="value"
      :state="state"
      @inputNative="onInput"
    >
      <div class="d-none">
        <slot />
      </div>
    </BInputLabel>
    <div
      v-if="receivedError"
      v-html="receivedError"
      class="invalid-feedback d-block"
    />
  </div>
</template>

<script>
import BInputLabel from './BInputLabel.vue';

export default {
  name: 'BInputLabelHtmlError',
  components: { BInputLabel },
  data() {
    return {
      input: {},
      hasError: false,
      receivedError: '',
      value: '',
    };
  },
  mounted() {
    const defaultSlot = this.$slots.default[0];
    this.input =
      defaultSlot.tag === 'input'
        ? defaultSlot.elm
        : defaultSlot.elm.querySelector('input');

    const error = this.$el.querySelector('.invalid-feedback');

    if (error) {
      this.receivedError = error.innerText;
      this.hasError = true;
    }

    this.value = this.input.value;
  },
  computed: {
    state() {
      if (!this.input.value && !this.hasError) {
        return null;
      }
      return this.receivedError ? !this.receivedError : !this.hasError;
    },
  },
  methods: {
    onInput() {
      this.input.value = this.value;
    },
  },
};
</script>
