<template>
  <div>
    <slot
      :plate-data="plateData"
      :user-id="userId"
      :client-id="clientId"
      :user-data="userData"
      :car-data="carData"
      :option-cars="optionCars"
      :geo-unit-data="geoUnitData"
      :name-cars-autocomplete="nameCarsAutocomplete"
    ></slot>
  </div>
</template>
<script>
export default {
  name: 'FiltersPlatesFormSlim',
  props: {
    userData: {
      type: Object,
      default: {},
    },
    plateData: {
      type: Object,
      default: {},
    },
    clientId: {
      type: Number,
      default: null,
    },
    carMarkData: {
      type: Object,
      default: null,
    },
    carModelData: {
      type: Object,
      default: null,
    },
    geoUnitData: {
      type: Object,
      default: null,
    },
    optionCars: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    userId() {
      if (this.userData) {
        if (this.userData.user_id) {
          return this.userData.user_id;
        }
        if (this.userData.id) {
          return this.userData.id;
        }
      }
      return null;
    },
    carData() {
      if (this.carModelData?.id) {
        return this.carModelData;
      } else if (this.carMarkData?.id) {
        return this.carMarkData;
      }
      return null;
    },
    nameCarsAutocomplete() {
      if (this.carModelData?.id) {
        return this.optionCars.model;
      } else if (this.carMarkData?.id) {
        return this.optionCars.mark;
      }
      return null;
    },
  },
};
</script>
