var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"portlet p-0 m-0 mb-3 mb-md-4 d-flex building-card"},[_c('a',{staticClass:"text-decoration-none d-flex flex-grow-1",attrs:{"href":`/manage/${_vm.clientId}/home/districts/${_vm.districtId}/buildings/${_vm.building.id}`}},[_c('div',{staticClass:"mr-3 d-none d-md-block",staticStyle:{"width":"200px"}},[(_vm.building.image)?_c('div',{staticClass:"rounded-left bg-secondary building-card__photo",style:(`background-image: url(${_vm.building.image});`)}):_c('div',{staticClass:"rounded-left-lg building-card__photo building-card__photo_default"})]),_vm._v(" "),_c('div',{staticClass:"pt-3 pb-2 px-4 px-md-2 d-flex flex-column flex-grow-1"},[_c('div',{staticClass:"ml-0 mb-0"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.building.name))]),_vm._v(" "),_c('div',{staticClass:"small text-muted"},[_vm._v("\n          "+_vm._s(_vm.building.construction_address)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"text-muted"},[_vm._v("\n          "+_vm._s(_vm.infoBuilding)+"\n        ")])]),_vm._v(" "),(
          _vm.building.equipment_installed && _vm.building.equipment_installed.status
        )?_c('div',{staticClass:"mt-2 d-flex flex-wrap align-items-center"},[_c('div',{staticClass:"mt-2 d-flex flex-wrap align-items-center"},[(
              _vm.isVisibleScore(
                _vm.building.score.intercom,
                _vm.building.equipment_blue_status.status_intercom,
              )
            )?_c('div',{staticClass:"d-flex align-items-center mr-3 mr-md-5",class:_vm.getScoreColorClass(
                _vm.building.score.intercom,
                _vm.building.equipment_blue_status.status_intercom,
              )},[_c('div',{staticClass:"h4 icon-local-intercom0 mr-2 mb-1"}),_vm._v("\n            "+_vm._s(_vm.building.score.intercom)+"%\n          ")]):_vm._e(),_vm._v(" "),(
              _vm.isVisibleScore(
                _vm.building.score.fire_alarm,
                _vm.building.equipment_blue_status.status_fa,
              )
            )?_c('div',{staticClass:"d-flex align-items-center mr-3 mr-md-5",class:_vm.getScoreColorClass(
                _vm.building.score.fire_alarm,
                _vm.building.equipment_blue_status.status_fa,
              )},[_c('div',{staticClass:"h4 icon-local-safety mr-2 mb-1"}),_vm._v("\n            "+_vm._s(_vm.building.score.fire_alarm)+"%\n          ")]):_vm._e(),_vm._v(" "),(
              _vm.isVisibleScore(
                _vm.building.score.metering,
                _vm.building.equipment_blue_status.status_meters,
              )
            )?_c('div',{staticClass:"d-flex align-items-center mr-3 mr-md-5",class:_vm.getScoreColorClass(
                _vm.building.score.metering,
                _vm.building.equipment_blue_status.status_meters,
              )},[_c('div',{staticClass:"h4 icon-local-askue mr-2 mb-1"}),_vm._v("\n            "+_vm._s(_vm.building.score.metering)+"%\n          ")]):_vm._e(),_vm._v(" "),(
              _vm.isVisibleScore(
                _vm.building.score.cctv,
                _vm.building.equipment_blue_status.status_cctv,
              )
            )?_c('div',{staticClass:"d-flex align-items-center mr-3 mr-md-5",class:_vm.getScoreColorClass(
                _vm.building.score.cctv,
                _vm.building.equipment_blue_status.status_cctv,
              )},[_c('div',{staticClass:"h4 icon-local-cam_f mr-2 mb-1"}),_vm._v("\n            "+_vm._s(_vm.building.score.cctv)+"%\n          ")]):_vm._e()])]):_vm._e()]),_vm._v(" "),(_vm.building.territory)?_c('div',[_c('a',{staticClass:"building-card__territory",attrs:{"href":`/manage/${_vm.clientId}/home/districts/${_vm.districtId}/geo_units/${_vm.building.territory.id}`}},[_vm._v("\n        "+_vm._s(_vm.building.territory.name)+"\n      ")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }