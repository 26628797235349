<template>
  <div>
    <b-modal
      :title="$t('building_create.addresses.district_modal.title')"
      ref="acessCreateDistrict"
      modal-class="fast-modal"
      @hidden="hide"
    >
      <div class="modal-body-row with-cols text-center p-8">
        <div
          class="d-flex gap-2 align-items-center justify-content-center mb-3"
        >
          <i class="icon-local-ok fast-modal__icon fast-modal__icon_ok" />
          <p class="fast-modal__text mb-0">
            {{ $t('building_create.addresses.district_modal.success') }}
          </p>
        </div>
      </div>
      <template #modal-footer>
        <div
          class="d-flex align-items-center justify-content-between flex-grow-1"
        >
          <b-button
            @click="hide"
            size="sm"
            class="fast-modal__btn w-auto h-auto"
            variant="outline-primary"
          >
            {{ $t('building_create.addresses.district_modal.close') }}
          </b-button>
          <a
            class="btn fast-modal__btn w-auto h-auto btn-outline-primary btn-sm"
            :href="`/manage/${clientId}/home/districts/${districtId}`"
          >
            {{ $t('building_create.addresses.district_modal.open') }}
          </a>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'AccessDistrict',
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
    districtId: {
      type: [String, Number],
    },
  },
  methods: {
    show() {
      this.$refs.acessCreateDistrict.show();
    },
    hide(onReload = true) {
      this.$refs.acessCreateDistrict.hide();
      if (onReload) {
        location.reload();
      }
    },
  },
};
</script>
