export const unitWithParentTypes = {
  Floor: ['Entrance'],
  Section: ['Floor'],
  Apartment: ['Floor', 'Section'],
  Bkfn: ['Building', 'Floor', 'Section', 'TechRoom'],
  StoreRoom: ['Building', 'Entrance', 'Floor', 'Section', 'TechRoom'],
  TechRoom: [
    'Building',
    'ParkingArea',
    'Entrance',
    'Floor',
    'Section',
    'TechRoom',
  ],
  ParkingArea: ['Building', 'Entrance'],
  ParkingPlace: ['ParkingArea'],
  Entrance: ['Building'],
  Roof: ['Building', 'Entrance'],
};

export const GROUPING_TYPES = ['Section', 'TechRoom'];
export const AVAILABLE_IN_SECTION = ['Apartment', 'StoreRoom', 'Bkfn'];
export const AVAILABLE_IN_TECHROOM = ['TechRoom', 'StoreRoom', 'Bkfn'];
export const AVAILABLE_IN_GROUP = [
  'Apartment',
  'StoreRoom',
  'TechRoom',
  'Bkfn',
];

export const allParentTypes = [
  'Building',
  'Entrance',
  'Floor',
  'Section',
  'ParkingArea',
  'TechRoom',
];
