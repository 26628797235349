<template>
  <UuidSelectModal
    :clientId="clientId"
    ref="UuidSelectModal"
    id="intercom-uuid-select"
  />
</template>

<script>
import EventBus from '@/packs/EventBus';
import UuidSelectModal from './camera/fastAdd/UuidSelectModal.vue';
import { EVENTS } from '@/consts';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'CameraFastModal',
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
    platformSupport: {
      type: String,
      required: true,
    },
  },
  components: {
    UuidSelectModal,
  },
  computed: {
    ...mapState('global', ['global']),
    ...mapGetters('global', ['getPlatformSupport']),

    platform_support: {
      get() {
        return this.getPlatformSupport;
      },
      set(value) {
        this.setPlatformSupport(value);
      },
    },
  },
  mounted() {
    EventBus.$on(EVENTS.camera.fast.modal.show, this.show);
    this.platform_support = this.platformSupport;
  },
  methods: {
    ...mapMutations('global', ['setPlatformSupport']),

    show(reset, geoUnit) {
      this.$refs.UuidSelectModal.show(reset, geoUnit);
    },
  },
};
</script>
