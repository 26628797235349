<template>
  <BImage
    :url="url"
    :thumbUrl="thumbUrl"
    :height="height"
    :width="width"
    :title="barrierAddress"
  >
    <template v-slot:modal-custom-title>
      <div class="d-flex flex-wrap gap-3 align-items-center">
        <div v-if="barrierAddress">{{ barrierAddress }},</div>
        <div v-if="barrierName">{{ barrierName }},</div>
        <div class="d-inline-flex align-items-center">
          <i
            v-if="enumNprDirection"
            :class="
              enumNprDirection == 'in'
                ? 'icon-local-small-arrow-up events__icon events__icon_in'
                : 'icon-local-small-arrow-down events__icon events__icon_out'
            "
          />
          <div v-if="humanNprDirection">{{ humanNprDirection }},</div>
        </div>
        <div>{{ plateNumber }}</div>
      </div>
    </template>
  </BImage>
</template>

<script>
import BImage from '@/components/base/BImage.vue';
export default {
  name: 'EventImage',
  components: { BImage },
  props: {
    barrierName: {
      type: String,
      default: null,
    },
    barrierAddress: {
      type: String,
      default: null,
    },
    nprDirection: {
      type: String,
      default: null,
    },
    thumbUrl: {
      type: String,
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
    plateNumber: {
      type: String,
      default: null,
    },
    humanNprDirection: {
      type: String,
      default: null,
    },
    enumNprDirection: {
      type: String,
      default: null,
    },
    width: {
      type: [String, Number],
      default: 'auto',
    },
    height: {
      type: [String, Number],
      default: 150,
    },
  },
};
</script>

<style></style>
