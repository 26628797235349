<template>
  <div>
    <div class="row my-3">
      <div class="col-2">
        <div class="form-group select optional area">
          <select-slot :label="$t('meters.accounting_area')">
            <select
              @change="getData"
              v-model="filter.area"
              name="area"
              id="area"
              class="form-control select optional"
            >
              <option value="0">{{ $t('abbreviations.IPU') }}</option>
              <option value="1">{{ $t('abbreviations.HMD') }}</option>
            </select>
          </select-slot>
        </div>
      </div>
      <div class="col-4">
        <date-picker
          v-model="filter.created_at"
          value-type="format"
          format="YYYY-MM-DD"
          range
          :disabled-date="notAfterToday"
          :lang="lang"
          @change="getData"
          @pick="handlePick"
        ></date-picker>
      </div>
      <div class="col-auto">
        <div class="form-group">
          <b-form-radio-group
            id="period"
            v-model="filter.period"
            :options="periodOptions"
            buttons
            button-variant="outline-primary"
            size="md"
            name="period"
          ></b-form-radio-group>
        </div>
      </div>
      <!-- <div class="col text-right">
        <button @click="getData()" class="btn btn-lg btn-outline-primary">
          Показать
        </button>
      </div> -->
    </div>
    <div class="position-relative">
      <div class="spinner" v-show="loading">
        <b-spinner small variant="primary" type="grow"></b-spinner>
        <b-spinner small variant="primary" type="grow"></b-spinner>
        <b-spinner small variant="primary" type="grow"></b-spinner>
      </div>
      <div>
        <bar-chart-custom
          :chart-data="chartdata"
          :kind="$t('meters.consumption_m_cube')"
        ></bar-chart-custom>
      </div>
    </div>
    <div class="row justify-content-center mt-3">
      <div class="col-auto">
        {{ consLabel }}
      </div>
    </div>
  </div>
</template>

<script>
import BarChartCustom from '@/components/charts/BarChartCustom.js';
import SelectSlot from '@/vue_slim/components/base/SelectSlot';
import i18n from '@app/i18n';
import axios from 'axios';
import { BFormRadioGroup } from 'bootstrap-vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';

const today = new Date();
// today.setHours(0, 0, 0, 0);

export default {
  name: 'ConsumptionChart',
  components: {
    BarChartCustom,
    DatePicker,
    SelectSlot,
    BFormRadioGroup,
  },
  props: {
    objectId: Number,
    clientId: Number,
    labels: Array,
    coldData: Array,
    hotData: Array,
  },
  data() {
    return {
      chartdata: {},
      consLabel: '',
      loading: false,
      filter: {
        area: 0,
        period: 'today',
        created_at: [],
      },
      startDay: today,
      endDay: today,
      periodOptions: [
        { text: i18n.t('periods.today'), value: 'today' },
        { text: i18n.t('periods.yesterday'), value: 'yesterday' },
        { text: i18n.t('periods.week'), value: 'week' },
        // { text: 'Месяц', value: 'month' },
        { text: i18n.t('global.own'), value: 'range' },
      ],
    };
  },
  computed: {
    lang() {
      return {
        defaultLocale: 'ru',
        firstDayOfWeek: 1,
      };
    },
  },
  mounted() {
    this.fillData(this.labels, this.coldData, this.hotData);
    this.changeLabel();
  },
  watch: {
    'filter.period': {
      handler(val, oldVal) {
        const today = new Date();
        if (val != 'range') {
          switch (val) {
            case 'today':
              this.startDay = today;
              this.endDay = today;
              break;
            case 'yesterday':
              this.startDay = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate() - 1,
              );
              this.endDay = this.startDay;
              break;
            case 'week':
              this.endDay = today;
              this.startDay = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate() - 7,
              );
              break;
            case 'month':
              this.endDay = today;
              this.startDay = new Date(
                today.getFullYear(),
                today.getMonth() - 1,
                today.getDate(),
              );
              break;
          }
          this.filter.created_at = [
            this.formatDate(this.startDay),
            this.formatDate(this.endDay),
          ];
          this.getData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    fillData(labels, cold, hot) {
      this.chartdata = {
        labels,
        datasets: [
          {
            barPercentage: 1.0,
            categoryPercentage: 0.7,
            label: this.$t('meters.cold'),
            backgroundColor: '#03A9F4',
            data: cold,
          },
          {
            barPercentage: 1.0,
            categoryPercentage: 0.7,
            label: this.$t('meters.hot'),
            backgroundColor: '#FF4D4D',
            data: hot,
          },
        ],
      };
    },
    parseData(data) {
      this.fillData(
        Object.keys(data.cold),
        Object.values(data.cold),
        Object.values(data.hot),
      );
    },
    getData() {
      const app = this;
      app.loading = true;
      if (this.filter.created_at.filter((date) => !!date).length === 2) {
        axios
          .get(`/api/backend/manage/${this.clientId}/consumption`, {
            params: {
              geo_unit_id: this.objectId,
              area: this.filter.area,
              created_at: this.filter.created_at,
            },
          })
          .then((response) => {
            if (response.data != null) {
              this.parseData(response.data);
              this.fillDays();
              this.changeLabel();
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .then(function () {
            app.loading = false;
          });
      }
    },
    notAfterToday(date) {
      return date > today;
    },
    formatDate(day) {
      const mm = day.getMonth() + 1; // getMonth() is zero-based
      const dd = day.getDate();

      return [
        day.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd,
      ].join('-');
    },
    labelDate(day) {
      const mm = day.getMonth() + 1; // getMonth() is zero-based
      const dd = day.getDate();

      return [
        (dd > 9 ? '' : '0') + dd,
        (mm > 9 ? '' : '0') + mm,
        day.getFullYear(),
      ].join('.');
    },
    changeLabel() {
      if (this.startDay.getTime() == this.endDay.getTime()) {
        this.consLabel = this.$t('meters.consumption_in', [
          this.labelDate(this.startDay),
        ]);
      } else {
        this.consLabel = this.$t('meters.consumption_since_till', [
          this.labelDate(this.startDay),
          this.labelDate(this.endDay),
        ]);
      }
    },
    fillDays() {
      if (this.filter.period == 'range') {
        this.startDay = new Date(this.filter.created_at[0]);
        this.endDay = new Date(this.filter.created_at[1]);
      }
    },
    handlePick(date) {
      this.filter.period = 'range';
    },
  },
};
</script>

<style lang="scss">
$primary-color: #03a9f4;
@import '~vue2-datepicker/scss/index';

.mx-datepicker {
  width: 100%;

  .mx-input {
    height: 50px;
    border: #edeef0 1px solid;
  }

  .mx-icon-calendar {
    margin-right: 10px;
  }
}

.spinner {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
}
</style>
