<template>
  <div>
    <BDropdownInput
      v-model="selected_id"
      :description="description"
      :selected="faDevice"
      :disabled="disabled"
      :label="label"
      :state="state"
      :validator="validator"
      :handler="fetch"
      autocomplete="off"
      :compare-property="compareProperty"
      :display-property="displayProperty"
      :minWordLength="minWordLength"
      :combo-box="comboBox"
      @clear="onClear"
      @focus="onFocus"
      @select="onSelect"
      @select:item="onSelectItem"
    />
  </div>
</template>

<script>
import { request3 } from '@/api/request';
import BDropdownInput from '@/components/base/BDropdownInput.vue';
import { API_URLS } from '@/consts';
import { getManageId } from '@/helpers';

export default {
  name: 'FaPpkDevicesAutocomplete',
  components: { BDropdownInput },
  props: {
    clientId: {
      type: [Number, String],
      default: () => getManageId(),
    },
    description: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    initName: {
      type: String,
    },
    label: {
      type: String,
      default: '',
    },
    state: {
      type: [Boolean, undefined],
      default: undefined,
    },
    validator: {
      type: Function,
    },
    value: {
      type: Number,
    },
    deviceData: {
      type: Object,
      default: null,
    },
    compareProperty: {
      type: [String, Function],
      default: 'id',
    },
    displayProperty: {
      type: [String, Function],
      default: 'name',
    },
    minWordLength: {
      type: Number,
      default: 0,
    },
    idCanDevice: {
      type: Number,
      default: null,
    },
    comboBox: {
      type: Boolean,
      default: true,
    },
    model: String,
    name: String,
  },
  data() {
    return {
      selected_id: null,
      faDevice: this.deviceData,
    };
  },
  mounted() {
    if (this.faDevice.id) {
      this.selected_id = this.faDevice.id;
    }
  },
  methods: {
    fetch(val) {
      return request3
        .get(API_URLS.backendManage.devices.fa.ppk.fetch(this.clientId), {
          params: {
            q: { name_cont: val, fa_can_eth_device_id_eq: this.idCanDevice },
            limit: 8,
          },
        })
        .then((res) => res.data);
    },
    onClear() {
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
    onSelectItem(item) {
      this.$emit('select:item', item);
      this.faDevice = item;
    },
    onSelect(value) {
      this.$emit('input', value);
      this.$emit('select', value);
      this.selected_id = value;
    },
  },
};
</script>
