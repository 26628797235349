<template>
  <div class="intercom-autocomplete">
    <BDropdownInput
      v-model="selected_id"
      :disabled="disabled"
      :label="placeholder"
      :handler="fetch"
      :selected="model"
      :state="state"
      :debouncedTts="debouncedTts"
      @clear="onClear"
      @focus="onFocus"
      @select="onSelect"
      @select:item="onSelectItem"
      :minWordLength="minWordLength"
      compare-property="id"
      :display-property="itemName"
      comboBox
    />
  </div>
</template>

<script>
import BDropdownInput from '@/components/base/BDropdownInput';
import { request3 } from '@/api/request';
import { isEmpty } from 'lodash';

export default {
  name: 'IntercomModelsAutocomplete',
  components: {
    BDropdownInput,
  },
  props: {
    debouncedTts: {
      type: Number,
      default: 200,
    },
    placeholder: String,
    state: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    items: Array,
    currentModel: {
      type: Object,
      default: null,
    },
    minWordLength: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selected_id: null,
      model: null,
    };
  },
  mounted() {
    if (this.currentModel instanceof Object && !isEmpty(this.currentModel)) {
      this.model = this.currentModel;
      this.selected_id = this.currentModel.id;
    }
  },
  methods: {
    onSelect(value) {
      this.$emit('input', value);
      this.$emit('select', value);
      this.selected_id = value;
    },
    onSelectItem(item) {
      this.model = item;
      this.$emit('select:item', item);
    },
    onClear() {
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
    itemName(item) {
      return `${item?.info?.vendor || ''} ${item?.name || ''}`;
    },
    fetch(val) {
      let models = this.items;
      if (val) {
        models = this.items.filter((item) => {
          return this.itemName(item).toLowerCase().includes(val.toLowerCase());
        });
      }
      return new Promise((resolve) => resolve(models));
    },
  },
};
</script>
