<template>
  <div class="meters-autocomplete">
    <BDropdownInput
      v-model="selected_id"
      :disabled="disabled"
      :label="placeholder"
      :handler="fetch"
      :selected="meterData"
      :state="state"
      :debouncedTts="debouncedTts"
      :canCreate="canCreate"
      compare-property="id"
      display-property="serial"
      @clear="onClear"
      @focus="onFocus"
      @select="onSelect"
      @select:item="onSelectItem"
    />
    <input
      :value="selected_id"
      :id="`${model}_${name}`"
      type="hidden"
      :name="`${model}[${name}]`"
    />
  </div>
</template>

<script>
import axios from 'axios';
import BDropdownInput from '@/components/base/BDropdownInput.vue';

export default {
  name: 'metersAutocomplete',
  components: {
    BDropdownInput,
  },
  props: {
    // форма сохраняется если сделан выбор
    saveFormId: String,
    model: String,
    name: String,
    meterData: Object,
    clientId: {
      type: Number,
    },
    placeholder: String,
    value: {
      type: Number,
    },
    state: {},
    debouncedTts: {
      type: Number,
      default: 200,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected_id: null,
    };
  },
  mounted() {
    if (this.meterData instanceof Object) {
      this.selected_id = this.meterData.id;
    }
  },
  methods: {
    onSelect(result) {
      if (result) {
        this.selected_id = result;
        this.$emit('input', result);
        this.$emit('input_full', result);
        this.saveForm();
      }
    },
    onSelectItem(result) {
      if (result.id) {
        this.selected_id = result.id;
        this.$emit('input', result.id);
        this.$emit('select', result);
        this.saveForm();
      } else if (result.serial == this.$t('meter.create_device')) {
        const win = window.open('/admin/metering/meters/new', '_blank');
        win.focus();
      }
    },
    onClear() {
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
    saveForm() {
      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    fetch(val) {
      let prms = {
        q: val,
        limit: 8,
      };

      return axios
        .get(`/api/backend/manage/${this.clientId}/meters`, {
          params: prms,
        })
        .then((response) => {
          if (response.data != null) {
            return response.data.meters;
          } else {
            return null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
