<template>
  <div>
    <b-modal
      centered
      id="apartment-key-add-modal"
      ref="modal"
      @ok="onOk"
      :ok-title="$t('apartment.add_key_modal.ok_title')"
      :cancel-title="$t('apartment.add_key_modal.cancel_title')"
      :title="$t('apartment.add_key_modal.title')"
    >
      <BFormValidate
        :model="model"
        :validations="validations"
        ref="form"
        @submit="onOk"
      >
        <template slot-scope="{ validateState }">
          <div class="modal-body-row with-cols mb-3">
            <div class="title">
              {{ $t('apartment.add_key_modal.params_label') }}
            </div>
            <b-form-row>
              <b-col cols="6">
                <BSelectLabel
                  v-model="model.keySet"
                  :items="keySets"
                  :label="$t('apartment.add_key_modal.key_set')"
                  :state="validateState('keySet')"
                  no-padding-label
                  display="name"
                  @selected="getKeys()"
                />
              </b-col>
              <b-col cols="6">
                <BSelectSearch
                  :label="$t('apartment.add_key_modal.key_number')"
                  :format-result="formatSearchKeyResult"
                  :state="validateState('key')"
                  :handler="searchKey"
                  display-property="number"
                  :disabled="!model.keySet.id"
                  @select:item="eventSelectKey"
                />
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col cols="6">
                <BSelectSearch
                  :label="$t('apartment.add_key_modal.habitat')"
                  :format-result="formatSearchResult"
                  :state="validateState('user')"
                  :handler="searchUser"
                  @select:item="inputUser"
                  :default-options="habitats"
                  :disabled="!model.key.id"
                  :selected="(model.key && model.key.user) || {}"
                  :displayProperty="getUserDisplay"
                />
              </b-col>
            </b-form-row>
          </div>
        </template>
      </BFormValidate>
    </b-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { ApiBackendKeySets, ApiBackendUsers, ApiBackendKeys, ApiKeys } from '@/api';
import { getUserName } from '@/helpers';
import EventBus from '@/packs/EventBus';

function initData() {
  return {
    model: {
      keySet: '',
      key: '',
      user: '',
    },
  };
}

export default {
  name: 'AddKeyModal',
  inject: ['preloader'],
  props: {
    selectedGeoUnit: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      ...initData(),
      validations: {
        keySet: { required },
        key: {
          required,
          related_geo_units_ids: {
            isIncludeGeoUnitId: (val) => {
              if (!val) return true;
              return !val.includes(Number(this.geoUnitId));
            },
          },
        },
      },
      keySets: [],
      keys: [],
      habitats: [],
      geoUnitId: '',
      getUserName,
      formatSearchKeyResult: {
        top: [
          {
            title: 'number',
            formatter: (val) => val,
          },
          { title: 'id', formatter: (value) => `#${value}` },
        ],
        bottom: [
          {
            title: 'name',
            formatter: (val) => val,
          },
        ],
      },
      formatSearchResult: {
        top: [
          {
            title: 'name',
            formatter: (val, item) => getUserName(item),
          },
          { title: 'id', formatter: (value) => `#${value}` },
        ],
        bottom: [
          {
            title: 'contact',
            formatter: (val, item) => item.phone || item.email,
          },
        ],
      },
    };
  },
  methods: {
    getUserDisplay(user) {
      if (user.name || user.surname) {
        return `${user.name || ''} ${user.surname || ''}`;
      } else {
        return user.phone ? user.phone : user.email;
      }
    },
    show({ geoUnitId } = {}) {
      this.geoUnitId = geoUnitId || this.$route?.params.geo_unit_id;
      if (!this.geoUnitId) Error('no geoUnitId');
      this.getKeySets();
      this.getHabitats();
      Object.assign(this.$data, initData());
      this.$refs.modal.show();
    },
    getKeySets() {
      const client = this.$route.params.client_id;
      ApiBackendKeySets.getAll({ q: { client_id_eq: client } }).then((res) => {
        this.keySets = res;
      });
    },
    getHabitats() {
      ApiBackendUsers.getAll({
        q: { geo_units_users_geo_unit_id_eq: this.selectedGeoUnit.id },
      }).then((res) => {
        this.habitats = res;
      });
    },
    getKeys() {
      this.model.key = '';
    },
    searchUser(val) {
      const encoldeVal = encodeURIComponent(val);
      return ApiBackendUsers.getPagination({
        q: {
          name_or_surname_or_patronymic_or_email_or_phone_or_full_name_or_reverse_full_name_cont_any:
            encoldeVal,
          permissions_client_id_or_users_clients_client_id_eq:
            this.$route.params.client_id,
        },
        per_page: 10,
      }).then((resp) => resp.data);
    },
    searchKey(val) {
      return ApiBackendKeys.getPagination({
        q: {
          name_or_number_cont: val,
          keyset_id_eq: this.model.keySet.id,
        },
        per_page: 10,
        includes: ['user', 'related_geo_units'],
      }).then((resp) => resp.data);
    },
    inputUser(val) {
      this.model.user = val;
    },
    async onOk(event) {
      if (Object.prototype.hasOwnProperty.call(event, 'preventDefault')) {
        event.preventDefault();
      }
      const [validation, validationFull] = this.$refs.form.validate(true);
      if (validation) {
        this.preloader.show(
          this.$t('apartment.add_key_modal.key_binding_in_process'),
        );
        const { key, user } = this.model;

        // Prepare key model for update
        const related_geo_units_ids = [
          ...key.related_geo_units.map((gu) => gu.id),
          this.geoUnitId,
        ];
        key.user_id = (user && user.id) || null;

        await ApiKeys.update({ ...key, related_geo_units_ids })
          .then(() => {
            // Emit events to refresh keys list, hide preloader and modal
            EventBus.$emit('Apartment::key::added');
            this.$refs.modal.hide();
          })
          .catch((err) => {
            this.$bvToast.toast(err.error || err);
          });

        EventBus.$emit('preloader::hide');
      } else if (
        !validationFull.model.key.related_geo_units_ids.isIncludeGeoUnitId
      ) {
        this.$bvToast.toast(
          this.$t('apartment.add_key_modal.this_key_already_added'),
        );
      }
    },
    eventSelectKey(key) {
      this.model.key = key;
      this.inputUser(key.user);
    },
  },
};
</script>

<style scoped></style>
