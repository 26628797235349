<template>
  <div class="dropdown-menu dropdown-menu-right">
    <div class="dropdown-item pointer" @click="openPlateModal">
    {{ $t('button.edit') }}
    </div>
    <div class="dropdown-item pointer" @click="removePlate">
    {{ $t('button.delete') }}
    </div>
  </div>
</template>

<script>
import EventBus from '@/packs/EventBus';
import { request3 } from '@/api/request';
import { API_URLS } from '@/consts';
import { getManageId } from '@/helpers';

export default {
  name: "PlateActionsLinks",
  props: {
    plate: {
      type: Object,
      default: null
    },
  },
  methods: {
    openPlateModal() {
      EventBus.$emit('PlateModal::show', this.plate, false);
    },
    removePlate() {
      this.$bvModal
        .msgBoxConfirm(
          this.$t('plate.confirm_delete.message', [
            this.plate.plate,
          ]),
          {
            bodyClass: 'modal-body__confirm',
            footerClass: 'border-0',
            headerClass: 'border-0',
            okTitle: this.$t('button.delete'),
            okVariant: 'danger',
            title: this.$t('plate.confirm_delete.title'),
          },
        )
        .then((res) => {
          if (res) {
            const manageId = getManageId();
            request3.delete(API_URLS.npr.plates.one(this.plate.id, manageId))
              .then(() => {
                this.$bvModal.msgBoxOk(
                  this.$t('plate.confirm_delete.success'),
                  {
                    modalClass: 'modal-ok',
                    okTitle: this.$t('button.ok'),
                    title: this.$t('title.successfully'),
                  },
                );
                setTimeout(() => {
                  location.reload();
                }, 500);
              })
          }
        });
    }
  }
}
</script>