<template>
  <b-modal
    id="modal"
    ref="modal"
    centered
    modal-class="modal-request"
    header-class="modal-request__head modal-request__head_create"
    size="sm"
  >
    <template #modal-header>
      <div class="title modal-request__title modal-request__title_left">
        {{ $t('client_request.modal.denied_alert') }}
      </div>
    </template>

    <b-form-validate ref="form" :validations="validations" :model="model">
      <template slot-scope="{ validateState, v }">
        <div class="modal-body-row with-cols d-grid gap-3 py-0">
          <div class="modal-request__label">
            {{ $t('client_request.modal.comment_label') }}
          </div>
          <b-form-row>
            <b-form-textarea
              id="textarea"
              v-model="model.comment"
              :placeholder="$t('client_request.modal.comment_label')"
              rows="3"
              max-rows="6"
              :state="validateState('comment')"
              class="mb-0"
            ></b-form-textarea>
            <b-error
              v-if="!v.model.comment.required && isSubmit"
              :error="$t('devices.errors.not_empty')"
            />
          </b-form-row>
        </div>
      </template>
    </b-form-validate>

    <template #modal-footer="{ close }">
      <div class="d-flex gap-2">
        <b-button variant="secondary" @click="close">
          {{ $t('client_request.modal.cancel') }}
        </b-button>
        <b-button variant="primary" @click="requestDenied">
          {{ $t('client_request.modal.denied') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { BFormTextarea } from 'bootstrap-vue';
import { required } from 'vuelidate/lib/validators';
import BError from '@/components/base/BError';

export default {
  name: 'ClientRequestCommentModal',
  components: { BFormTextarea, BError },
  data() {
    return {
      model: {
        comment: '',
      },
      isSubmit: false,
    };
  },
  computed: {
    validations() {
      return {
        comment: { required },
      };
    },
  },
  methods: {
    requestDenied() {
      this.isSubmit = true;
      if (!this.$refs.form.validate()) {
        return;
      }

      this.$emit('requestDenied', this.model.comment);

      this.$refs.modal.hide();
    },
    show() {
      this.isSubmit = false;
      this.$refs.modal.show();
      this.model = Object.assign({}, { comment: '' });
    },
  },
};
</script>

<style></style>
